// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import unenrolledCompositeView  from '../../../../templates/CCM/Unenrolled/unenrolledCompositeView';
import UnenrolledItemView from './UnenrolledItemView';

// Internal Dependencies - models/collections
import { UnenrolledModel } from './models/UnenrolledModel';

export default Marionette.CompositeView.extend({

	className: 'unenrolled-composite-view',

	childViewContainer: '.patient-list-container',

	childView: UnenrolledItemView,

	events: {
		'click .clear-search': 'resetSearch',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.currentPage = 0;
		this.pageSize = 20;

		let searchObj = {
			placeholder: 'Search first name, last name, or PID'
		}

		this.channel.trigger( 'show:search', searchObj, this.collection );

		this.app.ccm_controller.getUnenrolledList();
		this.collection.loading = true;
		this.listenTo( this.channel, 'rerender', this.render );
		this.listenTo( this.channel, 'set:patientList', this.setUnenrolledPatients );
		this.listenTo( this.channel, 'reset:currentPage', this.resetCurrentPage );
		this.listenTo( this.collection, 'update', this.render );
		this.listenTo( this.channel, 'set:selectedProviderId', this.getUnenrolledPatients );

		this.channel.reply( 'currentPage', () => {
			return this.currentPage;
		});
		this.channel.reply( 'pageSize', () => {
			return this.pageSize;
		});
		$('.content-container').addClass('widen');
	},

	getTemplate: function() {
		return unenrolledCompositeView( this.collection );
	},

	getUnenrolledPatients: function() {
		this.collection.loading = true;
		this.render();
		this.app.ccm_controller.getUnenrolledList( 'reset:currentPage' )
	},

	setUnenrolledPatients: function() {
		this.collection.loading = false;
		this.render();
		this.collection.reset();
		let unenrolledList = this.app.ccm_controller.patientList;
		this.collection.renderNoResults = unenrolledList && unenrolledList.length ? false : true;
		let search = this.app.ccm_controller.searchInput
		this.collection.search = search ? search.substring( search.lastIndexOf("=") + 1 ) : '';
		this.collection.add( unenrolledList );
		!unenrolledList.length && this.render();
	},

	resetSearch: function() {
		this.channel.trigger( 'reset:search' );
	},

	resetCurrentPage: function() {
		this.currentPage = 0;
		this.app.ccm_controller.patients.currentPage = this.currentPage;
        this.channel.trigger( 'reset:totals' );
	},

	onDestroy: function() {
		this.channel.trigger( 'remove:search' );
		this.app.ccm_controller.clearValue( 'unenrolled' );
		this.app.ccm_controller.clearValue( 'sortedBy' );
		$('.content-container').removeClass('widen');
	},

});
