// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';

// Internal Dependencies - screens/views/templates
import activityFilterView  from '../../../../templates/MIPS/ImprovementActivities/activityFilterView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    events: {
        'click .fa-close': 'deselectActivity',
    },

    initialize: function() {
        this.listenTo( this.model, 'change:choices', this.render );
        this.listenTo( this.model, 'change:selectedActivity', this.toggleSelectActivity );
        this.listenTo( this.model, 'change:showHighValues', this.render );
        this.listenTo( this.model, 'change:showLowValues', this.render );
    },

    getTemplate: function() {
        return activityFilterView( this.model );
    },

    toggleSelectActivity: function() {
        $( '.selected' ).removeClass( 'selected' );
        if ( this.model.get( 'selectedActivity' ) ) {
            $( `#${ this.model.get( 'selectedActivity' ).id }` ).addClass( 'selected' );
        }
    },

    deselectActivity: function() {
        this.model.deselectActivity();
    },

});
