// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';

// Internal Dependencies - screens/views/templates
import overviewContainerView  from '../../../../templates/MIPS/ImprovementActivities/overviewContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );

        this.listenTo( this.model, 'change', this.checkDif );
    },

    getTemplate: function() {
        return overviewContainerView( this.model );
    },

    onShow: function() {
        this.renderSummaryChart();
    },

    onDomRefresh: function() {
        this.renderSummaryChart();
    },

    checkDif: function() {
        if ( this.model.changed.hasOwnProperty( 'measureData' ) ) {
            this.render();
        }
    },

    renderSummaryChart: function() {
        let data = [];
        if ( this.model.get( 'measureData' ).points_available && this.model.get( 'measureData' ).points_earned ) {
            data = [
                {
                    name: 'Points earned',
                    y: this.model.get('measureData').points_earned || 0,
                    color: this.model.colors[this.model.get('measureData').score_threshold],
                },
                {
                    name: 'Points needed',
                    y: ( this.model.get('measureData').points_available - this.model.get('measureData').points_earned ) || 0,
                    color: this.model.colors['blue'],
                },
            ];
        }
        if ( !data.length ) {
            data = [
                {
                    name: 'Points achieved',
                    y: 0,
                },
                {
                    name: 'Points needed',
                    y: 40,
                    color: this.model.colors[ 'gray' ],
                },
            ];
        }
        let progressChart = Highcharts.chart('progressChartContainer', {
            chart: {
                height: 250,
                marginTop: 0,
            },
            title: {
                text: `Improvement Points Achieved`,
                style: {
                    color: '#505050',
                }
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        format: '<b>{point.y}</b>',
                    },
                    startAngle: -91,
                    endAngle: 90,
                    center: ['50%', '80%'],
                    showInLegend: true,
                    size: 200
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: data,
            }],
            credits: {
                enabled: false,
            },
        });
    },
});
