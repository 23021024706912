import Backbone from 'backbone';
import Marionette from 'backbone.marionette';
import moment from 'moment';
import _ from 'underscore';

export const ReportsModel = Backbone.Model.extend({

	defaults: {
		startDay: 1,
		startMonth: moment().format('M'),
		startYear: moment().format('YYYY'),
		startStamp: moment(`${moment().format('YYYY')}-${moment().format('MM')}-01`,'YYYY-MM-DD').valueOf(),
		startMonthRange: _.range( moment().daysInMonth() ),
		endDay: moment().format('D'),
		endMonth: moment().format('M'),
		endYear: moment().format('YYYY'),
		endStamp: moment().add(1,'day').valueOf(),
		endMonthRange: _.range( moment().daysInMonth() ),
		billingBreakdown: {
			billed_99490: { label: 'Success', value: 0 },
			billed_G0511:{label: 'Success', value: 0},
			failedAndRejected: { label: 'Failed', value: 0 },
			incomplete: { label: 'Incomplete', value: 0 },
		},
		estimatedTotal: 0,
	},


	getYears: function() {
		const currentYear = new Date().getFullYear(), availableYears = [];
		let startYear = 2017;

		while ( startYear <= currentYear ) {
			availableYears.push(startYear++);
		}
		this.set( { availableYears } );
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.getYears();
		this.colors = [
			'#169E54',
			'rgb(41, 171, 221)',
			'#A01313',
			'#8084D6',
			'#4FACAF',
		]
		this.colorMap = {
			'G0511':'#169E54',
			'99490':'rgb(41, 171, 221)',
			'Failed':'#A01313',
			'Incomplete':'#8084D6',
		}
	},

	updateStamps: function() {
		let startStamp = moment( this.get('startYear') + '-' + this.get('startMonth') + '-' + this.get('startDay'), 'YYYY-M-D').valueOf();
		let endStamp = moment( this.get('endYear') + '-' + this.get('endMonth') + '-' + this.get('endDay') + ' 24:00:00', 'YYYY-M-D hh:mm:ss').valueOf();
		this.set({ startStamp, endStamp });
	},

});
