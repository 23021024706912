// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  providerApprovalItemView  from '../../../../templates/CCM/ProviderApproval/providerApprovalItemView';
import DeclineProviderApprovalModalView from './DeclineProviderApprovalModalView';
// Internal Dependencies - models/collections
import { DeclineProviderApprovalModel } from './models/DeclineProviderApprovalModel';
import ProviderApprovalConfirmationModelView from "./ProviderApprovalConfirmationModelView";

export default Marionette.ItemView.extend({

  tagName: 'tr',

  events: {
    'click #approveProvider': 'approve',
    'click #declineProvider': 'decline'
  },

  getTemplate: function() {
    return providerApprovalItemView( this.model );
  },

  approve: function( e ) {
    e.preventDefault();
    let modalView = new ProviderApprovalConfirmationModelView({ model: this.model});
    modalView.render();
    $( 'body' ).append( modalView.el );
    $( '#modalReject' ).openModal();
    // this.model.approveProvider(()=>{
    //
    // });
  },

  decline: function() {
    // this.model.declineProvider();

    let declineProviderApprovalModel = new DeclineProviderApprovalModel( this.model.attributes );
    declineProviderApprovalModel.set( 'request_id', this.model.get( 'id' ) );
    // unenrollPatientModel.set( 'unenroll_reason_options', data );
    let modalView = new DeclineProviderApprovalModalView({ model: declineProviderApprovalModel});
    modalView.render();
    $( 'body' ).append( modalView.el );
    $( '#modalReject' ).openModal();
  }

});
