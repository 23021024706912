// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import addProviderButtonView  from '../../../../templates/Utilities/ManageUsers/addProviderButtonView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: 'add-provider-button mbm',

	getTemplate: function() {
		return addProviderButtonView( this.model );
	},
});
