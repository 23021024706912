// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';

// Internal Dependencies - screens/views/templates
import reviewBillingModalView  from '../../../../../templates/CCM/RapidBill/PendingReview/reviewBillingModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'click .cancel': 'closeModal',
		'click #saveBilling': 'updateBilling',
		'change #icd10code_1': 'checkSelectedCode',
		'change #icd10code_2': 'checkSelectedCode',
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
		this.listenTo( this.channel, 'trigger:show', this.showTriggered );
		this.listenTo( this.channel, 'updateBillingInfo:failure', this.flipText );
	},

	getTemplate: function() {
		return reviewBillingModalView( this.model );
	},


	checkSelectedCode: function( e ) {
		if ( e.target.id == 'icd10code_1' ) {
			this.selectedCode1 = e.target.value;
			return;
		}
		if ( e.target.id == 'icd10code_2' ) {
			this.selectedCode2 = e.target.value;
			return;
		}
	},

	showTriggered: function() {
		let errors = this.model.get( 'errors' );
		_.each( errors, ( error ) => $( '#' + error.field ).addClass( 'invalid' ) );
		const self = this;
		this.selectedCode1 = '';
		this.selectedCode2 = '';
		$( 'select' ).material_select();
		$('#icd10code_1').devbridgeAutocomplete({
			serviceUrl: `${ this.app.controller.engageUrl }v1/codelookup/icd10?start=0`,
			onSelect: function( suggestion ) {
				self.selectedCode1 = suggestion.id;
			},
			paramName: 'search',
	    	maxHeight: 150,
	    	ajaxSettings: {
	    		headers: {
	    			'X-AUTH-TOKEN': this.app.controller.getToken()
	    		}
	    	},
	    	transformResult: function( data ) {
	    		let response = _.reject( JSON.parse( data ), ( item ) => item.id == self.selectedCode2 );
	    		return {
	    			suggestions: response.map( ( item ) => {
	    				return { value: `${ item.id } - ${ item.desc }`, id: item.id }
	    			})
	    		}
	    	},
	    	autoSelectFirst: true,
	    	noCache: true,
	    });
	    $('#icd10code_2').devbridgeAutocomplete({
	    	serviceUrl: `${ this.app.controller.engageUrl }v1/codelookup/icd10?start=0`,
			onSelect: function( suggestion ) {
				self.selectedCode2 = suggestion.id;
			},
			paramName: 'search',
	    	maxHeight: 150,
	    	ajaxSettings: {
	    		headers: {
	    			'X-AUTH-TOKEN': this.app.controller.getToken()
	    		}
	    	},
	    	transformResult: function( data ) {
	    		let response = _.reject( JSON.parse( data ), ( item ) => item.id == self.selectedCode1 );
	    		return {
	    			suggestions: response.map( ( item ) => {
	    				return { value: `${ item.id } - ${ item.desc }`, id: item.id }
	    			})
	    		}
	    	},
	    	autoSelectFirst: true,
	    	noCache: true,
	    });
	},

	addValidations: function() {

	},

	flipText: function() {
		$('#saveBilling').html('Save');
	},

	closeModal: function() {
		$( '#modalReview' ).closeModal();
		this.destroy();
	},

	updateBilling: function( e ) {
		e && e.preventDefault();
		this.model.updateBillingInfo( 'REVIEW' );
	}

});
