import Backbone from 'backbone';
import _ from 'underscore';

export const BulkProviderTransitionModel = Backbone.Model.extend({

    initialize: function( options ) {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.controller = this.app.utilities_controller;
        this.controller.getProviders();
        this.listenTo(this.channel, 'set:bulkChangeProviders', this.setBulkChangeProviders);
        this.listenTo( this.channel, 'getUnsuspendCount:fail', (error)=>{
            if(error.status == 400){
                const errorMessage = error.responseJSON.message;
                this.set({errorMessage});
                this.trigger( 'set:currentProviderDetail' );
            }
        } );
    },

    setBulkChangeProviders: function() {
        let bulkChangeProviders = this.controller.bulkChangeProviders;
        this.set( { bulkChangeProviders:bulkChangeProviders,
            bulkChangeProvidersCurrent:bulkChangeProviders,bulkChangeProvidersTarget:bulkChangeProviders } );
        this.trigger( 'set:bulkChangeProviders' );
    },

    setSelectedCurrentProvider: function(selectedCurrentProvider) {
        const bulkChangeProviders = this.get('bulkChangeProviders');
        const resultArray = _.filter(bulkChangeProviders,(provider)=>{
            return provider.id == selectedCurrentProvider;
        });

        if(resultArray.length > 0){
            this.controller.getProviderTransitionDetail(resultArray[0],(data)=>{
                this.set({errorMessage:''});
                this.set({selectedCurrentProvider});
                this.set({currentNumberOfPatient:data.numberOfPatient});
                this.set({currentBillingName:data.billingName});
                this.set({currentProviderName:data.providerName});
                const newTargetProviders = [];
                _.each(bulkChangeProviders,(provider)=>{
                    if(provider.id == -1 || provider.id != selectedCurrentProvider){
                        newTargetProviders.push(provider);
                    }
                });
                this.set({bulkChangeProvidersTarget:newTargetProviders});

                this.trigger( 'set:currentProviderDetail' );
            });
        }

    },
    bulkProviderUpdate: function(callback){
        this.controller.bulkProviderTransition(
            this.get('selectedCurrentProvider'),
            this.get('selectedDate'),
            (data)=>{
                this.set('selectedCurrentProvider',-1);
                this.set('currentNumberOfPatient','');
                this.set('currentBillingName','');
                this.set('currentProviderName','');
                this.trigger( 'set:bulkChangeProviders' );
                callback(data);
            }
        );
    }
});