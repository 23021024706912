// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import userItemView  from '../../../../templates/Utilities/ManageUsers/userItemView';
import EditUserModalView from './EditUserModalView';
import DeleteUserModalView from './DeleteUserModalView';

// Internal Dependencies - models/collections
import { UserModel } from './models/UserModel';

export default Marionette.ItemView.extend({

	tagName: 'tr',

	events: {
		'click .edit-user': 'launchEditModal',
		'click .delete-user': 'launchDeleteModal',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
	},

	getTemplate: function() {
		return userItemView( this.model );
	},

	launchEditModal: function() {

		// Lauch edit modal using the UserModel from this row
		let modalView = new EditUserModalView( { model: this.model } );
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalEditUser' ).openModal();

		// Trigger the listener in 'EditUserModalView' that triggers the equivalent of an onShow for the modal
		this.channel.trigger( 'EditUserView:show' );
	},

	launchDeleteModal: function() {

		// Launch delete modal using the UserModel from this row
		let modalView = new DeleteUserModalView( { model: this.model } );
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalDeleteUser' ).openModal();
	}

});
