import Backbone from 'backbone';

export const AdvancingCareModel = Backbone.Model.extend({

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.colors = {
            'FAIL': '#A01313',
            'WARN': '#f57b3e',
            'blue': '#1080AF',
            'PASS': '#169E54',
            'gray': '#C6C6C6',
        };
        this.listenTo( this.channel, 'set:advancingSummaryBaseScore', this.setSummaryData );
	    this.listenTo( this.channel, 'success:saveMeasureData', this.resetNumAndDen );
    },

    getMeasureMetaData: function( criteria, useSecondaryCriteria = false ) {
        this.app.mips_controller.getMeasureMetaData( this.get( 'selected_criteria' ).name, useSecondaryCriteria );
    },

    getMeasureData: function( criteria, useSecondaryCriteria = false ) {
        if ( useSecondaryCriteria ) {
            this.app.mips_controller.getMeasureData( this.get( 'secondary_selected_criteria' ).id, useSecondaryCriteria );
            return;
        }

        this.app.mips_controller.getMeasureData( this.get( 'selected_criteria' ).id );
    },

    saveMeasureData: function() {
        this.app.mips_controller.saveMeasureData( this.get( 'data' ), this.get( 'selected_criteria' ).id );

        if ( this.get( 'secondary_selected_criteria' ) ) {
            let useSecondaryCriteria = true;
            this.app.mips_controller.saveMeasureData( this.get( 'secondary_data' ), this.get( 'secondary_selected_criteria' ).id, useSecondaryCriteria );
        }
    },

    getCriteriaSelections: function( category ) {
        this.app.mips_controller.getCriteriaSelections( category );
    },

    getSummaryData: function() {
        this.app.mips_controller.getAdvancingCareSummary( false );
    },

    setSummaryData: function() {
        const summaryData = this.app.mips_controller.advancingSummaryBaseScore;
        this.set({
            summaryData
        });
    },

	resetNumAndDen: function() {
		this.set({
			numerator: '',
			denominator: '',
		});
	},
});
