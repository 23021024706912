import Backbone from 'backbone';
import Marionette from 'backbone.marionette';
import _ from 'underscore';

import { RapidBillModel } from '../../models/RapidBillModel';

export const ErrorCollection = Backbone.Collection.extend({

	model: RapidBillModel,

    modelId: function( attrs ) {
        return attrs.billing_id;
    },

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'check:selected', this.checkSelected );
	},

	selectAll: function( shouldSelect ) {
		// shouldSelect will be a boolean value to determine whether all patients should be selected or not
		this.each( ( patient ) => {
			!patient.get( 'rejected' ) && patient.set( 'selected', shouldSelect );
		});
	},

	checkSelected: function() {
		let allSelected = this.length !== 0;
		let noneSelected = true;
		let submitBilling = $( '#submitBilling' );
		let manualBilling = $( '#manualBilling' );
		this.each( ( patient ) => {
			if ( !patient.get( 'selected' ) ) {
				allSelected = false;
				return;
			}
			noneSelected = false;
		});

		// All patients are selected - check "select all" checkbox and remove disabled class from "submit billing" button
		if ( this.length && allSelected ) {
			$( '#selectAll' ).prop( 'checked', true );
			submitBilling.removeClass( 'disabled' );
			manualBilling.removeClass( 'disabled' );
			return;
		}

		// Not all patients are selected - remove check from "select all" checkbox and proceed to further conditionals
		if ( !allSelected ) {
			$( '#selectAll' ).prop( 'checked', null );
		}

		// No patients are selected - disable "submit billing" button
		if ( noneSelected ) {
			submitBilling.addClass( 'disabled' );
			manualBilling.addClass( 'disabled' );
			return;
		}

		// At least one patient is selected - enable "submit billing" button
		if ( !noneSelected ) {
			submitBilling.removeClass( 'disabled' );
			manualBilling.removeClass( 'disabled' );
		}
	},

	submitBilling: function() {
		let rapidBillingPatients = this.app.ccm_controller.rapidBilling || [];
		let billingList = [];
		this.forEach( ( patient ) => {
			if ( patient.get( 'selected' ) ) {
				patient = _.find( rapidBillingPatients, ( nestedPatient ) => nestedPatient.id == patient.get( 'billing_id' ) );
				if ( patient ) {
					patient.billable_status = 'CHARGE';
					patient.billing_attempts = 0;
					billingList.push( patient );
					return;
				}
			}
		});
		billingList.length && this.app.ccm_controller.submitBilling( billingList, 'ERROR' );
		$('#confirmSummary').html('Confirm...');
	},

	selectedBillsCount: function() {
		return this.filter( ( patient ) => patient.get( 'selected' )).length;
	},

	downloadCSV: function( billable_status ) {
		this.app.ccm_controller.getRapidBillingQueueCSV( billable_status );
	}

});
