// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import searchContainerView from '../../../templates/Filtering/searchContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: 'input-field search-container',

	tagName: 'form',

	id: 'searchForm',

	events: {
		'keyup': 'whichKey',
		'click .fa-search': 'search',
		'click .clear-search': 'resetSearch',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.currentPage = this.channel.request( 'currentPage' );
		this.pageSize = this.channel.request( 'pageSize' );

		this.listenTo( this.channel, 'changed:currentPage', this.setCurrentPage );
		this.listenTo( this.channel, 'reset:search', this.resetSearch );
	},

	getTemplate: function() {
		return searchContainerView( this.model );
	},

	search: function( e ) {
		this.collection.loading = true;
		this.channel.trigger('rerender');
		e && e.preventDefault();
		let searchType = this.app.controller.currentApp + ' ' + this.app.controller.subnavItem;
		let searchInput = this.$( '#search' ).val();
		this.channel.trigger( 'reset:currentPage' );
		this.determineSearchType( searchType, searchInput );
		if ( !$(window).scrollTop() == 0 ) {
			$(window).scrollTop( 0 );
		}
		$( '#search' ).blur();
	},

	resetSearch: function() {
		this.collection.loading = true;
		this.channel.trigger('rerender');
		let searchType = this.app.controller.currentApp + ' ' + this.app.controller.subnavItem;
		this.channel.trigger( 'reset:currentPage' );
		this.determineSearchType( searchType, '' );
		if ( !$(window).scrollTop() == 0 ) {
			$(window).scrollTop( 0 );
		}
		$( '#search' ).val( '' );
		$( '#search' ).blur();
	},

	determineSearchType: function( searchType, searchInput ) {
		let searchField;
		switch( searchType ) {
			case 'CCM OptedOut':
				let searchField = searchInput ? 'search=' + searchInput : '';
				this.app.ccm_controller.searchOptedoutPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'CCM Support':
				searchField = searchInput ? 'search=' + searchInput : '';
				this.app.ccm_controller.searchSupportPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'CCM Unverified':
				searchField = searchInput ? 'search=' + searchInput : '';
				this.app.ccm_controller.searchUnverifiedPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'CCM PatientList':
				searchField = searchInput ? 'search=' + searchInput : '';
				this.app.ccm_controller.searchPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'CCM PendingUnenroll':
				searchField = searchInput ? 'search=' + searchInput : '';
				this.app.ccm_controller.searchPendingUnenrolledPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'CCM AutoEnroll':
				searchField = searchInput ? 'search=' + searchInput : '';
				this.app.ccm_controller.searchAutoPendingPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'CCM Unenrolled':
				searchField = searchInput ? 'search=' + searchInput : '';
				this.app.ccm_controller.searchUnenrolledPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'CCM Enrolled':
				searchField = searchInput ? 'search=' + searchInput : '';
				this.app.ccm_controller.searchEnrolledPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'CCM Suspended':
				searchField = searchInput ? 'search=' + searchInput : '';
				this.app.ccm_controller.searchSuspendedPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'CPC PatientList':
				searchField = searchInput ? 'search=' + searchInput : '';
				this.app.cpc_controller.searchPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'UTILITIES ManageUsers':
				searchField = searchInput ? 'search=' + searchInput : '';
				this.app.utilities_controller.searchUsers( this.currentPage, this.pageSize, searchField );
				break;
			case 'MAIN Notifications':
			case 'MAIN ArchivedNotifications':
				searchField = searchInput ? 'searchText=' + searchInput : '';
				this.app.main_controller.searchNotifications( this.currentPage, searchField );
				break;
		  case 'CCM ProviderApproval':
			  searchField = searchInput ? 'search=' + searchInput : '';
			this.app.ccm_controller.searchProviderApproval( this.currentPage, this.pageSize, searchField );
			break;
			case 'CCM ProviderTransition':
				searchField = searchInput ? 'search=' + searchInput : '';
				this.app.ccm_controller.searchProviderTransition( this.currentPage, this.pageSize, searchField );
				break;
			case 'PATIENTENROLLMENT RapidReview':
				this.app.ccm_controller.searchInsuranceValue = searchInput;
				this.channel.trigger( 'reset:searchInsuranceValue' );
				break;
			default:
				console.log('THIS SHOULD NOT HAPPEN. CHECK SEARCH CONTAINER VIEW');
				searchField = searchInput ? 'search=' + searchInput : '';
				this.app.ccm_controller.searchEnrolledPatients( this.currentPage, this.pageSize, searchField );
			}
	},

	whichKey: function( e ) {
		if ( e.keyCode == 13 ) {
			this.search();
		}
	},

	setCurrentPage: function( page ) {
		this.currentPage = page;
	},

});
