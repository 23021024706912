// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import  rapidBillCompositeView  from '../../../../../templates/CCM/RapidBill/Approval/rapidBillCompositeView';
import RapidBillItemView from './RapidBillItemView';
import ProviderInfoModalView from './ProviderInfoModalView'

// Internal Dependencies - models/collections
import { RapidBillCollection } from './models/RapidBillCollection';
import { RapidBillModel } from '../models/RapidBillModel';

export default Marionette.CompositeView.extend({

	childView: RapidBillItemView,

	childViewContainer: '.rapid-bill-collection-container',

	className: 'rapid-bill-composite-view striped',

	events: {
		'click #selectAll': 'selectAll',
		'click .download.csv': 'downloadCSV',
		'click .clear-search': 'resetSearch',
		'click #providerInfoId':'providerInfo'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.currentPage = 0;
		this.pageSize = 20;
		this.listenTo( this.channel, 'set:patientList', this.setApprovalList );
		this.listenTo( this.channel, 'submitBilling:success', this.indicateLoading );
		this.listenTo( this.collection, 'update', this.render );
		this.listenTo( this.channel, 'rerender', this.render );
		this.listenTo( this.channel, 'set:selectedProviderId', this.getApprovalList );
		this.listenTo( this.channel, 'reset:currentPage', this.resetCurrentPage.bind( this ) );
		this.collection.loading = true;
		this.channel.reply( 'currentPage', () => {
			return this.currentPage;
		});
		this.channel.reply( 'pageSize', () => {
			return this.pageSize;
		});

		$('.content-container').addClass('widen');
	},

	getTemplate: function() {
		return rapidBillCompositeView( this.collection );
	},

	getApprovalList: function() {
		this.collection.loading = true;
		this.render();
		this.app.ccm_controller.getRapidBilling('reset:currentPage');
	},

	setApprovalList: function() {
		this.collection.loading = false;
		this.channel.trigger( 'show:contentSpinner' );
		this.collection.reset();
		let approvalList = this.app.ccm_controller.patientList;
		approvalList = _.map( approvalList, ( patient ) => new RapidBillModel( patient ) );
		this.collection.renderNoResults = approvalList && approvalList.length ? false : true;
		let search = this.app.ccm_controller.searchInput;
		this.collection.search = search ? search.substring( search.lastIndexOf("=") + 1 ) : '';
		this.collection.add( approvalList );
		this.channel.trigger( 'set:rapidBilling', this.collection );
		this.collection.checkSelected();
		this.channel.trigger( 'hide:contentSpinner' );
		approvalList.length && $( '.has-billable-patients' ).removeClass( 'hide' );
		!approvalList.length && this.render();
	},

	resetSearch: function() {
		this.channel.trigger( 'reset:search' );
	},

	providerInfo: function() {
		let modalView = new ProviderInfoModalView({ collection: this.collection });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalSummary' ).openModal();
	},

	resetCurrentPage: function() {
		this.currentPage = 0;
		this.app.ccm_controller.patients.currentPage = this.currentPage;
		this.channel.trigger( 'reset:totals' );
	},

	selectAll: function() {
		let shouldSelect = $( '#selectAll' ).prop( 'checked' );
		this.collection.selectAll( shouldSelect );
	},

	downloadCSV: function() {
		let billable_status = 'APPROVE';
		this.collection.downloadCSV( billable_status );
	},

	indicateLoading: function() {
		this.collection.loading = true;
		this.render();
	},

	onDestroy: function() {
		this.channel.trigger( 'remove:search' );
		this.app.ccm_controller.clearValue( 'patientList' );
		$('.content-container').removeClass('widen');
	},

});
