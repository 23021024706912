// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';

// Internal Dependencies - screens/views/templates
import reportingContainerView  from '../../../../templates/PatientEnrollment/Reporting/reportingContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    getTemplate: function() {
        return reportingContainerView( this.model );
    },

    onShow: function() {
        this.renderSummaryChart();
    },

    renderSummaryChart: function() {
        let data = [
            {
                name: 'Awaiting Approval',
                y: 50,
                color: '#019547',
            },
            {
                name: 'Needs Attention',
                y: 25,
                color: '#1C81AD',
            },
            {
                name: 'Declined',
                y: 25,
                color: '#A01313',
            },
        ];
        let patientBreakdownChartContainer = Highcharts.chart('patientBreakdownChartContainer', {
            title: {
                text: null
            },
            tooltip: {
                pointFormat: '<b>{point.y}%</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -70,
                        formatter: function() {
                            return `${ this.percentage }%`;
                        },
                    },
                    showInLegend: true,
                }
            },
            legend: {
                layout: 'vertical',
                itemMarginBottom: 5,
            },
            series: [{
                type: 'pie',
                data: data,
                startAngle: 135,
            }],
            credits: {
                enabled: false,
            },
        });
    },

});
