// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import mipsNewDashboardContainerView  from '../../../../templates/MIPS/MIPS/mipsNewDashboardContainerView';

// Internal Dependencies - models/collections
import { DashboardModel } from './models/DashboardModel'
import WorksheetView from "./WorksheetView";
import ScoreView from './ScoreView';

export default Marionette.LayoutView.extend({

	regions: {
		'worksheetRegion': '#worksheetContainer',
		'scoreRegion': '#scoreRegion',
	},

	className: 'mips-new-dashboard',

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new DashboardModel();
	},

	onShow: function() {
		$('.content-container').addClass('widen');
		this.showChildView( 'worksheetRegion', new WorksheetView( { model: this.model } ) );
		this.showChildView( 'scoreRegion', new ScoreView( { model: this.model } ) );
	},

	getTemplate: function() {
		return mipsNewDashboardContainerView( this.model );
	},

	onDestroy: function() {
		$('.content-container').removeClass('widen');
	},

});
