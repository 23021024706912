// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';

// Internal Dependencies - screens/views/templates
import ccmChartsContainerView  from '../../../../templates/Main/Dashboard/ccmChartsContainerView';

// Internal Dependencies - models/collections
import { CcmDashboardModel } from './models/CcmDashboardModel';
import moment from "moment";

export default Marionette.ItemView.extend({
	events: {
		'click #rapidBillLink': 'gotoRapidBill',
		'click #revenueDashboardLink': 'gotoRevenueDashboard',
		'click #enrolledPatient': 'gotoEnrolledPatient',
		'click #unverifiedPatient' : 'gotoUnverifiedPatient',
		'click #locationRevenue':'gotoDetailLocationRevenue'
	},
	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new CcmDashboardModel();
		this.loading = true;
		this.listenTo( this.channel, 'set:mainDashboardData', this.setDataToModel );
		this.listenTo( this.channel, 'set:selectedProviderId', this.getData );
		this.listenTo( this.channel, 'set:rapidBillingRevenue', this.updateModel );
		this.listenTo( this.channel, 'set:locationDashboardData', this.setDataToModelLocation );
		this.listenTo( this.model, 'change', this.render );
		const currentYear = moment().format('YYYY');
		this.model.set({currentYear})
		this.getData();

		$('.content-container').addClass('widen');
	},

	getTemplate: function() {
		return ccmChartsContainerView( this.model );
	},
	updateModel: function() {
		let billingBreakdown = this.app.ccm_controller.rapidBillingBreakdown;
		let estimatedRevenue = this.app.ccm_controller.rapidBillingRevenue && parseFloat(this.app.ccm_controller.rapidBillingRevenue).toFixed(2);
		const count99490 = billingBreakdown['billed_99490']?billingBreakdown['billed_99490'].value:0;
		const countG0511 = billingBreakdown['billed_G0511']?billingBreakdown['billed_G0511'].value:0;
		const orgCPTCode = billingBreakdown['cptCode'];
		let cptCodeText = '';
		if(count99490 && countG0511){
			cptCodeText = 'CPT Code 99490 and CPT G0511'
		}else if(count99490){
			cptCodeText = 'CPT Code 99490'
		}else if(countG0511){
			cptCodeText = 'CPT Code G0511'
		}else{
			cptCodeText = `CPT Code ${orgCPTCode}`
		}
		this.model.set( { estimatedRevenue, billingBreakdown,cptCodeText } );
	},
	onDomRefresh: function() {
		this.model.get( 'enrolled' ) === 0 && $('#patientsWorkedChart').html('<p class="chart-string-value cs-blue-darkened mtl center">You currently do not have any enrolled patients</p>');
		this.renderCharts();

	},

	getData: function() {
		this.model.loading = true;
		let user = this.app.controller && this.app.controller.user;
		this.model.set({
			revenueVisible:user.revenueVisible})
		this.render();
		if(this.model.get("revenueDashboardType") != 'Location'){
			this.app.controller.selectedProviderId && this.app.main_controller.getCcmDashboard();
			let startStamp = moment(`${moment().format('YYYY')}-${moment().format('MM')}-01`,'YYYY-MM-DD').valueOf();
			let endStamp = moment().add(1,'day').valueOf();
			this.app.ccm_controller.getBillableReportSummary( startStamp, endStamp);
		}else{
			this.app.controller.selectedProviderId && this.app.main_controller.getLocationBillingDashboard();
			let startStamp = moment(`${moment().format('YYYY')}-${moment().format('MM')}-01`,'YYYY-MM-DD').valueOf();
			let endStamp = moment().add(1,'day').valueOf();
			this.app.ccm_controller.getBillableReportSummary( startStamp, endStamp);
		}

	},

	setDataToModelLocation: function (){
		this.model.loading = false;
		this.render();
		const data = this.app.main_controller.locationDashboardData;
		console.log("Data:",data);
		const cptData = {};
		let totalMonthly99490 = 0;
		let totalMonthlyG0511 = 0;
		let totalYearly99490 = 0;
		let totalYearlyG0511 = 0;
		let total99490Enrolled = 0;
		let totalG0511Enrolled = 0;

		let total99490Reimbursment = 0;
		let total99490Fees = 0;
		let totalG0511Fees = 0;
		let totalG0511Reimbursment = 0;
		let totalCurrentlyWorked = 0;
		let totalSuspeneded = 0;
		let totalConsentedCount = 0;
		let totalProvider_transition_count = 0;
		let total = 0;
		for(let i=0; i<data.length; i++){
			const dataRow = data[i];
			dataRow.totalReimbursment = parseFloat(dataRow.currently_enrolled)*parseFloat(dataRow.reimbursement);
			dataRow.totalFees = parseFloat(dataRow.currently_enrolled)*parseFloat(dataRow.fee);
			if(dataRow.cpt_code == "G0511"){
				totalMonthlyG0511 += parseFloat(dataRow.monthly_revenue);
				totalYearlyG0511 += parseFloat(dataRow.annual_revenue);
				totalG0511Enrolled += parseFloat(dataRow.currently_enrolled);
				totalG0511Reimbursment += dataRow.totalReimbursment;
				totalCurrentlyWorked += parseFloat(dataRow.currently_worked);
				totalConsentedCount += parseFloat(dataRow.currently_unverified);
				totalSuspeneded += parseFloat(dataRow.currently_suspended_count);
				totalProvider_transition_count += parseFloat(dataRow.currently_provider_transition_count);
				totalG0511Fees += dataRow.totalFees;
			}else if(dataRow.cpt_code == "99490"){
				totalMonthly99490 += parseFloat(dataRow.monthly_revenue);
				totalYearly99490 += parseFloat(dataRow.annual_revenue);
				total99490Enrolled += parseFloat(dataRow.currently_enrolled);
				total99490Reimbursment += dataRow.totalReimbursment;
				totalCurrentlyWorked += parseFloat(dataRow.currently_worked)
				totalConsentedCount += parseFloat(dataRow.currently_unverified);
				totalSuspeneded += parseFloat(dataRow.currently_suspended_count);
				totalProvider_transition_count += parseFloat(dataRow.currently_provider_transition_count);
				total99490Fees += dataRow.totalFees;
			}
			total += parseFloat(dataRow.annual_revenue);
		}

		let totalMonthly99490Profit = total99490Reimbursment - total99490Fees;
		let totalMonthlyG0511Profit = totalG0511Reimbursment - totalG0511Fees;
		let totalEnrolled = total99490Enrolled + totalG0511Enrolled;
		totalCurrentlyWorked = totalCurrentlyWorked ? totalCurrentlyWorked : 0;
		let totalUnworked = totalEnrolled - totalCurrentlyWorked;
		this.model.set({
			totalEnrolled,
			totalUnworked,
			unworked:totalUnworked,
			totalWorked:totalCurrentlyWorked,
			worked:totalCurrentlyWorked,
			totalProvider_transition_count:totalProvider_transition_count?totalProvider_transition_count:0,
			totalConsentedCount:totalConsentedCount?totalConsentedCount:0,
			totalSuspeneded:totalSuspeneded?totalSuspeneded:0,
			total99490Enrolled,
			totalG0511Enrolled,
			totalG0511Fees,
			total99490Fees,
			total99490Reimbursment,
			totalG0511Reimbursment,
			totalMonthly99490Profit,
			totalMonthlyG0511Profit,
			totalMonthly99490:`$${Number( totalMonthly99490 ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			totalMonthlyG0511: `$${Number( totalMonthlyG0511 ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			totalYearly99490:`$${Number( totalYearly99490 ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			totalYearlyG0511: `$${Number( totalYearlyG0511 ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			total: `$${Number( total ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			revenueData: data
		});
	},
	setDataToModel: function() {
		this.model.loading = false;
		this.render();
		const data = this.app.main_controller.mainDashboardData;

		this.model.set({
			enrolled: data.currently_enrolled,
			monthlyEnrolledRevenue: `$${Number( data.monthly_revenue ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			annualEnrolledRevenue: `$${Number( data.annual_revenue ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			monthly_reimbursement:`$${Number( data.monthly_reimbursement ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			monthly_cost: `$${Number( data.monthly_cost ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			monthly_potential_reimbursement:`$${Number( data.monthly_potential_reimbursement ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			worked: data.worked,
			unworked: data.unworked,
			unverified: data.unverified_count,
			suspended_count:data.suspended_count,
			provider_transition_count:data.provider_transition_count,
			optout: data.optedout_count,
			consented: data.consented_count,
			medicareCptCode:this.app.controller.selectedOrganization.medicareCptCode
		});
	},

	renderCharts: function() {
		if(this.model.get('revenueVisible') === undefined){
			return ;
		}
		if(this.model.get("revenueDashboardType") != 'Location'){
			if(this.model.get('revenueVisible')) {
				this.patientsWorkedChart();
			}
			this.patientsWorkedChartForlocation();
			this.patientsBilled();
		}else{
			if(this.model.get('revenueVisible')) {
				this.patientsWorkedChartLocation();
			}
			this.patientsWorkedChartForlocation();
			this.patientsBilled();
		}

	},

	patientsWorkedChartForlocation: function (){
		Highcharts.chart('patientsWorkedChart', {
			title: {
				text: null
			},
			tooltip: {
				pointFormat: '<b>{point.y}</b>'
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false,
						distance: -20,
						format: '<b>{point.y}</b>',
					},
					center: ['50%', '55%'],
					showInLegend: true,
				}
			},
			series: [{
				type: 'pie',
				data: [
					{
						name: 'Worked',
						y: this.model.get('worked'),
						color: '#29ABDD'
					}, {
						name: 'Unworked',
						y: this.model.get('unworked'),
						color: '#169E54'
					}
				]
			}],
			credits: {
				enabled: false
			},
			chart:{
				backgroundColor:'transparent'
			},
			legend:{
				itemStyle:{color:'#B3B3B3',marginBottom:'10px'},
				itemHoverStyle:{color:'#B3B3B3',marginBottom:'10px'}
			}
		});
	},
	patientsBilled: function() {
		let billingBreakdown = this.model.get('billingBreakdown');
		let index = 0;
		let data = [];
		$.each( billingBreakdown, ( key, value ) => {
			if( value.value > 0 ) {
				data.push({
					name: value.label,
					y: value.value,
					color: this.model.cptColors[value.label]
				});
				index++
			}
		});
		Highcharts.chart('patientsBilledMainId', {
			title: {
				text: null
			},
			tooltip: {
				pointFormat: '<b>{point.y}</b>'
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false,
						distance: -20,
						format: '<b>{point.y}</b>',
					},
					center: ['50%', '55%'],
					showInLegend: true,
				}
			},
			series: [{
				type: 'pie',
				data,
			}],
			credits: {
				enabled: false
			},
			chart:{
				backgroundColor:'transparent'
			},
			legend:{
				itemStyle:{color:'#B3B3B3',marginBottom:'10px'},
				itemHoverStyle:{color:'#B3B3B3',marginBottom:'10px'}
			}
		});
		if ( data.length == 0 ) {
			$('#patientsBilledMainId').html( `<div class="nobiilingdata">No Encounters</div>` )
		}
	},

	patientsWorkedChartLocation: function (){
		var patientsWorkedChart = Highcharts.chart('patientsWorkedChartRevenueLocation', {
			title: {
				text: null
			},
			tooltip: {
				pointFormat: '<b>{point.y}</b>'
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false,
						distance: -20,
						format: '<b>{point.y}</b>',
					},
					center: ['50%', '55%'],
					showInLegend: true,
				}
			},
			series: [{
				type: 'pie',
				data: [
					{
						name: 'CPT 99490',
						y: this.model.get('total99490Enrolled'),
						color: 'rgb(41, 171, 221)'
					}, {
						name: 'CPT G0511',
						y: this.model.get('totalG0511Enrolled'),
						color: '#169E54'
					}
				]
			}],
			credits: {
				enabled: false
			},
			chart:{
				backgroundColor:'transparent'
			},
			legend:{
				itemStyle:{color:'#B3B3B3',marginBottom:'10px'},
				itemHoverStyle:{color:'#B3B3B3',marginBottom:'10px'}
			}
		});
	},
	patientsWorkedChart: function() {
		var patientsWorkedChart = Highcharts.chart('patientsWorkedChartRevenue', {
			title: {
				text: null
			},
			tooltip: {
				pointFormat: '<b>{point.y}</b>'
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false,
						distance: -20,
						format: '<b>{point.y}</b>',
					},
					center: ['50%', '55%'],
					showInLegend: true,
				}
			},
			series: [{
				type: 'pie',
				data: [
					{
						name: 'Enrolled',
						y: this.model.get('enrolled'),
						color: '#29ABDD'
					}, {
						name: 'To be Consented',
						y: this.model.get('unverified'),
						color: '#FFF3CD'
					}
				]
			}],
			credits: {
				enabled: false
			},
			chart:{
				backgroundColor:'transparent'
			},
			legend:{
				itemStyle:{color:'#B3B3B3',marginBottom:'10px'},
				itemHoverStyle:{color:'#B3B3B3',marginBottom:'10px'}
			}
		});
	},

	onDestroy: function() {
		$('.content-container').removeClass('widen');
	},
	gotoRapidBill: function (){
		this.app.router.navigate( '/ccm/rapid-bill/reports', true );
	},
	gotoRevenueDashboard: function (){
		this.app.router.navigate( '/ccm/rapid-bill/reports', true );
	},
	gotoEnrolledPatient: function (){
		this.app.router.navigate( '/ccm/patient-status/enrolled', true );
	},
	gotoUnverifiedPatient: function (){
		this.app.router.navigate( '/ccm/patient-status/unverified', true );
	},
	gotoDetailLocationRevenue: function (){
		this.app.router.navigate( '/ccm/revenueDashboard', true );
	},

});
