import Backbone from 'backbone';

export const PatientListModel = Backbone.Model.extend({

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	unenrollPatient: function( patientId ) {
		let message = 'Clicking \"confirm\" will unenroll this patient.';
		let callback = this.app.ccm_controller.unenrollPatient.bind( this.app.ccm_controller );
		let data = {
			patientId
		};
		this.app.controller.confirmationModal( message, callback, data );
	},

  getSuspendReasons: function(callback) {
    this.app.ccm_controller.getSuspendReasons(callback);
  },

  getUnenrollReasons: function(callback) {
    this.app.ccm_controller.getUnenrollReasons(callback);
  },

	suspendPatient: function( patientId ) {
		this.app.ccm_controller.suspendPatient( patientId );	
	},

	unsuspendPatient: function( patientId ) {
		this.app.ccm_controller.unsuspendPatient( patientId );	
	},

	enrollPatient: function( patientId, patientData ) {
		this.app.ccm_controller.enrollPatient( patientId, patientData );
	},

	openPatientPlan: function( patientId ) {
		this.app.ccm_controller.openPatientPlan( patientId );
	},

    openCcda: function( patientId ) {
        this.app.ccm_controller.openCcda( patientId );
    },

	openConfidentialNotes: function( patientId ) {
		this.app.ccm_controller.openConfidentialNotes( patientId );
	},

});