// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  rapidBillContainerView  from '../../../../../templates/CCM/RapidBill/Approval/rapidBillContainerView';
import RapidBillCompositeView from './RapidBillCompositeView';
import PatientTotalsView from '../../PatientList/PatientTotalsView';
import BillingSummaryModalView from './BillingSummaryModalView';
import ManualBillingModalView from './ManualBillingModalView';

// Internal Dependencies - models/collections
import { RapidBillCollection } from './models/RapidBillCollection';
import {ApprovalModel} from "./models/ApprovalModel";


export default Marionette.LayoutView.extend({

	events: {
		'click #submitBilling': 'launchBillingSummary',
		'click #manualBilling': 'launchManualBilling',
		'click #phsBulkDownload': 'phsBulkDownload',
		'change #allResults': 'setAllResults',
		'change #byMonth': 'setByMonth',
		'change #cptcodefilter': 'setCPTCodeFilter',
		'change #month': 'setMonth',
		'change #year': 'setYear',
		'click #applyDateRange': 'applyRange',
	},

	regions: {
		'rapidBillContainer': '.rapid-bill-container',
		'patientTotals': '.patient-totals-container',
		'monthSelectContainer': '.month-select-container',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.controller = this.app.controller;
		this.collection = new RapidBillCollection();
		this.model = new ApprovalModel();
		this.collection.rapidBill = this.controller.modules && this.controller.modules.rapid;
		this.collection.showCPTFilter = false;
		this.getYears();
		this.app.ccm_controller.getRapidBilling();
		this.listenTo( this.model, 'change:dateRange', this.checkMonthSelect );
		this.listenTo( this.channel, 'set:rapidBilling', this.setCollection );
		this.listenTo( this.channel, 'update', this.render );
	},

	getYears: function() {
		const currentYear = new Date().getFullYear(), availableYears = [];
		let startYear = 2017;

		while ( startYear <= currentYear ) {
			availableYears.push(startYear++);
		}
		this.collection.availableYears=availableYears;
	},

	getTemplate: function() {
		return rapidBillContainerView( this.collection );
	},

	onShow: function() {
		this.showChildView( 'patientTotals', new PatientTotalsView( { sort: false, collection: this.collection } ) );
		this.showChildView( 'rapidBillContainer', new RapidBillCompositeView( {collection: this.collection} ) );
		const month = this.model.get( 'month' );
		const year = this.model.get( 'year' );
		this.$('#month option[value="' + month + '"]').attr('selected', true);
		this.$('#year option[value="' + year + '"]').attr('selected', true);
	},

	setCollection: function( collection ) {
		this.collection = collection;
	},

	launchBillingSummary: function() {
		let modalView = new BillingSummaryModalView({ collection: this.collection });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalSummary' ).openModal();
	},

	launchManualBilling: function() {
		let modalView = new ManualBillingModalView({ collection: this.collection });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalManualBilling' ).openModal();
	},

	phsBulkDownload: function() {
		this.collection.phsBulkDownload();
	},

	setAllResults: function() {
		this.model.set('dateRange', false);
	},

	setByMonth: function() {
		this.model.set('dateRange', true);
	},
	setCPTCodeFilter : function(e) {
		$('#applyDateRange').removeClass('disabled');
		this.model.set('cptCodeFilter', e.target.value);
	},

	checkMonthSelect: function() {
		$('#applyDateRange').removeClass('disabled');
		this.model.get('dateRange') ?
			this.$('#month, #year').attr('disabled', false) :
			this.$('#month, #year').attr('disabled', true);
	},

	setMonth: function( e ) {
		$('#applyDateRange').removeClass('disabled');
		this.model.set('month', e.target.value)
	},

	setYear: function( e ) {
		$('#applyDateRange').removeClass('disabled');
		this.model.set('year', e.target.value)
	},

	applyRange: function() {
		this.app.ccm_controller.setGlobalValue( 'approvalDateRange', this.model.get('dateRange') );
		this.app.ccm_controller.setGlobalValue( 'approvalMonth', this.model.get('month') );
		this.app.ccm_controller.setGlobalValue( 'approvalYear', this.model.get('year') );
		this.app.ccm_controller.setGlobalValue( 'cptCodeFilter', this.model.get('cptCodeFilter') );
		this.app.ccm_controller.getRapidBilling( null, 0 );
		$('#applyDateRange').addClass('disabled');
	},

	onDestroy: function() {
		this.app.ccm_controller.setGlobalValue( 'approvalDateRange', null );
		this.app.ccm_controller.setGlobalValue( 'approvalMonth', null );
		this.app.ccm_controller.setGlobalValue( 'approvalYear', null );
	}

});
