import Backbone from 'backbone';
import Highcharts from 'highcharts';

export const QualityMeasureModel = Backbone.Model.extend({
	
	initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
		this.colors = {
			'FAIL': '#A01313',
			'WARN': '#f57b3e',
			'blue': '#1080AF',
			'PASS': '#169E54',
		};
		this.listenTo( this.channel, 'success:saveMeasureData', this.resetNumAndDen );
		const currentApp = this.app.controller.currentApp;
		const selectedProviderId = this.app.controller.selectedProviderId;
		this.set( { currentApp, selectedProviderId } );
	},

	getMeasureMetaData: function( measure ) {
	    this.app.mips_controller.getMeasureMetaData( measure );
    },

	getMeasureData: function() {
		this.app.mips_controller.getMeasureData( this.get( 'measure' ).id );
	},

	saveMeasureData: function( measure_id ) {
        this.app.mips_controller.saveMeasureData( this.get( 'data' ), measure_id );
	},

	resetNumAndDen: function() {
		this.set({
			numerator: '',
			denominator: '',
		});
	},

});