import Backbone from 'backbone';

export const PatientListCollection = Backbone.Collection.extend({

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	downloadCSV: function() {
		this.app.ccm_controller.getPatientListCSV();
	}

});