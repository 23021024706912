// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import userLevelFilteringView  from '../../../templates/userLevelFilteringView';
import SearchContainerView from './SearchContainerView';
import UserDropdownFiltersView from './UserDropdownFiltersView';
import BreadcrumbContainerView from './BreadcrumbContainerView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	events: {
		'submit #searchForm': 'preventDefault'
	},

	regions: {
		searchContainer: '.search-view-container',
		dropdownFilterContainer: '.dropdown-filter-container',
		breadcrumbContainer: '.breadcrumb-container'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'show:search', this.showSearch );
		this.listenTo( this.channel, 'remove:search', this.removeSearch );
	},

	getTemplate: function() {
		return userLevelFilteringView( this.model );
	},

	preventDefault: function( e ) {
		e && e.preventDefault();
	},

	onShow: function() {
		$('select').material_select();
		// this.showChildView( 'dropdownFilterContainer', new UserDropdownFiltersView() );
		this.showChildView( 'breadcrumbContainer', new BreadcrumbContainerView() );
	},

	showSearch: function( searchObj, collection ) {
		if ( !searchObj ) {
			searchObj = {
				placeholder: 'Search first name, last name, PID, or DOB'
			}
		}
		let searchModel = new Backbone.Model(searchObj);
		this.showChildView( 'searchContainer', new SearchContainerView({ model: searchModel, collection: collection }) );
	},

	removeSearch: function() {
		let searchContainer = this.getRegion( 'searchContainer' );
		searchContainer && searchContainer.empty();
		this.app.ccm_controller.setGlobalValue( 'searchInput', null );
		this.app.main_controller.setGlobalValue( 'searchInput', null );
	}

});
