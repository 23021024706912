// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import tempFn from '../templates/regionMap';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	className: 'region-map-container',

	regions: {
		header: '.header-container',
		sideNav: '.sideNav-container',
		content: '.content-container'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel('AppChannel');
		this.app = this.channel.request( 'app' );
	},

	getTemplate: function() {
		return tempFn({rapid_eval: this.app.controller.rapid_eval});
	},
	compiledTemplate: function () {
		return tempFn({rapid_eval: this.app.controller.rapid_eval});
	}

});
