// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import  pendingReviewItemView  from '../../../../../templates/CCM/RapidBill/PendingReview/pendingReviewItemView';
import ReviewBillingModalView from './ReviewBillingModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: 'table-row clearfix',

	events: {
		'click .launch-review': 'launchReviewModal'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.model.checkFields();
	},

	getTemplate: function() {
		return pendingReviewItemView( this.model );
	},

	launchReviewModal: function() {
		let modalView = new ReviewBillingModalView({ model: this.model });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalReview' ).openModal();
		this.channel.trigger( 'trigger:show' );
	}

});
