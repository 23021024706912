// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import primaryInputCardView  from '../../../../templates/MIPS/Quality/primaryInputCardView';
import DataEntryView from './DataEntryView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

    regions: {
        'dataEntryContainer': '.data-entry-container',
    },

    events: {
        'click @ui.reverse': 'flipCard',
        'click @ui.saveEntry': 'saveEntry',
        'change @ui.monthInput': 'updateDataInput',
        'change @ui.yearInput': 'updateDataInput',
        'change @ui.criteriaSelect': 'updateCriteria',
    },

    ui: {
        reverse: '#reverse',
        flipper: '.flipper',
        front: '.front',
        back: '.back',
        flipContainer: '.flip-container',
        saveEntry: '#saveEntry',
        numeratorInput: '#numeratorInput',
        denominatorInput: '#denominatorInput',
        monthInput: '#month',
        yearInput: '#year',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.model.set( 'editable', true );
    },

    onShow: function() {
        this.showChildView( 'dataEntryContainer', new DataEntryView({ model: this.model }) );
        this.renderSummaryChart();
        let front = this.ui.front.height();
        let back = this.ui.back.height();
        let minHeight = front > back ? front : back;
        this.ui.flipper.css({ 'min-height': minHeight });
        this.updateDataInput();
    },

    getTemplate: function() {
        return primaryInputCardView( this.model );
    },

    renderSummaryChart: function() {
        let data = [];
        if ( this.model.get( 'measureData' ).numerator && this.model.get( 'measureData' ).denominator ) {
	        if ( this.model.get( 'measure_type' ) === 'diabetes_poor_control' ) {
		        data = [
			        {
				        name: this.model.get('measure').denominator_text,
				        y: this.model.get('measureData').denominator - this.model.get('measureData').numerator,
				        color: this.model.colors[this.model.get('measureData').score_threshold],
			        },
			        {
				        name: this.model.get('measure').numerator_text,
				        y: this.model.get('measureData').numerator,
				        color: this.model.colors['blue'],
			        },
		        ];
	        } else {
		        data = [
			        {
				        name: this.model.get('measure').numerator_text,
				        y: this.model.get('measureData').numerator,
				        color: this.model.colors[this.model.get('measureData').score_threshold],
			        },
			        {
				        name: this.model.get('measure').denominator_text,
				        y: this.model.get('measureData').denominator - this.model.get('measureData').numerator,
				        color: this.model.colors['blue'],
			        },
		        ];
	        }

        }
        if ( data.length == 0 ) {
            $('#qualityMeasureSummaryChart').html( `<div class="center-align ptxl"><h5>No data has been provided</h5></div>` )
            return;
        }
        let qualityMeasureProgress = Highcharts.chart('qualityMeasureSummaryChart', {
            title: {
                text: null
            },
            tooltip: {
                pointFormat: '<b>{point.y} patients</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -30,
                        formatter: function() {
                            return Math.round(this.percentage) + '%';
                        },
                    },
                    startAngle: -125,
                    endAngle: 125,
                    center: ['50%', '55%'],
                    showInLegend: true,
                }
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: data,
            }],
            credits: {
                enabled: false,
            },
        });
    },

    flipCard: function( e ) {
        e.preventDefault();
        this.ui.flipContainer.toggleClass( 'active' );
    },

    updateDataInput: function() {
        const ui = this.ui;
        let monthValue = ui.monthInput.val();
        let yearValue = ui.yearInput.val();
        if ( this.checkHasFullDate( monthValue, yearValue ) && this.hasMonthData() ) {
            const measureData = this.model.get( 'measureData' );
            let matchingDataPoint = _.find(measureData.historical_data, (dataPoint ) => dataPoint.month && dataPoint.month === Number( monthValue ) );
	        if ( matchingDataPoint ) {
		        this.model.set({
			        numerator: matchingDataPoint.numerator,
			        denominator: matchingDataPoint.denominator,
			        editable: false,
			        monthValue,
			        yearValue
		        });
	        } else {
		        this.model.set({
			        numerator: '',
			        denominator: '',
			        editable: true,
			        monthValue,
			        yearValue
		        });
		        this.model.trigger('change');
	        }
        }
    },

    checkHasFullDate: function( month, year ) {
        return !!( month && year );
    },

    hasMonthData: function() {
        let measureData = this.model.get( 'measureData' );
        return measureData && measureData.historical_data && measureData.historical_data.length;
    },

    saveEntry: function() {
        let ui = this.ui;
        let numeratorInput = $('#numeratorInput');
        let denominatorInput = $('#denominatorInput');
        let numeratorValue = Number( numeratorInput.val() );
        let denominatorValue = Number( denominatorInput.val() );

        if ( numeratorValue > denominatorValue ) {
            this.$( '.mips-validations' ).show();
            return;
        }
        this.model.set( 'data', {
            numerator: numeratorValue || 0,
            denominator: denominatorValue || 0,
            month: ui.monthInput.val() || ( ( new Date() ).getMonth() + 1).toString(),
            year: ui.yearInput.val() || ( ( new Date() ).getYear() + 1900 ).toString(),
        });
        this.model.saveMeasureData( this.model.get( 'measure' ).id );
    },

});
