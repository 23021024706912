// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import dateRangeDaySelectView  from '../../../../../templates/CCM/RapidBill/Reports/dateRangeDaySelectView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: 'inline-dropdown-container mls',

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.range = this.options.type == 'startDay' ? this.model.get( 'startMonthRange' ) : this.model.get( 'endMonthRange' );

		this.listenTo( this.model, 'change:startMonthRange change:endMonthRange', this.checkChanges );
	},

	onShow: function() {
		$('#' + this.options.type + ' option[value="' + this.model.get( this.options.type ) + '"]').attr("selected", "selected");
	},

	getTemplate: function() {
		return dateRangeDaySelectView( this.options );
	},

	checkChanges: function() {
		let newRange = this.options.type == 'startDay' ? this.model.get( 'startMonthRange' ) : this.model.get( 'endMonthRange' );
		this.options.type == 'startDay' && console.log(this.range.length);
		if ( this.range.length !== newRange.length ) {
			this.range = newRange;
			this.render();
		}
	}

});
