// External Dependencies
import Backbone from 'backbone';
import Marionette from 'backbone.marionette';

// Internal Dependecies - screens/views/templates
import autoEnrollCompositeView  from '../../../../templates/CCM/AutoEnroll/autoenrollCompositeView';
import AutoEnrollItemView from './AutoEnrollItemView';

// Internal Dependencies - models/collections
import { AutoEnrollCollection } from './models/AutoEnrollCollection';

export default Marionette.CompositeView.extend({

	className: 'auto-enroll-composite-view',

	childView: AutoEnrollItemView,

	childViewContainer: '.auto-enroll-collection-container',

	events: {
		'click #selectAll': 'selectAll',
		'click .download': 'downloadCSV',
		'click .clear-search': 'resetSearch',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection = new AutoEnrollCollection();
		this.currentPage = 0;
		this.pageSize = 20;

		const searchObj = {
			placeholder: 'Search first name, last name, or PID'
		}

		this.channel.trigger( 'show:search', searchObj );

		// TODO: More data is need to check full functionality. Can test using '.getPatientList'
		// this.app.ccm_controller.getPatientList();
		this.app.ccm_controller.getAutoPendingList();

		this.listenTo( this.channel, 'set:patientList', this.setPatientList );
		this.listenTo( this.collection, 'add remove sort update', this.render );
		this.listenTo( this.channel, 'reset:currentPage', this.resetCurrentPage );

		this.channel.reply( 'currentPage', () => {
			return this.currentPage;
		});

		this.channel.reply( 'pageSize', () => {
			return this.pageSize;
		});

		this.channel.trigger( 'check:selected' );
		$('.content-container').addClass('widen');
	},

	getTemplate: function() {
		return autoEnrollCompositeView( this.collection );
	},

	setPatientList: function() {
		this.channel.trigger( 'show:contentSpinner' );
		this.collection.reset();
		let patients = this.app.ccm_controller.patientList;
		this.collection.renderNoResults = patients && patients.length ? false : true;
		let search = this.app.ccm_controller.searchInput
		this.collection.search = search ? search.substring( search.lastIndexOf("=") + 1 ) : '';
		this.collection.add( patients );
		this.channel.trigger( 'set:autoEnroll', this.collection );
		this.channel.trigger( 'hide:contentSpinner' );
		!patients.length && this.render();
	},

	resetSearch: function() {
		this.channel.trigger( 'reset:search' );
	},

	resetCurrentPage: function() {
		this.currentPage = 0;
		this.app.ccm_controller.patients.currentPage = this.currentPage;
	},

	selectAll: function() {
		const shouldSelect = $( '#selectAll' ).prop( 'checked' );
		this.collection.selectAll( shouldSelect );
	},

	downloadCSV: function() {
		this.collection.downloadCSV();
	},

	onDestroy: function() {
		this.channel.trigger( 'remove:search' );
		this.app.ccm_controller.clearValue( 'patientList' );
	},

});
