// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  deleteUserModalView  from '../../../../templates/Utilities/ManageUsers/deleteUserModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'click .modal-close': 'closeModal',
		'click .confirm-user-delete': 'deleteUser',
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'deleteUser:success', this.closeModal );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
	},

	getTemplate: function() {
		return deleteUserModalView( this.model );
	},

	deleteUser: function( e ) {
		e.preventDefault();
		this.app.utilities_controller.deleteUser( this.model.get('id') );
	},

	closeModal: function( e ) {
		e && e.preventDefault();
		$( '#modalDeleteUser' ).closeModal();
		this.destroy();
	}

});
