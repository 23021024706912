import Backbone from 'backbone';
import _ from 'underscore';

export const AssociatedProvidersCollection = Backbone.Collection.extend({

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.providerList = this.app.controller.providers;
		!this.app.controller.providers && this.listenTo( this.channel, 'set:providers', () => this.providerList = this.app.controller.providers );
	},

	setInitialProviders: function( model ) {
		// Reset unusedProviders to include all available providers from the conroller
		this.unusedProviders = _.clone(this.providerList);

		// Get the latest associatedProviders array from the model
		let associatedProviders = model.get('associatedProviders')

		// If the array for associatedProviders does not include -1 (all providers), run this
		if ( associatedProviders.indexOf( -1 ) < 0 ) {

			// Each over each id in the associatedProviders array
			_.each( associatedProviders, ( provider ) => {

				this.unusedProviders = _.reject( this.unusedProviders, (unusedProvider) => {

					// Add the matching provider details to the AssociatedProvidersCollection
					if ( unusedProvider.id == provider ) {
						this.add( { providerId: unusedProvider.id, providerDetails: unusedProvider } )
					}

					// Remove this provider's id from the unusedProviders array
					return unusedProvider.id == provider
				});
			});

		} else {
			let firstProvider = this.unusedProviders[0];

			// Add the first provider in the unusedProviders array to the collection and remove it from the unusedProviders array
			this.add( { providerId: firstProvider.id, providerDetails: firstProvider } );
			this.unusedProviders.splice(0, 1)
		}
	},

	addProvider: function() {
		let firstProvider = this.unusedProviders[0];

		// Add the first provider in the unusedProviders array to the collection and remove it from the unusedProviders array
		this.unusedProviders.splice(0, 1)
		this.add( { providerId: firstProvider.id, providerDetails: firstProvider } );
	},

	deleteProvider: function( model ) {
		this.unusedProviders.push( model.get('providerDetails') );
		this.remove(model);
	},

	changeProvider: function( newProviderId, model ) {
		// Add now unusedProvider to the unusedProviders array
		this.unusedProviders.push( model.get( 'providerDetails' ) );
		let newProvider
		this.unusedProviders = _.reject( this.unusedProviders, (unusedProvider) => {

			// Add the matching provider details to the AssociatedProvidersCollection
			if ( unusedProvider.id == newProviderId ) {
				newProvider = { providerId: unusedProvider.id, providerDetails: unusedProvider };
			}

			// Remove this provider's id from the unusedProviders array
			return unusedProvider.id == newProviderId
		});
		model.set( newProvider );

	}

});