import Backbone from 'backbone';
import { UserModel } from './UserModel';

export const ManageUserCollection = Backbone.Collection.extend({

	model: UserModel,

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );		
	},

});