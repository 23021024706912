// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import optedOutItemView  from '../../../../templates/CCM/OptedOut/optedOutItemView';

export default Marionette.ItemView.extend({

	className: 'table-row clearfix',

	events: {
		'click .more': 'expandContent',
		'click .less': 'expandContent',
        'click .open-patient-plan': 'viewCarePlan',
        'click .open-ccda': 'viewCcda',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	getTemplate: function() {
		return optedOutItemView( this.model );
	},

	viewCarePlan: function() {
		let patientId = this.model.get( 'id' );
		this.model.viewCarePlan( patientId );
	},

    viewCcda: function() {
        let patientId = this.model.get( 'id' );
        this.model.viewCcda( patientId );
    },

	expandContent: function( e ) {
		this.$( '.more' ).toggleClass( 'active' );
		this.$( '.less' ).toggleClass( 'active' );
		this.$( '.patient-list-expanded-data' ).slideToggle( 200 );
	},

});
