// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import moduleView  from '../../../templates/moduleView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: 'app-links',

	tagName: 'ul',

	events: {
		'click .module-select': 'setCurrentApp',
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.model, 'change', this.render );
	},

	onShow: function() {
		this.determineWhichModule();
	},

	getTemplate: function() {
		return moduleView( this.model );
	},

	onDomRefresh: function() {
		this.determineWhichModule();
	},

	setModules: function() {
		this.model.set( 'modules', this.app.controller.modules );
	},

	setCurrentApp: function( e ) {
		this.model.changeCurrentApp( e.target.id );
	},

	determineWhichModule: function() {
		const appsLinkSelector = $('.app-links-link');
		let currentApp = this.model.get( 'currentApp' );
		switch( currentApp ) {
			case 'MAIN':
                appsLinkSelector.removeClass( 'active' );
				$('#MAIN').addClass( 'active' );
				break;
			case 'CCM':
                appsLinkSelector.removeClass( 'active' );
				$('#CCM').addClass( 'active' );
				break;
			case 'CPC':
                appsLinkSelector.removeClass( 'active' );
				$('#CPC').addClass( 'active' );
				break;
            case 'MIPSGROUP':
                appsLinkSelector.removeClass( 'active' );
                $('#MIPSGROUP').addClass( 'active' );
                break;
            case 'MIPSPROVIDER':
                appsLinkSelector.removeClass( 'active' );
                $('#MIPSPROVIDER').addClass( 'active' );
                break;
			case 'MIPS':
				appsLinkSelector.removeClass( 'active' );
				$('#MIPS').addClass( 'active' );
				break;
			case 'UTILITIES':
                appsLinkSelector.removeClass( 'active' );
				$('#UTILITIES').addClass( 'active' );
				break;
			case 'PATIENTENROLLMENT':
				appsLinkSelector.removeClass( 'active' );
                $('#PATIENTENROLLMENT').addClass( 'active' );
                break;
		}
	},

});
