// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';

// Internal Dependencies - screens/views/templates
import overviewContainerView  from '../../../../../templates/MIPS/AdvancingCare/BonusScore/overviewContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.setData();
        this.listenTo( this.model, 'change:measureData', this.render );
        this.listenTo( this.model, 'change:secondaryMeasureData', this.render );
    },

    getTemplate: function() {
        return overviewContainerView( this.model );
    },

    onShow: function() {
        this.renderSummaryChart();
    },

    onDomRefresh: function() {
        this.setData();
    },

    setData: function() {
        let measureData = this.model.get( 'measureData' );
        let secondaryMeasureData = this.model.get( 'secondaryMeasureData' );
        if ( measureData && measureData.historical_data && measureData.historical_data.length ) {
            this.model.set({
                fivePercentBonusCompleted: !!measureData.historical_data[0].numerator,
            });
        }
        if ( secondaryMeasureData && secondaryMeasureData.historical_data && secondaryMeasureData.historical_data.length ) {
            this.model.set({
                tenPercentBonusCompleted: !!secondaryMeasureData.historical_data[0].numerator,
            });
        }
    },

    renderSummaryChart: function() {
        let metCriteriaCount = 0;
        let unMetCriteriaCount = this.model.get( 'criteria' ).length || 2;
        if ( this.model.get( 'fivePercentBonusCompleted' ) ) {
            metCriteriaCount++;
            unMetCriteriaCount--;
        }
        if ( this.model.get( 'tenPercentBonusCompleted' ) ) {
            metCriteriaCount++;
            unMetCriteriaCount--;
        }
        let data = [
            {
                name: 'Met Bonus Criteria',
                y: metCriteriaCount,
                color: this.model.colors[this.model.get( 'measureData' ).resultColor],
            },
            {
                name: 'Unmet Bonus Criteria',
                y: unMetCriteriaCount,
                color: this.model.colors[ 'gray' ],
            },
        ];
        let bonusScoreSummaryChart = Highcharts.chart('bonusScoreSummaryContainer', {
            title: {
                text: null
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            chart: {
                height: 180,
                margin: [0,0,0,0]
            },
            labels: {
                y: -500,
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        format: '<b>{point.y}</b>',
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '55%'],
                    showInLegend: true,
                }
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: data,
            }],
            credits: {
                enabled: false,
            },
        });
        let front = $( '.front' ).height();
        let back = $( '.back' ).height();
        let minHeight = front > back ? front : back;
        $( '.flipper' ).css({ 'min-height': minHeight });
    },

});
