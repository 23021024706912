// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import placeholderContentView  from '../templates/placeholderContentView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	initialize: function( options ) {
		console.log(this.options)
	},

	getTemplate: function() {
		return placeholderContentView( this.options );
	},

});
