// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import patientEnrollmentConfirmationModalView  from '../../../../../templates/PatientEnrollment/PatientApproval/PatientApprovalComponents/patientEnrollmentConfirmationModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    events: {
        'click #confirmSummary': 'confirmSummary',
        'click .cancel': 'closeModal'
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.listenTo( this.channel, 'close:modal', this.closeModal );
    },

    getTemplate: function() {
        return patientEnrollmentConfirmationModalView( this.collection );
    },

    closeModal: function() {
        $( '#modalSummary' ).closeModal();
        this.destroy();
    },

    confirmSummary: function() {
        this.collection.confirmPatients();
    },

});
