// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import dashboardContainerView  from '../../../../templates/Main/Dashboard/dashboardContainerView';
import messengerDashboardView  from '../../../../templates/Main/Dashboard/messengerDashboardView';
import CcmChartsContainerView from './CcmChartsContainerView';
import CpcChartsContainerView from './CpcChartsContainerView';
import PlaceholderContentView from '../../../PlaceholderContentView';
import CpcDashboardPlaceholder from './CpcDashboardPlaceholder';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	regions: {
		'ccmChartsContainer': '.ccmChartsContainer',
		'cpcChartsContainer': '.cpcChartsContainer',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new Backbone.Model({   messenger: this.app.controller.user && this.app.controller.user.messenger,
											rapid_eval: this.app.controller.rapid_eval });
		let modules = this.app.controller && this.app.controller.modules;
		( !modules || !modules.ccm ) && this.listenTo( this.channel, 'set:modules', this.renderRegions );
	},

	getTemplate: function() {
		if ( !this.model.get( 'messenger' ) ) {
			return dashboardContainerView( this.model );
		}
		return messengerDashboardView( this.model );
	},

	onShow: function() {
		!this.model.get( 'messenger' ) && this.renderRegions();
	},

	renderRegions: function() {
		if ( !this.model.get( 'messenger' ) ) {
			let modules = this.app.controller && this.app.controller.modules;
			modules && modules.ccm && this.showChildView( 'ccmChartsContainer', new CcmChartsContainerView() );
			modules && modules.cpc && this.showChildView( 'cpcChartsContainer', new CpcDashboardPlaceholder() );
		}
	},

});
