// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';

// Internal Dependencies - screens/views/templates
import performanceScoreContainerView  from '../../../../../templates/MIPS/AdvancingCare/Summary/performanceScoreContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    className: 'col s12 phn',

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.listenTo( this.model, 'change:summaryData', this.render )
    },

    getTemplate: function() {
        return performanceScoreContainerView( this.model );
    },

    onDomRefresh: function() {
        this.renderCharts();
    },

    renderCharts: function() {
        this.performanceChartOutOfTwenty();
        this.performanceChartOutOfTen();
    },

    performanceChartOutOfTwenty: function() {
        const performanceCriteriaObject = this.model.get( 'advancingSummaryPerformanceCriteria' );
        Highcharts.chart('performanceChartTwenty', {
            chart: {
                type: 'bar',
                height: '145',
                marginLeft: 200
            },
            title: {
                text: null,
            },
            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 20,
                title: {
                    text: 'Points (out of 20)'
                },
                allowDecimals: false,
            },
            legend: {
                enabled: false,
            },
            series: [{
                name: 'Points',
                colorByPoint: true,
                data: [{
                    name: 'Provide Patient Access',
                    y: performanceCriteriaObject.patientAccessPoints,
                    color: this.model.colors[performanceCriteriaObject.patientAccessColor],
                }, {
                    name: 'Health Information Exchange',
                    y: performanceCriteriaObject.healthInformationExchangePoints,
                    color: this.model.colors[performanceCriteriaObject.healthInformationExchangeColor],
                }]
            }],
            credits: {
                enabled: false
            }
        });
    },

    performanceChartOutOfTen: function() {
        const performanceCriteriaObject = this.model.get( 'advancingSummaryPerformanceCriteria' );
        Highcharts.chart('performanceChartTen', {
            chart: {
                type: 'bar',
                height: '265',
                marginLeft: 200
            },
            title: {
                text: null,
            },
            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 10,
                title: {
                    text: 'Points (out of 10)'
                },
                allowDecimals: false,
            },
            legend: {
                enabled: false,
            },
            series: [{
                name: 'Points',
                colorByPoint: true,
                data: [{
                    name: 'View, Download, or Transmit',
                    y: performanceCriteriaObject.vdtPoints,
                    color: this.model.colors[performanceCriteriaObject.vdtColor],
                }, {
                    name: 'Patient-Specific Education',
                    y: performanceCriteriaObject.patientSpecificEducationPoints,
                    color: this.model.colors[performanceCriteriaObject.patientSpecificEducationColor],
                }, {
                    name: 'Secure Messaging',
                    y: performanceCriteriaObject.secureMessagingPoints,
                    color: this.model.colors[performanceCriteriaObject.secureMessagingColor],
                }, {
                    name: 'Medication Reconciliation',
                    y: performanceCriteriaObject.medicationReconciliationPoints,
                    color: this.model.colors[performanceCriteriaObject.medicationReconciliationColor],
                }, {
                    name: 'Immunization Registry Reporting',
                    y: performanceCriteriaObject.ImmunizationRegistryPoints,
                    color: this.model.colors[performanceCriteriaObject.ImmunizationRegistryColor],
                }]
            }],
            credits: {
                enabled: false
            }
        });
    },
});
