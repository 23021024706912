import Backbone from 'backbone';

const ForgotPasswordModel = Backbone.Model.extend({

	defaults: {
		email: '',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	validate: function() {
		let email = this.get( 'email' );
		if ( email.trim() && email.indexOf('@') > -1 ) {
			return true;
		}
		return false;
	},

	resetPassword: function(callback) {
		this.app.controller.resetForgottenPassword( this,callback );
	}

});

export default ForgotPasswordModel;