import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';

import { Utils } from '../../utils';

import ErrorModalView from '../../ErrorModalView';

const channel = Backbone.Radio.channel('AppChannel');
const utils = new Utils();
const userCookie = 'user=';

const inbox = 1, outbox = 0;

export const Main_Controller = ( app ) => {

	const BASE_ENGAGE_URL = app.config.BASE_ENGAGE_URL;

	return {

		/**********************
		Global values
		**********************/

		messaging: {
			currentPage: 0,
			pageSize: 50
		},

		// Takes a valueName and data and creates or replaces a variable with that name and data
		setGlobalValue: function ( valueName, data ) {
			this[ valueName ] = data;
			channel.trigger( 'set:' + valueName );
		},

		// Takes a collection and a single updated model and replaces the old instance of the model with the new
		// Model must have all properties that were returned with initial fetch of collection
		updateModelInCollection: function ( collection, updatedItem ) {
			collection.splice(_.findIndex(collection, 'id', updatedItem.id), 1, updatedItem);
		},

		/**********************
		Main Specific Methods
		**********************/

		clearValue: function( value ) {
			this[ value ] = null;
		},

		getHeaderURL: function( userRole='', selectedLocationId, selectedProviderId, organizationId, mailboxType = 1 ) {
		    let endpoint;
		    switch(userRole.toLowerCase()) {
		        case 'messenger':
		        case 'user':
		            if( selectedLocationId == -1 && selectedProviderId == -1 ) {
		                return endpoint = `v1/message/organization/${organizationId}/headers?mailbox=${mailboxType}`;
		            } else if( selectedProviderId != -1 ) {
		                return endpoint = `v1/message/provider/${selectedProviderId}/headers?mailbox=${mailboxType}`;
		            } else if( selectedLocationId != -1 ) {
		                return endpoint = `v1/message/location/${selectedLocationId}/headers?mailbox=${mailboxType}`;
		            }
		            break;
		        default:
		            return endpoint = `v1/message/headers?mailbox=${mailboxType}`;
		    }
		},

		setMessagingIndex: function() {
			let lastPage = this.messaging.currentPage + 1 >= this.messaging.totalPages;
			this.messaging.beginIndex = this.messaging.currentPage * this.messaging.pageSize;
			this.messaging.endIndex = !lastPage ? ( this.messaging.currentPage + 1 ) * this.messaging.pageSize - 1 : this.messaging.totalMessages;
		},

		getFilteredMessages: function() {
			this.setMessagingIndex();
			if ( this.filteredMessages ) {
				return this.filteredMessages.slice( this.messaging.beginIndex, this.messaging.endIndex + 1 );
			}
			return this.messages.slice( this.messaging.beginIndex, this.messaging.endIndex + 1 );
		},

		filterMessages: function( value ) {
			value = value && value.trim().toLowerCase();
			let filteredMessages;
			if ( !value ) {
				filteredMessages = this.messages;
			} else {
				let senderFilters = value.indexOf( ',' ) == -1 ?
										value.split( ' ' ) :
										value.indexOf( ' ' ) == -1 ?
											value.split( ',' ) :
											value.split( ', ' );
				senderFilters = _.reject( senderFilters, ( filter ) => filter == ',' || filter == '' );
				filteredMessages = this.messages.filter( ( message ) => {
					let senderNames = [ message.last_name.toLowerCase(), message.first_name.toLowerCase() ];
					if ( senderFilters.length > 1 ) {
						return _.intersection( senderFilters, senderNames ).length > 1;
					}
					return _.intersection( senderFilters, senderNames ).length;
				});
			}

			this.messaging.currentPage = 0;
			this.messaging.pageSize = 50;
			if ( filteredMessages.length || ( value && !filteredMessages.length ) ) {
				this.messaging.totalMessages = filteredMessages.length;
				this.messaging.totalPages = Math.ceil( filteredMessages.length / 50 );
				this.setMessagingIndex();
				this.setGlobalValue( 'filteredMessages', filteredMessages );
				return;
			}
			this.messaging.totalMessages = this.messages.length;
			this.messaging.totalPages = Math.ceil( this.messages.length / 50 );
			this.setMessagingIndex();
			this.setGlobalValue( 'filteredMessages', null );
		},

		// Inbox methods

		getMessages: function( readStatus = null ) {
			let endpoint = BASE_ENGAGE_URL;
			let userRole = '';
			let controller = app.controller;
			if ( controller.user ) {
				if ( controller.user.user ) {
					userRole = 'user';
				} else if ( controller.user.messenger ) {
					userRole = 'messenger';
				} else {
					userRole = 'admin';
				}
			}

			let selectedLocation = controller.selectedLocationId || -1;
			let selectedProvider = controller.selectedProviderId || -1;
			let selectedOrganization = controller.selectedOrganization && controller.selectedOrganization.id;

			switch( userRole.toLowerCase() ) {
	            case 'messenger':
	            case 'user':
	                if( selectedLocation == -1 && selectedProvider == -1 ) {
	                    endpoint += `v1/message/organization/${selectedOrganization}/headers?mailbox=1`;
	                } else if( selectedProvider != -1 ) {
	                    endpoint += `v1/message/provider/${selectedProvider}/headers?mailbox=1`;
	                } else if( selectedLocation != -1 ) {
	                    endpoint += `v1/message/location/${selectedLocation}/headers?mailbox=1`;
	                }
	                break;
	            default:
	                endpoint += 'v1/message/headers?mailbox=1';
	        }

	        let successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			if ( selectedOrganization ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveMessages.bind( this, readStatus ) );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getMessages.bind( this ) );
		},

		resolveMessages: function( readStatus, data ) {
			let messages = data.map( ( message ) => {
				return Object.assign( {}, message, {
					sent_date: app.controller.formatLocalDateForUI( message.date_created )
				});
			});
			this.messaging.currentPage = 0;
			this.messaging.pageSize = 50;
			this.setMessagingIndex();
			this.messaging.totalMessages = data.length;
			this.messaging.totalPages = Math.ceil( data.length / 50 );
			this.setGlobalValue( 'messages', messages );
			this.setGlobalValue( 'filteredMessages', null );
			readStatus == false && app.controller.setGlobalValue( 'unreadMessages', app.controller.unreadMessages - 1 );
		},

		markUnreadThread: function(patientIds,toggleSpinner=true,callback){
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/notifications/thread/unread?patientIds=' + patientIds;
			let successTrigger = [ 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];

			// Show spinner during HTTP request
			toggleSpinner && channel.trigger( 'show:contentSpinner' );
			utils.put( endpoint, {}, successTrigger, errorTrigger, null, true, () => {
				if(callback){
					callback();
				}else{
					this.getNotifications();
				}

			} );
		},

		markMessagesRead: function( message_id, toggleSpinner=true ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/message/' + message_id;
			let successTrigger = [ 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let updateMessageDto = {
				id: message_id,
				read: true,
				date_read: moment().unix()
			}
			// Show spinner during HTTP request
			toggleSpinner && channel.trigger( 'show:contentSpinner' );
			utils.put( endpoint, updateMessageDto, successTrigger, errorTrigger, null, true, this.updateInboxMessages.bind( this, message_id, 'read' ) );
		},

		markMessagesUnread: function( message_id ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/message/' + message_id;
			let successTrigger = [ 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let updateMessageDto = {
				id: message_id,
				read: false
			}
			channel.trigger( 'show:contentSpinner' );
			utils.put( endpoint, updateMessageDto, successTrigger, errorTrigger, null, true, this.updateInboxMessages.bind( this, message_id, 'unread' ) );
		},

		markMessagesArchived: function( message_id, readStatus ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/message/' + message_id;
			let successTrigger = [ 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let updateMessageDto = {
				id: message_id,
				archived: true
			}
			channel.trigger( 'show:contentSpinner' );
			utils.put( endpoint, updateMessageDto, successTrigger, errorTrigger, null, true, this.updateInboxMessages.bind( this, message_id, 'archived', readStatus ) );
		},

		markMessagesUnArchived: function( message_id, readStatus ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/message/' + message_id;
			let successTrigger = [ 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let updateMessageDto = {
				id: message_id,
				archived: false
			}
			channel.trigger( 'show:contentSpinner' );
			utils.put( endpoint, updateMessageDto, successTrigger, errorTrigger, null, true, this.updateArchivedMessages.bind( this, readStatus ) );
		},

 		updateInboxMessages: function( message_id, updateType, readStatus, data, toggleSpinner=true ) {
 			if( updateType !== 'archived'){
 				let messages = this.messages.filter( ( message ) => {
	 				if ( message.id == message_id ) {
	 					switch( updateType ) {
	 						case 'read':
	 							message.read = true;
	 							app.controller.setGlobalValue( 'unreadMessages', app.controller.unreadMessages - 1 );
	 							break;
	 						case 'unread':
	 							message.read = false;
	 							app.controller.setGlobalValue( 'unreadMessages', app.controller.unreadMessages + 1 );
	 							break;
	 					}
	 				}
	 				return !message.archived;
	 			});
	 			this.setGlobalValue( 'messages', messages );
	 			return;
	 		}
	 		this.getMessages( readStatus );
 		},

		updateArchivedMessages: function( readStatus ) {
			readStatus == false && app.controller.setGlobalValue( 'unreadMessages', app.controller.unreadMessages + 1 );
			this.getArchivedMessages();
		},

		getMessageContent: function( message_id ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/message/' + message_id;

	        let successTrigger = '';
			let errorTrigger = ['failed:api' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
	        utils.get( endpoint, resultTriggers, this.resolveMessageContent.bind( this ) );
		},

		resolveMessageContent: function( data ) {
			channel.trigger( 'received:message', data );
		},

		sendMessageReply: function( payload ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/message';
			let successTrigger = [ 'reply:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner', 'reply:failure' ];
			// Show spinner during HTTP request
			channel.trigger( 'toggle:globalSpinner' );
			utils.post( endpoint, payload, successTrigger, errorTrigger, null, true );
		},

		sendMessage: function( message ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/message';
			let payload = {
				body: message.body,
				contact_id: message.patient,
				subject: message.subject
			}
			let successTrigger = [ 'sent:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner', 'sent:failure' ];
			// Show spinner during HTTP request
			channel.trigger( 'toggle:globalSpinner' );
			utils.post( endpoint, payload, successTrigger, errorTrigger, null, true );
		},

		sendMassMail: function( message ) {
			let endpoint = `${ BASE_ENGAGE_URL }v1/message/provider/${ message.provider }/massmail`;
			let payload = {
				body: message.body,
				subject: message.subject
			}
			let successTrigger = [ 'sent:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner', 'sent:failure' ];
			// Show spinner during HTTP request
			channel.trigger( 'toggle:globalSpinner' );
			utils.post( endpoint, payload, successTrigger, errorTrigger, null, true );
		},

		// Archive methods

		getArchivedMessages: function( currentPage = 0 ) {
			const controller = app.controller;
			let selectedLocation = controller.selectedLocationId || -1;
			let selectedProvider = controller.selectedProviderId || -1;
			let selectedOrganization = controller.selectedOrganization && controller.selectedOrganization.id ;
			let userRole = '';
			if ( controller.user ) {
				if ( controller.user.user ) {
					userRole = 'user';
				} else if ( controller.user.messenger ) {
					userRole = 'messenger';
				} else {
					userRole = 'admin';
				}
			}
			let endpoint = BASE_ENGAGE_URL + this.getHeaderURL( userRole, selectedLocation, selectedProvider, selectedOrganization, inbox ) + '&fetch_archive=true';

	        let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			channel.trigger( 'show:contentSpinner' );
	        utils.get( endpoint, resultTriggers, this.resolveArchivedMessages.bind( this, currentPage ) );
		},

		resolveArchivedMessages: function( currentPage, data ) {
			let messages = data.map( ( message ) => {
				return Object.assign( {}, message, {
					sent_date: app.controller.formatLocalDateForUI( message.date_created )
				});
			});
			this.messaging.currentPage = currentPage;
			this.messaging.pageSize = 50;
			this.setMessagingIndex();
			this.messaging.totalMessages = data.length;
			this.messaging.totalPages = Math.ceil( data.length / 50 );
			this.setGlobalValue( 'messages', messages );
			this.setGlobalValue( 'filteredMessages', null );
		},

		getSentMessages: function() {
			const controller = app.controller;
			let selectedLocation = controller.selectedLocationId || -1;
			let selectedProvider = controller.selectedProviderId || -1;
			let selectedOrganization = controller.selectedOrganization && controller.selectedOrganization.id ;
			let userRole = '';
			if ( controller.user ) {
				if ( controller.user.user ) {
					userRole = 'user';
				} else if ( controller.user.messenger ) {
					userRole = 'messenger';
				} else {
					userRole = 'admin';
				}
			}
			let endpoint = BASE_ENGAGE_URL + this.getHeaderURL( userRole, selectedLocation, selectedProvider, selectedOrganization, outbox );

	        let successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			channel.trigger( 'show:contentSpinner' );
	        utils.get( endpoint, resultTriggers, this.resolveSentMessages.bind( this ) );
		},

		resolveSentMessages: function( data ) {
			let messages = data.map( ( message ) => {
				return Object.assign( {}, message, {
					sent_date: app.controller.formatLocalDateForUI( message.date_created )
				});
			});
			this.messaging.currentPage = 0;
			this.messaging.pageSize = 50;
			this.setMessagingIndex();
			this.messaging.totalMessages = data.length;
			this.messaging.totalPages = Math.ceil( data.length / 50 );
			this.setGlobalValue( 'messages', messages );
			this.setGlobalValue( 'filteredMessages', messages );
		},

		getNotificationsURL: function( userRole, selectedLocation, selectedProvider, selectedOrganization, searchInput,queryNurse,queryRefill,queryAppointment ) {
		    switch(userRole.toLowerCase()) {
		        case 'messenger':
		        case 'user':
		            if( selectedLocation == -1 && selectedProvider == -1 ) {
			            endpoint = `v2/ccm/notifications/organization/${selectedOrganization}/threads?type=organization&id=${selectedOrganization}${this.notification_type === 'archived' ? '&active=false' : this.notification_type === 'priority' ? '&priority=true' : '&active=true'}`
		            } else if( selectedProvider != -1 ) {
			            endpoint = `v2/ccm/notifications/organization/${selectedOrganization}/threads?type=provider&id=${selectedProvider}${this.notification_type === 'archived' ? '&active=false' : this.notification_type === 'priority' ? '&priority=true' : '&active=true'}`
		            } else if( selectedLocation != -1 ) {
			            endpoint = `v2/ccm/notifications/organization/${selectedOrganization}/threads?type=location&id=${selectedLocation}${this.notification_type === 'archived' ? '&active=false' : this.notification_type === 'priority' ? '&priority=true' : '&active=true'}`
		            }
		            if ( searchInput ) {
		            	endpoint = endpoint + '&' + searchInput;
		            }
		            if(queryNurse){
		            	endpoint = endpoint + '&' +'tags=Nurse';
					}
					if(queryRefill){
						endpoint = endpoint + '&' +'tags=Refill';
					}
					if(queryAppointment){
						endpoint = endpoint + '&' +'tags=Appointment';
					}
		            return endpoint;
		            break;
		        default:
		            let endpoint = `v2/ccm/notifications/provider/${selectedProvider}/threads${this.notification_type === 'archived' ? '?active=false' : this.notification_type === 'priority' ?'?priority=true' :''}`;
				    if ( searchInput ) {
		            	endpoint = endpoint + '?' + searchInput
				    }
					if(queryNurse){
						if(endpoint.indexOf('?') != -1) {
							endpoint = endpoint + '&' + 'tags=Nurse';
						}else{
							endpoint = endpoint + '?' + 'tags=Nurse';
						}
					}
					if(queryRefill){
						if(endpoint.indexOf('?') != -1) {
							endpoint = endpoint + '&' + 'tags=Refill';
						}else{
							endpoint = endpoint + '?' + 'tags=Refill';
						}
					}
					if(queryAppointment){
						if(endpoint.indexOf('?') != -1) {
							endpoint = endpoint + '&' + 'tags=Appointment';
						}else{
							endpoint = endpoint + '?' + 'tags=Appointment';
						}
					}
				    return endpoint;
		    }
		},

		getNotifications: function( page = 0,queryNurse = false,queryRefill = false, queryAppointment =false ) {
			if(app.controller.integratedNotificationsEnabled){
				return;
			}
			let controller = app.controller;
			let selectedLocation = controller.selectedLocationId || -1;
			let selectedProvider = controller.selectedProviderId || -1;
			let selectedOrganization = controller.selectedOrganization && controller.selectedOrganization.id;
			let userRole = '';
			if ( controller.user ) {
				if ( controller.user.user ) {
					userRole = 'user';
				} else if ( controller.user.messenger ) {
					userRole = 'messenger';
				} else {
					userRole = 'admin';
				}
			}
			let endpoint = BASE_ENGAGE_URL + this.getNotificationsURL( userRole, selectedLocation, selectedProvider, selectedOrganization, null,
				queryNurse,queryRefill,queryAppointment);
			let headerData = {
				page: page,
				pageSize: 20,
				contentType: 'application/json; charset=UTF-8'
			}

	        let successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveNotifications.bind( this ), null, headerData );
		},

		searchNotifications: function( page = 0, searchInput = '',queryNurse = false,queryRefill = false, queryAppointment =false) {
			let controller = app.controller;
			let selectedLocation = controller.selectedLocationId || -1;
			let selectedProvider = controller.selectedProviderId || -1;
			let selectedOrganization = controller.selectedOrganization && controller.selectedOrganization.id;
			let userRole = '';
			if ( !this.searchInput || this.searchInput != searchInput ) {
				this.setGlobalValue( 'searchInput', searchInput );
			}
			if ( controller.user ) {
				if ( controller.user.user ) {
					userRole = 'user';
				} else if ( controller.user.messenger ) {
					userRole = 'messenger';
				} else {
					userRole = 'admin';
				}
			}
			let endpoint = BASE_ENGAGE_URL + this.getNotificationsURL( userRole, selectedLocation, selectedProvider, selectedOrganization, searchInput,
				queryNurse, queryRefill,queryAppointment);
			let headerData = {
				page: page,
				pageSize: 20,
				contentType: 'application/json; charset=UTF-8'
			};

			let successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveNotifications.bind( this ), null, headerData );
		},

		resolveNotifications: function( data ) {
			let controller = app.controller;
			let notifications = _.map( data.items, ( notification ) => {
				let differentProvider = false;
				if(controller.user.provider && notification.provider_id != controller.user.id){
					differentProvider = true;
				}

				return Object.assign( {}, notification, {
					sent_date: app.controller.formatLocalDateAndTimeForUI( notification.last_message_date ),
					dob: app.controller.formatFixedDateForUI( notification.patient_dob ),
					differentProvider: differentProvider
				});
			});
			let beginIndex = ( data.page * 20 ) + 1;
			let endIndex = ( data.page * 20 ) + 20 < data.total_items ? ( data.page * 20 ) + 20 : data.total_items;
			let notificationMetadata = {
				beginIndex,
				endIndex,
				currentPage: data.page,
				totalNotifications: data.total_items,
				totalPages: data.total_pages,
			};
			this.setGlobalValue( 'notificationMetadata', notificationMetadata );
			this.setGlobalValue( 'notifications', notifications );
		},

		getNotificationContent: function( patientId, providerId, page = 0 ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/notifications/provider/' + providerId + '/thread/patient/' + patientId;
	        let successTrigger = [ 'notificationContent:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			};
			let headers = {
				page,
				pageSize: 20,
				contentType: 'application/json; charset=UTF-8',
				accept: '*/*'
			};
			channel.trigger( 'toggle:globalSpinner' );
			utils.get( endpoint, '', (data) => {
			  const providersDetail = {};
			  let providerIds = '';
			  _.each(data.items,(item)=>{
			    if(!providersDetail[item.provider_id]){
            providerIds += 'providerIds=' + item.provider_id + '&';
          }
          providersDetail[item.provider_id] ={};

        });
        providerIds =  providerIds.substring(0, providerIds.length -1);
			  const providerDetailEndPoint = BASE_ENGAGE_URL+'v1/providers/notifications/'+patientId+'?'+providerIds;
        utils.get( providerDetailEndPoint, resultTriggers, (providerDetailResult) => {
          _.each(providerDetailResult,(provider)=>{
            providersDetail[provider.id] = provider;
          });
          // alert(providersDetail);
          this.resolveNotificationContent(data,providersDetail);
        });


      }, null, headers );
		},

		resolveNotificationContent: function( data, providersDetail ) {
			let notificationContent = _.map( data.items, ( notification ) => {

				return Object.assign( {}, notification, {
					sent_time: app.controller.formatLocalDateAndTimeForUI( notification.created_ts, 'MMM D, YYYY h:mm a' ),
					unix: +moment( notification.created_ts ).format('x'),
					providerName: notification.Send_type === 'CCM' ?
              ((notification.nurse_name ? notification.nurse_name : '') +
                  (notification.title ? ', '+notification.title : '')) :
              providersDetail[notification.provider_id].name
				});
			});
			let notificationThreadMetadata = {
				page: data.page,
				totalPages: data.total_pages,
				}
			this.setGlobalValue( 'notificationThreadMetadata', notificationThreadMetadata );
			this.setGlobalValue( 'notificationContent', data.page == 0 ? notificationContent : this.notificationContent.concat( notificationContent ) );
		},

		notificationReply: function( value, patient_id, provider_id, sender_id, page,priority=false,tags =[]) {
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/notifications/provider';
	        let successTrigger = [ 'replyNotification:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner', 'replyNotification:failure' ];
			let body = {
				body: value,
				patient_id,
				provider_id,
				sender_id,
				priority,
				tags
			};
			channel.trigger( 'toggle:globalSpinner' );
			utils.post( endpoint, body, successTrigger, errorTrigger, null, true, this.resolveNotificationReply.bind(this) )
		},

		resolveNotificationReply: function( data ) {
			this.getNotifications( this.notificationMetadata.currentPage );
		},

		markPatientNotificationRead: function(patientId){
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/notifications/thread/patient/'+patientId+'/read';
			let successTrigger = [ 'markNotificationRead:success' ];
			let errorTrigger = [ 'failed:api' ];
			utils.put( endpoint, {}, successTrigger, errorTrigger, null, true, this.resolveMarkNotificationRead.bind(this) );
		},

		markNotificationRead: function( notification_ids, user_id ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/notifications/provider';
	        let successTrigger = [ 'markNotificationRead:success' ];
			let errorTrigger = [ 'failed:api' ];
			let body = {
				ids: notification_ids,
				read_by: user_id
			};
			utils.put( endpoint, body, successTrigger, errorTrigger, null, true, this.resolveMarkNotificationRead.bind(this) );
		},

		resolveMarkNotificationRead: function( data ) {
			app.controller.getUnreadNotificationsCount();
		},

		getNotificationProviders: function( locationId ) {
			let providers = [];
			_.each(app.controller.locations, (location) => {
				if ( location.id == locationId ) {
					providers = providers.concat( location.providers )
				}
			});
			this.setGlobalValue( 'filteredNotificationProviders', providers );
		},

		getNotificationPatients: function( providerId ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + app.controller.selectedOrganization.id + '/provider/' + providerId + '/enrolledpatient';
	        let successTrigger = [ 'filteredNotificationPatients:success' ];
			let errorTrigger = [ 'failed:api' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			utils.get( endpoint, resultTriggers, this.resolveGetNotificationPatients.bind(this) );
		},

		resolveGetNotificationPatients: function( data ) {
			data.items && data.items.length && data.items.forEach( ( patient ) => {
				patient.formatted_birthdate = app.controller.formatFixedDateForUI( data.birthdate );
			});
			this.setGlobalValue( 'filteredNotificationPatients', data.items );
		},

		submitNewNotification: function( notification ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/notifications/provider';
	        let successTrigger = [ 'submitNewNotification:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			};
			let body = {
				body: notification.body,
				patient_id: notification.patient_id,
				provider_id: notification.provider_id,
				sender_id: notification.sender_id,
			}
			channel.trigger( 'toggle:globalSpinner' )
			utils.post( endpoint, body, successTrigger, errorTrigger, null, true, this.resolveSubmitNewNotification.bind(this) );
		},

		resolveSubmitNewNotification: function() {
			this.getNotifications( this.notificationMetadata.currentPage );
		},

		toggleNotificationActive: function( provider_id, patient_id, is_active,callback ) {
			let endpoint = `${BASE_ENGAGE_URL}v1/ccm/notifications/thread/patient/${patient_id}/${is_active ? 'archive' : 'unarchive'}`;
			let successTrigger = [ 'toggleNotificationActive:success' ];
			let errorTrigger = [ 'failed:api' ];
			utils.post( endpoint, null, successTrigger, errorTrigger, null, true, this.resolveToggleNotificationActive );
			if(callback){
				callback()
			}
		},

		resolveToggleNotificationActive: function( data ) {

		},

		getCcmDashboardUrl: function( userRole='', selectedLocationId, selectedProviderId, organizationId ) {
		    let endpoint = `v1/ccm/organization/${organizationId}/dashboard`;
            if( selectedLocationId === -1 && selectedProviderId === -1 ) {
                return endpoint;
            } else if( selectedProviderId !== -1 ) {
                return endpoint = endpoint + `?type=provider&id=${selectedProviderId}`;
            } else if( selectedLocationId !== -1 ) {
                return endpoint = endpoint + `?type=location&id=${selectedLocationId}`;
            }
		},

		getLocationDashboardUrl: function(  selectedLocationId, selectedProviderId, organizationId ) {
			let endpoint = `v1/ccm/organization/${organizationId}/location_billing_dashboard`;
			if( selectedLocationId === -1 && selectedProviderId === -1 ) {
				return endpoint;
			} else if( selectedProviderId !== -1 ) {
				return endpoint + `?type=provider&id=${selectedProviderId}`;
			} else if( selectedLocationId !== -1 ) {
				return endpoint + `?type=location&id=${selectedLocationId}`;
			}
		},

		getLocationBillingDashboard: function() {
			let organizationId = ( app.controller.selectedOrganization && Number(app.controller.selectedOrganization.id) ) || null;
			let selectedLocation = Number(app.controller.selectedLocationId) || -1;
			let selectedProvider = Number(app.controller.selectedProviderId) || -1;
			let selectedOrganization = ( app.controller.selectedOrganization && Number(app.controller.selectedOrganization.id) ) || null;
			let endpoint = BASE_ENGAGE_URL + this.getLocationDashboardUrl( selectedLocation, selectedProvider, selectedOrganization );
			let successTrigger = ['getCcmDashboard:success', 'hide:contentSpinner'];
			let errorTrigger = ['getCcmDashboard:fail', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveLocationDashboard.bind( this ) );

		},
		getCcmDashboard: function() {
			let userRole = ( app.controller.user && app.controller.user.user ) ? 'admin' : 'user';
			let selectedLocation = Number(app.controller.selectedLocationId) || -1;
			let selectedProvider = Number(app.controller.selectedProviderId) || -1;
			let selectedOrganization = ( app.controller.selectedOrganization && Number(app.controller.selectedOrganization.id) ) || null;
			let endpoint = BASE_ENGAGE_URL + this.getCcmDashboardUrl( userRole, selectedLocation, selectedProvider, selectedOrganization );
			let successTrigger = ['getCcmDashboard:success', 'hide:contentSpinner'];
			let errorTrigger = ['getCcmDashboard:fail', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveGetCcmDashboard.bind( this ) );
		},

		resolveGetCcmDashboard: function( data ) {
			this.setGlobalValue( 'mainDashboardData', data )
		},

		resolveLocationDashboard: function(data) {
			this.setGlobalValue( 'locationDashboardData', data )
		}

	}
}
