import Backbone from 'backbone';

export const SupportModel = Backbone.Model.extend({

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	viewCarePlan: function( patientId ) {
		this.app.ccm_controller.openPatientPlan( patientId );
	},

    viewCcda: function( patientId ) {
        this.app.ccm_controller.openCcda( patientId );
    },

	viewPatientHistory: function( patientId ) {
		this.app.ccm_controller.openPatientHistory( patientId );
	},

    sendPatientToSupport: function( patientId ) {
        let message = 'Clicking \"confirm\" will send this patient to be worked by a Retention Specialist.';
        let callback = this.app.ccm_controller.sendPatientToSupport.bind( this.app.ccm_controller );
        let data = {
            patientId
        };
        this.app.controller.confirmationModal( message, callback, data );
    },

    getSuspendReasons: function(callback) {
        this.app.ccm_controller.getSuspendReasons(callback);
    },

    getUnenrollReasons: function(callback) {
        this.app.ccm_controller.getUnenrollReasons(callback);
    },
    openConfidentialNotes: function( patientId ) {
		this.app.ccm_controller.openConfidentialNotes( patientId );
	},

});
