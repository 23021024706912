import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';

import { Utils } from '../../utils';

import ErrorModalView from '../../ErrorModalView';

const channel = Backbone.Radio.channel('AppChannel');
const utils = new Utils();
const userCookie = 'user=';

export const CPC_Controller = ( app ) => {

	const BASE_ENGAGE_URL = app.config.BASE_ENGAGE_URL;

	return {

		/**********************
		Global values
		**********************/

		patients: {
			totalPages: 0,
			currentPage: 0,
			totalPatients: 0
		},

		// Takes a valueName and data and creates or replaces a variable with that name and data
		setGlobalValue: function ( valueName, data ) {
			this[ valueName ] = data;
			channel.trigger( 'set:' + valueName );
		},

		// Takes a collection and a single updated model and replaces the old instance of the model with the new
		// Model must have all properties that were returned with initial fetch of collection
		updateModelInCollection: function ( collection, updatedItem ) {
			collection.splice(_.findIndex(collection, 'id', updatedItem.id), 1, updatedItem);
		},

		/**********************
		CPC Specific Methods
		**********************/

		clearValue: function( value ) {
			this[ value ] = null;
		},

		patientListPageUp: function() {
			if ( this.patients.currentPage + 1 == this.patients.totalPages ) {
				return;
			}
			this.patients.currentPage++;
			channel.trigger( 'set:patients' );

			if( !this.searchInput ) {
				this.determineNonSearchedGetType( this.patients.currentPage );
				return;
			}
			this.determineSearchedGetType( this.patients.currentPage, 20, this.searchInput );
		},

		patientListPageDown: function() {
			if ( this.patients.currentPage == 0 ) {
				return;
			}
			this.patients.currentPage--;
			channel.trigger( 'set:patients' );

			if( !this.searchInput ) {
				this.determineNonSearchedGetType( this.patients.currentPage );
				return;
			}
			this.determineSearchedGetType( this.patients.currentPage, 20, this.searchInput );
		},

		determineNonSearchedGetType: function( currentPage ) {
			let currentScreen = app.controller.subnavItem;
			switch( currentScreen ) {
				case 'Patient List':
					this.getPatientList( currentPage );
					break;
				default:
					this.getPatientList( currentPage );
			}
		},

		determineSearchedGetType: function( currentPage, pageSize, searchInput ) {
			let currentScreen = app.controller.subnavItem;
			switch( currentScreen ) {
				case 'Patient List':
					this.searchPatients( currentPage, pageSize, searchInput );
					break;
				default:
					this.searchPatiens( currentPage, pageSize, searchInput );
			}
		},

		getPatientList: function( page = 0, pageSize = 20 ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients';
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind(this), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getPatientList.bind( this ) );
		},

		resolveGetPatientList: function( data ) {

			let hasProviders = app.controller.providers && app.controller.providers.length;
			let patients = [];
			if ( data.items && data.items.length ) {
				patients = data.items.map( ( patient ) => {
					if ( hasProviders ) {
						let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
						return Object.assign( {}, patient, {
							provider: ( providerObj && providerObj.name ) || '',	
							birthdate: app.controller.formatFixedDateForUI( patient.birthdate ),
						});
					}
					return Object.assign( {}, patient, {
						birthdate: app.controller.formatFixedDateForUI( patient.birthdate ),
					});
				});	
			}

			let triggerSetPatients = false;

			if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
				this.patients.totalPages = data.total_pages;
				triggerSetPatients = true;
			}
			if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
				this.patients.totalPatients = data.total_items;
				triggerSetPatients = true;
			}
			if ( !this.patients.currentPage ) {
				this.patients.currentPage = channel.request( 'currentPage' );
				triggerSetPatients = true;
			}
			if ( !hasProviders ) {
				channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
			}
			this.setGlobalValue( 'patientList', patients );
			triggerSetPatients && channel.trigger( 'set:patients' );

		},

		addProvidersToPatients: function() {
			let patientList = app.cpc_controller.patientList;
			patientList = patientList.map( ( patient ) => {
				let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
				patient.provider = ( providerObj && providerObj.name ) || '' ;
				return patient;
			});
			this.setGlobalValue( 'patientList', patientList );
		},

		getPatientListCSV: function( page = 0, pageSize = 20 ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			let searchInput = this.searchInput ? this.searchInput.replace( /search=/gi, '' ) : '';
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients?search=${ searchInput }`;
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				accept: 'text/csv'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientListCSV.bind( this ), null, headerData );
				return;
			}
		},

		resolveGetPatientListCSV: function( data ) {
			let file = new Blob( [ data ], { type: 'csv' } );
			let searchInput = this.searchInput ? this.searchInput.replace(/search=/gi, '') : '';
			let filename = `patient-list_${ searchInput }_${moment().format('MM/DD/YYYY')}.csv`;
			if (navigator.msSaveOrOpenBlob) {
				navigator.msSaveOrOpenBlob(file, filename);
			} else {
				let a = document.createElement('a');
				let url = URL.createObjectURL(file);
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				}, 0);
			}
		},

		searchPatients: function( page, pageSize, searchInput ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			if ( !this.searchInput || this.searchInput != searchInput ) {
				this.setGlobalValue( 'searchInput', searchInput );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients?' + searchInput;
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveSearchPatients.bind( this ), null, headerData );
				return;
			}
		},

		resolveSearchPatients: function( data ) {
			let hasProviders = app.controller.providers && app.controller.providers.length;
			let patients = [];
			if ( data.items && data.items.length ) {
				patients = data.items.map( ( patient ) => {
					if ( hasProviders ) {
						let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
						return Object.assign( {}, patient, {
							provider: ( providerObj && providerObj.name ) || '',	
							birthdate: app.controller.formatFixedDateForUI( patient.birthdate ),
						});
					}
					return Object.assign( {}, patient, {
						birthdate: app.controller.formatFixedDateForUI( patient.birthdate ),
					});
				});	
			}

			let triggerSetPatients = false;

			if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
				this.patients.totalPages = data.total_pages;
				triggerSetPatients = true;
			}
			if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
				this.patients.totalPatients = data.total_items;
				triggerSetPatients = true;
			}
			if ( !this.patients.currentPage ) {
				this.patients.currentPage = channel.request( 'currentPage' );
				triggerSetPatients = true;
			}
			if ( !hasProviders ) {
				channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
			}
			this.setGlobalValue( 'patientList', patients );
			triggerSetPatients && channel.trigger( 'set:patients' );
		},

		editPatient: function( patientId, patientData ) {
			console.log( 'editing patient', patientId, patientData );
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/update_patient';
			let successTrigger = [ 'enroll:success', 'close:modal', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner' ];
			// Show spinner during HTTP request
			channel.trigger( 'toggle:globalSpinner' );
			utils.put( endpoint, patientData, successTrigger, errorTrigger, null, true, this.updatePatientList.bind( this ) );
		},

		updatePatientList: function( data ) {
			let hasProviders = app.controller.providers && app.controller.providers.length;
			let updatedPatientList = this.patientList.map( ( patient ) => {
				patient.birthdate = app.controller.formatFixedDateForUI( patient.birthdate );
				return patient;
			});
			this.setGlobalValue( 'patientList', updatedPatientList );
		},		
		
	}
};
