import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

import bulkChangeInsuranceModalView from '../../../../templates/PatientEnrollment/RapidReview/bulkChangeInsuranceModalView';

export default Marionette.ItemView.extend({
    events: {
        'click .cancel': 'closeModal',
        'click #saveBulkInsuranceSelection': 'saveBulkInsuranceSelection',
        'change #changePrimary': 'changePrimary',
        'change #changeSecondary': 'changeSecondary'
    },

    initialize: function(options) {
        this.channel = Backbone.Radio.channel('AppChannel');
        this.app = this.channel.request('app');
        this.selectedInsurances = options.selectedInsurances;
        this.listenTo(this.channel, 'close:modal', this.closeModal);
    },

    getTemplate: function() {
        return bulkChangeInsuranceModalView({ count: this.selectedInsurances.length });
    },

    closeModal: function() {
        $('#bulkChangeModal').closeModal();
        this.destroy();
    },

    onRender: function() {
        this.$('#primaryInsuranceToggleSection').hide();
        this.$('#secondaryInsuranceToggleSection').hide();
    },
    
    changePrimary: function(e) {
        if (e.target.checked) {
            this.$('#primaryInsuranceToggleSection').show();
        } else {
            this.$('#primaryInsuranceToggleSection').hide();
        }
    },

    changeSecondary: function(e) {
        if (e.target.checked) {
            this.$('#secondaryInsuranceToggleSection').show();
        } else {
            this.$('#secondaryInsuranceToggleSection').hide();
        }
    },

    showTriggered: function() {
		let errors = this.model.get( 'errors' );
		_.each( errors, ( error ) => $( '#' + error.field ).addClass( 'invalid' ) );
		const self = this;
	},

    saveBulkInsuranceSelection: function(e) {
        e.preventDefault();
        const changePrimary = this.$('#changePrimary').prop('checked');
        const changeSecondary = this.$('#changeSecondary').prop('checked');
        const includeAsPrimary = changePrimary ? this.$('#bulkIncludeAsPrimary').prop('checked') : null;
        const includeAsSecondary = changeSecondary ? this.$('#bulkIncludeAsSecondary').prop('checked') : null;

        this.applyChangesToSelectedInsurances(
          includeAsPrimary,
          includeAsSecondary
        );
        this.closeModal();
        this.channel.trigger("change:reRender");
    },
    
    applyChangesToSelectedInsurances: function(includeAsPrimary, includeAsSecondary) {
		const rinConUserId = this.model.get('rinConUserId');

        const items = [];
        for (let i = 0; i < this.selectedInsurances.length; i++) {
            items.push({
              id: this.selectedInsurances[i].get("id"),
              includeForEligibilityPrimary:
                includeAsPrimary !== null
                  ? includeAsPrimary
                  : this.selectedInsurances[i].get(
                      "includeForEligibilityPrimary"
                    ),
              includeForEligibilitySecondary:
                includeAsSecondary !== null
                  ? includeAsSecondary
                  : this.selectedInsurances[i].get(
                      "includeForEligibilitySecondary"
                    ),
            });
        }
        const payload = {
            orgInsuranceUpdates: items
        };

        if (items.length > 0) {
            this.app.controller.saveIntegratedInsurances(rinConUserId, payload)
        }

		this.closeModal();
		this.channel.trigger("change:reRender");
    }    
});
