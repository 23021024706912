// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import moment from 'moment';

// Internal Dependencies - screens/views/templates
import notificationConversationItemView  from '../../../../templates/Main/Notifications/notificationConversationItemView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: function() {
		let baseClasses = 'col m10 pvs phxs '
		return this.model.get( 'Send_type' ) == 'CCM' ? baseClasses + 'ccm-notification-item' : baseClasses + 'provider-notification-item offset-m2';
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	getTemplate: function() {
		return notificationConversationItemView( this.model );
	},

});
