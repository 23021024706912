import Backbone from 'backbone';

export const NavbarModel = Backbone.Model.extend({
	
	initialize: function( options ) {
		this.app = options.app;
		this.channel = options.channel;
		this.listenTo( this.channel, 'set:unreadMessages', this.setUnread );
		this.listenTo( this.channel, 'set:unreadNotifications', this.setUnread )
	},

	setUnread: function( ) {
		const unreadMessages = this.app.controller.unreadMessages;
		const unreadNotifications = this.app.controller.unreadNotifications;
		this.set( 'unreadMessages', unreadMessages );
		this.set( 'unreadNotifications', unreadNotifications );
	},

});