// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import supportHelpDeskView  from '../../../../templates/Utilities/Support/supportHelpDeskView';
// Internal Dependencies - models/collections
import { SupportModel } from './models/SupportModel';


export default Marionette.ItemView.extend({

	tagName: 'div',

	events: {
		'click #sendSupportMessage': 'sendSupportMessage'
	},

	className: 'support-help-desk-view',

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new SupportModel();
	},

	getTemplate: function() {
		 return supportHelpDeskView( this.model );
	},

	sendSupportMessage: function() {
		let message = {
				name: $('#support-contact-name').val(),
				message: $('.support-message-content').text()
			}
		if ( this.model.isValid( message ) ) {
			this.model.sendSupportMessage( message );
			$('.support-message-content').text('');
			return;
		}
		//if not valid produce error
		console.log( 'Support Message Must be populated to send request');
	}
});
