import Backbone from 'backbone';

export const SummaryModel = Backbone.Model.extend({

    defaults: {
        dateRange: false,
        startMonth: null,
        endMonth: null,
        year: 2017,
        advancingSummaryTotals: {
            totalAdvancingScore: '',
            totalAdvancingColor: '',
        },
        advancingSummaryBaseScore: {
            pointsAchieved: null,
            pointsAvailable: null,
            color: '',
            criteriaComplete: null,
            totalCriteria: null,
        },
        advancingSummaryBonusScore: {
            criteriaComplete: null,
            totalCriteria: null,
            healthDataRegistryComplete: null,
            improvementComplete: null,
        },
        advancingSummaryPerformanceScore: {
            criteriaComplete: null,
            totalCriteria: null,
            pointsAchieved: null,
            pointsAvailable: null,
            color: '',
        },
        advancingSummaryPerformanceCriteria: {
            patientAccessPoints: null,
            patientAccessColor: '',
            healthInformationExchangePoints: null,
            healthInformationExchangeColor: '',
            vdtPoints: null,
            vdtColor: '',
            patientSpecificEducationPoints: null,
            patientSpecificEducationColor: '',
            secureMessagingPoints: null,
            secureMessagingColor: '',
            medicationReconciliationPoints: null,
            medicationReconciliationColor: '',
            ImmunizationRegistryPoints: null,
            ImmunizationRegistryColor: '',
        }
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.colors = {
            'FAIL': '#A01313',
            'WARN': '#f57b3e',
            'PASS': '#169E54',
            'blue': '#1080AF',
            'gray': '#C6C6C6',
        };
        this.listenTo( this.channel, 'set:advancingSummaryPerformanceCriteria', this.setSummaryData );
	    const currentApp = this.app.controller.currentApp;
	    const url = currentApp === 'MIPSPROVIDER' ? 'mips-provider' : 'mips-group';
	    this.set( { url } );
    },

    setSummaryData: function() {
        const controller = this.app.mips_controller;
        const advancingSummaryTotals = controller.advancingSummaryTotals;
        const advancingSummaryBaseScore = controller.advancingSummaryBaseScore;
        const advancingSummaryBonusScore = controller.advancingSummaryBonusScore;
        const advancingSummaryPerformanceScore = controller.advancingSummaryPerformanceScore;
        const advancingSummaryPerformanceCriteria = controller.advancingSummaryPerformanceCriteria;
        this.set( {
            advancingSummaryTotals,
            advancingSummaryBaseScore,
            advancingSummaryBonusScore,
            advancingSummaryPerformanceScore,
            advancingSummaryPerformanceCriteria,
        } );
        this.trigger( 'change:summaryData' );
    },

});