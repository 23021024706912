// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts'

// Internal Dependencies - screens/views/templates
import summaryContainerView  from '../../../../../templates/MIPS/Quality/Summary/summaryContainerView';
import SummaryDateRangeContainerView from '../../DateRangeContainerView'
import OverallScoreContainerView from './OverallScoreContainerView';

// Internal Dependencies - models/collections
import { SummaryModel } from '../models/SummaryModel'

export default Marionette.LayoutView.extend({

    regions: {
        'dateRangeContainer': '.date-range-container',
        'overallScoreContainer': '#overallScoreContainer',
    },

    events: {
        'change #yearToDate': 'setYearToDate',
        'change #dateRange': 'setDateRange',
        'click #applyDateRange': 'fetchData',
    },

    initialize: function( options ) {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.model = new SummaryModel();
        this.app.mips_controller.getQualitySummary( false );
        this.listenTo( this.model, 'change:yearToDate change:dateRange', this.checkAttestationPeriod );
        this.listenTo( this.model, 'change:summaryData', this.updateSummary );
	    if ( this.model.get( 'currentApp' ) === 'MIPSPROVIDER' ) {
		    this.listenTo( this.channel, 'set:selectedProviderId', this.fetchData );
	    }
    },

    getTemplate: function() {
    	const url = this.model.get( 'currentApp' ) === 'MIPSPROVIDER' ? 'mips-provider' : 'mips-group';
        return summaryContainerView( { url } );
    },

    onShow: function() {
        $('.content-container').addClass('widen');
        this.showChildView( 'overallScoreContainer', new OverallScoreContainerView( { model: this.model } ) );
        this.renderCharts()
    },

    updateSummary: function () {
        this.renderCharts();
    },

    renderCharts: function() {
        this.model.diabetesPoorControlChart();
        this.model.advanceCarePlanChart();
        this.model.pneumococcalVaccineChart();
        this.model.tobaccoChart();
        this.model.fallsRiskAssessmentChart();
        this.model.fallsCarePlanChart()
    },

    checkAttestationPeriod: function() {
        this.model.get('dateRange') ?
            this.showChildView( 'dateRangeContainer', new SummaryDateRangeContainerView( { model: this.model } ) ) :
            this.getRegion('dateRangeContainer').empty();
    },

    setYearToDate: function() {
        this.model.set('dateRange', false);
    },

    setDateRange: function() {
        this.model.set('dateRange', true);
    },

	fetchData: function() {
        const dateRange = this.model.get( 'dateRange' );
        const startMonth = this.model.get( 'startMonth' );
        const endMonth = this.model.get( 'endMonth' );
        const year = this.model.get( 'year' );
        if ( dateRange ) {
            this.app.mips_controller.getQualitySummary( dateRange, startMonth, endMonth, year );
        } else {
            this.app.mips_controller.getQualitySummary( dateRange );
        }
    },

    onDestroy: function() {
        $('.content-container').removeClass('widen');
    },

});
