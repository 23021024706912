// External Dependencies
import Backbone from 'backbone';
import Marionette from 'backbone.marionette';

// Internal Dependencies - screens/views
import RegionMap from './RegionMap';
import LoginView from './Components/Login/LoginView';
import ForgotPasswordView from './Components/ForgotPassword/ForgotPasswordView';
import ResetPasswordView from "./Components/ResetPassword/ResetPasswordView";
import ContentView from './ContentView';
import NavbarView from './Components/NavBar/NavbarView';
import SideNavContainerView from './Components/SideNav/SideNavContainerView';

// Internal Dependencies - models/collections/controllers
import { AppListeners } from './AppListeners';
import { CCM_Controller } from './Components/CCM/CCM_Controller';

Backbone.Marionette.Renderer.render = function(template, data){
	return template;
};


export default Marionette.AppRouter.extend({

	// Each route is tied to a method that checks for a user's authentication before navigating to the content
	// displayed on that page via a second method that is called to actually navigate.
	routes: {
		'': 'directMain',
		'login': 'login',
		'forgot-password': 'forgotPassword',
		'reset-password': 'resetPassword',
		'main/dashboard': 'dashboard',
		'main/notifications': 'notifications',
		'main/notifications/priority': 'priorityNotifications',
		'main/notifications/archive': 'archivedNotifications',
		'ccm/patient-list': 'patientList',
		'ccm/auto-enroll': 'autoEnroll',
		'ccm/patient-status/enrolled': 'enrolled',
		'ccm/patient-status/suspended': 'ccmSuspended',
		'ccm/patient-status/provider-transition': 'ccmProviderTransition',
		'ccm/patient-status/support': 'ccmSupport',
        'ccm/patient-status/unverified': 'ccmUnverified',
		'ccm/patient-status/opted-out': 'ccmOptedOut',
		'ccm/rapid-bill/approval-queue': 'rapidBill',
		'ccm/rapid-bill/error-queue': 'errorQueue',
		'ccm/rapid-bill/pending-review': 'pendingReview',
		'ccm/rapid-bill/reports': 'rapidBillReports',
		'ccm/patient-status/pending-unenroll': 'pendingUnenroll',
		'ccm/patient-status/unenrolled': 'unenrolled',
    	'ccm/providerApproval':'providerApproval',
		'ccm/revenueDashboard':'revenueDashboard',
		'cpc/patient-list': 'cpcPatientList',
		'cpc/ecqm': 'cpcEcqm',
		'cpc/reports': 'cpcReports',
		'cpc/documents': 'cpcDocuments',
		'mips/dashboard': 'mipsNewDashboard',
		'mips-group/dashboard': 'mipsGroupDashboard',
		'mips-group/quality-measures/summary': 'mipsGroupQualityMeasures',
		'mips-group/quality-measures/diabetes-poor-control': 'mipsGroupQualityMeasureDiabetesPoorControl',
		'mips-group/quality-measures/advance-care-plan': 'mipsGroupQualityMeasureAdvanceCarePlan',
		'mips-group/quality-measures/pneumococcal-vaccination': 'mipsGroupQualityMeasurePneumococcalVaccination',
		'mips-group/quality-measures/fall-risk-assessment': 'mipsGroupQualityMeasureFallRiskAssessment',
		'mips-group/quality-measures/fall-care-plan': 'mipsGroupQualityMeasureFallCarePlan',
		'mips-group/quality-measures/tobacco-use': 'mipsGroupQualityMeasureTobaccoUse',
		'mips-group/advancing-care/summary': 'mipsGroupAdvancingCare',
		'mips-group/advancing-care/base-score': 'mipsGroupAdvancingCareBaseScore',
		'mips-group/advancing-care/performance-score': 'mipsGroupAdvancingCarePerformanceScore',
		'mips-group/advancing-care/bonus-score': 'mipsGroupAdvancingCareBonusScore',
		'mips-group/improvement-activities': 'mipsGroupImprovementActivities',
		'mips-provider/dashboard': 'mipsProviderDashboard',
		'mips-provider/quality-measures/summary': 'mipsProviderQualityMeasures',
		'mips-provider/quality-measures/diabetes-poor-control': 'mipsProviderQualityMeasureDiabetesPoorControl',
		'mips-provider/quality-measures/advance-care-plan': 'mipsProviderQualityMeasureAdvanceCarePlan',
		'mips-provider/quality-measures/pneumococcal-vaccination': 'mipsProviderQualityMeasurePneumococcalVaccination',
		'mips-provider/quality-measures/fall-risk-assessment': 'mipsProviderQualityMeasureFallRiskAssessment',
		'mips-provider/quality-measures/fall-care-plan': 'mipsProviderQualityMeasureFallCarePlan',
		'mips-provider/quality-measures/tobacco-use': 'mipsProviderQualityMeasureTobaccoUse',
		'mips-provider/advancing-care/summary': 'mipsProviderAdvancingCare',
		'mips-provider/advancing-care/base-score': 'mipsProviderAdvancingCareBaseScore',
		'mips-provider/advancing-care/performance-score': 'mipsProviderAdvancingCarePerformanceScore',
		'mips-provider/advancing-care/bonus-score': 'mipsProviderAdvancingCareBonusScore',
		'mips-provider/improvement-activities': 'mipsProviderImprovementActivities',
		'pe/reporting': 'peReporting',
		'pe/file-manager': 'peFileManager',
		'pe/rapid-review': 'peRapidReview',
		'pe/patient-approval/awaiting-approval': 'peApproval',
        'pe/patient-approval/needs-attention': 'peNeedsAttention',
        'pe/patient-approval/declined': 'peDeclined',
		// 'utilities/historical-billing': 'historicalBilling',
		'utilities/manage-users': 'manageUsers',
		'utilities/resources': 'resources',
		'utilities/support': 'support',
		'utilities/change-password': 'changePassword',
    	'utilities/reset-env': 'resetEnv',
		'utilities/bulkProviderChange': 'bulkProviderChange',
		'utilities/bulkProviderSuspension': 'bulkProviderSuspension',
		'utilities/bulkProviderTransition': 'bulkProviderTransition',
		'*notFound': 'notFound',
	},

	initialize: function(app) {
		this.app = app;
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		AppListeners( this, this.channel, this.app );
	},

	notFound: function() {
		this.navigate( '/main/dashboard', true );
	},

	directMain: function() {
		this.navigate( '/main/dashboard', true );
	},

	login: function() {
		this.app.controller.checkUserAuthentication( { route: 'LoginSuccessHome', module: 'Main' }, true );
		if ( this.regionMap ) {
			this.regionMap = null;
		}
		this.app.getRegion('main').show(new LoginView());
	},

	forgotPassword: function() {
		this.app.getRegion('main').show(new ForgotPasswordView());
	},

	resetPassword: function() {
		this.app.getRegion('main').show(new ResetPasswordView());
	},

	/***************************
		Main module routes
	***************************/

	dashboard: function() {
		this.app.controller.checkUserAuthentication( { route: 'Dashboard', module: 'Main' } );
	},

	goDashboard: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MAIN_DASHBOARD' );
			return
		}
		this.channel.trigger( 'change:content', 'MAIN_DASHBOARD');
	},

	messaging: function() {
		this.app.controller.checkUserAuthentication( { route: 'Messaging', module: 'Main' } );
	},

	goMessaging: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MAIN_MESSAGING' );
			return
		}
		this.channel.trigger( 'change:content', 'MAIN_MESSAGING' );
	},

	archive: function() {
		this.app.controller.checkUserAuthentication( { route: 'Archive', module: 'Main' } );
	},

	goArchive: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MAIN_ARCHIVE' );
			return
		}
		this.channel.trigger( 'change:content', 'MAIN_ARCHIVE' );
	},

	sent: function() {
		this.app.controller.checkUserAuthentication( { route: 'Sent', module: 'Main' } );
	},

	goSent: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MAIN_SENT' );
			return
		}
		this.channel.trigger( 'change:content', 'MAIN_SENT' );
	},


	notifications: function() {
		this.app.controller.checkUserAuthentication( { route: 'Notifications', module: 'Main' } );
	},

	goNotifications: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MAIN_NOTIFICATIONS' );
			return
		}
		this.channel.trigger( 'change:content', 'MAIN_NOTIFICATIONS' );
	},

	archivedNotifications: function() {
		this.app.controller.checkUserAuthentication( { route: 'ArchivedNotifications', module: 'Main' } );
	},

	priorityNotifications: function() {
		this.app.controller.checkUserAuthentication( { route: 'PriorityNotifications', module: 'Main' } );
	},

	goArchivedNotifications: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MAIN_ARCHIVED_NOTIFICATIONS' );
			return
		}
		this.channel.trigger( 'change:content', 'MAIN_ARCHIVED_NOTIFICATIONS' );
	},

	goPriorityNotifications: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MAIN_PRIORITY_NOTIFICATIONS' );
			return
		}
		this.channel.trigger( 'change:content', 'MAIN_PRIORITY_NOTIFICATIONS' );
	},

	/***************************
		CCM module routes
	***************************/

	patientList: function() {
		this.app.controller.checkUserAuthentication( { route: 'PatientList', module: 'Ccm' } );
	},

	goPatientList: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_PATIENT_LIST' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_PATIENT_LIST');
	},

	enrolled: function() {
		this.app.controller.checkUserAuthentication( { route: 'Enrolled', module: 'Ccm' } );
	},

	goEnrolled: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_ENROLLED' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_ENROLLED');
	},

	ccmSuspended: function() {
		this.app.controller.checkUserAuthentication( { route: 'Suspended', module: 'Ccm' } );
	},


	goCcmSuspended: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_SUSPENDED' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_SUSPENDED');
	},

	ccmProviderTransition: function(){
		this.app.controller.checkUserAuthentication( { route: 'ProviderTransition', module: 'Ccm' } );
	},

	goCcmProviderTransition: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_PROVIDER_TRANSITION' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_PROVIDER_TRANSITION');
	},

	ccmSupport: function() {
		this.app.controller.checkUserAuthentication( { route: 'Support', module: 'Ccm' } );
	},
	goCcmSupport: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_SUPPORT' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_SUPPORT');
	},

    ccmUnverified: function() {
        this.app.controller.checkUserAuthentication( { route: 'Unverified', module: 'Ccm' } );
    },
    goCcmUnverified: function() {
        if ( !this.regionMap ) {
            this.determineOrgStatus( 'CCM_UNVERIFIED' );
            return
        }
        this.channel.trigger( 'change:content', 'CCM_UNVERIFIED');
    },

	ccmOptedOut: function() {
		this.app.controller.checkUserAuthentication( { route: 'OptedOut', module: 'Ccm' } );
	},
	goCcmOptedOut: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_OPTED_OUT' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_OPTED_OUT');
	},
	rapidBill: function() {
		this.app.controller.checkUserAuthentication( { route: 'RapidBill', module: 'Ccm' } );
	},

	goRapidBill: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_RAPID_BILL' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_RAPID_BILL');
	},

	errorQueue: function() {
		this.app.controller.checkUserAuthentication( { route: 'ErrorQueue', module: 'Ccm' } );
	},

	goErrorQueue: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_ERROR_QUEUE' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_ERROR_QUEUE');
	},

	pendingReview: function() {
		this.app.controller.checkUserAuthentication( { route: 'PendingReview', module: 'Ccm' } );
	},

	goPendingReview: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_PENDING_REVIEW' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_PENDING_REVIEW');
	},

	rapidBillReports: function() {
		this.app.controller.checkUserAuthentication( { route: 'RapidBillReports', module: 'Ccm' } );
	},

	goRapidBillReports: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_RAPID_BILL_REPORTS' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_RAPID_BILL_REPORTS');
	},

	pendingUnenroll: function() {
		this.app.controller.checkUserAuthentication( { route: 'PendingUnenroll', module: 'Ccm' } );
	},

  providerApproval: function() {
    this.app.controller.checkUserAuthentication( { route: 'ProviderApproval', module: 'Ccm' } );
  },
	revenueDashboard: function() {
		this.app.controller.checkUserAuthentication( { route: 'RevenueDashboard', module: 'Ccm' } );
	},

	goRevenueDashboard: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_REVENUE_DASHBOARD' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_REVENUE_DASHBOARD');
	},

	goPendingUnenroll: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_PENDING_UNENROLL' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_PENDING_UNENROLL');
	},

  goProviderApproval: function() {
    if ( !this.regionMap ) {
      this.determineOrgStatus( 'CCM_PROVIDER_APPROVAL' );
      return
    }
    this.channel.trigger( 'change:content', 'CCM_PROVIDER_APPROVAL');
  },


	unenrolled: function() {
		this.app.controller.checkUserAuthentication( { route: 'Unenrolled', module: 'Ccm' } );
	},

	bulkProviderSuspension: function() {
		this.app.controller.checkUserAuthentication( { route: 'BulkProviderSuspension', module: 'Utilities' } );
	},
	goBulkProviderSuspension: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'UTILITIES_BULK_PROVIDER_SUSPENSION' );
			return
		}
		this.channel.trigger( 'change:content', 'UTILITIES_BULK_PROVIDER_SUSPENSION');
	},
	bulkProviderTransition: function() {
		this.app.controller.checkUserAuthentication( { route: 'BulkProviderTransition', module: 'Utilities' } );
	},
	goBulkProviderTransition: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'UTILITIES_BULK_PROVIDER_TRANSITION' );
			return
		}
		this.channel.trigger( 'change:content', 'UTILITIES_BULK_PROVIDER_TRANSITION');
	},
	goUnenrolled: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_UNENROLLED' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_UNENROLLED' );
	},

	autoEnroll: function() {
		this.app.controller.checkUserAuthentication( { route: 'AutoEnroll', module: 'Ccm' } );
	},

	goAutoEnroll: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CCM_AUTO_ENROLL' );
			return
		}
		this.channel.trigger( 'change:content', 'CCM_AUTO_ENROLL' );
	},

	/***************************
		CPC+ module routes
	***************************/

	cpcPatientList: function() {
		this.app.controller.checkUserAuthentication( { route: 'PatientList', module: 'Cpc' } );
	},

	goCpcPatientList: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CPC_PATIENT_LIST' );
			return
		}
		this.channel.trigger( 'change:content', 'CPC_PATIENT_LIST' );
	},

	cpcEcqm: function() {
		this.app.controller.checkUserAuthentication( { route: 'Ecqm', module: 'Cpc' } );
	},

	goCpcEcqm: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CPC_ECQM' );
			return
		}
		this.channel.trigger( 'change:content', 'CPC_ECQM' );
	},

	cpcDocuments: function() {
		this.app.controller.checkUserAuthentication( { route: 'Documents', module: 'Cpc' } );
	},

	goCpcDocuments: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'CPC_DOCUMENTS' );
			return
		}
		this.channel.trigger( 'change:content', 'CPC_DOCUMENTS' );
	},

	/***************************
		MIPS module routes
	***************************/

	mipsNewDashboard: function() {
		this.app.controller.checkUserAuthentication( { route: 'Dashboard', module: 'Mips' } );
	},

	goMipsNewDashboard: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MIPS_NEW_DASHBOARD' );
			return
		}
		this.channel.trigger( 'change:content', 'MIPS_NEW_DASHBOARD' );
	},

	mipsGroupDashboard: function() {
		this.app.controller.checkUserAuthentication( { route: 'Dashboard', module: 'MipsGroup' } );
	},

	mipsProviderDashboard: function() {
		this.app.controller.checkUserAuthentication( { route: 'Dashboard', module: 'MipsProvider' } );
	},

	goMipsDashboard: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MIPS_DASHBOARD' );
			return
		}
		this.channel.trigger( 'change:content', 'MIPS_DASHBOARD' );
	},

	mipsGroupQualityMeasures: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasures', module: 'MipsGroup' } );
	},

	mipsProviderQualityMeasures: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasures', module: 'MipsProvider' } );
	},

	goMipsQualityMeasures: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MIPS_QUALITY_MEASURES' );
			return
		}
		this.channel.trigger( 'change:content', 'MIPS_QUALITY_MEASURES' );
	},

	mipsGroupQualityMeasureDiabetesPoorControl: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasureDiabetesPoorControl', module: 'MipsGroup' } );
	},

	mipsProviderQualityMeasureDiabetesPoorControl: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasureDiabetesPoorControl', module: 'MipsProvider' } );
	},

    goMipsQualityMeasureDiabetesPoorControl: function() {
        if ( !this.regionMap ) {
            this.determineOrgStatus( 'MIPS_QUALITY_MEASURES_DIABETES_POOR_CONTROL' );
            return
        }
        this.channel.trigger( 'change:content', 'MIPS_QUALITY_MEASURES_DIABETES_POOR_CONTROL' );
    },

	mipsGroupQualityMeasureTobaccoUse: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasureTobaccoUse', module: 'MipsGroup' } );
	},

	mipsProviderQualityMeasureTobaccoUse: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasureTobaccoUse', module: 'MipsProvider' } );
	},

	goMipsQualityMeasureTobaccoUse: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MIPS_QUALITY_MEASURES_TOBACCO_USE' );
			return
		}
		this.channel.trigger( 'change:content', 'MIPS_QUALITY_MEASURES_TOBACCO_USE' );
	},

	mipsGroupQualityMeasureAdvanceCarePlan: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasureAdvanceCarePlan', module: 'MipsGroup' } );
	},

	mipsProviderQualityMeasureAdvanceCarePlan: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasureAdvanceCarePlan', module: 'MipsProvider' } );
	},

	goMipsQualityMeasureAdvanceCarePlan: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MIPS_QUALITY_MEASURES_ADVANCE_CARE_PLAN' );
			return
		}
		this.channel.trigger( 'change:content', 'MIPS_QUALITY_MEASURES_ADVANCE_CARE_PLAN' );
	},

	mipsGroupQualityMeasurePneumococcalVaccination: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasurePneumococcalVaccination', module: 'MipsGroup' } );
	},

	mipsProviderQualityMeasurePneumococcalVaccination: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasurePneumococcalVaccination', module: 'MipsProvider' } );
	},

    goMipsQualityMeasurePneumococcalVaccination: function() {
        if ( !this.regionMap ) {
            this.determineOrgStatus( 'MIPS_QUALITY_MEASURES_PNEUMOCOCCAL_VACCINATION' );
            return
        }
        this.channel.trigger( 'change:content', 'MIPS_QUALITY_MEASURES_PNEUMOCOCCAL_VACCINATION' );
    },

	mipsGroupQualityMeasureFallRiskAssessment: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasureFallRiskAssessment', module: 'MipsGroup' } );
	},

	mipsProviderQualityMeasureFallRiskAssessment: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasureFallRiskAssessment', module: 'MipsProvider' } );
	},

    goMipsQualityMeasureFallRiskAssessment: function() {
        if ( !this.regionMap ) {
            this.determineOrgStatus( 'MIPS_QUALITY_MEASURES_FALL_RISK_ASSESSMENT' );
            return
        }
        this.channel.trigger( 'change:content', 'MIPS_QUALITY_MEASURES_FALL_RISK_ASSESSMENT' );
    },

	mipsGroupQualityMeasureFallCarePlan: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasureFallCarePlan', module: 'MipsGroup' } );
	},

	mipsProviderQualityMeasureFallCarePlan: function() {
		this.app.controller.checkUserAuthentication( { route: 'QualityMeasureFallCarePlan', module: 'MipsProvider' } );
	},

    goMipsQualityMeasureFallCarePlan: function() {
        if ( !this.regionMap ) {
            this.determineOrgStatus( 'MIPS_QUALITY_MEASURES_FALL_CARE_PLAN' );
            return
        }
        this.channel.trigger( 'change:content', 'MIPS_QUALITY_MEASURES_FALL_CARE_PLAN' );
    },

	mipsGroupAdvancingCare: function() {
		this.app.controller.checkUserAuthentication( { route: 'AdvancingCare', module: 'MipsGroup' } );
	},

	mipsProviderAdvancingCare: function() {
		this.app.controller.checkUserAuthentication( { route: 'AdvancingCare', module: 'MipsProvider' } );
	},

	goMipsAdvancingCare: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MIPS_ADVANCING_CARE' );
			return
		}
		this.channel.trigger( 'change:content', 'MIPS_ADVANCING_CARE' );
	},

	mipsGroupAdvancingCareBaseScore: function() {
		this.app.controller.checkUserAuthentication( { route: 'AdvancingCareBaseScore', module: 'MipsGroup' } );
	},

	mipsProviderAdvancingCareBaseScore: function() {
		this.app.controller.checkUserAuthentication( { route: 'AdvancingCareBaseScore', module: 'MipsProvider' } );
	},

    goMipsAdvancingCareBaseScore: function() {
        if ( !this.regionMap ) {
            this.determineOrgStatus( 'MIPS_ADVANCING_CARE_BASE_SCORE' );
            return
        }
        this.channel.trigger( 'change:content', 'MIPS_ADVANCING_CARE_BASE_SCORE' );
    },

	mipsGroupAdvancingCarePerformanceScore: function() {
		this.app.controller.checkUserAuthentication( { route: 'AdvancingCarePerformanceScore', module: 'MipsGroup' } );
	},

	mipsProviderAdvancingCarePerformanceScore: function() {
		this.app.controller.checkUserAuthentication( { route: 'AdvancingCarePerformanceScore', module: 'MipsProvider' } );
	},

    goMipsAdvancingCarePerformanceScore: function() {
        if ( !this.regionMap ) {
            this.determineOrgStatus( 'MIPS_ADVANCING_CARE_PERFORMANCE_SCORE' );
            return
        }
        this.channel.trigger( 'change:content', 'MIPS_ADVANCING_CARE_PERFORMANCE_SCORE' );
    },

	mipsGroupAdvancingCareBonusScore: function() {
		this.app.controller.checkUserAuthentication( { route: 'AdvancingCareBonusScore', module: 'MipsGroup' } );
	},

	mipsProviderAdvancingCareBonusScore: function() {
		this.app.controller.checkUserAuthentication( { route: 'AdvancingCareBonusScore', module: 'MipsProvider' } );
	},

    goMipsAdvancingCareBonusScore: function() {
        if ( !this.regionMap ) {
            this.determineOrgStatus( 'MIPS_ADVANCING_CARE_BONUS_SCORE' );
            return
        }
        this.channel.trigger( 'change:content', 'MIPS_ADVANCING_CARE_BONUS_SCORE' );
    },

	mipsGroupImprovementActivities: function() {
		this.app.controller.checkUserAuthentication( { route: 'ImprovementActivities', module: 'MipsGroup' } );
	},

	mipsProviderImprovementActivities: function() {
		this.app.controller.checkUserAuthentication( { route: 'ImprovementActivities', module: 'MipsProvider' } );
	},

	goMipsImprovementActivities: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'MIPS_IMPROVEMENT_ACTIVITIES' );
			return
		}
		this.channel.trigger( 'change:content', 'MIPS_IMPROVEMENT_ACTIVITIES' );
	},

    /***************************
     Patient Enrollment module routes
     ***************************/

    peReporting: function() {
        this.app.controller.checkUserAuthentication( { route: 'PeReporting', module: 'PatientEnrollment' } );
	},

	goPeReporting: function() {
        if ( !this.regionMap ) {
            this.determineOrgStatus( 'PE_REPORTING' );
            return;
        }
        this.channel.trigger( 'change:content', 'PE_REPORTING' );
	},

	peFileManager: function() {
    	this.app.controller.checkUserAuthentication( { route: 'FileManager', module: 'PatientEnrollment' } );
	},

	peRapidReview: function() {
		this.app.controller.checkUserAuthentication( { route: 'RapidReview', module: 'PatientEnrollment' } );
	},

	goPeFileManager: function() {
    	if ( !this.regionMap ) {
    		this.determineOrgStatus( 'PE_FILE_MANAGER' );
    		return;
		}
		this.channel.trigger( 'change:content', 'PE_FILE_MANAGER' );
	},


	goPeRapidReview: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'PE_RAPID_REVIEW' );
			return;
		}
		this.channel.trigger( 'change:content', 'PE_RAPID_REVIEW' );
	},

	peApproval: function() {
        this.app.controller.checkUserAuthentication( { route: 'AwaitingApproval', module: 'PatientEnrollment' } );
	},

    goPeApproval: function() {
        if ( !this.regionMap ) {
            this.determineOrgStatus( 'PE_AWAITING_APPROVAL' );
            return;
        }
        this.channel.trigger( 'change:content', 'PE_AWAITING_APPROVAL' );
    },

	peDeclined: function() {
		this.app.controller.checkUserAuthentication( { route: 'Declined', module: 'PatientEnrollment' } );
	},

	goPeDeclined: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'PE_DECLINED' );
			return;
		}
		this.channel.trigger( 'change:content', 'PE_DECLINED' );
	},

	/***************************
		Utilities module routes
	***************************/

	historicalBilling: function() {
		this.app.controller.checkUserAuthentication( { route: 'HistoricalBilling', module: 'Utilities' } );
	},

	goHistoricalBilling: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'UTILITIES_HISTORICAL_BILLING' );
			return
		}
		this.channel.trigger( 'change:content', 'UTILITIES_HISTORICAL_BILLING');
	},

	manageUsers: function() {
		this.app.controller.checkUserAuthentication( { route: 'manageUsers', module: 'Utilities' } );
	},

	goManageUsers: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'UTILITIES_MANAGE_USERS' );
			return
		}
		this.channel.trigger( 'change:content', 'UTILITIES_MANAGE_USERS');
	},

	resources: function() {
		this.app.controller.checkUserAuthentication( { route: 'Resources', module: 'Utilities' } );
	},

	goResources: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'UTILITIES_RESOURCES' );
			return
		}
		this.channel.trigger( 'change:content', 'UTILITIES_RESOURCES');
	},

	support: function() {
		this.app.controller.checkUserAuthentication( { route: 'Support', module: 'Utilities' } );
	},

	goSupport: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'UTILITIES_SUPPORT' );
			return
		}
		this.channel.trigger( 'change:content', 'UTILITIES_SUPPORT');
	},

	bulkProviderChange: function() {
		this.app.controller.checkUserAuthentication( { route: 'BulkProviderChange', module: 'Utilities' } );
	},
	goBulkProviderChange: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'UTILITIES_BULK_PROVIDER_CHANGE' );
			return
		}
		this.channel.trigger( 'change:content', 'UTILITIES_BULK_PROVIDER_CHANGE');
	},

	upload: function() {
		this.app.controller.checkUserAuthentication( { route: 'Upload', module: 'Utilities' } );
	},

	goUpload: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'UTILITIES_UPLOAD' );
			return
		}
		this.channel.trigger( 'change:content', 'UTILITIES_UPLOAD');
	},

	changePassword: function() {
		this.app.controller.checkUserAuthentication( { route: 'ChangePassword', module: 'Utilities' } );
	},

  resetEnv: function() {
    this.app.controller.checkUserAuthentication( { route: 'ResetEnvironment', module: 'Utilities' } );
  },

	goChangePassword: function() {
		if ( !this.regionMap ) {
			this.determineOrgStatus( 'UTILITIES_CHANGE_PASSWORD' );
			return
		}
		this.channel.trigger( 'change:content', 'UTILITIES_CHANGE_PASSWORD' );
	},

  goResetEnvironment: function() {
    if ( !this.regionMap ) {
      this.determineOrgStatus( 'UTILITIES_RESET_ENVIRONMENT' );
      return
    }
    this.channel.trigger( 'change:content', 'UTILITIES_RESET_ENVIRONMENT' );
  },

	/***************************
		Helper methods
	***************************/

	determineOrgStatus: function( route ) {
		if ( this.app.controller && this.app.controller.selectedOrganization ) {
			this.createRegionMap( route );
		} else {
			this.listenToOnce( this.channel, 'set:selectedOrganization', this.createRegionMap.bind(this, route) );
		}
	},

	createRegionMap: function( route ) {
		let controller = this.app.controller;
		this.regionMap = new RegionMap();
		this.app.getRegion( 'main' ).show( this.regionMap );
		this.regionMap.showChildView( 'header', new NavbarView() );
		this.regionMap.showChildView( 'sideNav', new SideNavContainerView({ currentApp: controller.currentApp }) );
		this.regionMap.showChildView( 'content', new ContentView() );
		this.channel.trigger( 'change:content', route);
		this.channel.trigger( 'nav:success' );
	}

});
