// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import providerAssociationDropdownContainerView  from '../../../../templates/Utilities/ManageUsers/providerAssociationDropdownContainerView';
import ProviderAssociationDropdownView from './ProviderAssociationDropdownView';

// Internal Dependencies - models/collections
import { AssociatedProvidersCollection } from './models/AssociatedProvidersCollection';

export default Marionette.CompositeView.extend({

	className: 'col m12',

	childViewContainer: '.provider-association-select-container',

	childView: ProviderAssociationDropdownView,

	getTemplate: function() {
		return providerAssociationDropdownContainerView( this.model );
	},

});
