// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import ccmSubNavView  from '../../../templates/CCM/ccmSubNavView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.model, 'change', this.addActive );
		let modules = this.app.controller && this.app.controller.modules;
		let user = this.app.controller && this.app.controller.user;
		this.model.set({
			isRapid: modules && modules.rapid,
			messenger: user.messenger,
			revenueVisible:user.revenueVisible,
			revenueDashboardType:this.app.controller.revenueDashboardType
		});
	},

	getTemplate: function() {
		return ccmSubNavView( this.model );
	},

	onShow: function() {
		this.addActive();
	},

	addActive: function() {
		// Remove active class from any subnav-items
		$( '.subnav-item a' ).removeClass( 'active' );

		// Get the URL hash and add the active class to the anchor linking to current page
		let currentLocation = window.location.hash;
		$('a[href="' + currentLocation + '"]').addClass('active');

		// Handling rapid-bill folder
		let rapidBillFolders = $( '.billing-queues' );

		if ( currentLocation.indexOf( 'rapid-bill' ) !== -1 ) {
			$('#CcmRapidBill').addClass('active');
			this.whichRapidBillRoute();
		} else if ( rapidBillFolders.is(':visible') ) {
			rapidBillFolders.slideUp();
		}

		//Handle patin=ent status  folder
		let patientStatusFolder = $( '.patient-status-queues');
		if ( currentLocation.indexOf( 'patient-status' ) !== -1 ) {
			$('#CcmPatientStatus').addClass('active');
			this.whichPatientStatusRoute();
		} else if ( patientStatusFolder.is(':visible') ) {
			patientStatusFolder.slideUp();
		}

	},

	whichRapidBillRoute: function() {
		// Remove active from all folder routes
		$( '.billing-queues a' ).removeClass( 'active' );

		// Get the active rapid-bill folder
		let billingQueues = $( '.billing-queues' );
		let currentLocation = window.location.hash;
		let currentQueue = currentLocation.slice( currentLocation.lastIndexOf( '/' ) + 1 );
		let activeQueue = this.determineActiveQueue( currentQueue );
		billingQueues.slideDown();
		$( '#' + activeQueue ).addClass( 'active' );
	},

	whichPatientStatusRoute: function() {
		// Remove active from all folder routes
		$( '.patient-status-queues a' ).removeClass( 'active' );

		// Get the active rapid-bill folder
		let patientStatusQueues = $( '.patient-status-queues' );
		let currentLocation = window.location.hash;
		let currentQueue = currentLocation.slice( currentLocation.lastIndexOf( '/' ) + 1 );
		let activeQueue = this.determineActiveQueue( currentQueue );
		patientStatusQueues.slideDown();
		$( '#' + activeQueue ).addClass( 'active' );
	},

	determineActiveQueue: function( currentQueue ) {
		switch( currentQueue ) {
			case 'approval-queue':
				return 'Approval';
				break;
			case 'error-queue':
				return 'Error';
				break;
			case 'pending-review':
				return 'Pending';
				break;
			case 'reports':
				return 'Reports';
				break;
			case 'enrolled':
				return 'CcmEnrolled';
			case 'support':
				return 'CcmSupport';
			case 'unverified':
				return 'CcmUnverified';
			case 'opted-out':
				return 'CcmOptedOut';
			case 'suspended':
				return 'CcmSuspended';
			case 'pending-unenroll':
				return 'CcmPendingUnenroll';
			case 'unenrolled':
				return 'CcmUnenrolled';
			case 'provider-transition':
				return 'CcmProviderTransition'
			default:
				return 'Approval';
		}
	},

});
