// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import providerTransitionContainerView  from '../../../../templates/CCM/ProviderTransition/providerTransitionView';
import ProviderTransitionCompositeView from './ProviderTransitionCompositeView';
import PatientTotalsView from '../PatientList/PatientTotalsView';

// Internal Dependencies - models/collections
import { ProviderTransitionCollection } from './models/ProviderTransitionCollection';

export default Marionette.LayoutView.extend({

	regions: {
		'enrolledContainerView': '.enrolled-container-view',
		'patientTotals': '.patient-totals-container'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection = new ProviderTransitionCollection();
	},

	getTemplate: function() {
		return providerTransitionContainerView( this.model );
	},

	onShow: function() {
		this.showChildView( 'enrolledContainerView', new ProviderTransitionCompositeView({collection: this.collection}) );
		this.showChildView( 'patientTotals', new PatientTotalsView({collection: this.collection}) );
	}

});
