// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import breadcrumbContainerView  from '../../../templates/Filtering/breadcrumbContainerView';

// Internal Dependencies - models/collections
import { BreadcrumbModel } from './models/BreadcrumbModel';

export default Marionette.ItemView.extend({

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new BreadcrumbModel({ app: this.app, channel: this.channel });
		this.listenTo( this.model, 'change', this.render );
	},

	getTemplate: function() {
		return breadcrumbContainerView( this.model );
	},

});
