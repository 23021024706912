import Backbone from 'backbone';

export const SupportModel = Backbone.Model.extend({

	defaults: {
		name: '',
		message: ''
	},

	id: '',

	initialize: function() {
		this.channel = Backbone.Radio.channel('AppChannel');
		this.app = this.channel.request('app');
		this.set ( 'name', (this.app.controller.user && this.app.controller.user.name) || "Please enter name");
	},

	isValid: function ( message ) {
		if ( !message.name || message.name.length  < 3 ) {
			return false;
		}
		if ( !message.message || message.message.length < 3 ) {
			return false;
		}
		return true;
	},

	sendSupportMessage: function( message ) {
		this.app.utilities_controller.sendSupportMessage( message );
	}
});