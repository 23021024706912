// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import supportItemView  from '../../../../templates/CCM/Support/supportItemView';
import {EnrollPatientModel} from "../PatientList/models/EnrollPatientModel";
import EnrollPatientModalView from "../PatientList/EnrollPatientModalView";
import {SuspendPatientModel} from "../PatientList/models/SuspendPatientModel";
import SuspendPatientModalView from "../PatientList/SuspendPatientModelView";
import {UnenrollPatientModel} from "../PatientList/models/UnenrollPatientModel";
import UnenrollPatientModelView from "../PatientList/UnenrollPatientModelView";
import SpecialistRecordsModalView from "../PatientList/SpecialistRecordsModalView";
import TriageRecordsModalView from '../PatientList/TriageRecordsModalView';

export default Marionette.ItemView.extend({

	className: 'table-row clearfix',

	events: {
		'click .more': 'expandContent',
		'click .less': 'expandContent',
        'click .open-patient-plan': 'viewCarePlan',
		'click .edit-patient-information': 'launchEditPatient',
		'click .unenroll': 'unenrollPatient',
		'click .suspend': 'suspendPatient',
        'click .open-ccda': 'viewCcda',
		'click .enrolled-patient-history': 'viewPatientHistory',
		'click .support': 'sendPatientToSupport',
		'click .specialist-records':'openRecordsModal',
		'click .triage-records': 'openTriageRecordsModal',
		'click .open-confidential-note': 'openPatientConfidentialNote',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	getTemplate: function() {
		return supportItemView( this.model );
	},

	viewCarePlan: function() {
		let patientId = this.model.get( 'id' );
		this.model.viewCarePlan( patientId );
	},

    viewCcda: function() {
        let patientId = this.model.get( 'id' );
        this.model.viewCcda( patientId );
    },

	viewPatientHistory: function() {
		let patientId = this.model.get( 'id' );
		this.model.viewPatientHistory( patientId );
	},

	expandContent: function( e ) {
		this.$( '.more' ).toggleClass( 'active' );
		this.$( '.less' ).toggleClass( 'active' );
		this.$( '.patient-list-expanded-data' ).slideToggle( 200 );
	},

    sendPatientToSupport: function() {
        let patientId = this.model.get( 'id' );
        this.model.sendPatientToSupport( patientId );
    },
	launchEditPatient: function( e ) {
		let enrollPatientModel = new EnrollPatientModel( Object.assign({},this.model.attributes,{edit_action:true} ) );
		let modalView = new EnrollPatientModalView({ model: enrollPatientModel });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalEnrollPatient' ).openModal();
		this.channel.trigger( 'trigger:show' );
	},
	suspendPatient: function() {
		// this.model.suspendPatient( this.model.get( 'id' ) );
		this.model.getSuspendReasons((data) => {
			let suspendPatientModel = new SuspendPatientModel( this.model.attributes );
			suspendPatientModel.set( 'patient_id', this.model.get( 'id' ) );
			suspendPatientModel.set( 'reason_options', data );
			let modalView = new SuspendPatientModalView({ model: suspendPatientModel,callback:()=>{
					// alert('Success');
					this.app.ccm_controller.getSupportPatientList();
				}});
			modalView.render();
			$( 'body' ).append( modalView.el );
			$( '#modalReject' ).openModal();
		});
	},

	unenrollPatient: function() {
		// this.model.unenrollPatient( this.model.get( 'id' ) );
		this.model.getUnenrollReasons((data) => {
			let unenrollPatientModel = new UnenrollPatientModel( this.model.attributes );
			unenrollPatientModel.set( 'patient_id', this.model.get( 'id' ) );
			unenrollPatientModel.set( 'unenroll_reason_options', data );
			let modalView = new UnenrollPatientModelView({ model: unenrollPatientModel,callback:()=>{
					// alert('Success');
					this.app.ccm_controller.getSupportPatientList();
				}});
			modalView.render();
			$( 'body' ).append( modalView.el );
			$( '#modalReject' ).openModal();
		});
	},
	openRecordsModal: function() {
		let modalView = new SpecialistRecordsModalView({model: this.model});
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#specialistRecordsModal' ).openModal();
		this.channel.trigger( 'trigger:records_modal' );
	},
	openTriageRecordsModal: function() {
		let modalView = new TriageRecordsModalView({model: this.model});
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#triageRecordsModal' ).openModal();
		this.channel.trigger( 'trigger:triage_records_modal' );
	},
	openPatientConfidentialNote: function() {
		let patientId = this.model.get( 'id' );
		this.model.openConfidentialNotes( patientId );
	},
});
