// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';

// Internal Dependencies - screens/views/templates
import dashboardContainerView  from '../../../../templates/MIPS/Dashboard/dashboardContainerView';
import DateRangeContainerView from '../DateRangeContainerView';
import MipsSettingsModal from './MipsSettingsModal';
import QualityContainerView from './QualityContainerView';
import AdvancingCareContainerView from './AdvancingCareContainerView';
import ImprovementActivitiesContainerView from './ImprovementActivitiesContainerView';
import CompositeScoreContainerView from './CompositeScoreContainer';

// Internal Dependencies - models/collections
import { DashboardModel } from './models/DashboardModel';

export default Marionette.LayoutView.extend({

    regions: {
        'dateRangeContainer': '.date-range-container',
        'qualityContainer': '#qualityContainer',
        'advancingContainer': '#advancingContainer',
        'improvementContainer': '#improvementContainer',
        'compositeScoreContainer': '#compositeScoreContainer',
    },

    events: {
        'change #yearToDate': 'setYearToDate',
        'change #dateRange': 'setDateRange',
        'click #applyDateRange': 'applyDateRange',
        'click .open-mips-settings': 'openMipsSettings',
    },

    initialize: function( options ) {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.model = new DashboardModel();
        this.app.mips_controller.getDashboardData( this.model.get( 'dateRange' ) );
        this.app.mips_controller.getMipsSettings();
        this.listenTo( this.model, 'change:dashboardData', this.updateDashboard );
        this.listenTo( this.model, 'change:dateRange', this.checkAttestationPeriod );
        this.listenTo( this.model, 'change:exclusions', this.fetchDashboardData );
        if ( this.app.controller.currentApp === 'MIPSPROVIDER' ) {
	        this.listenTo( this.channel, 'set:selectedProviderId', this.fetchAllData );
        }
    },

	getTemplate: function() {
		return dashboardContainerView( this.model );
	},

    updateDashboard: function() {
        this.renderCharts();
    },

	fetchDashboardData: function() {
		this.app.mips_controller.getDashboardData( this.model.get( 'dateRange' ) );
	},

	fetchAllData: function() {
		this.app.mips_controller.getDashboardData( this.model.get( 'dateRange' ) );
		this.app.mips_controller.getMipsSettings();
	},

    onShow: function() {
        $('.content-container').addClass('widen');
        this.renderCharts();
        this.showChildView( 'qualityContainer', new QualityContainerView( { model: this.model } ) );
        this.showChildView( 'advancingContainer', new AdvancingCareContainerView( { model: this.model } ) );
        this.showChildView( 'improvementContainer', new ImprovementActivitiesContainerView( { model: this.model } ) );
        this.showChildView( 'compositeScoreContainer', new CompositeScoreContainerView( { model: this.model } ) );

    },

    checkAttestationPeriod: function() {
        this.model.get('dateRange') ?
            this.showChildView( 'dateRangeContainer', new DateRangeContainerView( { model: this.model } ) ) :
            this.getRegion('dateRangeContainer').empty();
    },

    setYearToDate: function() {
        this.model.set('dateRange', false);
    },

    setDateRange: function() {
        this.model.set('dateRange', true);
    },

    applyDateRange: function() {
        const dateRange = this.model.get( 'dateRange' );
        const startMonth = this.model.get( 'startMonth' );
        const endMonth = this.model.get( 'endMonth' );
        const year = this.model.get( 'year' );
        if ( dateRange ) {
            this.app.mips_controller.getDashboardData( dateRange, startMonth, endMonth, year );
        } else {
            this.app.mips_controller.getDashboardData( dateRange );
        }
    },

    openMipsSettings: function() {
        const modalView = new MipsSettingsModal( { model: this.model } );
        modalView.render();
        $( 'body' ).append( modalView.el );
        $( '#mipsSettingsModal' ).openModal();
    },

    renderCharts: function() {
        this.qualityChart();
        this.advancingChart();
        this.improvementChart();
    },

    qualityChart: function() {
        const qualityObject = this.model.get( 'dashboardQuality' );
        Highcharts.chart('qualityChart', {
            title: {
                text: `Criteria Complete (out of ${qualityObject.totalCriteria || ''})`,
                style: {
                    color: '#6683CB'
                },
            },
            chart: {
                height: 200,
                spacingBottom: 30,
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        format: '<b>{point.y}</b>'
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '80%'],
                    showInLegend: true,
                    size: 160,
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: [
                    {
                        name: 'Complete',
                        y: qualityObject.criteriaComplete,
                        color: '#6683CB'
                    }, {
                        name: 'Incomplete',
                        y: qualityObject.totalCriteria - qualityObject.criteriaComplete,
                        color: 'rgb(170,170,170)'
                    }
                ]
            }],
            credits: {
                enabled: false
            }
        });
    },

    advancingChart: function() {
        const advancingCareObject = this.model.get( 'dashboardAdvancingCare' );
        Highcharts.chart('advancingChart', {
            title: {
                text: `Criteria Complete (out of ${advancingCareObject.totalCriteria || ''})`,
                style: {
                    color: '#529FC0'
                },
            },
            chart: {
                height: 200,
                spacingBottom: 30,
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        format: '<b>{point.y}</b>'
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '80%'],
                    showInLegend: true,
                    size: 160,
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: [
                    {
                        name: 'Complete',
                        y: advancingCareObject.criteriaComplete,
                        color: '#529FC0'
                    }, {
                        name: 'Incomplete',
                        y: advancingCareObject.totalCriteria - advancingCareObject.criteriaComplete,
                        color: 'rgb(170,170,170)'
                    }
                ]
            }],
            credits: {
                enabled: false
            }
        });
    },

    improvementChart: function() {
        const improvementObject = this.model.get( 'dashboardClinicalImprovement' );
        let percentageEarned = ( improvementObject.overallScore ) / 100;
        let pointsEarned = Math.floor( percentageEarned * 40 );
        Highcharts.chart('improvementChart', {
            title: {
                text: `Points Achieved (out of 40)`,
                style: {
                    color: '#1C81AD'
                },
            },
            chart: {
                height: 200,
                spacingBottom: 30,
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        format: '<b>{point.y}</b>'
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '80%'],
                    showInLegend: true,
                    size: 160,
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: [
                    {
                        name: 'Points earned',
                        y: pointsEarned,
                        color: '#1C81AD'
                    }, {
                        name: 'Points needed',
                        y: 40 - pointsEarned,
                        color: 'rgb(170,170,170)'
                    }
                ]
            }],
            credits: {
                enabled: false
            }
        });
    },

    onDestroy: function() {
        $('.content-container').removeClass('widen');
    },

});
