import Backbone from 'backbone';
import Marionette from 'backbone.marionette';
import _ from 'underscore';
import moment from 'moment';

export const RapidBillModel = Backbone.Model.extend({
	
	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.setMonth();
		this.setBillableTime();
	},

	requiredFields: [ 
		'ehr_pid', 
		'last_name', 
		'first_name', 
		'icd10code_1', 
		'icd10code_2', 
		'birthdate',
		'ccm_provider_id', 
		/*'location_id',
		'cpt_code', */
		'provider',
		/*'patient_visit_date' */
	],

	triggerCheckSelected: function() {
		this.channel.trigger( 'check:selected' );
	},

	checkFields: function() {
		let errors = [];
		let modelAttrs = this.attributes;
		let keys = _.keys( modelAttrs );
		_.each( this.requiredFields, ( field ) => {
			if ( !_.contains( keys, field ) || !this.get( field ) ) {
				errors.push({
					field,
					error: 'Must provide a valid ' + this.determineValueType( field )
				});
			}
		});

		_.each( modelAttrs, ( attr ) => {
			let error = this.validateData( attr );
			error && errors.push( error );
		});

		this.set({ errors });
	},

	determineValueType: function( key ) {
		switch( key ) {
			case 'ehr_pid':
				return 'Patient ID';
				break
			case 'last_name':
				return 'Last Name';
				break;
			case 'first_name':
				return 'First Name';
				break;
			case 'icd10code_1':
				return 'ICD10 Code 1';
				break;
			case 'icd10code_2':
				return 'ICD10 Code 2';
				break;
			case 'birthdate':
				return 'Date of Birth';
				break;
			case 'ccm_provider_id':
				return 'Provider ID';
				break;
			case 'cpt_code':
				return 'CPT Code';
				break;
			case 'provider':
				return 'Provider Name';
				break;
			case 'location_id':
				return 'Location ID';
				break;
			case 'patient_visit_date':
				return 'Patient Visit Date';
				break;
		}
	},

	validateData: function( key, value ) {
		switch( key ) {
			case 'ehr_pid':
			case 'last_name':
			case 'first_name':
			case 'provider':
				if ( value.length < 3 ) {
					return {
						key,
						value: this.determineValueType( key ) + ' must be at least 3 characters in length'
					}
				}
				break;
			case 'icd10code_1':
			case 'icd10code_2':
				break;
			case 'ccm_provider_id':
			case 'location_id':
			case 'cpt_code':
				break;
			case 'birthdate':
			case 'patient_visit_date':
				break;
		}
	},

	setMonth: function() {
        let availableMonths = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
        let month = availableMonths[ this.get( 'month' ) - 1 ];
        this.set({ month });
	},

	setBillableTime: function() {

		if ( this.get('time_worked') ) {
			let time_worked = this.msToTime( this.get('time_worked') );
			let splitIndex = time_worked.indexOf( ':' );
			let minutes_worked = time_worked.slice( 0, splitIndex );
			let seconds_worked = time_worked.slice( splitIndex + 1 );
			this.set({ minutes_worked, seconds_worked });
		}
	},

  msToTime : function(duration) {
    var milliseconds = parseInt((duration%1000)/100)
        , seconds = parseInt((duration/1000)%60)
        , minutes = parseInt((duration/(1000*60)));
        // , hours = parseInt((duration/(1000*60*60))%24);

    // hours = (hours < 10) ? "0" + hours : hours;
    // let hoursValue = hours < 10 ? 0 : hours;
    // minutes += hoursValue;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    //return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
    //return hours + ":" + minutes + ":" + seconds;

    return  minutes + ":" + seconds;
  },

	updateBillingInfo: function( currentQueue ) {
		let rapidBilling = this.app.ccm_controller.rapidBilling;
		let rapidBillingPatient;
		if ( rapidBilling && rapidBilling.length ) {
			rapidBillingPatient = _.find( rapidBilling, ( patientWrapper ) => {
			  let patientId = null;
        if(patientWrapper.patient){
          patientId = patientWrapper.patient.id;
        }else{
          patientId = patientWrapper.id;
        }
            return patientId == this.get('id');
          }
      );
		}
		if ( rapidBillingPatient ) {
			rapidBillingPatient = {
        date_of_birth: $( '#birthdate' ).val() ? moment( $( '#birthdate' ).val() ).format( 'YYYY-MM-DD' ) : moment( this.get( 'birthdate' ) ).format( 'YYYY-MM-DD' ),
				ccm_provider_id: $( '#provider_id' ).val() || this.get( 'ccm_provider_id' ),
				ehr_pid: $( '#ehr_id' ).val() || this.get( 'ehr_pid' ),
				first_name: $( '#first_name' ).val() || this.get( 'first_name' ),
				last_name: $( '#last_name' ).val() || this.get( 'last_name' ),
				icd10code_1: this.parseICD10Code( $( '#icd10code_1' ).val() ) || this.get( '#icd10code_1' ) || null,
				icd10code_2: this.parseICD10Code( $( '#icd10code_2' ).val() ) || this.get( '#icd10code_2' )  || null,
				id: this.get( 'id' ),
        patient_xref_id: this.get('patient_xref_id')
			}
			$('#saveBilling').html('Saving');
			this.app.ccm_controller.updateBillingInfo( rapidBillingPatient, currentQueue );
		}
	},

	parseICD10Code: function( value ) {
		if ( value ) {
			if ( value.indexOf( ' - ' ) > -1 ) {
				return value.slice( 0, value.indexOf( ' - ' ) ).trim();
			} else {
				return value.toUpperCase();
			}
		}
		return;
	},

    openPatientPlan: function( patientId ) {
		console.log(patientId)
        this.app.ccm_controller.openPatientPlan( patientId );
    },

	openConfidentialNotes: function( patientId ) {
		this.app.ccm_controller.openConfidentialNotes( patientId );
	},
});