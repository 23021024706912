// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import healthInfoExchangeProvidePatientAccessCardView  from '../../../../../templates/MIPS/AdvancingCare/BaseScore/healthInfoExchangeProvidePatientAccessCardView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

    events: {
        'click .enter-data': 'doStuff',
    },

    ui: {
        flipper: '.flipper',
        front: '.front',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
    },

    onShow: function() {
        this.ui.flipper.css({ 'min-height': this.ui.front.height() });
        // if ( this.model.get( 'measureData' ).historical_data.length ) {
        //     this.renderHistoricalDataChart();
        // }
    },

    onDomRefresh: function() {
        this.ui.flipper.css({ 'min-height': this.ui.front.height() });
        // if ( this.model.get( 'measureData' ).historical_data.length ) {
        //     this.renderHistoricalDataChart();
        // }
    },

    getTemplate: function() {
        return healthInfoExchangeProvidePatientAccessCardView( this.model );
    },

    doStuff: function() {
        console.log( 'stuff' );
    },

    renderHistoricalDataChart: function() {
        let data = [];
        if ( this.model.get( 'measureData' ).historical_data.length ) {
            this.model.get( 'measureData' ).historical_data.forEach( ( dataPoint, idx ) => {
                if ( idx !== dataPoint.month - 1 ) {
                    data.push({
                        x: idx,
                        y: 0,
                        color: this.model.colors['FAIL'],
                    });
                }
                const y = isNaN(dataPoint.score_raw) ? 0 : parseFloat(dataPoint.score_raw).toFixed(0)/1;
                data.push({
                    x: dataPoint.month - 1,
                    y: y,
                    color: this.model.colors[ dataPoint.score_threshold ] || this.model.colors[ 'blue' ] ,
                });
            });
        }
        if ( !data.length ) {
            if ( data.length == 0 ) {
                $('#historicalDataContainer').html( `<div class="center-align"><h5>No historical data is available</h5></div>` );
                return;
            }
        }
        Highcharts.chart('historicalDataContainer', {
            title: {
                text: null
            },
            chart: {
                type: 'column',
                height: 300,
            },
            credits: {
                enabled: false,
            },
            xAxis: {
                categories: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May','Jun','Jul','Aug','Sep','Oct','Nov','Dec' ],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Patients',
                },
                allowDecimals: false,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                color: 'white',
                name: 'Monthly Reported Data',
                data,
            }],
        });
    },

});
