// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import triageRecordsItemView  from '../../../../templates/CCM/PatientList/triageRecordsItemView';

// Internal Dependencies - models/collections


export default Marionette.ItemView.extend({

	className: 'table-row clearfix',

	events: {
		'click .download-patient-triage-record': 'openRecord',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.controller = this.app.ccm_controller;
		this.listenTo( this.model, 'change', this.render );
	},

	getTemplate: function() {
		return triageRecordsItemView( this.model );
	},

	openRecord: function() {
		this.controller.getPatientTriageRecord( this.model.get('patient_id'), this.model.get('record_id'), this.model.get('title') )
	},

});
