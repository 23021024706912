// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import validationsContainerView  from '../../../templates/Validations/validationsContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
	},

	getTemplate: function() {
		return validationsContainerView( this.model );
	},

	onShow: function() {
		this.iterateErrors();
	},

	onDomRefresh: function() {
		this.iterateErrors();
	},

	iterateErrors: function() {
		this.channel.trigger( 'remove:errors' );
		let errors = this.model.get( 'errors' ) || [];
		errors.length && errors.forEach( ( error ) => $( '#' + error.field ).addClass( 'invalid' ) );
	},

});
