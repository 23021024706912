import Backbone from 'backbone';
import moment from 'moment';

export const SuspendedModel = Backbone.Model.extend({

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.setSuspensionStrings();
	},

	setSuspensionStrings() {
		let suspension_time = this.get('suspension_date');
		this.set( 'suspension_date_string', suspension_time === null ? 'N/A': moment(suspension_time).format('MM/DD/YYYY') );
		this.set( 'unsuspension_date_string', suspension_time === null ? 'N/A': moment(suspension_time).add(30, 'days').format('MM/DD/YYYY') );
	},

	viewCarePlan: function( patientId ) {
		this.app.ccm_controller.openPatientPlan( patientId );
	},

    viewCcda: function( patientId ) {
        this.app.ccm_controller.openCcda( patientId );
    },

	viewPatientHistory: function( patientId ) {
		this.app.ccm_controller.openPatientHistory( patientId );
	},

    sendPatientToSupport: function( patientId ) {
        let message = 'Clicking \"confirm\" will send this patient to be worked by a Retention Specialist.';
        let callback = this.app.ccm_controller.sendPatientToSupport.bind( this.app.ccm_controller );
        let data = {
            patientId
        };
        this.app.controller.confirmationModal( message, callback, data );
    },

	triggerUnsuspendModal: function( patientId ) {
		let message = 'Clicking \"confirm\" will unsuspend this patient.';
		let callback = this.app.ccm_controller.unsuspendPatientFromSuspendedQueue.bind( this.app.ccm_controller );
		this.app.controller.confirmationModal( message, callback, patientId );
	},

	getUnenrollReasons: function(callback) {
		this.app.ccm_controller.getUnenrollReasons(callback);
	},
	openConfidentialNotes: function( patientId ) {
		this.app.ccm_controller.openConfidentialNotes( patientId );
	},

});