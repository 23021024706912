import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import bulkProviderConfirmationModalView  from '../../../../templates/Utilities/BulkProviderChange/bulkProviderConfirmationModalView';

export default Marionette.LayoutView.extend({
  events: {
    'click .modal-close': 'closeModal',
    'click #confirmButton': 'confirmProviderChange',
    'click #new_billing_12_months': 'setAgreeNewBilling12Months',
    'click #new_provider_change_agree': 'setAgreeNewBilling12Months',
  },

  initialize: function( options ) {
    this.channel = Backbone.Radio.channel( 'AppChannel' );
    this.app = this.channel.request( 'app' );
    this.listenTo( this.channel, 'close:modal', this.closeModal );
    this.listenTo( this.channel, 'remove:errors', this.removeErrors );
  },

  getTemplate: function() {
    return bulkProviderConfirmationModalView( this.model );
  },

  closeModal: function( e ) {
    e && e.preventDefault();
    $( '#modalReject' ).closeModal();
    this.destroy();
  },

  confirmProviderChange: function( e ) {
    e.preventDefault();
    this.model.bulkProviderUpdate(()=>{
      $( '#modalReject' ).closeModal();
      this.destroy();
    });
  },

  setAgreeNewBilling12Months: function(e){
    const new_billing_12_months = $('#new_billing_12_months')[0].checked;
    const new_provider_change_agree = $('#new_provider_change_agree')[0].checked;
    if(new_provider_change_agree && new_billing_12_months){
      $('#confirmButton').removeClass('disabled');
    }else{
      $('#confirmButton').addClass('disabled');
    }
  },

});
