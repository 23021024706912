import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import bulkProviderTransitionConfirmationModalView  from '../../../../templates/Utilities/BulkProviderTransition/bulkProviderTransitionConfirmationModalView';

export default Marionette.LayoutView.extend({
  events: {
    'click .modal-close': 'closeModal',
    'click #confirmButton': 'confirmProviderChange',
  },

  initialize: function( options ) {
    this.channel = Backbone.Radio.channel( 'AppChannel' );
    this.app = this.channel.request( 'app' );
    this.listenTo( this.channel, 'close:modal', this.closeModal );
    this.listenTo( this.channel, 'remove:errors', this.removeErrors );
  },

  getTemplate: function() {
    return bulkProviderTransitionConfirmationModalView( this.model );
  },

  closeModal: function( e ) {
    e && e.preventDefault();
    $( '#modalReject' ).closeModal();
    this.destroy();
  },

  confirmProviderChange: function( e ) {
    e.preventDefault();
    if($('#confirmButton').text() == 'Ok'){
      $('#modalReject').closeModal();
      this.destroy();
    }else{
      this.model.bulkProviderUpdate((data)=>{
        if(data.reason){
          $('#cancelButton').css('display','none')
          $('#confirmButton').html('Ok')
          $('#suspension_message').html(data.reason);
        }else {
          $('#modalReject').closeModal();
          this.destroy();
        }
      });
    }

  },

});