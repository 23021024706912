import Backbone from 'backbone';

export const PendingUnenrollModel = Backbone.Model.extend({

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel('AppChannel');
		this.app = this.channel.request( 'app' );
	},

	confirmUnenroll: function() {
		let patient_id = this.get( 'id' );
		this.app.ccm_controller.confirmUnenroll( patient_id );
	},

	cancelUnenroll: function() {
		let patient_id = this.get( 'id' );
		this.app.ccm_controller.cancelUnenroll( patient_id );	
	}

});