// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import bulkProviderSuspensionContainerView  from '../../../../templates/Utilities/BulkProviderSuspension/bulkProviderSuspensionContainerView';
import BulkProviderSuspensionCompositeView from './BulkProviderSuspensionCompositeView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

    regions: {
        'bulkProviderSuspensionCompositeView': '.bulk-provider-change-composite-container'
    },

    getTemplate: function() {
        return bulkProviderSuspensionContainerView( );
    },

    onShow: function() {
        this.showChildView( 'bulkProviderSuspensionCompositeView', new BulkProviderSuspensionCompositeView() );
    }
});
