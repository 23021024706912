// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  providerAssociationDropdownView  from '../../../../templates/Utilities/ManageUsers/providerAssociationDropdownView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: 'row',

	events: {
		'change .associated-provider-select': 'changeProvider',
		'click .delete-provider-button-container': 'deleteProvider'
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.collection = this.channel.request( 'AssociatedProvidersCollection' );
		this.model.set( 'unusedProviders', this.collection.unusedProviders );
		this.model.set( 'associatedProviderLength', this.collection.length )
		this.listenTo( this.collection, 'update change', this.updateUnusedProviders );
	},

	changeProvider: function() {
		let newProviderId = this.$('.associated-provider-select').val();
		this.collection.changeProvider( newProviderId, this.model )
	},

	deleteProvider: function() {
		this.collection.deleteProvider( this.model );
	},

	updateUnusedProviders: function() {
		this.model.set( {associatedProviderLength: this.collection.length, unusedProviders: this.collection.unusedProviders } );
		this.render();
	},

	getTemplate: function() {
		return providerAssociationDropdownView( this.model );
	},

});
