// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  rapidBillItemView  from '../../../../../templates/CCM/RapidBill/Error/errorItemView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: function() {
		return 'table-row clearfix' + ( this.model.get( 'new_patient_for_billing' ) ? ' new-patient' : '' );
	},

	events: {
		'click .more': 'expandContent',
		'click .less': 'expandContent',
		'click [type="checkbox"]': 'selectPatient',
		'click .open-patient-plan': 'openPatientPlan',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.model, 'change', this.render );
		this.listenTo( this.model, 'change', this.model.triggerCheckSelected );
	},

	getTemplate: function() {
		return rapidBillItemView( this.model );
	},

	expandContent: function() {
		this.$( '.more' ).toggleClass( 'active' );
		this.$( '.less' ).toggleClass( 'active' );
		this.$( '.rapid-bill-patient-expanded-data' ).slideToggle( 200 );
	},

	selectPatient: function() {
		this.model.set( 'selected', !this.model.get( 'selected' ) );
	},

	openPatientPlan: function() {
		let patientId = this.model.get( 'patient_xref_id' );
		this.app.ccm_controller.openPatientPlan( patientId );
	},

});
