// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';
import {EditInsuranceModel} from "./models/EditInsuranceModel"
// Internal Dependencies - screens/views/templates
import editInsuranceModalView  from '../../../../templates/PatientEnrollment/RapidReview/editInsuranceModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'click .cancel': 'closeModal',
		'click #saveInsuranceSelection': 'saveInsuranceSelection',
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
		this.listenTo( this.channel, 'trigger:show', this.showTriggered );
		this.listenTo( this.channel, 'updateBillingInfo:failure', this.flipText );
	},

	getTemplate: function() {
		return editInsuranceModalView( this.model );
	},


	showTriggered: function() {
		let errors = this.model.get( 'errors' );
		_.each( errors, ( error ) => $( '#' + error.field ).addClass( 'invalid' ) );
		const self = this;
	},

	addValidations: function() {

	},

	flipText: function() {
		$('#saveBilling').html('Save');
	},

	closeModal: function() {
		$( '#modalReview' ).closeModal();
		this.destroy();
	},

	saveInsuranceSelection: function( e ) {
		e && e.preventDefault();
		this.model.set({errors:[]});
		const includeAsPrimary = document.getElementById('includeAsPrimary').checked;
		const includeAsSecondary = document.getElementById('includeAsSecondary').checked;
		const rinConUserId = this.model.get('rinConUserId');

		console.log("includeAsPrimary:",includeAsPrimary);
		console.log("includeAsSecondary:",includeAsSecondary);
		console.log("this.model:",this.model);

		const payload = {
			orgInsuranceUpdates:[
				{
					id:this.model.get('id'),
					includeForEligibilityPrimary:includeAsPrimary,
					includeForEligibilitySecondary:includeAsSecondary
				}
			]
		}
		this.app.controller.saveIntegratedInsurances(rinConUserId, payload)
		this.closeModal();
		this.channel.trigger("change:reRender");
	}

});
