import Backbone from 'backbone';
import _ from 'underscore';

export const PatientCollection = Backbone.Collection.extend({

	initialize: function( patientType = 'approval' ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.patientType = patientType;
	},

	downloadCSV: function() {
		this.app.ccm_controller.getPatientListCSV();
	},

	getPatients: function( patientType = 'awaiting_approval' ) {
		switch( patientType ) {
			case 'awaiting_approval':
				this.app.pe_controller.getAwaitingApprovalPatients();
				break;
			case 'needs_attention':
				this.app.pe_controller.getNeedsAttentionPatients();
				break;
			case 'declined':
				this.app.pe_controller.getDeclinedPatients();
				break;
			default:
                this.app.pe_controller.getAwaitingApprovalPatients();
		}
	},

	patientsSelected: function() {
        let anyPatientsSelected = false;
        this.each( ( patient ) => {
            if ( patient.get( 'selected' ) ) {
                anyPatientsSelected = true;
            }
        });
        this.checkSelected();
        return anyPatientsSelected;
	},

    allSelected: function() {
	    let allPatientsSelected = true;
	    if ( !this.length ) {
	        return false;
        }
	    this.each( ( patient ) => {
	        if ( !patient.get( 'selected' ) ) {
	            allPatientsSelected = false;
            }
        });
        return allPatientsSelected;
    },

    toggleSelectAll: function( isChecked=false ) {
	    this.each( ( patient ) => ( isChecked && patient.set( 'selected', true ) ) || patient.set( 'selected', false ) );
    },

    checkSelected: function() {
	    const selectAll = $( '#selectAll' );
	    if ( !this.allSelected() && selectAll.prop( 'checked' ) ) {
	        selectAll.prop( 'checked', false );
        } else if ( this.allSelected() && !selectAll.prop( 'checked' ) ) {
            selectAll.prop( 'checked', true );
        }
    },

    numSelected: function() {
	    return this.reduce( ( a, b ) => {
	        if ( b.get( 'selected' ) ) {
	            a += 1;
            }
            return a;
        }, 0);
    },

    confirmPatients: function() {
	    let selectedPatients = this.filter( ( patient ) => patient.get( 'selected' ) );
	    this.determineWhichAction( _.pluck( selectedPatients, 'id' ), this.actionType );
    },

    determineWhichAction: function( patients = [], actionType = 'approve' ) {
	    const controller = this.app.pe_controller;
	    switch( actionType ) {
            case 'approve':
                controller.approvePatients( patients );
                break;
            case 'decline':
                controller.declinePatients( patients );
                break;
            case 'sendToApproval':
                controller.sendToApproval( patients );
                break;
            default:
                controller.approvePatients( patients );
        }
    },

});