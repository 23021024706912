// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';

// Internal Dependencies - screens/views/templates
import expandedActivityView  from '../../../../templates/MIPS/ImprovementActivities/expandedActivityView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    getTemplate: function() {
        return expandedActivityView( this.model );
    },

});
