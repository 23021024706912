// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import patientTotalsView  from '../../../../../templates/PatientEnrollment/PatientApproval/PatientApprovalComponents/patientTotalsView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'click #pageRight': 'pageUp',
		'click #pageLeft': 'pageDown',
		'change #ccm_provider': 'sortResults'
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		if ( options.sort == undefined ) {
			this.model = new Backbone.Model( { sort: true } );
		} else {
			this.model = new Backbone.Model( { sort: options.sort } );
		}
		this.model.set({
            subnavItem: this.app.controller.subnavItem,
		});
		this.listenTo( this.model, 'change', this.render );
		this.listenTo( this.channel, 'set:patients', this.setTotals );
		this.listenTo( this.channel, 'reset:totals', this.setTotals );
	},

	getTemplate: function() {
		return patientTotalsView( this.model );
	},

	setTotals: function() {
		let controller = this.app.pe_controller;
		let itemCount = this.determineItemCount();
		let currentPage = controller.patients.currentPage;
		let totalPatients = controller.patients.totalPatients;
		this.model.set({
			sortedBy: controller.sortedBy || 3,
			totalPatients: totalPatients,
			currentPage: currentPage,
			beginIndex: currentPage == 0 ? 1 : currentPage * itemCount + 1,
			endIndex: ( currentPage + 1 ) * itemCount >= totalPatients ?
										totalPatients :
										( currentPage + 1 ) * itemCount
		});
	},

	determineItemCount: function() {
		return 100;
	},

	pageUp: function() {
		this.app.pe_controller.patientListPageUp();
	},

	pageDown: function() {
		this.app.pe_controller.patientListPageDown();
	},

	sortResults: function() {
		let searchType = this.app.controller.subnavItem;
		let searchInput = $( '#search' ).val();
		let sortType = this.whichSort( $( '#ccm_provider' ).val() );
		this.determineSearchType( searchType, searchInput, sortType );
	},

	determineSearchType: function( searchType, searchInput, sortType ) {
		searchInput = searchInput ? sortType + '&search=' + searchInput : sortType;
		let searchField;
		switch( searchType ) {
			case 'AwaitingApproval':
				searchField = searchInput;
				this.app.pe_controller.getAwaitingApprovalPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'Declined':
				searchField = searchInput;
				this.app.pe_controller.getDeclinedPatients( this.currentPage, this.pageSize, searchField );
				break;
			default:
				console.log('THIS SHOULD NOT HAPPEN. CHECK PATIENT TOTALS VIEW IN PATIENT ENROLLMENT MODULE');
				searchField = searchInput;
				this.app.pe_controller.getAwaitingApprovalPatients( this.currentPage, this.pageSize, searchField );
		}
	},

	whichSort: function( value ) {
		switch ( value ) {
			case '1':
				this.app.pe_controller.setGlobalValue( 'sortedBy', '1' );
				return 'sort_order=asc&sort_field=patientId';
				break;
			case '2':
				this.app.pe_controller.setGlobalValue( 'sortedBy', '2' );
				return 'sort_order=desc&sort_field=patientId';
				break;
			case '3':
				this.app.pe_controller.setGlobalValue( 'sortedBy', '3' );
				return 'sort_order=asc&sort_field=last_name';
				break;
			case '4':
				this.app.pe_controller.setGlobalValue( 'sortedBy', '4' );
				return 'sort_order=desc&sort_field=last_name';
				break;
			case '5':
				this.app.pe_controller.setGlobalValue( 'sortedBy', '5' );
				return 'sort_order=asc&sort_field=first_name';
				break;
			case '6':
				this.app.pe_controller.setGlobalValue( 'sortedBy', '6' );
				return 'sort_order=desc&sort_field=first_name';
				break;
			case '7':
				this.app.pe_controller.setGlobalValue( 'sortedBy', '7' );
				return 'sort_order=asc&sort_field=birthdate';
				break;
			case '8':
				this.app.pe_controller.setGlobalValue( 'sortedBy', '8' );
				return 'sort_order=desc&sort_field=birthdate';
				break;
			case '9':
				this.app.pe_controller.setGlobalValue( 'sortedBy', '9' );
				return 'sort_order=asc&sort_field=insurance1';
				break;
			case '10':
				this.app.pe_controller.setGlobalValue( 'sortedBy', '10' );
				return 'sort_order=desc&sort_field=insurance1';
				break;
            case '11':
                this.app.pe_controller.setGlobalValue( 'sortedBy', '11' );
                return 'sort_order=asc&sort_field=insurance2';
                break;
            case '12':
                this.app.pe_controller.setGlobalValue( 'sortedBy', '12' );
                return 'sort_order=desc&sort_field=insurance2';
                break;
			default:
				return '';
		}
	},

	onDestroy: function() {
		this.app.pe_controller.patients.currentPage = 0;
	},

});
