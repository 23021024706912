import Backbone from 'backbone';

export const SwitchOrgModel = Backbone.Model.extend({

	defaults: {
		currentApp: null,
		subNav: null,
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	

});
