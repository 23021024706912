import Backbone from 'backbone';

export const ProviderTransitionModel = Backbone.Model.extend({

  defaults: {
    patient_id: '',
    reason_options: '',
    selected_provider:''
  },

  initialize: function( options ) {
    this.channel = Backbone.Radio.channel( 'AppChannel' );
    this.app = this.channel.request( 'app' );
    this.controller = this.app.utilities_controller;
  },

  getProviders: function(callback) {
    this.controller.getProviders(callback);
  }

});