// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import mainSubNavView  from '../../../templates/Main/mainSubNavView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'set:unreadMessages', this.setUnreadCount );
		this.model.set( 'messenger', this.app.controller.user && this.app.controller.user.messenger );
		this.listenTo( this.model, 'change', this.addActive );
	},

	getTemplate: function() {
		return mainSubNavView( this.model );
	},

	onShow: function() {
		this.setUnreadCount();
		this.addActive();
	},

	addActive: function() {
		// Remove active class from any subnav-items
		$( '.subnav-item a' ).removeClass( 'active' );

		// Get the URL hash and add the active class to the anchor linking to current page
		let currentLocation = window.location.hash;
		$('a[href="' + currentLocation + '"]').addClass('active');

		// Handling notification folder
		let notificationFolders = $( '.notifications-folders' );

		// If 'notifications' is in the URL hash, add active class to the 'Notifications' subnav-item
		// and find active message folder route
		if ( currentLocation.indexOf( 'notifications' ) !== -1 ) {
			$('#MainNotifications').addClass('active');
			this.whichNotificationRoute();

		// If 'messaging' is not in the URL hash, but the folder is visible (aka, the user just
		// navigated out of messaging), slide up the folder so it is no longer visible
		} else if ( notificationFolders.is(':visible') ) {
			notificationFolders.slideUp();
		}
	},

	whichNotificationRoute: function() {
		// Remove active from all folder routes
		$( '.notifications-folders-folder a' ).removeClass( 'active' );

		// Get the active messaging folder
		let notificationFolders = $( '.notifications-folders' );
		let currentLocation = window.location.hash;
		let currentFolder = currentLocation.slice( currentLocation.lastIndexOf( '/' ) + 1 );
		let activeFolder = this.determineActiveFolder( currentFolder );
		notificationFolders.slideDown();
		$( '#' + activeFolder ).addClass( 'active' );
	},

	determineActiveFolder: function( currentFolder ) {
		switch( currentFolder ) {
			case 'priority':
				return 'Priority';
			case 'archive':
				return 'Archived';
			default:
				return 'Active';
		}
	},

	setUnreadCount: function() {
		if ( this.app.controller.unreadMessages !== 0 ) {
			this.$('.messaging-unread-inbox').html( `(${this.app.controller.unreadMessages || ' - '})` );
		} else {
			this.$('.messaging-unread-inbox').html( `` );
		}
	}
});
