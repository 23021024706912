import Backbone from 'backbone';

export const InsuranceModel = Backbone.Model.extend({

    // events: {
    //     'click [type="checkbox"]': 'selectInsurance'
    // },

    initialize: function () {
        this.channel = Backbone.Radio.channel('AppChannel');
        this.app = this.channel.request('app');
    },
    // selectPatient: function(event) {
    //     console.log(event);
    // }
});
