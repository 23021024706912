// External Dependencies


import Backbone from 'backbone';
import Marionette from 'backbone.marionette';

// Internal Dependencies - screens/views/templates
import loginView from '../../../templates/loginView'

// Internal Dependencies - models/collections
import LoginModel from './models/LoginModel';

export default Marionette.ItemView.extend({

	className: 'login-container',

	events: {
		'click .login': 'login'
	},

	initialize: function() {
		this.model = new LoginModel();
		this.channel = Backbone.Radio.channel('AppChannel');
		this.app = this.channel.request('app');
		this.router = this.channel.request('router');
		this.listenTo( this.channel, 'login:fail', this.showAuthError );
		this.app.controller.removeCookie();
	},

	getTemplate: function() {
		// console.log(window.render['login-view']);
		return loginView();
	},

	onShow: function() {
		if ( this.app.controller.forcedLogout ) {
			this.showAuthError( { responseJSON: { message: 'Session timed out' } } );
			this.app.controller.toggleForcedLogout();
		}
	},

	login: function(e) {
		e.preventDefault();
		let username = this.$('#emailAddress').val();
		let password = this.$('#userPassword').val();
		this.model.set({
			username,
			password
		});
		if ( this.model.validate() ) {
			this.model.authenticateUser();
			$('.login').html('Authenticating');
		}
	},

	showAuthError: function( error ) {
		let errorMessage = (error && error.responseJSON && error.responseJSON.message) || 'Error Authenticating.';
		$('.error-content').text(errorMessage);
		$('.error').show();
		$('#emailAddress').focus();
		$('.login').html('Login');
	}

});
