// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import  newNotificationModalView  from '../../../../templates/Main/Notifications/newNotificationModalView';

// Internal Dependencies - models/collections
import { NewNotificationModel } from './models/NewNotificationModel';

export default Marionette.LayoutView.extend({

	className: 'notification-modal',

	events: {
		'click .modal-close': 'closeModal',
		'change #notificationFilterLocation': 'selectLocation',
		'change #notificationFilterProvider': 'selectProvider',
		'change #notificationFilterPatient': 'selectPatient',
		'click .submit-new-notification': 'submitNotification',
	},

	ui: {
		notificationFilterLocation: '#notificationFilterLocation',
		notificationFilterProvider: '#notificationFilterProvider',
		notificationFilterPatient: '#notificationFilterPatient',
		notificationNewMessage: '#notificationNewMessage',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new NewNotificationModel();
		this.listenTo( this.channel, 'set:filteredNotificationProviders', this.setProviders );
		this.listenTo( this.channel, 'set:filteredNotificationPatients', this.setPatients );
		this.listenTo( this.channel, 'submitNewNotification:success', this.closeModal );
		if ( this.app.controller.selectedProviderId != -1 ) {
			this.setPatientsForProvider();
		}
		this.listenTo( this.channel, 'NewNotificationModal:show', this.modalOnShow );
	},

	modalOnShow: function() {
		if ( this.app.controller.selectedProviderId != -1 ) {
			this.setDefaultsForProvider();
		} else if ( this.app.controller.selectedLocationId != -1 ) {
			this.setDefaultsForLocation();
		} else {
			this.ui.notificationFilterLocation.prop( 'disabled', false );
		}
	},

	getTemplate: function() {
		return newNotificationModalView( this.model );
	},

	disableSelects: function() {
		this.ui.notificationFilterLocation.prop( 'disabled', true );
		this.ui.notificationFilterProvider.prop( 'disabled', true );
		this.ui.notificationFilterPatient.prop( 'disabled', true );
	},

	resetPatients: function() {
		if ( this.ui.notificationFilterPatient.val() ) {
			this.ui.notificationFilterPatient.prop( 'disabled', true );
			this.ui.notificationFilterPatient.prop( 'placeholder', 'Type the name of patient' );
			this.ui.notificationFilterPatient.val('');
		}

	},

	setDefaultsForLocation: function() {
		let controller = this.app.controller;
		let selectedLocationId = controller.selectedLocationId;
		let location = _.find( controller.locations, ( location ) => {
			return location.id == selectedLocationId
		});
		this.ui.notificationFilterLocation
		    .find('option')
		    .remove()
		    .end()
		    .append('<option value=' + location.id + ' disabled selected>' + location.name +  ' | ' + location.city +  ', ' + location.state + '</option>')
		;
		this.app.main_controller.getNotificationProviders( this.app.controller.selectedLocationId );
	},

	selectLocation: function( e ) {
		let locationId = this.ui.notificationFilterLocation.val();
		this.ui.notificationFilterPatient
		    .find('option')
		    .remove()
		    .end()
		    .append('<option value=-1 disabled selected>Select a patient</option>')
		;
		this.disableSelects();
		this.app.main_controller.getNotificationProviders( locationId );
	},

	setProviders: function() {
		let providers = this.app.main_controller.filteredNotificationProviders;
		let locationSelected = this.app.controller.selectedLocationId != -1;
		this.ui.notificationFilterProvider
		    .find('option')
		    .remove()
		    .end()
		    .append('<option value=-1 disabled selected>Select a provider</option>')
		;
		$.each(providers, (index, value) => {
	     	this.ui.notificationFilterProvider
		        .append(
		        	$('<option></option>')
            			.attr( 'value', value.id )
            			.text( value.name )
        		);
		});
		this.resetPatients();
		this.ui.notificationFilterLocation.prop( 'disabled', locationSelected );
		this.ui.notificationFilterProvider.prop( 'disabled', false );
	},

	selectProvider: function() {
		let providerId = this.ui.notificationFilterProvider.val();
		this.disableSelects();
		this.app.main_controller.getNotificationPatients( providerId );
	},

	setPatients: function() {
		let providerSelected = this.app.controller.selectedProviderId != -1;
		let locationSelected = this.app.controller.selectedLocationId != -1;
		let patients = 	this.app.main_controller &&
						this.app.main_controller.filteredNotificationPatients &&
						this.app.main_controller.filteredNotificationPatients.length &&
						this.app.main_controller.filteredNotificationPatients.map( ( patient ) => {
							return Object.assign( {}, {
								value: `${ patient.last_name }, ${ patient.first_name } - ${ this.app.controller.formatFixedDateForUI( patient.birthdate ) }`,
								data: patient.ccm_patient_id,
							});
						});
		this.model.set( 'patients', patients );
		this.resetPatients();
		$( '#notificationFilterPatient' ).devbridgeAutocomplete({
			lookup: patients,
			autoSelectFirst: true,
		});
		this.ui.notificationFilterLocation.prop( 'disabled', locationSelected || providerSelected );
		this.ui.notificationFilterProvider.prop( 'disabled', providerSelected );
		this.ui.notificationFilterPatient.prop( 'disabled', false );
		( !patients || ( patients && !patients.length ) ) && this.removeCreateNotification();
	},

	setPatientsForProvider: function() {
		let providerId = this.app.controller.selectedProviderId;
		this.app.main_controller.getNotificationPatients( providerId );
	},

	setDefaultsForProvider: function() {
		let controller = this.app.controller;
		let provider = _.find( controller.providers, ( provider ) => {
			return provider.id == controller.selectedProviderId;
		});
		let location = _.find( controller.locations, ( location ) => {
			return location.id == controller.selectedLocationId;
		}) || { id: -1, name: 'All locations', city: '', state: '' }
		let locationOption;
		if ( location.id != -1 ) {
			locationOption = '<option value=' + location.id + ' disabled selected>' + location.name +  ' | ' + location.city +  ', ' + location.state + '</option>';
		} else {
			locationOption = '<option value=' + location.id + ' disabled selected>' + location.name + '</option>';
		}
		this.ui.notificationFilterLocation
		    .find('option')
		    .remove()
		    .end()
		    .append(locationOption)
		;
		this.ui.notificationFilterProvider
		    .find('option')
		    .remove()
		    .end()
		    .append('<option value=' + provider.id + ' disabled selected>' + provider.name + '</option>')
		;
	},

	submitNotification: function() {
		let controller = this.app.controller;
		const preSelectedProvider = controller.selectedProviderId != -1;
		const preSelectedLocation = controller.selectedLocationId != -1;
		let body = this.ui.notificationNewMessage.val();
		const provider_id = preSelectedProvider ? controller.selectedProviderId : this.ui.notificationFilterProvider.val();
		// location_id value is only needed for validation and is not sent to the backend
		// If there is a preSelectedLocation or preSelectedProvider, we pass true to pass validation
		const location_id = ( preSelectedLocation || preSelectedProvider ) ? true : this.ui.notificationFilterLocation.val();
		const sender_id = this.app.controller.user.id;
		let notification = {
			patient_id: this.model.determinePatientIdFromInput( this.ui.notificationFilterPatient.val() ),
			body,
			provider_id,
			sender_id,
			location_id,
		}
		if ( this.model.isValid( notification ) ) {
			// If validation passes, check to append sender's name if not provider
			if ( provider_id !== sender_id ) {
				notification.body = notification.body + "\n(Sent by: " + this.app.controller.user.name + ")"
			};
			this.model.sendNotification( notification );
			return;
		}
		// Do some error stuff
		let missingData = this.model.determineMissingData( notification );
		let allData = [ 'notificationFilterPatient', 'provider_id', 'location_id', 'notificationNewMessage' ];
		allData.forEach( ( data ) => {
			if ( missingData.indexOf( data ) == -1 ) {
				$( `#${ data }` ).removeClass( 'invalid' );
			}
		});
		missingData.length && missingData.forEach( ( data ) => {
			$( `#${ data }` ).addClass( 'invalid' );
		});
		$( `#${ missingData[0] }` ).focus();

	},

	closeModal: function(e) {
		e && e.preventDefault();
		$( '#newNotificationModal' ).closeModal();
		this.destroy();
	},

	removeCreateNotification: function() {
		this.$( '.modal-header' ).hide();
		this.$( '.modal-content' ).hide();
		this.$( '.no-enrolled-patients' ).toggleClass( 'hide' );
	},

});
