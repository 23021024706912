// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts'

// Internal Dependencies - screens/views/templates
import summaryContainerView  from '../../../../../templates/MIPS/AdvancingCare/Summary/summaryContainerView';
import SummaryDateRangeContainerView from '../../DateRangeContainerView'
import OverallScoreContainerView from './OverallScoreContainerView';
import BaseScoreContainerView from './BaseScoreContainerView';
import BonusScoreContainerView from './BonusScoreContainerView';
import PerformanceScoreContainerView from './PerformanceScoreContainerView';

// Internal Dependencies - models/collections
import { SummaryModel } from '../models/SummaryModel'

export default Marionette.LayoutView.extend({

    regions: {
        'dateRangeContainer': '.date-range-container',
        'overallScoreContainer': '#overallScoreContainer',
        'baseScoreContainer': '#baseScoreContainer',
        'bonusScoreContainer': '#bonusScoreContainer',
        'performanceScoreContainer': '#performanceScoreContainer',
    },

    events: {
        'change #yearToDate': 'setYearToDate',
        'change #dateRange': 'setDateRange',
        'click #applyDateRange': 'applyDateRange',
    },

    initialize: function( options ) {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.model = new SummaryModel();
        this.app.mips_controller.getAdvancingCareSummary( false );
        this.listenTo( this.model, 'change:yearToDate change:dateRange', this.checkAttestationPeriod );
        this.listenTo( this.model, 'change:summaryData', this.renderCharts );
	    if ( this.app.controller.currentApp === 'MIPSPROVIDER' ) {
		    this.listenTo( this.channel, 'set:selectedProviderId', this.applyDateRange );
	    }
    },

    getTemplate: function() {
        return summaryContainerView( );
    },

    onShow: function() {
        $('.content-container').addClass('widen');
        this.showChildView( 'overallScoreContainer', new OverallScoreContainerView( { model: this.model } ) );
        this.showChildView( 'baseScoreContainer', new BaseScoreContainerView( { model: this.model } ) );
        this.showChildView( 'bonusScoreContainer', new BonusScoreContainerView( { model: this.model } ) );
        this.showChildView( 'performanceScoreContainer', new PerformanceScoreContainerView( { model: this.model } ) );
        this.renderCharts();
    },

    renderCharts: function() {
        this.overallBaseScoreChart();
        this.overallBonusScoreChart();
        this.overallPerformanceScoreChart();
    },

    checkAttestationPeriod: function() {
        this.model.get('dateRange') ?
            this.showChildView( 'dateRangeContainer', new SummaryDateRangeContainerView( { model: this.model } ) ) :
            this.getRegion('dateRangeContainer').empty();
    },

    setYearToDate: function() {
        this.model.set('dateRange', false);
    },

    setDateRange: function() {
        this.model.set('dateRange', true);
    },

    applyDateRange: function() {
        const dateRange = this.model.get( 'dateRange' );
        const startMonth = this.model.get( 'startMonth' );
        const endMonth = this.model.get( 'endMonth' );
        const year = this.model.get( 'year' );
        if ( dateRange ) {
            this.app.mips_controller.getAdvancingCareSummary( dateRange, startMonth, endMonth, year );
        } else {
            this.app.mips_controller.getAdvancingCareSummary( dateRange );
        }
    },

    overallBaseScoreChart: function() {
        const baseScoreObject = this.model.get( 'advancingSummaryBaseScore' );
        Highcharts.chart('overallBaseScore', {
            title: {
                text: `Base Criteria Complete <br> (out of ${baseScoreObject.totalCriteria})`,
                style: {
                    color: '#529FC0'
                }
            },
            chart: {
                height: 200,
                spacingBottom: 30,
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        format: '<b>{point.y}</b>'
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '80%'],
                    showInLegend: true,
                    size: 160,
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: [
                    {
                        name: 'Complete',
                        y: baseScoreObject.criteriaComplete,
                        color: '#529FC0'
                    }, {
                        name: 'Incomplete',
                        y: baseScoreObject.totalCriteria - baseScoreObject.criteriaComplete,
                        color: 'rgb(170,170,170)'
                    }
                ]
            }],
            credits: {
                enabled: false
            }
        });
    },

    overallBonusScoreChart: function() {
        const bonusScoreObject = this.model.get( 'advancingSummaryBonusScore' );
        Highcharts.chart('overallBonusScore', {
            title: {
                text: `Bonus Criteria Complete <br> (out of ${bonusScoreObject.totalCriteria})`,
                style: {
                    color: '#529FC0'
                }
            },
            chart: {
                height: 200,
                spacingBottom: 30,
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        format: '<b>{point.y}</b>'
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '80%'],
                    showInLegend: true,
                    size: 160,
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: [
                    {
                        name: 'Complete',
                        y: bonusScoreObject.criteriaComplete,
                        color: '#529FC0'
                    }, {
                        name: 'Incomplete',
                        y: bonusScoreObject.totalCriteria - bonusScoreObject.criteriaComplete,
                        color: 'rgb(170,170,170)'
                    }
                ]
            }],
            credits: {
                enabled: false
            }
        });
    },

    overallPerformanceScoreChart: function() {
        const performanceScoreObject = this.model.get( 'advancingSummaryPerformanceScore' );
        Highcharts.chart('overallPerformanceScore', {
            title: {
                text: `Performance Criteria Complete (out of ${performanceScoreObject.totalCriteria})`,
                style: {
                    color: '#529FC0'
                }
            },
            chart: {
                height: 200,
                spacingBottom: 30,
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        format: '<b>{point.y}</b>'
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '80%'],
                    showInLegend: true,
                    size: 160,
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: [
                    {
                        name: 'Complete',
                        y: performanceScoreObject.criteriaComplete,
                        color: '#529FC0'
                    }, {
                        name: 'Incomplete',
                        y: performanceScoreObject.totalCriteria - performanceScoreObject.criteriaComplete,
                        color: 'rgb(170,170,170)'
                    }
                ]
            }],
            credits: {
                enabled: false
            }
        });
    },

    onDestroy: function() {
        $('.content-container').removeClass('widen');
    },

});
