import Backbone from 'backbone';

let contentSpinnerCount = 0;

export const AppListeners = ( router, channel, app ) => {
	channel.reply( 'app', () => {
		return app;
	});
	channel.reply( 'router', () => {
		return router;
	});

	/******************
	Authentication Listeners
	******************/

	router.listenTo( channel, 'login:success', () => {
		channel.trigger( 'toggle:globalSpinner' );
		if ( app.controller && app.controller.user ) {
			router.navigate('/main/dashboard', true);
		} else {
			app.listenTo( channel, 'set:user', () => {
				router.navigate('/main/dashboard', true);
			})
		}
	});
	app.listenTo( channel, 'logout:user', () => {
		app.controller.removeCookie();
		channel.trigger( 'close:modal' );
		channel.trigger( 'toggle:globalSpinner' );
		channel.trigger( 'nav:Login' );
		channel.trigger( 'reset:app' );
	});
	app.listenTo( channel, 'toggle:forcedLogout', () => {
		app.controller.toggleForcedLogout();
	});

	/******************
	Navigation Listeners
	******************/

	app.listenTo( channel, 'nav:Main', () => {
		router.goMain();
	});
	app.listenTo( channel, 'nav:Login', () => {
		router.navigate( '/login', true );
		$('.global-spinner').hide();
	});
	app.listenTo( channel, 'nav:LoginSuccessHome', () => {
		router.navigate( '/main/dashboard', true );
	});
	app.listenTo( channel, 'nav:MainDashboard', () => {
		$('.global-spinner').hide();
		router.goDashboard();
	});
	app.listenTo( channel, 'nav:MainMessaging', () => {
		router.goMessaging();
	});
	app.listenTo( channel, 'nav:MainArchive', () => {
		router.goArchive();
	});
	app.listenTo( channel, 'nav:MainSent', () => {
		router.goSent();
	});
	app.listenTo( channel, 'nav:MainNotifications', () => {
		router.goNotifications();
	});
	app.listenTo( channel, 'nav:MainArchivedNotifications', () => {
		router.goArchivedNotifications();
	});
	app.listenTo( channel, 'nav:MainPriorityNotifications', () => {
		router.goPriorityNotifications();
	});
	app.listenTo( channel, 'nav:CcmPatientList', () => {
		router.goPatientList();
	});
	app.listenTo( channel, 'nav:CcmAutoEnroll', () => {
		router.goAutoEnroll();
	});
	app.listenTo( channel, 'nav:CcmEnrolled', () => {
		router.goEnrolled();
	});
	app.listenTo( channel, 'nav:CcmSuspended', () => {
		router.goCcmSuspended();
	});
	app.listenTo( channel, 'nav:CcmProviderTransition', () => {
		router.goCcmProviderTransition();
	});
	app.listenTo( channel, 'nav:CcmSupport', () => {
		router.goCcmSupport();
	});
	app.listenTo( channel, 'nav:CcmUnverified', () => {
		router.goCcmUnverified();
	});
	app.listenTo( channel, 'nav:CcmOptedOut', () => {
		router.goCcmOptedOut();
	});
	app.listenTo( channel, 'nav:CcmRapidBill', () => {
		router.goRapidBill();
	});
	app.listenTo( channel, 'nav:CcmErrorQueue', () => {
		router.goErrorQueue();
	});
	app.listenTo( channel, 'nav:CcmPendingReview', () => {
		router.goPendingReview();
	});
	app.listenTo( channel, 'nav:CcmPendingUnenroll', () => {
		router.goPendingUnenroll();
	});
    app.listenTo( channel, 'nav:CcmProviderApproval', () => {
	  router.goProviderApproval();
    });
	app.listenTo( channel, 'nav:CcmRevenueDashboard', () => {
		router.goRevenueDashboard();
	});
	app.listenTo( channel, 'nav:CcmRapidBillReports', () => {
		router.goRapidBillReports();
	});
	app.listenTo( channel, 'nav:CcmUnenrolled', () => {
		router.goUnenrolled();
	});
	app.listenTo( channel, 'nav:CpcPatientList', () => {
		router.goCpcPatientList();
	});
	app.listenTo( channel, 'nav:CpcEcqm', () => {
		router.goCpcEcqm();
	});
	app.listenTo( channel, 'nav:CpcQualityMeasures', () => {
		router.goCpcQualityMeasures();
	});
	app.listenTo( channel, 'nav:CpcDocuments', () => {
		router.goCpcDocuments();
	});
	app.listenTo( channel, 'nav:MipsDashboard', () => {
		router.goMipsNewDashboard();
	});
	app.listenTo( channel, 'nav:MipsGroupDashboard nav:MipsProviderDashboard', () => {
		router.goMipsDashboard();
	});
	app.listenTo( channel, 'nav:MipsGroupQualityMeasures nav:MipsProviderQualityMeasures', () => {
		router.goMipsQualityMeasures();
	});
    app.listenTo( channel, 'nav:MipsGroupQualityMeasureDiabetesPoorControl nav:MipsProviderQualityMeasureDiabetesPoorControl', () => {
        router.goMipsQualityMeasureDiabetesPoorControl();
    });
	app.listenTo( channel, 'nav:MipsGroupQualityMeasureTobaccoUse nav:MipsProviderQualityMeasureTobaccoUse', () => {
		router.goMipsQualityMeasureTobaccoUse();
	});
	app.listenTo( channel, 'nav:MipsGroupQualityMeasureAdvanceCarePlan nav:MipsProviderQualityMeasureAdvanceCarePlan', () => {
		router.goMipsQualityMeasureAdvanceCarePlan();
	});
    app.listenTo( channel, 'nav:MipsGroupQualityMeasurePneumococcalVaccination nav:MipsProviderQualityMeasurePneumococcalVaccination', () => {
        router.goMipsQualityMeasurePneumococcalVaccination();
    });
    app.listenTo( channel, 'nav:MipsGroupQualityMeasureFallRiskAssessment nav:MipsProviderQualityMeasureFallRiskAssessment', () => {
        router.goMipsQualityMeasureFallRiskAssessment();
    });
    app.listenTo( channel, 'nav:MipsGroupQualityMeasureFallCarePlan nav:MipsProviderQualityMeasureFallCarePlan', () => {
        router.goMipsQualityMeasureFallCarePlan();
    });
	app.listenTo( channel, 'nav:MipsGroupAdvancingCare nav:MipsProviderAdvancingCare', () => {
		router.goMipsAdvancingCare();
	});
    app.listenTo( channel, 'nav:MipsGroupAdvancingCareBaseScore nav:MipsProviderAdvancingCareBaseScore', () => {
        router.goMipsAdvancingCareBaseScore();
    });
    app.listenTo( channel, 'nav:MipsGroupAdvancingCarePerformanceScore nav:MipsProviderAdvancingCarePerformanceScore', () => {
        router.goMipsAdvancingCarePerformanceScore();
	});
    app.listenTo( channel, 'nav:MipsGroupAdvancingCareBonusScore nav:MipsProviderAdvancingCareBonusScore', () => {
        router.goMipsAdvancingCareBonusScore();
    });
	app.listenTo( channel, 'nav:MipsGroupImprovementActivities nav:MipsProviderImprovementActivities', () => {
		router.goMipsImprovementActivities();
	});
    app.listenTo( channel, 'nav:PatientEnrollmentPeReporting', () => {
        router.goPeReporting();
    });
	app.listenTo( channel, 'nav:PatientEnrollmentFileManager', () => {
		router.goPeFileManager();
	});
	app.listenTo( channel, 'nav:PatientEnrollmentRapidReview', () => {
		router.goPeRapidReview();
	});
    app.listenTo( channel, 'nav:PatientEnrollmentAwaitingApproval', () => {
        router.goPeApproval();
    });
	app.listenTo( channel, 'nav:PatientEnrollmentDeclined', () => {
		router.goPeDeclined();
	});
	app.listenTo( channel, 'nav:UtilitiesHistoricalBilling', () => {
		router.goHistoricalBilling();
	});
	app.listenTo( channel, 'nav:UtilitiesManageUsers', () => {
		router.goManageUsers();
	});
	app.listenTo( channel, 'nav:UtilitiesResources', () => {
		router.goResources();
	});
	app.listenTo( channel, 'nav:UtilitiesSupport', () => {
		router.goSupport();
	});
	app.listenTo( channel, 'nav:UtilitiesChangePassword', () => {
		router.goChangePassword();
		$( '.global-spinner' ).hide();
	});
    app.listenTo( channel, 'nav:UtilitiesResetEnvironment', () => {
	  router.goResetEnvironment();
    });
	app.listenTo( channel, 'nav:UtilitiesUpload', () => {
		router.goUpload();
	});

	app.listenTo(channel, 'nav:UtilitiesBulkProviderChange', () => {
		router.goBulkProviderChange();
	});

	app.listenTo(channel, 'nav:UtilitiesBulkProviderSuspension', () => {
		router.goBulkProviderSuspension();
	});

	app.listenTo(channel, 'nav:UtilitiesBulkProviderTransition', () => {
		router.goBulkProviderTransition();
	});

	/*****************
	Action Listeners
	*****************/

	app.listenTo( channel, 'toggle:globalSpinner', () => {
		app.controller.toggleGlobalSpinner();
	});
	app.listenTo( channel, 'toggle:contentSpinner', () => {
		app.controller.toggleContentSpinner();
	});
	app.listenTo( channel, 'hide:contentSpinner', () => {
		contentSpinnerCount--;
		contentSpinnerCount == 0 && app.controller.hideContentSpinner();
	});
	app.listenTo( channel, 'show:contentSpinner', () => {
		contentSpinnerCount++
		app.controller.showContentSpinner();
	});
	app.listenTo( channel, 'toggle:menu', () => {
		$( '.sideNav-container' ).toggleClass( 'expanded' );
		$( '.content-container' ).toggleClass( 'expanded' );
	});
	app.listenTo( channel, 'failed:api', ( error ) => {
		if ( !error.status || error.status == 401 ) {
			app.controller.toggleForcedLogout();
			channel.trigger( 'logout:user' );
			return;
		}
		app.controller.displayError( error );
	});
	app.listenTo( channel, 'failed:unenroll', ( error ) => {
		if ( error.status == 401 ) {
			app.controller.toggleForcedLogout();
			channel.trigger( 'logout:user' );
			return;
		}
		if ( error.status == 400 ) {
			const mockError = {
				status:400,
				statusText: 'Failed to Unenroll',
				responseJSON: { message: 'Patient information is still synchronizing. Please try again in 30 minutes. If failure continues, please contact ChartSpan Client Services' },
			};
			app.controller.displayError( mockError );
			return;
		}
		app.controller.displayError( error );
	});
	app.listenTo( channel, 'set:messagingIndex', () => {
		app.main_controller.setMessagingIndex();
	});
	app.listenTo( channel, 'set:selectedOrganization', () => {
		// app.mips_controller.getMipsSettings();
	});
	app.listenTo( channel, 'error:timeout', () => {
	    const mockError = {
			status:400,
            statusText: 'Error - Timeout',
            responseJSON: { message: 'Please refresh the page or check your internet connection.' },
        }
		app.controller.displayError( mockError );
	});

	app.listenTo( channel, 'bulkProviderSuspension:fail', (error) => {
		if(error.status == 400){
			const mockError = {
				status:400,
				statusText: 'Error',
				responseJSON: { message: error.responseJSON.message },
			}
			app.controller.displayError( mockError );
		}

	});

}
