// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import switchOrgModalView  from '../../../templates/switchOrgModalView';

export default Marionette.LayoutView.extend({

	regions: {
		
	},

	events: {
		'click .modal-close': 'closeModal',
		'click #switchOrgButtonId': 'switchOrgAction',
	},

	initialize: function( options ) {
		this.callback = options.callback;
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
		this.listenTo( this.channel, 'remove:errors', this.removeErrors );
		this.listenTo( this.channel, 'trigger:show', this.showTriggered );
	},

	switchOrgChanged: function( selectedOrgId ) {
			this.selectedSwitchOrgId = selectedOrgId;
			const ActiveOrganizations = this.app.controller.ActiveOrganizations;
			const selectedOrgData = ActiveOrganizations.filter(org =>  org.id == this.selectedSwitchOrgId);
			if(selectedOrgData.length > 0){
				this.model.set( 'selectedSwitchOrg', selectedOrgData[0] );
				const label = selectedOrgData[0].name + ' ('+selectedOrgData[0].id + ')';
				$('#switchOrgMessageId').css('display', 'block');
				$('#selectedSwitchOrgLabelId').html(label);
				$('#switchOrgButtonId').prop('disabled', false);
			}else{
				this.model.set( 'selectedSwitchOrg', null );
				$('#switchOrgMessageId').css('display', 'none');
				$('#switchOrgButtonId').prop('disabled', true);
			}
			
			
			return;
	},

	getTemplate: function() {
		return switchOrgModalView( this.model );
	},

	closeModal: function( e ) {
		e && e.preventDefault();
		$( '#modalEnrollPatient' ).closeModal();
		this.model.set( 'selectedSwitchOrg', null );
		this.destroy();
	},

	switchOrgAction: function( e ) {
		e.preventDefault();
		this.app.controller.switchOrganization(this.selectedSwitchOrgId);
	},
	showTriggered: function() {
		const self = this;
		this.selectedSwitchOrgId = '';
		$('#selected_org_id').devbridgeAutocomplete({
			serviceUrl: `${ this.app.controller.engageUrl }v1/organizations/_search`,
			onSelect: function( suggestion ) {
				self.switchOrgChanged(suggestion.id);
			},
			paramName: 'name',
	    	maxHeight: 150,
	    	ajaxSettings: {
	    		headers: {
	    			'X-AUTH-TOKEN': this.app.controller.getToken()
	    		}
	    	},
	    	transformResult: function( data ) {
	    		let response = JSON.parse( data );
	    		return {
	    			suggestions: response.map( ( item ) => {
	    				return { value: `${ item.name }`, id: item.id }
	    			})
	    		}
	    	},
	    	autoSelectFirst: true,
	    	noCache: true,
	    });
	}
	

});
