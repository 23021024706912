// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import patientListItemView  from '../../../../templates/CCM/PatientList/patientListItemView';
import EnrollPatientModalView from './EnrollPatientModalView';
import SuspendPatientModalView from './SuspendPatientModelView';
import SpecialistRecordsModalView from './SpecialistRecordsModalView';
import UnenrollPatientModelView from './UnenrollPatientModelView';

// Internal Dependencies - models/collections
import { EnrollPatientModel } from './models/EnrollPatientModel';
import { SuspendPatientModel } from './models/SuspendPatientModel';
import { UnenrollPatientModel } from './models/UnenrollPatientModel';


export default Marionette.ItemView.extend({

	className: 'table-row clearfix',

	events: {
		'click .more': 'expandContent',
		'click .less': 'expandContent',
		'click .enroll': 'launchEnrollPatient',
		'click .edit-patient-information': 'launchEditPatient',
		'click .unenroll': 'unenrollPatient',
		'click .suspend': 'suspendPatient',
		'click .unsuspend': 'unsuspendPatient',
        'click .open-patient-plan': 'openPatientPlan',
        'click .open-ccda': 'openCcda',
		'click .specialist-records': 'openRecordsModal',
		'click .pending-unenroll-link':'gotoPendingUnenroll'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.model, 'change', this.render );
	},

	getTemplate: function() {
		return patientListItemView( this.model );
	},

	expandContent: function( e ) {
		this.$( '.more' ).toggleClass( 'active' );
		this.$( '.less' ).toggleClass( 'active' );
		this.$( '.patient-list-expanded-data' ).slideToggle( 200 );
	},

	unenrollPatient: function() {
		// this.model.unenrollPatient( this.model.get( 'id' ) );
    this.model.getUnenrollReasons((data) => {
      let unenrollPatientModel = new UnenrollPatientModel( this.model.attributes );
      unenrollPatientModel.set( 'patient_id', this.model.get( 'id' ) );
      unenrollPatientModel.set( 'unenroll_reason_options', data );
      let modalView = new UnenrollPatientModelView({ model: unenrollPatientModel});
      modalView.render();
      $( 'body' ).append( modalView.el );
      $( '#modalReject' ).openModal();
    });
	},

	suspendPatient: function() {
		// this.model.suspendPatient( this.model.get( 'id' ) );
    this.model.getSuspendReasons((data) => {
      let suspendPatientModel = new SuspendPatientModel( this.model.attributes );
      suspendPatientModel.set( 'patient_id', this.model.get( 'id' ) );
      suspendPatientModel.set( 'reason_options', data );
      let modalView = new SuspendPatientModalView({ model: suspendPatientModel});
      modalView.render();
      $( 'body' ).append( modalView.el );
      $( '#modalReject' ).openModal();
    });
	},

	unsuspendPatient: function() {
		this.model.unsuspendPatient( this.model.get( 'id' ) );
	},

	openPatient: function() {
		this.togglePatientDetails( null, this.model.get( 'id' ) );
	},

	launchEnrollPatient: function( e ) {
		let enrollPatientModel = new EnrollPatientModel( Object.assign({},this.model.attributes,{edit_action:false} ));
		// enrollPatientModel.edit_action = true;
		let modalView = new EnrollPatientModalView({ model: enrollPatientModel });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalEnrollPatient' ).openModal();
		this.channel.trigger( 'trigger:show' );
	},

	launchEditPatient: function( e ) {
		let enrollPatientModel = new EnrollPatientModel(Object.assign({},this.model.attributes,{edit_action:true} ) );
		// enrollPatientModel.edit_action = false;
		let modalView = new EnrollPatientModalView({ model: enrollPatientModel });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalEnrollPatient' ).openModal();
		this.channel.trigger( 'trigger:show' );
	},

	openPatientPlan: function() {
		let patientId = this.model.get( 'id' );
		this.model.openPatientPlan( patientId );
	},

	openCcda: function() {
		let patientId = this.model.get( 'id' );
		this.model.openCcda( patientId );
	},

	openRecordsModal: function() {
		let modalView = new SpecialistRecordsModalView({model: this.model});
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#specialistRecordsModal' ).openModal();
		this.channel.trigger( 'trigger:records_modal' );
	},
	gotoPendingUnenroll: function() {
		// alert('goto');
		this.app.router.navigate('ccm/patient-status/pending-unenroll',true);
	}


});
