import Backbone from 'backbone';

export const ChangePasswordModel = Backbone.Model.extend({

	defaults: {
		newPassword: '',
		oldPassword: '',
	},

	id: '',

	initialize: function() {
		this.channel = Backbone.Radio.channel('AppChannel');
		this.app = this.channel.request('app');
	},

	isValidLength: function ( passwords ) {	
		if ( !passwords.newPassword || passwords.newPassword.length < 8 ) {
			return false;
		}
		return true;
	},

	isValidMatch: function ( passwords ){
		if ( !passwords.confirmPassword || passwords.confirmPassword !== passwords.newPassword ) {
			return false;
		}
		return true;
	},

	changePassword: function( passwords ) {
		this.app.utilities_controller.changePassword( passwords );
	}
});