export const QUALITY_MEASURES = {
	advance_care: {
		title: 'Advancing Care Information Plan',
		description: 'Percentage of patients aged 65 and older who have an advance care plan or surrogate decision maker documented in the medical record or documentation in the medical record that an advance care plan was discussed but the patient did not wish or was not able to name a surrogate decision maker or provide an advance care plan.',
		numeratorText: 'patients meeting criteria',
		denominatorText: 'total eligible patients',
		numeratorInputText: 'Patients with advance care plan or care documentation',
		denominatorInputText: 'Patients aged 65 or older',
		percent1: 'No Care Plan',
		percent2: 'Have Care Plan',
		additional_info_url: 'http://google.com',
	},
};

export const ADVANCING_CARE_SCORES = {
    base_score: {
        title: 'Base Score',
        criteria: [
            {
                title: 'Security Analysis',
                criteriaKey: 'security_analysis',
                description: 'Conduct or review a security risk analysis in accordance with the requirements in 45 CFR 164.308(a)(1), including addressing the security (to include encryption) of ePHI data created or maintained by certified EHR technology in accordance with requirements in 45 CFR 164.312(a)(2)(iv) and 45 CFR 164.306(d)(3), and implement security updates as necessary and correct identified security deficiencies as part of the MIPS eligible clinician\'s risk management process.',
                inputText: 'if yes, date analysis completed',
                dataText: 'Completion of this Criteria meets one of the ',
                additionalQuestionText: 'Security Analysis',
                additionalQuestionType: 'yes/no',
                additional_info_url: 'http://google.com',
                totalSuffix: null,
            },
            {
                title: 'E-Prescription',
                criteriaKey: 'e-prescription',
                description: 'At least one permissable prescription written by the MIPS eligible clinician is queried for a drug formulary and transmitted electronically using certified EHR technology.',
                inputText: 'Monthly e-Prescriptions',
                dataText: 'Total e-Prescriptions',
                additionalQuestionText: null,
                additionalQuestionType: null,
                additionaL_info_url: 'http://google.com',
                totalSuffix: 'Rx',
            },
            {
                title: 'Health Info Exchange',
                criteriaKey: 'health_info_exchange',
                description: 'The MIPS eligible clinician that transitions or refers their patients to another setting of care or health care clinician (1) uses CEHRT to create a summary of care record; and (2) electronically transmits each summary to a receiving health care clinician or at least one transition of care or referral.',
                inputText: null,
                dataText: 'NOTE: total calculated based on Advancing Care Information Performance Measure Health Info Exchange',
                additionalQuestionText: null,
                additionalQuestionType: null,
                additional_info_url: 'http://google.com',
                totalSuffix: null,
            },
            {
                title: 'Provide Patient Access',
                criteriaKey: 'provide_patient_access',
                description: 'At least one patient seen by the MIPS eligible clinician during the performance period is provided timely access to view online, download, and transmit to a third party their health information subject to the MIPS elibible clinician\'s discretion to withhold certain information.',
                inputText: null,
                dataText: 'NOTE: total calculated based on Advancing Care Information Performance Measure Provide Patient Access',
                additionalQuestionText: null,
                additionalQuestionType: null,
                additional_info_url: 'http://google.com',
                totalSuffix: null,
            },
        ],
    },
    performance_score: {
        title: 'Performance Score',
        criteria: [
            {
                title: 'Provide Patient Access',
                criteriaKey: 'provide_patient_access',
                description: 'Patients seen by the MIPS eligible clinician during the performance period provided timely access to view online, download, and transmit to a third party their health information subject to the MIPS eligible clinician\'s discretion to withhold certain information.',
                numeratorText: 'achieved',
                denominatorText: 'possible',
                numeratorInputText: 'unique patients seen by the clinician during the performance period who were provided "timely" access to view online, download, and transmit to a third party their health information',
                denominatorInputText: 'unique patients seen by the clinician during the performance period',
                percent1: 'Provided Access',
                percent2: 'Not Provided Access',
                additional_info_url: 'http://google.com',
                points_possible: 20,
            },
            {
                title: 'Immunization Registry',
                criteriaKey: 'immunization_registry',
                description: 'The MIPS eligible clinician is in active engagement with a public health agency to submit immunization data.',
                numeratorText: 'Immunization Registry Submission',
                denominatorText: 'if yes, enter name of Registry',
                numeratorInputText: null,
                denominatorInputText: null,
                percent1: null,
                percent2: null,
                additional_info_url: 'http://google.com',
                points_possible: 10,
            },
        ],
    },
    bonus_score: {
        title: 'Bonus Score',
        criteria: [
            {
                title: 'Public and Clinical Health Data Registry Bonus',
                criteriaKey: 'health_data_registry',
                description: 'The MIPS eligible clinician is in active engagement with a public health agency to submit Syndromic Surveillance data. Earn a 5 % bonus in the advancing care information performance category score for submitting to one or more public health or clinical data registries.',
                numeratorText: null,
                denominatorText: null,
                numeratorInputText: 'if yes, please enter Registry Name',
                denominatorInputText: null,
                percent1: 'Health Data Registry',
                percent2: null,
                additional_info_url: 'http://google.com',
                points_possible: null,
            },
            {
                title: 'Improvement Activity Bonus',
                criteriaKey: 'improvement_activity',
                description: 'Reporting "yes” to the implementation and/or completion of at least 1 of the specified Improvement Activities using CEHRT will result in a 10% bonus.',
                numeratorText: null,
                denominatorText: null,
                numeratorInputText: 'if yes, please choose an Activity',
                denominatorInputText: null,
                percent1: 'Improvement Activity',
                percent2: null,
                additional_info_url: 'http://google.com',
                points_possible: null,
            },
        ],
    }
};

export const MONTH_OPTIONS = [
	{
		value: 1,
		text: 'January',
	},
    {
        value: 2,
        text: 'February',
    },
    {
        value: 3,
        text: 'March',
    },
    {
        value: 4,
        text: 'April',
    },
    {
        value: 5,
        text: 'May',
    },
    {
        value: 6,
        text: 'June',
    },
    {
        value: 7,
        text: 'July',
    },
    {
        value: 8,
        text: 'August',
    },
    {
        value: 9,
        text: 'September',
    },
    {
        value: 10,
        text: 'October',
    },
    {
        value: 11,
        text: 'November',
    },
    {
        value: 12,
        text: 'December',
    },
];

export const YEAR_OPTIONS = [ '2017' ];