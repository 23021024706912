import Backbone from 'backbone';
import moment from 'moment';

export const FileModel = Backbone.Model.extend({

    defaults: {
        cs_upload: false,
        file_notes: '',
        uploaded_by: 0,
        upload_date: '',
        file_name: '',
        file_url: '',
        file_id: 0
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
    },

    uploadFile: function() {
        let file = this.get( 'filePath' );
        let notes = this.get( 'fileNotes' );
        let noOfPatients = '';
        let start_date= '';
        notes = notes ? notes : '';
        let data = new FormData();
        data.append( 'file_notes', notes );
        data.append( 'file', file );
        if(this.get( 'showFileReviewBlock' )){
            data.append( 'file', file );
            noOfPatients = this.get( 'noOfPatients' )
            data.append( 'number_of_patients', noOfPatients );
            start_date = this.get( 'dateOfCnECallStart' )
            data.append( 'start_date', moment(start_date).format('MM/DD/YYYY') );
        }

        this.app.controller.uploadFile( data, file, notes );
    },

    deleteFile: function() {
        let message = 'Clicking \"confirm\" will delete this file and cannot be undone.';
        let callback = this.app.controller.deleteFile.bind( this.app.controller );
        let data = {
            id: this.get( 'file_id' ),
        };
        this.app.controller.confirmationModal( message, callback, data );
    },

    downloadFile: function() {
        this.app.controller.downloadFile( this.get( 'file_id' ) );
    },

    saveEditNote: function( newNote = '' ) {
        this.app.controller.updateNote( this.get( 'file_id' ), newNote );
    },

});
