import Backbone from 'backbone';
import Highcharts from 'highcharts';

export const SummaryModel = Backbone.Model.extend({

    defaults: {
        dateRange: false,
        startMonth: null,
        endMonth: null,
        year: 2017,
        qualitySummaryTotals: {
            totalQualityScore: '',
            score_threshold: '',
        },
        qualitySummaryDiabetesPoorControl: {
            numerator: null,
            denominator: null,
            score_threshold: '',
        },
        qualitySummaryAdvanceCarePlan: {
            numerator: null,
            denominator: null,
            score_threshold: '',
        },
        qualitySummaryPneumococcalVaccine: {
            numerator: null,
            denominator: null,
            score_threshold: '',
        },
	    qualitySummaryTobacco: {
            numerator: null,
            denominator: null,
            score_threshold: '',
        },
        qualitySummaryFallRiskAssessment: {
            numerator: null,
            denominator: null,
            score_threshold: '',
        },
        qualitySummaryFallCarePlan: {
            numerator: null,
            denominator: null,
            score_threshold: '',
        },
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.colors = {
            'FAIL': '#A01313',
            'WARN': '#f57b3e',
            'PASS': '#169E54',
            'blue': '#1080AF',
            'gray': '#C6C6C6',
        };
        this.listenTo( this.channel, 'set:qualitySummaryFallCarePlan', this.setSummaryData );
        const currentApp = this.app.controller.currentApp;
        this.set( { currentApp } );
        this.url = currentApp === 'MIPSPROVIDER' ? 'mips-provider' : 'mips-group';
    },

    setSummaryData: function() {
        const controller = this.app.mips_controller;
        const qualitySummaryTotals = controller.qualitySummaryTotals;
        const qualitySummaryDiabetesPoorControl = controller.qualitySummaryDiabetesPoorControl;
        const qualitySummaryAdvanceCarePlan = controller.qualitySummaryAdvanceCarePlan;
        const qualitySummaryPneumococcalVaccine = controller.qualitySummaryPneumococcalVaccine;
        const qualitySummaryTobacco = controller.qualitySummaryTobacco;
        const qualitySummaryFallRiskAssessment = controller.qualitySummaryFallRiskAssessment;
        const qualitySummaryFallCarePlan = controller.qualitySummaryFallCarePlan;
        this.set( {
            qualitySummaryTotals,
            qualitySummaryDiabetesPoorControl,
            qualitySummaryAdvanceCarePlan,
            qualitySummaryPneumococcalVaccine,
	        qualitySummaryTobacco,
            qualitySummaryFallRiskAssessment,
            qualitySummaryFallCarePlan,
        } );
        this.trigger( 'change:summaryData' );
    },

    diabetesPoorControlChart: function() {
        const diabetesObject = this.get( 'qualitySummaryDiabetesPoorControl' );
        const chart = Highcharts.chart('diabetesPoorControlChart', {
            title: {
                text: null,
            },
            chart: {
                height: 200,
                spacingBottom: 30,
            },
            tooltip: {
                pointFormat: "<b>{point.y} patients</b>"
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        formatter: function() {
                            return Math.round(this.percentage) + '%';
                        },
                    },
                    startAngle: -125,
                    endAngle: 125,
                    center: ['50%', '45%'],
                    showInLegend: true,
                    size: 150,
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: [
                    {
                        name: 'Well Controlled',
                        y: diabetesObject.denominator - diabetesObject.numerator,
                        color: this.colors[diabetesObject.score_threshold],
                    }, {
                        name: 'Poorly Controlled',
                        y: diabetesObject.numerator,
                        color: '#1080AF'
                    }
                ]
            }],
            credits: {
                enabled: false
            }
        });
        if ( diabetesObject.numerator === 0 && diabetesObject.denominator === 0 ) {
            chart.destroy();
            $('#diabetesPoorControlChart').html(`
                <h5 class="mips-chart-placeholder mvn ptl pbm phxl center">Data has not been submitted for the selected period</h5>
                <a href="#${this.url}/quality-measures/diabetes-poor-control" class="mips-chart-placeholder-link"><h5 class="mips-chart-placeholder center mvn phxl">Enter data here</h5></a>
            `)
        }
    },

    advanceCarePlanChart: function() {
        const advanceObject = this.get( 'qualitySummaryAdvanceCarePlan' );
        const chart = Highcharts.chart('advanceCarePlanChart', {
            title: {
                text: null,
            },
            chart: {
                height: 200,
                spacingBottom: 30,
            },
            tooltip: {
                pointFormat: '<b>{point.y} patients</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        formatter: function() {
                            return Math.round(this.percentage) + '%';
                        },
                    },
                    startAngle: -125,
                    endAngle: 125,
                    center: ['50%', '45%'],
                    showInLegend: true,
                    size: 150,
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: [
                    {
                        name: 'Have Care Plan',
                        y: advanceObject.numerator,
                        color: this.colors[advanceObject.score_threshold]
                    }, {
                        name: 'No Care Plan',
                        y: advanceObject.denominator - advanceObject.numerator,
                        color: '#1080AF'
                    }
                ]
            }],
            credits: {
                enabled: false
            }
        });
        if ( advanceObject.numerator === 0 && advanceObject.denominator === 0 ) {
            chart.destroy();
            $('#advanceCarePlanChart').html(`
                <h5 class="mips-chart-placeholder mvn ptl pbm phxl center">Data has not been submitted for the selected period</h5>
                <a href="#${this.url}/quality-measures/advance-care-plan" class="mips-chart-placeholder-link"><h5 class="mips-chart-placeholder center mvn phxl">Enter data here</h5></a>
            `)
        }
    },

    pneumococcalVaccineChart: function() {
        const pneumococcalObject = this.get( 'qualitySummaryPneumococcalVaccine' );
        const chart = Highcharts.chart('pneumococcalVaccineChart', {
            title: {
                text: null,
            },
            chart: {
                height: 200,
                spacingBottom: 30,
            },
            tooltip: {
                pointFormat: '<b>{point.y} patients</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        formatter: function() {
                            return Math.round(this.percentage) + '%';
                        },
                    },
                    startAngle: -125,
                    endAngle: 125,
                    center: ['50%', '45%'],
                    showInLegend: true,
                    size: 150,
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: [
                    {
                        name: 'Have Vaccine',
                        y: pneumococcalObject.numerator,
                        color: this.colors[pneumococcalObject.score_threshold]
                    }, {
                        name: 'No Vaccine',
                        y: pneumococcalObject.denominator - pneumococcalObject.numerator,
                        color: '#1080AF'
                    }
                ]
            }],
            credits: {
                enabled: false
            }
        });
        if ( pneumococcalObject.numerator === 0 && pneumococcalObject.denominator === 0 ) {
            chart.destroy();
            $('#pneumococcalVaccineChart').html(`
                <h5 class="mips-chart-placeholder mvn ptl pbm phxl center">Data has not been submitted for the selected period</h5>
                <a href="#${this.url}/quality-measures/pneumococcal-vaccination" class="mips-chart-placeholder-link"><h5 class="mips-chart-placeholder center mvn phxl">Enter data here</h5></a>
            `)
        }
    },

	tobaccoChart: function() {
        const tobaccoObject = this.get( 'qualitySummaryTobacco' );
        const chart = Highcharts.chart('tobaccoChart', {
            title: {
                text: null,
            },
            chart: {
                height: 200,
                spacingBottom: 30,
            },
            tooltip: {
                pointFormat: '<b>{point.y} patients</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        formatter: function() {
                            return Math.round(this.percentage) + '%';
                        },
                    },
                    startAngle: -125,
                    endAngle: 125,
                    center: ['50%', '45%'],
                    showInLegend: true,
                    size: 150,
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: [
                    {
                        name: 'Counseled',
                        y: tobaccoObject.numerator,
                        color: this.colors[tobaccoObject.score_threshold]
                    }, {
                        name: 'Not Counseled',
                        y: tobaccoObject.denominator - tobaccoObject.numerator,
                        color: '#1080AF'
                    }
                ]
            }],
            credits: {
                enabled: false
            }
        });
        if ( tobaccoObject.numerator === 0 && tobaccoObject.denominator === 0 ) {
            chart.destroy();
            $('#tobaccoChart').html(`
                <h5 class="mips-chart-placeholder mvn ptl pbm phxl center">Data has not been submitted for the selected period</h5>
                <a href="#${this.url}/quality-measures/tobacco-use" class="mips-chart-placeholder-link"><h5 class="mips-chart-placeholder center mvn phxl">Enter data here</h5></a>
            `)
        }
    },

    fallsRiskAssessmentChart: function() {
        const fallRiskObject = this.get( 'qualitySummaryFallRiskAssessment' );
        const chart = Highcharts.chart('fallsRiskAssessmentChart', {
            title: {
                text: null,
            },
            chart: {
                height: 200,
                spacingBottom: 30,
            },
            tooltip: {
                pointFormat: '<b>{point.y} patients</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        formatter: function() {
                            return Math.round(this.percentage) + '%';
                        },
                    },
                    startAngle: -125,
                    endAngle: 125,
                    center: ['50%', '45%'],
                    showInLegend: true,
                    size: 150,
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: [
                    {
                        name: 'Completed',
                        y: fallRiskObject.numerator,
                        color: this.colors[fallRiskObject.score_threshold]
                    }, {
                        name: 'Not Completed',
                        y: fallRiskObject.denominator - fallRiskObject.numerator,
                        color: '#1080AF'
                    }
                ]
            }],
            credits: {
                enabled: false
            }
        });
        if ( fallRiskObject.numerator === 0 && fallRiskObject.denominator === 0 ) {
            chart.destroy();
            $('#fallsRiskAssessmentChart').html(`
                <h5 class="mips-chart-placeholder mvn ptl pbm phxl center">Data has not been submitted for the selected period</h5>
                <a href="#${this.url}/quality-measures/fall-risk-assessment" class="mips-chart-placeholder-link"><h5 class="mips-chart-placeholder center mvn phxl">Enter data here</h5></a>
            `)
        }
    },

    fallsCarePlanChart: function() {
        const fallPlanObject = this.get( 'qualitySummaryFallCarePlan' );
        const chart = Highcharts.chart('fallsCarePlanChart', {
            title: {
                text: null,
            },
            chart: {
                height: 200,
                spacingBottom: 30,
            },
            tooltip: {
                pointFormat: '<b>{point.y} patients</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        formatter: function() {
                            return Math.round(this.percentage) + '%';
                        },
                    },
                    startAngle: -125,
                    endAngle: 125,
                    center: ['50%', '45%'],
                    showInLegend: true,
                    size: 150,
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: [
                    {
                        name: 'Completed',
                        y: fallPlanObject.numerator,
                        color: this.colors[fallPlanObject.score_threshold]
                    }, {
                        name: 'Not Completed',
                        y: fallPlanObject.denominator - fallPlanObject.numerator,
                        color: '#1080AF'
                    }
                ]
            }],
            credits: {
                enabled: false
            }
        });
        if ( fallPlanObject.numerator === 0 && fallPlanObject.denominator === 0 ) {
            chart.destroy();
            $('#fallsCarePlanChart').html(`
                <h5 class="mips-chart-placeholder mvn ptl pbm phxl center">Data has not been submitted for the selected period</h5>
                <a href="#${this.url}/quality-measures/fall-care-plan" class="mips-chart-placeholder-link"><h5 class="mips-chart-placeholder center mvn phxl">Enter data here</h5></a>
            `)
        }
    },

});