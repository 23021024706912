// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import viewNotificationModalView  from '../../../../templates/Main/Notifications/viewNotificationModalView';
import ViewNotificationModalCollectionView from './ViewNotificationModalCollectionView';

// Internal Dependencies - models/collections
import { NotificationThreadCollection } from './models/NotificationThreadCollection';

export default Marionette.LayoutView.extend({

	regions: {
		notificationItemsCollection: '.notification-items-collection'
	},

	className: 'notification-modal',

	events: {
		'click .fa-times': 'closeModal',
		'click #close': 'closeModal',
		'click #sendIcon': 'notificationReply',
		'click .notification-archive-action': 'toggleActive',
		'click .mark-unread-button':'markUnread',
		'click #queryNurse':'toggleNurse',
		'click #queryRefill':'toggleRefill',
		'click #queryAppointment':'toggleAppointment',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection = new NotificationThreadCollection();
		this.collection.patient_name = this.model.get('patient_name');
		this.collection.provider_name = this.model.get('provider_name');
		this.model.set('type', this.app.main_controller.notification_type);
		this.app.main_controller.getNotificationContent( this.model.get( 'patient_id' ), this.model.get('provider_id') );
		$( '.message-spinner' ).show();
		$('.nurseSelected').css( { 'display': 'none' } );
		$('.refillSelected').css( { 'display': 'none' } );
		$('.appointmentSelected').css( { 'display': 'none' } );
		this.listenTo( this.channel, 'set:notificationContent', this.setNotificationContent );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
		this.listenTo( this.channel, 'replyNotification:success', this.success );
		this.listenTo( this.channel, 'toggleNotificationActive:success', this.closeAndRefetch );
		this.listenTo( this.channel, 'replyNotification:failure', this.flipIcon );
		this.listenTo( this.channel, 'ViewNotificationModalView:show', this.modalOnShow );
	},

	getTemplate: function() {
		return viewNotificationModalView( this.model );
	},

	modalOnShow: function() {
		$('.notification-items-collection').scroll( this.checkForFetch.bind(this) );
        if( this.model.get('differentProvider')){
            $('#NotificationReply').css( { 'display': 'none' } );
            $('.fa-arrow-circle-up').css({'display' : 'none'});
        }
	},

	setNotificationContent: function( ) {
		let notificationContent = this.app.main_controller.notificationContent;
		if ( !this.collection.length ) {
			this.collection.add( notificationContent );
			$( '.message-spinner' ).hide();
			$( '.notification-reply-container' ).css( { 'display': 'block' } );
			$( '.notification-items-collection' ).css( { 'display': 'block' } );
			this.showChildView( 'notificationItemsCollection', new ViewNotificationModalCollectionView( { collection: this.collection, model: this.model } ) );
		} else {
			let lastId = this.collection.at( 0 ).get( 'id' );
			this.collection.previousLastNotification = lastId;
			this.collection.previousLastNotificationOffset = $("#" + lastId).offset().top;
			this.collection.add( notificationContent );
		}
		$(".notification-items-collection").scrollTop($(".notification-items-collection")[0].scrollHeight)
	},

	notificationReply: function() {
		let value = $( '#NotificationReply' ).val();
		const priority = $('#priorityCheck').prop("checked");
		const tags = [];
		if(this.model.get('queryNurse')){
			tags.push('Nurse');
		}
		if(this.model.get('queryRefill')){
			tags.push('Refill');
		}
		if(this.model.get('queryAppointment')){
			tags.push('Appointment');
		}
		const patient_id = this.model.get( 'patient_id' );
		const provider_id = this.model.get( 'provider_id' );
		const sender_id = this.app.controller.user.id;
		if ( value.trim() < 1 ) {
			$( '#NotificationReply' ).addClass( 'invalid' );
			$( '#NotificationReply' ).focus();
			return;
		}
		if ( provider_id !== sender_id ) {
			value = value + "\n(Sent by: " + this.app.controller.user.name + ")"
		}
		// $('#sendIcon').removeClass('fa-arrow-circle-up');
		// $('#sendIcon').addClass('fa-refresh');
		this.app.main_controller.notificationReply( value, patient_id, provider_id, sender_id,0,priority,tags );
		$( '.message-spinner' ).show();
	},

	flipIcon: function() {
		// $('#sendIcon').removeClass('fa-refresh');
		// $('#sendIcon').addClass('fa-arrow-circle-up');
		$( '.message-spinner' ).hide();
	},

	success: function() {
		this.app.main_controller.getNotificationContent( this.model.get( 'patient_id' ), this.model.get('provider_id') );
		this.flipIcon();
		$('#NotificationReply').val('');
	},

	checkForFetch: function() {
		let scrollTop = ($('.notification-items-collection').scrollTop());
		let metadata = this.app.main_controller.notificationThreadMetadata;
		if ( scrollTop == 0 && metadata && ( metadata.totalPages - metadata.page > 1 ) ) {
			this.app.main_controller.getNotificationContent( this.model.get( 'patient_id' ), this.model.get('provider_id'), metadata.page + 1 );
		}
	},

	toggleActive: function() {
		const isActive = this.model.get('type') === 'active';
		this.app.main_controller.toggleNotificationActive( this.model.get('provider_id'), this.model.get('patient_id'), isActive );
	},

	markUnread: function() {
		if(this.model.get('type') == 'active') {
			this.app.main_controller.markUnreadThread([this.model.get('patient_id')], true, () => {
				this.closeAndRefetch();
			});
		}else{
			this.app.main_controller.toggleNotificationActive( this.model.get('provider_id'), this.model.get('patient_id'), false,()=>{
				this.app.main_controller.markUnreadThread([this.model.get('patient_id')], true, () => {
					this.closeAndRefetch();
				});
			} );
		}

	},

	closeAndRefetch: function() {
		$( '#modalNotification' ).closeModal();
		const searchInput = this.app.main_controller.searchInput || null;
		const metaData = this.app.main_controller.notificationMetadata;
		const page = ( metaData.beginIndex === metaData.endIndex && metaData.totalPages > 1 ) ? metaData.currentPage - 1 : metaData.currentPage;
		this.app.main_controller.searchNotifications( page, searchInput );
		this.destroy();
	},

	closeModal: function() {
		$( '#modalNotification' ).closeModal();
		const searchInput = this.app.main_controller.searchInput || null;
		this.app.main_controller.searchNotifications( this.app.main_controller.notificationMetadata.currentPage, searchInput );
		this.destroy();
	},

	toggleNurse: function() {
		const newValue = !this.model.get('queryNurse');
		if(newValue){
			$('.nurseSelected').css( { 'display': 'block' } );
			$('.nurseUnselected').css( { 'display': 'none' } );
		}else{
			$('.nurseSelected').css( { 'display': 'none' } );
			$('.nurseUnselected').css( { 'display': 'block' } );
		}

		this.model.set('queryNurse',newValue);

	},
	toggleRefill: function() {
		const newValue = !this.model.get('queryRefill');
		if(newValue){
			$('.refillSelected').css( { 'display': 'block' } );
			$('.refillUnselected').css( { 'display': 'none' } );
		}else{
			$('.refillSelected').css( { 'display': 'none' } );
			$('.refillUnselected').css( { 'display': 'block' } );
		}

		this.model.set('queryRefill',newValue);
	},
	toggleAppointment: function() {
		const newValue = !this.model.get('queryAppointment');
		if(newValue){
			$('.appointmentSelected').css( { 'display': 'block' } );
			$('.appointmentUnselected').css( { 'display': 'none' } );
		}else{
			$('.appointmentSelected').css( { 'display': 'none' } );
			$('.appointmentUnselected').css( { 'display': 'block' } );
		}

		this.model.set('queryAppointment',newValue);
	},
});
