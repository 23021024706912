// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import editUserModalView  from '../../../../templates/Utilities/ManageUsers/editUserModalView';
import ProviderAssociationView from './ProviderAssociationView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	regions: {
		associatedProviderContainer: '.associated-provider-container',
	},

	events: {
		'change #permission_level': 'changePermissionLevel',
		'click .modal-close': 'closeModal',
		'click .modal-save': 'saveUser'
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );

		// Listen for 'EditUserView:show' which acts as a trigger for the modal's 'onShow' method
		// Triggered in UserItemView
		this.listenTo( this.channel, 'EditUserView:show', this.modalOnShow );

		// Adds all available providers to the model's 'provider-options' attribute
		this.model.set( 'provider-options', this.app.controller.providers );

		this.listenTo( this.channel, 'saveUser:success', this.closeModal )
		this.listenTo( this.channel, 'close:modal', this.closeModal );
	},

	getTemplate: function() {
		return editUserModalView( this.model );
	},

	modalOnShow: function() {
		let permissionLevel = this.model.get('permissionLevel');

		// Determine value to be selected in the "Permission Level" dropdown
		this.$('#permission_level option[value="' + permissionLevel + '"]').attr('selected', true);

		// If permission level is nurse, show the associated provider container
		this.changePermissionLevel();
	},

	changePermissionLevel: function() {
		let permissionLevel = $('#permission_level').val();

		// If new permission level is nurse, show the associated provider container, else empty the associated provider region
		if ( permissionLevel == 'nurse' ) {
			this.showChildView( 'associatedProviderContainer', new ProviderAssociationView( {model: this.model} ) );
		} else {
			this.getRegion('associatedProviderContainer').empty();
		}
	},

	closeModal: function( e ) {
		e && e.preventDefault();
		$( '#modalEditUser' ).closeModal();
		this.destroy();
	},

	saveUser: function( e ) {
		e.preventDefault();
		let firstName = this.$('#first_name').val();
		let lastName = this.$('#last_name').val();
		let username = this.$('#username').val();
		let password = this.$('#password').val();
		let permissionLevel = this.$('#permission_level').val();
		let associatedProviders = [ -1 ]; // Default associatedProviders to all unless overwritten in if statement

		// If the permission level is a nurse, and associateAllProviders is not checked, map over all of the
		// associated provider selects and return those values as numbers
		if ( !this.$('#selectAllProviders').prop('checked') && this.$('#permission_level').val() == 'nurse' ) {
			associatedProviders = $('.associated-provider-select').map( function() {
				return +this.value
			}).get()
		}

		this.app.utilities_controller.saveUser( firstName, lastName, username, password, permissionLevel, associatedProviders );

	}

});
