// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import manageUsersCompositeView  from '../../../../templates/Utilities/ManageUsers/manageUsersCompositeView';
import UserItemView from './UserItemView';
import EditUserModalView from './EditUserModalView';

// Internal Dependencies - models/collections
import { ManageUserCollection } from './models/ManageUserCollection';
import { UserModel } from './models/UserModel';

export default Marionette.CompositeView.extend({

	tagName: 'table',

	className: 'striped',

	childViewContainer: '.user-list-collection-container',

	childView: UserItemView,

	events: {
		'click .new-user-button': 'launchNewModal',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection = new ManageUserCollection
		this.setUserList();
		this.listenTo( this.collection, 'add remove sort update', this.render );
		let searchObj = {
			placeholder: 'Search first name and/or last name'
		}
		this.channel.trigger( 'show:search', searchObj );
		$('.content-container').addClass('widen');
	},

	getTemplate: function() {
		return manageUsersCompositeView( this.collection.models );
	},

	setUserList: function() {
		this.collection.add(
			[
				{
					firstName: 'Rick',
					lastName: 'Lawson',
					username: 'rlawson',
					lastSignInDate: '09/09/16',
					lastSignInTime: '9:05pm',
					permissionLevel: 'provider',
					associatedProviders: [-1],
					id: 1
				},
				{
					firstName: 'Matthew',
					lastName: 'Chastain',
					username: 'mattchastain1',
					lastSignInDate: '09/09/16',
					lastSignInTime: '9:05pm',
					permissionLevel: 'nurse',
					associatedProviders: [5, 8, 11, 9],
					id: 2
				},
				{
					firstName: 'Chad',
					lastName: 'McMillian',
					username: 'cmcmillian',
					lastSignInDate: '09/09/16',
					lastSignInTime: '9:05pm',
					permissionLevel: 'messenger',
					associatedProviders: [-1],
					id: 3
				}
			]
		)
	},

	launchNewModal: function() {
		let firstProvider = this.app.controller.providers[0] && this.app.controller.providers[0].id;
		// Launch the modal for a new user using a default UserModel
		let modalView = new EditUserModalView( { model: new UserModel( {associatedProviders: [ firstProvider ]} ) } );
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalEditUser' ).openModal();
	},

	onDestroy: function() {
		$('.content-container').removeClass('widen');
	}

});
