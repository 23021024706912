// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import mipsSettingsModal  from '../../../../templates/MIPS/Dashboard/mipsSettingsModal';

export default Marionette.ItemView.extend({

    events: {
        'click #submitMipsSettings': 'submitMipsSettings',
        'click .cancel': 'closeModal',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.listenTo( this.channel, 'close:modal', this.closeModal );
    },

    getTemplate: function() {
        return mipsSettingsModal( this.model );
    },

    closeModal: function() {
        $( '#mipsSettingsModal' ).closeModal();
        this.destroy();
    },

    submitMipsSettings: function() {
        const excludeQualityMeasures = $( '#qualityMeasures' ).prop( 'checked' );
        const excludeImprovementActivities = $( '#improvementActivities' ).prop( 'checked' );
        this.model.updateSettings( excludeQualityMeasures, excludeImprovementActivities );
        this.closeModal();
    },

});
