// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import dateRangeContainerView  from '../../../templates/MIPS/dateRangeContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    events: {
        'change #startMonth': 'setStartMonth',
        'change #endMonth': 'setEndMonth',
        'change #year': 'setYear',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
    },

    getTemplate: function() {
        return dateRangeContainerView( this.model );
    },

    onShow: function() {
        const startMonth = this.model.get( 'startMonth' );
        const endMonth = this.model.get( 'endMonth' );
        startMonth && this.$('#startMonth option[value="' + startMonth + '"]').attr('selected', true);
        endMonth && this.$('#endMonth option[value="' + endMonth + '"]').attr('selected', true);
    },

    setStartMonth: function( e ) {
        const month = e.target.value;
        this.model.set( 'startMonth', month );
    },

    setEndMonth: function( e ) {
        const month = e.target.value;
        this.model.set( 'endMonth', month );
    },

    setYear: function( e ) {
        const year = e.target.value;
        this.model.set( 'year', year );
    },

});
