import Backbone from 'backbone';

export const AutoEnrollModel = Backbone.Model.extend({

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
	},

	triggerCheckSelected: function() {
		this.channel.trigger( 'check:selected' );
	},

});
