// External Dependencies
import Backbone from 'backbone';
import Marionette from 'backbone.marionette';
import AppRadio from 'backbone.radio';
import $ from 'jquery';

// Internal Dependencies
import { config } from '../dev-config';
import Router from './AppRouter';
import { AppController } from './AppController';
import { CCM_Controller } from './Components/CCM/CCM_Controller';
import { CPC_Controller } from './Components/CPC/CPC_Controller';
import { MIPS_Controller } from './Components/MIPS/MIPS_Controller';
import { Main_Controller } from './Components/Main/Main_Controller';
import { Utilities_Controller } from './Components/Utilities/Utilities_Controller';
import { PE_Controller} from "./Components/PatientEnrollment/PE_Controller";


const App = Marionette.Application.extend({
    initialize: function() {
        this.config = config;
        this.controller = new AppController( this );
        this.ccm_controller = new CCM_Controller( this );
        this.cpc_controller = new CPC_Controller( this );
        this.mips_controller = new MIPS_Controller( this );
        this.main_controller = new Main_Controller( this );
        this.utilities_controller = new Utilities_Controller( this );
        this.pe_controller = new PE_Controller(this);
        this.router = new Router( this );
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.listenTo( this.channel, 'reset:app', this.resetControllers );
    },
    regions: {
        'main': '.app'
    },
    resetControllers: function() {
        this.controller = new AppController( this );
        this.ccm_controller = new CCM_Controller( this );
        this.cpc_controller = new CPC_Controller( this );
        this.mips_controller = new MIPS_Controller( this );
        this.main_controller = new Main_Controller( this );
        this.utilities_controller = new Utilities_Controller( this );
        this.pe_controller = new PE_Controller(this);
    }
});

const app = new App();

app.on('start', function() {
	if (Backbone.history) {
		Backbone.history.start();
	}
});

$(document).ready(function(){
	app.start();
	this.channel = AppRadio.channel('AppChannel');
	$( document ).on( 'click', '.lean-overlay', () => this.channel.trigger( 'close:modal' ) );
});
