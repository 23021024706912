import Backbone from 'backbone';

const LoginModel = Backbone.Model.extend({

	defaults: {
		username: '',
		password: '',
		authenticating: false,
	},

	id: '',

	validate: function() {
		let username = this.get('username');
		let password = this.get('password');
		if ( username && password ) {
			return true;
		}
		return false;
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel('AppChannel');
		this.app = this.channel.request('app');
	},

	authenticateUser: function() {
		this.app.controller.authenticateUser( this );
	}

});

export default LoginModel;