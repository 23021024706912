// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import  pendingReviewCompositeView  from '../../../../../templates/CCM/RapidBill/PendingReview/pendingReviewCompositeView';
import PendingReviewItemView from './PendingReviewItemView';

// Internal Dependencies - models/collections
import { PendingReviewCollection } from './models/PendingReviewCollection';
import { RapidBillModel } from '../models/RapidBillModel';

export default Marionette.CompositeView.extend({

	childView: PendingReviewItemView,

	childViewContainer: '.pending-review-collection-container',

	className: 'pending-review-composite-view striped',

	events: {
		'click #selectAll': 'selectAll'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.currentPage = 0;
		this.pageSize = 20;

		this.app.ccm_controller.getPendingReview();
		this.collection.loading = true;
		this.listenTo( this.channel, 'rerender', this.render );
		this.listenTo( this.channel, 'set:patientList', this.setReviewList );
		this.listenTo( this.collection, 'update', this.render );
		this.listenTo( this.channel, 'set:selectedProviderId', this.getReviewList );

		this.channel.reply( 'currentPage', () => {
			return this.currentPage;
		});
		this.channel.reply( 'pageSize', () => {
			return this.pageSize;
		});
		$('.content-container').addClass('widen');
	},

	getTemplate: function() {
		return pendingReviewCompositeView( this.collection );
	},

	getReviewList: function() {
		this.collection.loading = true;
		this.render();
		this.app.ccm_controller.getPendingReview( 'reset:currentPage' );
	},

	setReviewList: function() {
		this.collection.loading = false;
		this.render();
		this.channel.trigger( 'show:contentSpinner' );
		let reviewList = this.app.ccm_controller.patientList;
		reviewList = _.map( reviewList, ( patient ) => new RapidBillModel( patient ) );
		this.collection.renderNoResults = reviewList && reviewList.length ? false : true;
		let search = this.app.ccm_controller.searchInput
		this.collection.search = search ? search.substring( search.lastIndexOf("=") + 1 ) : '';
		this.collection.set( reviewList );
		this.channel.trigger( 'hide:contentSpinner' );
		!reviewList.length && this.render();
	},

	resetCurrentPage: function() {
		this.currentPage = 0;
		this.app.ccm_controller.patients.currentPage = this.currentPage;
	},

	selectAll: function() {
		let shouldSelect = $( '#selectAll' ).prop( 'checked' );
		this.collection.selectAll( shouldSelect );
	},

	onDestroy: function() {
		this.app.ccm_controller.clearValue( 'patientList' );
		$('.content-container').removeClass('widen');
	},

});
