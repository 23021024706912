// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import manageUsersContainerView  from '../../../../templates/Utilities/ManageUsers/manageUsersContainerView';
import ManageUsersCompositeView from './ManageUsersCompositeView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	regions: {
		userContainer: '.user-container',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.model = new Backbone.Model();
	},

	getTemplate: function() {
		return manageUsersContainerView( this.model );
	},

	onShow: function() {
		this.showChildView( 'userContainer', new ManageUsersCompositeView() );
	},

	onDestroy: function() {
		this.channel.trigger( 'remove:search' );
	}

});
