// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';

// Internal Dependencies - screens/views/templates
import estimatedNetRevenueDashboard  from '../../../../templates/CCM/RevenueDashboard/estimatedNetRevenueDashboard';

// Internal Dependencies - models/collections
import { RevenueDashboardModel } from './models/RevenueDashboardModel';

export default Marionette.ItemView.extend({
	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new RevenueDashboardModel();
		this.loading = true;
		this.listenTo( this.channel, 'set:locationDashboardData', this.setDataToModel );
		this.listenTo( this.channel, 'set:selectedProviderId', this.getData );
		this.listenTo( this.model, 'change', this.render );

		this.getData();

		$('.content-container').addClass('widen');
	},

	getTemplate: function() {
		return estimatedNetRevenueDashboard( this.model );
	},

	onDomRefresh: function() {
		this.model.get( 'totalG0511Enrolled' ) === 0 && this.model.get( 'total99490Enrolled' ) === 0
		&& $('#patientsWorkedChart').html('<p class="chart-string-value cs-blue-darkened mtl center">You currently do not have any enrolled patients</p>');
		( this.model.get( 'total99490Enrolled' ) || this.model.get( 'totalG0511Enrolled' ) ) && this.renderCharts();

	},

	getData: function() {
		this.model.loading = true;
		this.render();
		this.app.controller.selectedProviderId && this.app.main_controller.getLocationBillingDashboard();
	},

	setDataToModel: function() {
		this.model.loading = false;
		this.render();
		const data = this.app.main_controller.locationDashboardData;
		console.log("Data:",data);
		const cptData = {};
		let totalMonthly99490 = 0;
		let totalMonthlyG0511 = 0;
		let totalYearly99490 = 0;
		let totalYearlyG0511 = 0;
		let total99490Enrolled = 0;
		let totalG0511Enrolled = 0;
		let total99490Reimbursment = 0;
		let total99490Fees = 0;
		let totalG0511Fees = 0;
		let totalG0511Reimbursment = 0;
		let total = 0;
		for(let i=0; i<data.length; i++){
			const dataRow = data[i];
			dataRow.totalReimbursment = parseFloat(dataRow.currently_enrolled)*parseFloat(dataRow.reimbursement);
			dataRow.totalFees = parseFloat(dataRow.currently_enrolled)*parseFloat(dataRow.fee);
			if(dataRow.cpt_code == "G0511"){
				totalMonthlyG0511 += parseFloat(dataRow.monthly_revenue);
				totalYearlyG0511 += parseFloat(dataRow.annual_revenue);
				totalG0511Enrolled += parseFloat(dataRow.currently_enrolled);
				totalG0511Reimbursment += dataRow.totalReimbursment;
				totalG0511Fees += dataRow.totalFees;
			}else if(dataRow.cpt_code == "99490"){
				totalMonthly99490 += parseFloat(dataRow.monthly_revenue);
				totalYearly99490 += parseFloat(dataRow.annual_revenue);
				total99490Enrolled += parseFloat(dataRow.currently_enrolled);
				total99490Reimbursment += dataRow.totalReimbursment;
				total99490Fees += dataRow.totalFees;
			}
			total += parseFloat(dataRow.annual_revenue);
		}

		let totalMonthly99490Profit = total99490Reimbursment - total99490Fees;
		let totalMonthlyG0511Profit = totalG0511Reimbursment - totalG0511Fees;

		this.model.set({
			total99490Enrolled,
			totalG0511Enrolled,
			totalG0511Fees,
			total99490Fees,
			total99490Reimbursment,
			totalG0511Reimbursment,
			totalMonthly99490Profit,
			totalMonthlyG0511Profit,
			totalMonthly99490:`$${Number( totalMonthly99490 ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			totalMonthlyG0511: `$${Number( totalMonthlyG0511 ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			totalYearly99490:`$${Number( totalYearly99490 ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			totalYearlyG0511: `$${Number( totalYearlyG0511 ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			total: `$${Number( total ).toLocaleString( 'en', {minimumFractionDigits: 2} )}`,
			revenueData: data
		});
	},

	renderCharts: function() {
		this.patientsWorkedChart();
	},

	patientsWorkedChart: function() {
		var patientsWorkedChart = Highcharts.chart('patientsWorkedChart', {
			title: {
				text: null
			},
			tooltip: {
				pointFormat: '<b>{point.y}</b>'
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false,
						distance: -20,
						format: '<b>{point.y}</b>',
					},
					center: ['50%', '55%'],
					showInLegend: true,
				}
			},
			series: [{
				type: 'pie',
				data: [
					{
						name: 'CPT 99490',
						y: this.model.get('total99490Enrolled'),
						color: '#29ABDD'
					}, {
						name: 'CPT G0511',
						y: this.model.get('totalG0511Enrolled'),
						color: '#169E54'
					}
				]
			}],
			credits: {
				enabled: false
			},
			chart:{
				backgroundColor:'transparent'
			},
			legend:{
				itemStyle:{color:'#B3B3B3',marginBottom:'10px'},
				itemHoverStyle:{color:'#B3B3B3',marginBottom:'10px'}
			}
		});
	},

	onDestroy: function() {
		$('.content-container').removeClass('widen');
	},

});
