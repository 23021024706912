// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import criteriaSelectionContainerView  from '../../../../templates/MIPS/AdvancingCare/criteriaSelectionContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
    },

    getTemplate: function() {
        return criteriaSelectionContainerView( this.model );
    },

    onShow: function() {
        this.channel.trigger( 'render:summaryChart' );
    },

});
