// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import subnavView  from '../../../templates/subnavView';
import CCM_SubNavView from '../CCM/CCM_SubNavView';
import CPC_SubNavView from '../CPC/CPC_SubNavView';
import MIPS_SubNavView from '../MIPS/MIPS_SubNavView';
import MIPSGroup_SubNavView from '../MIPS/MIPSGroup_SubNavView';
import MIPSProvider_SubNavView from '../MIPS/MIPSProvider_SubNavView';
import Main_SubNavView from '../Main/Main_SubNavView';
import Utilities_SubNavView from '../Utilities/Utilities_SubNavView';
import PE_SubNavView from "../PatientEnrollment/PE_SubNavView";

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	regions: {
		'subnav': '.subnav-view-container',
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.model, 'change:currentApp', this.updateSubnav );
	},

	getTemplate: function() {
		return subnavView( this.model );
	},

	onShow: function() {
		this.showChildView( 'subnav', this.determineWhichSubNav() );
	},

	determineWhichSubNav: function() {
		let currentApp = this.model.get( 'currentApp' );
		switch( currentApp ) {
			case 'MAIN':
				return new Main_SubNavView({ model: this.model });
				break;
			case 'CCM':
				return new CCM_SubNavView({ model: this.model });
				break;
			case 'CPC':
				return new CPC_SubNavView({ model: this.model });
				break;
			case 'MIPS':
				return new MIPS_SubNavView({ model: this.model });
				break;
			case 'MIPSGROUP':
				return new MIPSGroup_SubNavView({ model: this.model });
				break;
            case 'MIPSPROVIDER':
                return new MIPSProvider_SubNavView({ model: this.model });
                break;
			case 'UTILITIES':
				return new Utilities_SubNavView({ model: this.model });
				break;
			case 'PATIENTENROLLMENT':
				return new PE_SubNavView({ model: this.model });
				break;
			default:
				return new CCM_SubNavView({ model: this.model });
				break;
		}
	},

	updateSubnav: function() {
		this.showChildView( 'subnav', this.determineWhichSubNav() );
	},

});
