import Backbone from 'backbone';

export const FilteringModel = Backbone.Model.extend({
	
	initialize: function() {
		this.listenTo( this.channel, 'set:filterOrganization', this.setOrganization );
		this.listenTo( this.channel, 'set:filterLocation', this.setLocation );
		this.listenTo( this.channel, 'set:filterProvider', this.setProvider );
	},

	setOrganization: function( org ) {
		this.set( 'organization', org );
	},

	setLocation: function( location ) {
		this.set( 'location', location );
	},

	setProvider: function( provider ) {
		this.set( 'provider', provider );
	}

});