// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import supportContainerView  from '../../../../templates/CCM/Support/supportContainerView';
import SupportCompositeView from './SupportCompositeView';
import PatientTotalsView from '../PatientList/PatientTotalsView';

// Internal Dependencies - models/collections
import { SupportCollection } from './models/SupportCollection';

export default Marionette.LayoutView.extend({

	regions: {
		'supportContainerView': '.support-container-view',
		'patientTotals': '.patient-totals-container'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection = new SupportCollection();
	},

	getTemplate: function() {
		return supportContainerView( this.model );
	},

	onShow: function() {
		this.showChildView( 'supportContainerView', new SupportCompositeView({collection: this.collection}) );
		this.showChildView( 'patientTotals', new PatientTotalsView({collection: this.collection}) );
	}

});
