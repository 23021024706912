// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import autoEnrollItemView  from '../../../../templates/CCM/AutoEnroll/autoenrollItemView';

export default Marionette.ItemView.extend({

	tagName: 'tr',

	className: 'auto-enroll-patient-row',

	events: {
		'click [type="checkbox"]': 'selectPatient'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.listenTo( this.model, 'change', this.render );
		this.listenTo( this.model, 'change', this.model.triggerCheckSelected );
	},

	getTemplate: function() {
		return autoEnrollItemView( this.model );
	},

	selectPatient: function() {
		this.model.set( 'selected', !this.model.get( 'selected' ) );
	}

});
