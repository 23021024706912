// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import home  from '../../../templates/homeView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	getTemplate: function() {
		return home(this.model);
	}

});
