// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  adminLevelFilteringView  from '../../../templates/adminLevelFilteringView';

// Internal Dependencies - models/collections
import { AdminLevelFilteringModel } from './models/AdminLevelFilteringModel';

export default Marionette.ItemView.extend({

	events: {
		'change #filterLocation': 'setFilterLocation',
		'change #filterProvider': 'setFilterProvider'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new AdminLevelFilteringModel({ app: this.app, channel: this.channel });
		this.listenTo( this.model, 'change', this.render );
	},

	getTemplate: function() {
		return adminLevelFilteringView( this.model );
	},

	onDomRefresh: function() {
		this.app.controller.selectedLocationId && $('#filterLocation option[value="' + this.app.controller.selectedLocationId + '"]').attr('selected', true);
		this.app.controller.selectedProviderId && $('#filterProvider option[value="' + this.app.controller.selectedProviderId + '"]').attr('selected', true);
	},

	setFilterLocation: function( e ) {
		this.app.controller.updateLocation( e.target.value );
	},

	setFilterProvider: function( e ) {
		this.app.controller.updateProvider( e.target.value );
	}

});
