// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import improvementSettingsModalView  from '../../../../templates/MIPS/ImprovementActivities/improvementSettingsModalView';

export default Marionette.ItemView.extend({

    events: {
        'click #submitImprovementActivitiesSettings': 'submitImprovementActivitiesSettings',
        'click .cancel': 'closeModal',
    },

    ui: {
        'fewerThan15': '#fewerThan15',
        'nonFacing': '#nonFacing',
        'ruralPractice': '#ruralPractice',
        'hpsaPractice': '#hpsaPractice',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.listenTo( this.channel, 'close:modal', this.closeModal );
    },

    getTemplate: function() {
        return improvementSettingsModalView( this.model );
    },

    submitImprovementActivitiesSettings: function() {
        let ui = this.ui;
        let settings = {
            fifteen_fewer: ui.fewerThan15.prop( 'checked' ),
            non_facing: ui.nonFacing.prop( 'checked' ),
            rural_zip: ui.ruralPractice.prop( 'checked' ),
            hpsa_area: ui.hpsaPractice.prop( 'checked' ),
        };
        this.model.saveIASettings( settings );
    },

    closeModal: function() {
        $( '#improvementActivitiesSettingsModal' ).closeModal();
        this.destroy();
    },

});
