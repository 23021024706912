import Backbone from 'backbone';

export const SideNavModel = Backbone.Model.extend({

	defaults: {
		currentApp: null,
		subNav: null,
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'set:currentApp', this.setCurrentApp );
		this.listenTo( this.channel, 'set:subnavItem', this.setSubnavItem );
	},

	setCurrentApp: function() {
		this.set( 'currentApp', this.app.controller.currentApp );
	},

	setSubnavItem: function() {
		this.set( 'subnavItem', this.app.controller.subnavItem );
		this.trigger( 'change' );
	},

	changeCurrentApp: function( app ) {
		this.app.controller.setCurrentApp( app );
	},

});
