// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import actionsContainerView  from '../../../../../templates/PatientEnrollment/PatientApproval/PatientApprovalComponents/actionsContainerView';

// Internal Dependencies - models/collections
import PatientEnrollmentConfirmationView from './PatientEnrollmentConfirmationModalView';

export default Marionette.ItemView.extend({

    events: {
        'click #approvePatients': 'launchConfirmationModal',
        'click #declinePatients': 'launchConfirmationModal',
        'click #sendToApproval': 'launchConfirmationModal',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.listenTo( this.collection, 'change:selected', this.render );
    },

    getTemplate: function() {
        return actionsContainerView( this.collection );
    },

    launchConfirmationModal: function( e ) {
        this.collection.actionType = this.determineActionType( e.target.id );
        let modalView = new PatientEnrollmentConfirmationView({ collection: this.collection });
        modalView.render();
        $( 'body' ).append( modalView.el );
        $( '#modalSummary' ).openModal();
    },

    determineActionType: function( id ) {
        switch( id ) {
            case 'approvePatients':
                return 'approve';
                break;
            case 'declinePatients':
                return 'decline';
                break;
            case 'sendToApproval':
                return 'sendToApproval';
                break;
            default:
                return 'approve';
        }
    },

});
