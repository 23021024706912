// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import cpcDashboardPlaceholder  from '../../../../templates/Main/Dashboard/cpcDashboardPlaceholder';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	className: 'cpc-dashboard-placeholder',

	getTemplate: function() {
		return cpcDashboardPlaceholder( );
	},

});
