// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import bulkProviderTransitionContainerView  from '../../../../templates/Utilities/BulkProviderTransition/bulkProviderTransitionContainerView';
import BulkProviderTransitionCompositeView from './BulkProviderTransitionCompositeView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

    regions: {
        'bulkProviderSuspensionCompositeView': '.bulk-provider-change-composite-container'
    },

    getTemplate: function() {
        return bulkProviderTransitionContainerView( );
    },

    onShow: function() {
        this.showChildView( 'bulkProviderSuspensionCompositeView', new BulkProviderTransitionCompositeView() );
    }
});
