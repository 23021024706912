import Backbone from 'backbone';
import _ from 'underscore';

export const BulkProviderChangeModel = Backbone.Model.extend({

    initialize: function( options ) {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.controller = this.app.utilities_controller;
        this.controller.getProviders();
        this.listenTo(this.channel, 'set:bulkChangeProviders', this.setBulkChangeProviders);
    },

    setBulkChangeProviders: function() {
        let bulkChangeProviders = this.controller.bulkChangeProviders;
        this.set( { bulkChangeProviders:bulkChangeProviders,
            bulkChangeProvidersCurrent:bulkChangeProviders,bulkChangeProvidersTarget:bulkChangeProviders } );
        this.trigger( 'set:bulkChangeProviders' );
    },

    setSelectedCurrentProvider: function(selectedCurrentProvider) {
        const bulkChangeProviders = this.get('bulkChangeProviders');
        const resultArray = _.filter(bulkChangeProviders,(provider)=>{
            return provider.id == selectedCurrentProvider;
        });


        if(resultArray.length > 0){
            this.controller.getProviderDetail(resultArray[0],(data)=>{
                this.set({selectedCurrentProvider});
                this.set({currentNumberOfPatient:data.numberOfPatient});
                this.set({currentBillingName:data.billingName});
                this.set({currentProviderName:data.providerName});
                const newTargetProviders = [];
                _.each(bulkChangeProviders,(provider)=>{
                    if(provider.id == -1 || provider.id != selectedCurrentProvider){
                        newTargetProviders.push(provider);
                    }
                });
                this.set({bulkChangeProvidersTarget:newTargetProviders});

                this.trigger( 'set:currentProviderDetail' );
            });
        }

    },

    setSelectedTargetProvider: function(selectedTargetProvider) {
        const bulkChangeProviders = this.get('bulkChangeProviders');
        const resultArray = _.filter(bulkChangeProviders,(provider)=>{
            return provider.id == selectedTargetProvider;
        });
        if(resultArray.length > 0){
            this.controller.getProviderDetail(resultArray[0],(data)=>{
                this.set({selectedTargetProvider});
                this.set({targetNumberOfPatient:data.numberOfPatient});
                this.set({targetBillingName:data.billingName});
                this.set({targetProviderName:data.providerName});
                const newCurrentProviders = [];
                _.each(bulkChangeProviders,(provider)=>{
                    if(provider.id == -1 || provider.id != selectedTargetProvider){
                        newCurrentProviders.push(provider);
                    }
                });
                this.set({bulkChangeProvidersCurrent:newCurrentProviders});
                this.trigger( 'set:targetProviderDetail' );
            });
        }

    },
    bulkProviderUpdate: function(callback){
        this.controller.bulkProviderUpdate(
            this.get('selectedCurrentProvider'),
            this.get('selectedTargetProvider'),
            (data)=>{
                this.set({selectedCurrentProvider:-1});
                this.set({selectedTargetProvider:-1});
                this.set({currentNumberOfPatient:''});
                this.set({currentBillingName:''});
                this.set({currentProviderName:''});
                this.set({targetNumberOfPatient:''});
                this.set({targetBillingName:''});
                this.set({targetProviderName:''});
                this.trigger( 'set:bulkChangeProviders' );
                callback();
            }
        );
    }
});