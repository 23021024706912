// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import qualityContainerView  from '../../../../templates/MIPS/Dashboard/qualityContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.listenTo( this.model, 'change:dashboardData change:exclusions', this.render );
    },

    getTemplate: function() {
        return qualityContainerView( this.model );
    },

});
