// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import fileManagerItemView  from './../../../../templates/PatientEnrollment/FileManager/fileManagerItemView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    className: 'table-row clearfix',

    events: {
        'click .expand-note': 'showExpandedNote',
        'click .delete': 'deleteFile',
        'click .download': 'downloadFile',
        'click .edit-note': 'showEditNote',
        'click .save-edit': 'saveEditNote',
        'click .cancel-edit': 'cancelEditNote',
        'click .add-note': 'addNote',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );

        this.listenTo( this.model, 'change:showExpandedNote change:showEditNote', this.render );
        if (this.model.get('file_notes') === 'undefined') {
            this.model.set('file_notes', undefined);
        }
    },

    getTemplate: function() {
        return fileManagerItemView( this.model );
    },

    showExpandedNote: function() {
        this.model.set( 'showExpandedNote', !this.model.get( 'showExpandedNote' ) );
    },

    deleteFile: function() {
        this.model.deleteFile();
    },

    downloadFile: function( e ) {
        e.preventDefault();
        this.model.downloadFile();
    },

    showEditNote: function() {
        this.model.set( 'showEditNote', true );
    },

    saveEditNote: function() {
        let newNote = this.$( '#fileNotes' ).val();
        this.model.saveEditNote( newNote );
    },

    cancelEditNote: function() {
        if ( this.model.get( 'file_notes' ) ) {
            this.model.set( 'showEditNote', false );
        } else {
            this.model.set({
                showExpandedNote: false,
                showEditNote: false,
            });
        }
    },

    addNote: function() {
        this.showExpandedNote();
        this.showEditNote();
    },

});
