// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import mipsSubNavView  from '../../../templates/MIPS/mipsSubNavView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.model, 'change', this.addActive );
	},

	getTemplate: function() {
		return mipsSubNavView( this.model );
	},

	onShow: function() {
		this.addActive();
	},

	onDomRefresh: function() {
		this.addActive();
	},

	addActive: function() {
		// Remove active class from any subnav-items
		$( '.subnav-item a' ).removeClass( 'active' );

		// Get the URL hash and add the active class to the anchor linking to current page
		let currentLocation = window.location.hash;
		$('a[href="' + currentLocation + '"]').addClass('active');
	},

});
