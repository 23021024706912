//External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import resetEnvironmentCompositeView  from '../../../../templates/Utilities/ResetEnvironment/resetEnvironmentCompositeView';

// Internal Dependencies - models/collections
import { ResetEnvironmentModel } from './Models/ResetEnvironmentModel';

export default Marionette.ItemView.extend({

  className: 'reset-environment-composite-view',

  events: {
    'click #submitRestEnv': 'submitRestEnvironment'
  },

  initialize: function() {
    this.channel = Backbone.Radio.channel( 'AppChannel' );
    this.app = this.channel.request( 'app' );
    this.model = new ResetEnvironmentModel({ user: this.app.controller.user });
    this.controller = this.app.utilities_controller;
    this.controller.getDemoResetProgressStatus();
    this.listenTo( this.channel, 'set:demoResetProgressStatus', this.setProgressStatus );
  },

  getTemplate: function() {
    return resetEnvironmentCompositeView( this.model );
  },

  submitRestEnvironment: function( e ) {
    e && e.preventDefault();
    this.controller.resetDemoEnvironment();
  },

  setProgressStatus: function() {
    if ( this.controller.demoResetProgressStatus && this.controller.demoResetProgressStatus === 'IN_PROGRESS' ) {
      $('#submitRestEnv').removeClass('hide');
      $('#submitRestEnv').addClass('disabled');
      $('#submitRestEnv').html('Reset is in progress');
    } else {
      $('#submitRestEnv').removeClass('disabled');
      $('#submitRestEnv').removeClass('hide');
    }

  }
});
