// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import resetEnvironmentContainerView  from '../../../../templates/Utilities/ResetEnvironment/resetEnvironmentContainerView';
import ResetEnvironmentCompositeView from './ResetEnvironmentCompositeView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

  regions: {
    'resetEnvironmentCompositeView': '.reset-environment-composite-container'
  },

  getTemplate: function() {
    return resetEnvironmentContainerView( );
  },

  onShow: function() {
    this.showChildView( 'resetEnvironmentCompositeView', new ResetEnvironmentCompositeView() );
  }

});
