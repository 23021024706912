// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  providerInfoModal  from '../../../../../templates/CCM/RapidBill/Approval/providerInfoModal';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
	},

	getTemplate: function() {
		return providerInfoModal( this.collection );
	},

	closeModal: function() {
		$( '#modalSummary' ).closeModal();
		this.destroy();
	},

});
