// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import documentsContainerView  from '../../../../templates/CPC/Documents/documentsContainerView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	className: 'documents-container-view',

	getTemplate: function() {
		return documentsContainerView( );
	},

});
