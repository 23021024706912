// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import appFilteringContainerView  from '../../../templates/Filtering/appFilteringContainerView';
import UserLevelFilteringView from './UserLevelFilteringView';

// Internal Dependencies - models/collections
import { FilteringModel } from './models/FilteringModel';

export default Marionette.LayoutView.extend({

	regions: {
		userLevel: '.user-level'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new FilteringModel({ channel: this.channel, app: this.app });
	},

	getTemplate: function() {
		return appFilteringContainerView( this.model );
	},

	onShow: function() {
		this.showChildView( 'userLevel', new UserLevelFilteringView() );
	}

});
