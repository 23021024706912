// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import primaryInputCardView  from '../../../../../templates/MIPS/AdvancingCare/PerformanceScore/primaryInputCardView';
import DataEntryView from './DataEntryView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

    regions: {
        'dataEntryContainer': '.data-entry-container',
    },

    events: {
        'click @ui.reverse': 'flipCard',
        'click @ui.saveEntry': 'saveEntry',
        'change @ui.monthInput': 'updateDataInput',
        'change @ui.yearInput': 'updateDataInput',
        'change @ui.criteriaSelect': 'updateCriteria',
    },

    ui: {
        reverse: '#reverse',
        flipper: '.flipper',
        front: '.front',
        back: '.back',
        flipContainer: '.flip-container',
        saveEntry: '#saveEntry',
        numeratorInput: '#numeratorInput',
        denominatorInput: '#denominatorInput',
        monthInput: '#month',
        yearInput: '#year',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.model.set( 'editable', true );
    },

    onShow: function() {
        this.showChildView( 'dataEntryContainer', new DataEntryView({ model: this.model }) );
        this.updateDataInput();
    },

    getTemplate: function() {
        return primaryInputCardView( this.model );
    },

    flipCard: function( e ) {
        e.preventDefault();
        this.ui.flipContainer.toggleClass( 'active' );
        // Adjust height of container if necessary based on height of Front or Back, whichever is visible
    },

    updateDataInput: function() {
        const ui = this.ui;
        let monthValue = ui.monthInput.val();
        let yearValue = ui.yearInput.val();
        if ( this.checkHasFullDate( monthValue, yearValue ) && this.hasMonthData() ) {
            const measureData = this.model.get('measureData');
            let matchingDataPoint = _.find(measureData.historical_data, (dataPoint) => dataPoint.month && dataPoint.month === Number(monthValue));
            if ( matchingDataPoint ) {
                this.model.set({
                    numerator: matchingDataPoint.numerator,
                    denominator: matchingDataPoint.denominator,
                    editable: false,
	                monthValue,
	                yearValue,
                });
            } else {
                this.model.set({
	                numerator: '',
	                denominator: '',
	                editable: true,
	                monthValue,
	                yearValue,
                });
                this.model.trigger('change');
            }
        } else if ( this.checkHasFullDate( monthValue, yearValue ) ) {
            this.model.set({
                numerator: '',
                denominator: '',
                editable: true,
                monthValue,
                yearValue,
            });
            this.model.trigger('change');
        }
    },

    checkHasFullDate: function( month, year ) {
        return !!( month && year );
    },

    hasMonthData: function() {
        let measureData = this.model.get( 'measureData' );
        return measureData && measureData.historical_data && measureData.historical_data.length;
    },

    saveEntry: function() {
        let ui = this.ui;
        let numeratorInput = $('#numeratorInput');
        let denominatorInput = $('#denominatorInput');
        let numeratorValue = Number( numeratorInput.val() );
        let denominatorValue = Number( denominatorInput.val() );

        if ( numeratorValue > denominatorValue ) {
            this.$( '.mips-validations' ).show();
            return;
        }
        this.model.set( 'data', {
            numerator: Number( $('#numeratorInput').val() ) || 0,
            denominator: Number( $('#denominatorInput').val() ) || 0,
            month: ui.monthInput.val() || ( ( new Date() ).getMonth() + 1).toString(),
            year: ui.yearInput.val() || ( ( new Date() ).getYear() + 1900 ).toString(),
            text_value: '',
        });

        this.model.saveMeasureData( this.model.get( 'selected_criteria' ) );
    },

});
