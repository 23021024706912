import Backbone from 'backbone';
import moment from "moment/moment";
import _ from 'underscore';

export const DashboardModel = Backbone.Model.extend({

	defaults: {
		selectedProvider: -1,
		selectedYear: moment().format('YYYY'),
		availableYears: [],
		isAdmin: false,
		mipsProviders: [],
		compositePercent: {
			id: null,
			param: 'compositePercent',
			value: 0,
		},
		compositePoints: {
			id: null,
			param: 'compositePoints',
			value: 0,
		},
		aciBase: {
			id: null,
			param: 'aciBase',
			value: 0,
		},
		aciPerformance: {
			id: null,
			param: 'aciPerformance',
			value: 0,
		},
		aciBonus: {
			id: null,
			param: 'aciBonus',
			value: 0,
		},
		aciBonusTotal: {
			id: null,
			param: 'aciBonusTotal',
			value: 0,
		},
		aciTotalScore: {
			id: null,
			param: 'aciTotalScore',
			value: 0,
		},
		qualityPoints: {
			id: null,
			param: 'qualityPoints',
			value: 0,
		},
		qualityTotalScore: {
			id: null,
			param: 'qualityTotalScore',
			value: 0,
		},
		costPoints: {
			id: null,
			param: 'costPoints',
			value: 0,
		},
		costTotalScore: {
			id: null,
			param: 'costTotalScore',
			value: 0,
		},
		improvementPoints: {
			id: null,
			param: 'improvementPoints',
			value: 0,
		},
		improvementTotalScore: {
			id: null,
			param: 'improvementTotalScore',
			value: 0,
		},
		worksheet: {
			id: null,
			exists: false,
		}
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.controller = this.app.mips_controller;
		this.set({ 'isAdmin': this.app.controller.user && this.app.controller.user.csadmin });
		this.getData();
		this.getYears();
		this.controller.getProviders();
		this.listenTo(this.channel, 'set:mipsNewDashboardData', this.setData);
		this.listenTo(this.channel, 'set:worksheetStatus', this.setWorksheetStatus);
		this.listenTo(this.channel, 'set:mipsProviders', this.setMipsProviders);
		this.listenTo(this.channel, 'success:uploadWorksheet', this.resetFilePath);
	},

	getYears: function() {
		const currentYear = new Date().getFullYear(), availableYears = [];
		let startYear = 2018;

		while ( startYear <= currentYear ) {
			availableYears.push(startYear++);
		}
		this.set( { availableYears } );
	},

	getData: function( provider_id = this.get('selectedProvider'), year = this.get('selectedYear')) {
		this.controller.getNewDashboardData( provider_id, year );
		this.controller.checkForWorksheet( provider_id, year );
	},

	setMipsProviders: function() {
		let mipsProviders = this.controller.mipsProviders;
		this.set( { mipsProviders } );
	},

	setData: function() {
		let newData = this.controller.mipsNewDashboardData;
		_.each(this.attributes, (attribute) => {
			if ( attribute['param'] ) {
				let data = _.find(newData, (dataPoint) => {
					return dataPoint['param'] === attribute['param']
				}) || { 'value': 0, 'id': null };
				this.setMipsValue(attribute['param'], data['value'], data['id'])
			}
		});
		this.trigger( 'set:mipsNewDashboardData' );
	},

	setWorksheetStatus: function() {
		let worksheet = this.get('worksheet');
		worksheet['exists'] = this.controller.worksheetStatus;
		this.set( { worksheet } );
		this.trigger( 'set:worksheetStatus' );
	},

	setMipsValue: function( object_id, object_value, id ) {
		let item = this.get( object_id );
		item['value'] = parseInt(object_value, 10);
		if((object_id == 'compositePercent' || object_id == 'aciTotalScore'
			|| object_id == 'costTotalScore'
			|| object_id == 'improvementTotalScore' || object_id == 'qualityTotalScore') && this.get('selectedYear') != 2018){
      		item['value'] = parseFloat(object_value).toFixed(1);
    	}
        
		if ( id || id === null ) item['id'] = id;
		this.set( object_id, item );
	},

	setFilter: function( object_id, object_value ) {
		this.set( object_id, object_value );
	},

	saveDashboard: function( provider_id = this.get('selectedProvider'), year = this.get('selectedYear') ) {
		let body = [];
		_.each(this.attributes, function(attribute) {
			if ( attribute['param'] ) {
				body.push(attribute)
			}
		});
		this.controller.setNewDashboardData(body, provider_id, year);
	},

	uploadFile: function( provider_id = this.get('selectedProvider'), year = this.get('selectedYear') ) {
		let file = this.get( 'filePath' );
		let data = new FormData();
		data.append( 'file', file );
		this.controller.uploadWorksheet( data, file, provider_id, year );
	},

	resetFilePath: function() {
		this.set({'filePath': ''})
	},

	openWorksheet: function( provider_id = this.get('selectedProvider'), year = this.get('selectedYear') ) {
		this.controller.openWorksheet( provider_id, year );
	}

});