import Backbone from 'backbone';
import _ from 'underscore';

export const PatientModel = Backbone.Model.extend({

    defaults: {
        id: 0,
        ehr_pid: '',
        last_name: '',
        first_name: '',
        dob: '',
        insurance1: '',
        insurance2: '',
        email: '',
        phone: '',
        provider: '',
        icd10code_1: '',
        icd10code_2: '',
        selected: false,
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
    },

    requiredFields: [
        'last_name',
        'first_name',
        'patient_id',
        'birthdate',
        'home_phone',
        'phone',
        'ccm_provider_id',
    ],

    patientData: function() {
        let currentData = this.attributes;
        return Object.assign( {}, currentData, {
            patient_id: currentData.ehr_pid || currentData.patient_id,
            // first_name: currentData.first_name,
            // last_name: currentData.last_name,
            birthdate: this.app.controller.formatDateForServer( currentData.dob || currentData.birthdate ),
            home_phone: currentData.phone,
            // phone: currentData.phone,
            // secondary_phone: currentData.secondary_phone,
            icd10code_1: this.parseICD10Code( currentData.icd10code_1 ) || null,
            icd10code_2: this.parseICD10Code( currentData.icd10code_2 ) || null,
            email: currentData.email,
            ccm_provider_id: this.whichProvider( currentData.provider ),
            // id: currentData.id,
            no_email: !!!currentData.email,
            // existing_patient: false,
            // explained_consent: false,
            // explained_records_request: false,
            // suspended: currentData.suspended,
            // enrolled_ccm: currentData.enrolled_ccm,
            // date_created: currentData.date_created,
            // date_updated: currentData.date_updated,
            organization_id: null,
        });
    },

    editPatient: function() {
        this.checkFields();
        ( !this.get( 'errors' ) || ( this.get( 'errors' ) && !this.get( 'errors' ).length ) ) && this.app.pe_controller.editPatient( this.patientData() );
    },

    whichProvider: function() {
        let selectedProviderText = this.get( 'provider' );
        let providers = this.app.controller.providers;
        let matchedProvider = 0;
        providers.forEach( ( provider ) => {
            if ( provider.name === selectedProviderText ) {
                matchedProvider = provider.id;
            }
        });
        return matchedProvider;
    },

    parseICD10Code: function( value ) {
        if ( value ) {
            if ( value.indexOf( ' - ' ) > -1 ) {
                return value.slice( 0, value.indexOf( ' - ' ) ).trim();
            } else {
                return value.toUpperCase();
            }
        }
    },

    checkFields: function() {
        let errors = [];
        let modelAttrs = this.patientData();
        let keys = _.keys( modelAttrs );
        let requiredFields = this.requiredFields;
        _.each( requiredFields, ( field ) => {
            if ( !_.contains( keys, field ) || ( Number( this.get( field ) ) < 1 ) ) {
                errors.push( this.handleError( field ) );
            } else {
                let error = this.validateData( this.get( field ), field );
                error && errors.push( error );
            }
        });
        this.set({ errors });
    },

    determineValueType: function( key ) {
        switch( key ) {
            case 'last_name':
                return 'Last Name';
                break;
            case 'first_name':
                return 'First Name';
                break;
            case 'birthdate':
                return 'Date of Birth';
                break;
            case 'pid':
                return 'EHR Patient ID'
                break;
            case 'ccm_provider_id':
            case 'provider_id':
                return 'Provider';
                break;
            case 'phone':
                return 'Phone Number';
                break;
            case 'home_phone':
                return 'Primary Phone';
                break;
            case 'cell_phone':
                return 'Secondary Phone';
                break;
            case 'explained_consent':
                return 'Explain Consent';
                break;
            case 'explained_records_request':
                return 'Explain Records Request';
                break;
        }
    },

    handleError: function( field ) {
        switch( field ) {
            case 'explained_consent':
            case 'explained_records_request':
                return {
                    field,
                    error: `Must complete the following task: ${ this.determineValueType( field ) }`
                }
                break;
            case 'pid':
                return {
                    field,
                    error: `Must provide a valid ${ this.determineValueType( field ) }`
                }
            case 'ccm_provider_id':
            case 'provider_id':
                return {
                    field,
                    error: `Must select a ${ this.determineValueType( field ) }`
                }
                break;
            // case 'last_name':
            // case 'first_name':
            // case 'patient_id':
            case 'phone':
            case 'home_phone':
                return {
                    field: 'primary_phone',
                    error: `Must provide a valid ${this.determineValueType( field ) }`
                }
                break;
            case 'birthdate':
                return {
                    field,
                    error: `Must provide a valid ${ this.determineValueType( field ) } in the following format: MM/DD/YYYY`
                }
                break;
            default:
                return {
                    field,
                    error: `Must provide a valid ${ this.determineValueType( field ) }`
                }
        }
    },

    validateData: function( value, key ) {
        switch( key ) {
            case 'last_name':
            case 'first_name':
                if ( !value || ( value && value.length < 2 ) ) {
                    return {
                        field: key,
                        error: `${ this.determineValueType( key ) } must be at least 2 characters in length`
                    }
                }
                break;
            case 'pid':
                if ( !value || ( value && value.length < 2 ) ) {
                    return {
                        field: key,
                        error: `${ this.determineValueType( key ) } must be at least 2 characters in length`
                    }
                }
                break;
            case 'birthdate':
                if ( !value || ( value && value.replace( /[-/]/g, '' ).length < 8 ) ) {
                    return {
                        field: key,
                        error: `Must provide a valid ${ this.determineValueType( key ) } (MM/DD/YYYY)`
                    }
                }
                break;
            default:
                return null;
        }
    },

});