// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import providerTransitionItemView  from '../../../../templates/CCM/ProviderTransition/providerTransitionItemView';
import {EnrollPatientModel} from "../PatientList/models/EnrollPatientModel";
import EnrollPatientModalView from "../PatientList/EnrollPatientModalView";
import {ProviderTransitionModel} from "../PatientList/models/ProviderTransitionModel";
import SuspendPatientModalView from "../PatientList/SuspendPatientModelView";
import {UnenrollPatientModel} from "../PatientList/models/UnenrollPatientModel";
import UnenrollPatientModelView from "../PatientList/UnenrollPatientModelView";
import SpecialistRecordsModalView from "../PatientList/SpecialistRecordsModalView";
import ProviderTransitionModelView from '../PatientList/ProviderTransitionModelView';
import TriageRecordsModalView from '../PatientList/TriageRecordsModalView';

export default Marionette.ItemView.extend({

	className: 'table-row clearfix',

	events: {
		'click .more': 'expandContent',
		'click .less': 'expandContent',
        'click .open-patient-plan': 'viewCarePlan',
		'click .edit-patient-information': 'launchProviderTransition',
        'click .open-ccda': 'viewCcda',
		'click .enrolled-patient-history': 'viewPatientHistory',
		'click .support': 'sendPatientToSupport',
		'click .specialist-records':'openRecordsModal',
		'click .triage-records': 'openTriageRecordsModal',
		'click .unenroll':'unenrollPatient',
		'click .open-confidential-note': 'openPatientConfidentialNote',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	getTemplate: function() {
		return providerTransitionItemView( this.model );
	},

	viewCarePlan: function() {
		let patientId = this.model.get( 'id' );
		this.model.viewCarePlan( patientId );
	},

    viewCcda: function() {
        let patientId = this.model.get( 'id' );
        this.model.viewCcda( patientId );
    },

	viewPatientHistory: function() {
		let patientId = this.model.get( 'id' );
		this.model.viewPatientHistory( patientId );
	},



	expandContent: function( e ) {
		this.$( '.more' ).toggleClass( 'active' );
		this.$( '.less' ).toggleClass( 'active' );
		this.$( '.patient-list-expanded-data' ).slideToggle( 200 );
	},

    sendPatientToSupport: function() {
        let patientId = this.model.get( 'id' );
        this.model.sendPatientToSupport( patientId );
    },

	launchProviderTransition: function(){
		this.model.getProviders((data) => {
			let providerTransitionModel = new ProviderTransitionModel( this.model.attributes );
			providerTransitionModel.set( 'patient_id', this.model.get( 'id' ) );
			providerTransitionModel.set( 'bulkChangeProvidersCurrent', data );
			const ccmProviderId = this.model.get( 'ccm_provider_id' );
			const resultArray = _.filter(data,(provider)=>{
				return provider.id == ccmProviderId;
			});
			let currentProvider = '';
			if(resultArray.length > 0){
				currentProvider = resultArray[0].name;
			}
			providerTransitionModel.set( 'currentProvider', currentProvider );
			let modalView = new ProviderTransitionModelView({ model: providerTransitionModel,callback:()=>{
					// alert('Success');
					this.app.ccm_controller.getProviderTransitionList();
				}});
			modalView.render();
			$( 'body' ).append( modalView.el );
			$( '#modalReject' ).openModal();
		});
	},


	openRecordsModal: function() {
		let modalView = new SpecialistRecordsModalView({model: this.model});
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#specialistRecordsModal' ).openModal();
		this.channel.trigger( 'trigger:records_modal' );
	},

	openTriageRecordsModal: function() {
		let modalView = new TriageRecordsModalView({model: this.model});
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#triageRecordsModal' ).openModal();
		this.channel.trigger( 'trigger:triage_records_modal' );
	},

	unenrollPatient: function(){
		this.model.getUnenrollReasons((data) => {
			let unenrollPatientModel = new UnenrollPatientModel( this.model.attributes );
			unenrollPatientModel.set( 'patient_id', this.model.get( 'id' ) );
			unenrollPatientModel.set( 'unenroll_reason_options', data );
			let modalView = new UnenrollPatientModelView({ model: unenrollPatientModel,callback:()=>{
					// alert('Success');
					this.app.ccm_controller.getProviderTransitionList();
				}});
			modalView.render();
			$( 'body' ).append( modalView.el );
			$( '#modalReject' ).openModal();
		});
	},
	openPatientConfidentialNote: function() {
		let patientId = this.model.get( 'id' );
		this.model.openConfidentialNotes( patientId );
	},

});
