import Backbone from 'backbone';
import _ from 'underscore';

export const UnverifiedModel = Backbone.Model.extend({

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	viewCarePlan: function( patientId ) {
		this.app.ccm_controller.openPatientPlan( patientId );
	},

    viewCcda: function( patientId ) {
        this.app.ccm_controller.openCcda( patientId );
    },

});