// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import editPatientInformationModalView  from '../../../../../templates/PatientEnrollment/PatientApproval/PatientApprovalComponents/editPatientInformationModalView';
import ValidationsContainerView from '../../../Validations/ValidationsContainerView';

// Internal Dependencies - models/collections
import { ValidationsModel } from '../../../Validations/models/ValidationsModel';

export default Marionette.LayoutView.extend({

    regions: {
        'validationContainer': '.validations-container',
    },

    events: {
        'click .modal-close': 'closeModal',
        'click .edit-patient': 'editPatient',
        'change #icd10code_1': 'checkSelectedCode',
        'change #icd10code_2': 'checkSelectedCode',
        'keyup input': 'updateModel',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.model.set( 'provider-options', this.app.controller.providers );
        this.listenTo( this.model, 'change:errors', this.displayValidations );
        this.listenTo( this.channel, 'close:modal', this.closeModal );
        this.listenTo( this.channel, 'remove:errors', this.removeErrors );
        this.listenTo( this.channel, 'trigger:show', this.showTriggered );
    },

    checkSelectedCode: function( e ) {
        if ( e.target.id == 'icd10code_1' ) {
            this.selectedCode1 = e.target.value;
            return;
        }
        if ( e.target.id == 'icd10code_2' ) {
            this.selectedCode2 = e.target.value;
            return;
        }
    },

    showTriggered: function() {
        const self = this;
        this.selectedCode1 = '';
        this.selectedCode2 = '';
        $( 'select' ).material_select();
        $('#icd10code_1').devbridgeAutocomplete({
            serviceUrl: `${ this.app.controller.engageUrl }v1/codelookup/icd10?start=0`,
            onSelect: function( suggestion ) {
                self.selectedCode1 = suggestion.id;
            },
            paramName: 'search',
            maxHeight: 150,
            ajaxSettings: {
                headers: {
                    'X-AUTH-TOKEN': this.app.controller.getToken()
                }
            },
            transformResult: function( data ) {
                let response = _.reject( JSON.parse( data ), ( item ) => item.id == self.selectedCode2 );
                return {
                    suggestions: response.map( ( item ) => {
                        return { value: `${ item.id } - ${ item.desc }`, id: item.id }
                    })
                }
            },
            autoSelectFirst: true,
            noCache: true,
        });
        $('#icd10code_2').devbridgeAutocomplete({
            serviceUrl: `${ this.app.controller.engageUrl }v1/codelookup/icd10?start=0`,
            onSelect: function( suggestion ) {
                self.selectedCode2 = suggestion.id;
            },
            paramName: 'search',
            maxHeight: 150,
            ajaxSettings: {
                headers: {
                    'X-AUTH-TOKEN': this.app.controller.getToken()
                }
            },
            transformResult: function( data ) {
                let response = _.reject( JSON.parse( data ), ( item ) => item.id == self.selectedCode1 );
                return {
                    suggestions: response.map( ( item ) => {
                        return { value: `${ item.id } - ${ item.desc }`, id: item.id }
                    })
                }
            },
            autoSelectFirst: true,
            noCache: true,
        });
    },

    getTemplate: function() {
        return editPatientInformationModalView( this.model );
    },

    closeModal: function( e ) {
        e && e.preventDefault();
        $( '#modalEditPatient' ).closeModal();
        this.destroy();
    },

    editPatient: function( e ) {
        e.preventDefault();
        this.model.editPatient();
    },

    displayValidations: function() {
        let validationsModel = new ValidationsModel({ errors: this.model.get( 'errors' ) });
        this.showChildView( 'validationContainer', new ValidationsContainerView({ model: validationsModel }) );
        $( '#modalEditPatient' ).scrollTop( 0 );
    },

    removeErrors: function() {
        this.$( 'input' ).each( ( index, value ) => {
            let selector = $( value );
            selector.hasClass( 'invalid' ) && selector.removeClass( 'invalid' );
        });
        this.$( 'select' ).each( ( index, value ) => {
            let selector = $( value );
            selector.hasClass( 'invalid' ) && selector.removeClass( 'invalid' );
        });
    },

    updateModel: function( e ) {
        this.model.set( e.target.id, e.target.value );
    },

});
