// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  peSubNavView  from '../../../templates/PatientEnrollment/peSubNavView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.model.set({ 'isCSAdmin': this.app.controller.user && this.app.controller.user.csadmin });
        this.listenTo( this.model, 'change', this.addActive );
    },

    getTemplate: function() {
        return peSubNavView( this.model );
    },

    onShow: function() {
        this.addActive();
    },

    addActive: function() {
        // Remove active class from any subnav-items
        $( '.subnav-item a' ).removeClass( 'active' );

        // Get the URL hash and add the active class to the anchor linking to current page
        let currentLocation = window.location.hash;
        $('a[href="' + currentLocation + '"]').addClass('active');

        // Handling rapid-bill folder
        let patientFolders = $( '.patient-folders' );

        if ( currentLocation.indexOf( 'patient-approval' ) !== -1 ) {
            $('#PatientApproval').addClass('active');
            this.whichPatientApprovalRoute();
        } else if ( patientFolders.is(':visible') ) {
            patientFolders.slideUp();
        }
    },

    whichPatientApprovalRoute: function() {
        // Remove active from all folder routes
        $( '.patient-folders a' ).removeClass( 'active' );

        // Get the active rapid-bill folder
        let patientFolders = $( '.patient-folders' );
        let currentLocation = window.location.hash;
        let currentFolder = currentLocation.slice( currentLocation.lastIndexOf( '/' ) + 1 );
        let activeFolder = this.determineActiveFolder( currentFolder );
        patientFolders.slideDown();
        $( '#' + activeFolder ).addClass( 'active' );
    },

    determineActiveFolder: function( currentFolder ) {
        switch( currentFolder ) {
            case 'reporting':
                return 'Reporting';
                break;
            case 'file-manager':
                return 'FileManager';
                break;
	        case 'awaiting-approval':
		        return 'AwaitingApproval';
		        break;
	        case 'declined':
		        return 'Declined';
		        break;
            default:
                return 'Reporting';
        }
    },

});
