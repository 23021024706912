// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  notificationsContainerView  from '../../../../templates/Main/Notifications/notificationsContainerView';
import NotificationsCompositeView from './NotificationsCompositeView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	regions: {
		'notificationsContainer': '.notifications-container'
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new Backbone.Model();
		this.type = options.type;
	},

	getTemplate: function() {
		return notificationsContainerView( this.model );
	},

	onShow: function() {
		this.showChildView( 'notificationsContainer', new NotificationsCompositeView( { type: this.type } ) );
	},

});
