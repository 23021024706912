// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';

// Internal Dependencies - screens/views/templates
import cpcChartsContainerView  from '../../../../templates/Main/Dashboard/cpcChartsContainerView';

// Internal Dependencies - models/collections
import { CcmDashboardModel } from './models/CcmDashboardModel';

export default Marionette.ItemView.extend({

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new CcmDashboardModel();
		$('.content-container').addClass('widen');

	},

	getTemplate: function() {
		return cpcChartsContainerView( this.model );
	},

	onShow: function() {
		this.renderCharts();
	},

	onDestroy: function() {
		$('.content-container').removeClass('widen');
	},

	renderCharts: function() {
		this.patientsAttributed();
		this.careManagementFeeQuarter();
		this.careManagementFeeYear();
		this.careManagementFeeLifetime();
		this.performanceFeeQuarter();
		this.performanceFeeYear();
		this.performanceFeeLifetime();
		this.payerOneTier();
		this.payerTwoTier();
		this.payerThreeTier();
		this.payerFourTier();
		this.payerFiveTier();
	},

	patientsAttributed: function() {
		Highcharts.chart('patientsAttributed', {
	        chart: {
	            type: 'column'
	        },
	        title: {
	            text: null,
	        },
	        xAxis: {
	            type: 'category',
	            labels: {
	                rotation: -45,
	                style: {
	                    fontSize: '13px',
	                    fontFamily: 'Verdana, sans-serif'
	                }
	            }
	        },
	        yAxis: {
	            min: 0,
	            title: {
	                text: 'Patients'
	            }
	        },
	        legend: {
	        	enabled: false
	        },
	        series: [{
	        	name: 'Payer',
	        	colorByPoint: true,
	        	data: [{
	        		name: 'Payer 1',
	        		y: 100,
	        		color: this.model.colors[0]
	        	}, {
	        		name: 'Payer 2',
	        		y: 120,
	        		color: this.model.colors[1]
	        	}, {
	        		name: 'Payer 3',
	        		y: 110,
	        		color: this.model.colors[2]
	        	}, {
	        		name: 'Payer 4',
	        		y: 150,
	        		color: this.model.colors[3]
	        	}, {
	        		name: 'Payer 5',
	        		y: 90,
	        		color: this.model.colors[4]
	        	}]
	        }],
	        credits: {
	        	enabled: false
	        }
	    });
	},

	careManagementFeeQuarter: function() {
		Highcharts.chart('careManagementFeeQuarter', {
	        chart: {
	            type: 'column'
	        },
	        title: {
	            text: 'Current Quarter',
	            align: 'left',
	            'style': {
	            	fontWeight: 100,
                    fontFamily: 'Helvetica'
	            }
	        },
	        xAxis: {
	            type: 'category',
	            labels: {
	                rotation: -45,
	                style: {
	                    fontSize: '13px',
	                    fontFamily: 'Verdana, sans-serif'
	                }
	            }
	        },
	        yAxis: {
	            min: 0,
	            title: {
	                text: 'Revenue'
	            }
	        },
	        legend: {
	        	enabled: false
	        },
	        series: [{
	        	name: 'Payer',
	        	colorByPoint: true,
	        	data: [{
	        		name: 'Payer 1',
	        		y: 1300,
	        		color: this.model.colors[0]
	        	}, {
	        		name: 'Payer 2',
	        		y: 1202,
	        		color: this.model.colors[1]
	        	}, {
	        		name: 'Payer 3',
	        		y: 1310,
	        		color: this.model.colors[2]
	        	}, {
	        		name: 'Payer 4',
	        		y: 1504,
	        		color: this.model.colors[3]
	        	}, {
	        		name: 'Payer 5',
	        		y: 904,
	        		color: this.model.colors[4]
	        	}]
	        }],
	        credits: {
	        	enabled: false
	        }
	    });
	},

	careManagementFeeYear: function() {
		Highcharts.chart('careManagementFeeYear', {
	        chart: {
	            type: 'column'
	        },
	        title: {
	            text: 'Year to Date',
	            align: 'left',
	            'style': {
	            	fontWeight: 100,
                    fontFamily: 'Helvetica'
	            }
	        },
	        xAxis: {
	            type: 'category',
	            labels: {
	                rotation: -45,
	                style: {
	                    fontSize: '13px',
	                    fontFamily: 'Verdana, sans-serif'
	                }
	            }
	        },
	        yAxis: {
	            min: 0,
	            title: {
	                text: 'Revenue'
	            }
	        },
	        legend: {
	        	enabled: false
	        },
	        series: [{
	        	name: 'Payer',
	        	colorByPoint: true,
	        	data: [{
	        		name: 'Payer 1',
	        		y: 100,
	        		color: this.model.colors[0]
	        	}, {
	        		name: 'Payer 2',
	        		y: 120,
	        		color: this.model.colors[1]
	        	}, {
	        		name: 'Payer 3',
	        		y: 110,
	        		color: this.model.colors[2]
	        	}, {
	        		name: 'Payer 4',
	        		y: 150,
	        		color: this.model.colors[3]
	        	}, {
	        		name: 'Payer 5',
	        		y: 90,
	        		color: this.model.colors[4]
	        	}]
	        }],
	        credits: {
	        	enabled: false
	        }
	    });
	},

	careManagementFeeLifetime: function() {
		Highcharts.chart('careManagementFeeLifetime', {
	        chart: {
	            type: 'column'
	        },
	        title: {
	            text: 'Lifetime',
	            align: 'left',
	            'style': {
	            	fontWeight: 100,
                    fontFamily: 'Helvetica'
	            }
	        },
	        xAxis: {
	            type: 'category',
	            labels: {
	                rotation: -45,
	                style: {
	                    fontSize: '13px',
	                    fontFamily: 'Verdana, sans-serif'
	                }
	            }
	        },
	        yAxis: {
	            min: 0,
	            title: {
	                text: 'Revenue'
	            }
	        },
	        legend: {
	        	enabled: false
	        },
	        series: [{
	        	name: 'Payer',
	        	colorByPoint: true,
	        	data: [{
	        		name: 'Payer 1',
	        		y: 1000,
	        		color: this.model.colors[0]
	        	}, {
	        		name: 'Payer 2',
	        		y: 1205,
	        		color: this.model.colors[1]
	        	}, {
	        		name: 'Payer 3',
	        		y: 1510,
	        		color: this.model.colors[2]
	        	}, {
	        		name: 'Payer 4',
	        		y: 1550,
	        		color: this.model.colors[3]
	        	}, {
	        		name: 'Payer 5',
	        		y: 940,
	        		color: this.model.colors[4]
	        	}]
	        }],
	        credits: {
	        	enabled: false
	        }
	    });
	},

	performanceFeeQuarter: function() {
		Highcharts.chart('performanceFeeQuarter', {
	        chart: {
	            type: 'column'
	        },
	        title: {
	            text: 'Current Quarter',
	            align: 'left',
	            'style': {
	            	fontWeight: 100,
                    fontFamily: 'Helvetica'
	            }
	        },
	        xAxis: {
	            type: 'category',
	            labels: {
	                rotation: -45,
	                style: {
	                    fontSize: '13px',
	                    fontFamily: 'Verdana, sans-serif'
	                }
	            }
	        },
	        yAxis: {
	            min: 0,
	            title: {
	                text: 'Revenue'
	            }
	        },
	        legend: {
	        	enabled: false
	        },
	        series: [{
	        	name: 'Payer',
	        	colorByPoint: true,
	        	data: [{
	        		name: 'Payer 1',
	        		y: 100,
	        		color: this.model.colors[0]
	        	}, {
	        		name: 'Payer 2',
	        		y: 120,
	        		color: this.model.colors[1]
	        	}, {
	        		name: 'Payer 3',
	        		y: 110,
	        		color: this.model.colors[2]
	        	}, {
	        		name: 'Payer 4',
	        		y: 150,
	        		color: this.model.colors[3]
	        	}, {
	        		name: 'Payer 5',
	        		y: 90,
	        		color: this.model.colors[4]
	        	}]
	        }],
	        credits: {
	        	enabled: false
	        }
	    });
	},

	performanceFeeYear: function() {
		Highcharts.chart('performanceFeeYear', {
	        chart: {
	            type: 'column'
	        },
	        title: {
	            text: 'Year to Date',
	            align: 'left',
	            'style': {
	            	fontWeight: 100,
                    fontFamily: 'Helvetica'
	            }
	        },
	        xAxis: {
	            type: 'category',
	            labels: {
	                rotation: -45,
	                style: {
	                    fontSize: '13px',
	                    fontFamily: 'Verdana, sans-serif'
	                }
	            }
	        },
	        yAxis: {
	            min: 0,
	            title: {
	                text: 'Revenue'
	            }
	        },
	        legend: {
	        	enabled: false
	        },
	        series: [{
	        	name: 'Payer',
	        	colorByPoint: true,
	        	data: [{
	        		name: 'Payer 1',
	        		y: 1040,
	        		color: this.model.colors[0]
	        	}, {
	        		name: 'Payer 2',
	        		y: 1420,
	        		color: this.model.colors[1]
	        	}, {
	        		name: 'Payer 3',
	        		y: 1104,
	        		color: this.model.colors[2]
	        	}, {
	        		name: 'Payer 4',
	        		y: 1540,
	        		color: this.model.colors[3]
	        	}, {
	        		name: 'Payer 5',
	        		y: 940,
	        		color: this.model.colors[4]
	        	}]
	        }],
	        credits: {
	        	enabled: false
	        }
	    });
	},

	performanceFeeLifetime: function() {
		Highcharts.chart('performanceFeeLifetime', {
	        chart: {
	            type: 'column'
	        },
	        title: {
	            text: 'Lifetime',
	            align: 'left',
	            'style': {
	            	fontWeight: 100,
                    fontFamily: 'Helvetica'
	            }
	        },
	        xAxis: {
	            type: 'category',
	            labels: {
	                rotation: -45,
	                style: {
	                    fontSize: '13px',
	                    fontFamily: 'Verdana, sans-serif'
	                }
	            }
	        },
	        yAxis: {
	            min: 0,
	            title: {
	                text: 'Revenue'
	            }
	        },
	        legend: {
	        	enabled: false
	        },
	        series: [{
	        	name: 'Payer',
	        	colorByPoint: true,
	        	data: [{
	        		name: 'Payer 1',
	        		y: 1040,
	        		color: this.model.colors[0]
	        	}, {
	        		name: 'Payer 2',
	        		y: 1440,
	        		color: this.model.colors[1]
	        	}, {
	        		name: 'Payer 3',
	        		y: 3110,
	        		color: this.model.colors[2]
	        	}, {
	        		name: 'Payer 4',
	        		y: 1240,
	        		color: this.model.colors[3]
	        	}, {
	        		name: 'Payer 5',
	        		y: 840,
	        		color: this.model.colors[4]
	        	}]
	        }],
	        credits: {
	        	enabled: false
	        }
	    });
	},

	payerOneTier: function() {
		Highcharts.chart('payerOneTier', {
			chart: {
				type: 'pie'
			},
			title: {
				text: null
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false
					},
					showInLegend: true
				}
			},
			series: [{
				name: 'Tiers',
				data: [
					{
						name: 'Tier 1',
						y: 35,
						color: this.model.colors[0]
					}, {
						name: 'Tier 2',
						y: 44,
						color: this.model.colors[1]
					}, {
						name: 'Tier 3',
						y: 45,
						color: this.model.colors[2]
					}, {
						name: 'Tier 4',
						y: 84,
						color: this.model.colors[3]
					}
				]
			}],
			credits: {
				enabled: false
			}
		});
	},

	payerTwoTier: function() {
		Highcharts.chart('payerTwoTier', {
			chart: {
				type: 'pie'
			},
			title: {
				text: null
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false
					},
					showInLegend: true
				}
			},
			series: [{
				name: 'Tiers',
				data: [
					{
						name: 'Tier 1',
						y: 33,
						color: this.model.colors[0]
					}, {
						name: 'Tier 2',
						y: 54,
						color: this.model.colors[1]
					}, {
						name: 'Tier 3',
						y: 21,
						color: this.model.colors[2]
					}, {
						name: 'Tier 4',
						y: 34,
						color: this.model.colors[3]
					}
				]
			}],
			credits: {
				enabled: false
			}
		});
	},

	payerThreeTier: function() {
		Highcharts.chart('payerThreeTier', {
			chart: {
				type: 'pie'
			},
			title: {
				text: null
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false
					},
					showInLegend: true
				}
			},
			series: [{
				name: 'Tiers',
				data: [
					{
						name: 'Tier 1',
						y: 235,
						color: this.model.colors[0]
					}, {
						name: 'Tier 2',
						y: 455,
						color: this.model.colors[1]
					}, {
						name: 'Tier 3',
						y: 385,
						color: this.model.colors[2]
					}, {
						name: 'Tier 4',
						y: 514,
						color: this.model.colors[3]
					}
				]
			}],
			credits: {
				enabled: false
			}
		});
	},

	payerFourTier: function() {
		Highcharts.chart('payerFourTier', {
			chart: {
				type: 'pie'
			},
			title: {
				text: null
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false
					},
					showInLegend: true
				}
			},
			series: [{
				name: 'Tiers',
				data: [
					{
						name: 'Tier 1',
						y: 23,
						color: this.model.colors[0]
					}, {
						name: 'Tier 2',
						y: 44,
						color: this.model.colors[1]
					}, {
						name: 'Tier 3',
						y: 11,
						color: this.model.colors[2]
					}, {
						name: 'Tier 4',
						y: 14,
						color: this.model.colors[3]
					}
				]
			}],
			credits: {
				enabled: false
			}
		});
	},

	payerFiveTier: function() {
		Highcharts.chart('payerFiveTier', {
			chart: {
				type: 'pie'
			},
			title: {
				text: null
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false
					},
					showInLegend: true
				}
			},
			series: [{
				name: 'Tiers',
				data: [
					{
						name: 'Tier 1',
						y: 456,
						color: this.model.colors[0]
					}, {
						name: 'Tier 2',
						y: 156,
						color: this.model.colors[1]
					}, {
						name: 'Tier 3',
						y: 489,
						color: this.model.colors[2]
					}, {
						name: 'Tier 4',
						y: 687,
						color: this.model.colors[3]
					}, {
						name: 'Tier 5',
						y: 779,
						color: this.model.colors[4]
					}
				]
			}],
			credits: {
				enabled: false
			}
		});
	}

});
