// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import notificationItemView  from '../../../../templates/Main/Notifications/notificationItemView';
import ViewNotificationModalView from './ViewNotificationModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	tagName: 'tr',

	id: function() {
		return this.model.get( 'id' );
	},

	className: function() {
		return (this.model.get( 'read' )  || this.model.get( 'differentProvider' )) ? 'read' : 'unread';
	},

	events: {
		'click .open-message': 'openNotification',
		'click [type="checkbox"]': 'selectPatient',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	getTemplate: function() {
		return notificationItemView( this.model );
	},

	openNotification: function() {
		let modalView = new ViewNotificationModalView( { model: this.model } );
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalNotification' ).openModal();
		this.channel.trigger( 'ViewNotificationModalView:show' )
	},

	selectPatient: function() {
		this.model.set( 'selected', !this.model.get( 'selected' ) );
	},
});
