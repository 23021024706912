// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import  bonusScoreContainerView  from '../../../../../templates/MIPS/AdvancingCare/BonusScore/bonusScoreContainerView';
import CardView from './BonusScoreCardView';
import OverviewContainerView from './BonusScoreOverviewContainerView';
import { ADVANCING_CARE_SCORES, MONTH_OPTIONS, YEAR_OPTIONS } from '../../constants';

// Internal Dependencies - models/collections
import { AdvancingCareModel } from '../models/AdvancingCareModel';

export default Marionette.LayoutView.extend({

    regions: {
        'cardContainer': '.card-container',
        'advancingCareDetailsContainer': '.advancing-care-score-details',
    },

    initialize: function( options ) {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );

        this.model = new AdvancingCareModel();
        // Set the variable data used to populate reusable score template with relevant constants
        // let scoreMeta = ADVANCING_CARE_SCORES[ options.score ];
        // this.model.set({
        //     score: scoreMeta,
        //     month_options: MONTH_OPTIONS,
        //     year_options: YEAR_OPTIONS,
        //     measureData: this.app.mips_controller.measureData,
        //     secondaryMeasureData: this.app.mips_controller.secondaryMeasureData,
        //     criteria: scoreMeta.criteria,
        //     selected_criteria: scoreMeta.criteria[ 0 ],
        //     secondary_selected_criteria: scoreMeta.criteria[ 1 ],
        //     criteriaSetTotal: 0,
        // });

        this.model.set({
            score: this.app.mips_controller.measureMetaData,
            measureData: this.app.mips_controller.measureData,
            editable: true,
            criteriaSetTotal: 0,
            improvementActivities: this.app.mips_controller.improvementActivities,
        });

        // this.model.getMeasureData( this.model.get( 'selected_criteria' ).name );
        // this.model.getMeasureData( this.model.get( 'secondary_selected_criteria' ).name, true );

        this.listenTo( this.channel, 'set:measureData', this.determineIfBothCriteriaHaveBeenSet );
        this.listenTo( this.channel, 'set:secondaryMeasureData', this.determineIfBothCriteriaHaveBeenSet );
        this.listenTo( this.channel, 'set:criteriaSelections', this.setCriteriaSelections );

        this.model.getCriteriaSelections( options.score );
	    if ( this.app.controller.currentApp === 'MIPSPROVIDER' ) {
		    this.listenTo( this.channel, 'set:selectedProviderId', this.fetchData );
	    }
    },

    getTemplate: function() {
        return bonusScoreContainerView( this.model );
    },

    onShow: function() {
        $('.content-container').addClass('widen');
        $( '.flipper' ).css({ 'min-height': $( '.front' ).height() });
    },

	fetchData: function() {
		this.model.getMeasureMetaData( this.model.get( 'selected_criteria' ).name );
		this.model.getMeasureData( this.model.get( 'selected_criteria' ).name );
		this.model.getMeasureMetaData( this.model.get( 'secondary_selected_criteria' ).name, true );
		this.model.getMeasureData( this.model.get( 'secondary_selected_criteria' ).name, true );
	},

    determineIfBothCriteriaHaveBeenSet: function() {
        if ( this.model.get( 'criteriaSetTotal' ) > 0 ) {
            this.setMeasureData();
            return;
        }
        this.model.set( 'criteriaSetTotal', this.model.get( 'criteriaSetTotal' ) + 1 );
    },

    setMeasureData: function() {
        this.model.set({
            measureData: this.app.mips_controller.measureData,
            secondaryMeasureData: this.app.mips_controller.secondaryMeasureData,
        });
        this.changedSelectedCriteria();
    },

    onDestroy: function() {
        $('.content-container').removeClass('widen');
        this.app.mips_controller.measureData = {
            score: 0,
            numerator: 0,
            denominator: 0,
            score_raw: 0,
            score_threshold: 'FAIL',
            points_available: 0,
            points_earned: 0,
            historical_data: [],
        };
    },

    changedSelectedCriteria: function() {
        this.showChildView( 'cardContainer', new CardView({ model: this.model }) );
        this.showChildView( 'advancingCareDetailsContainer', new OverviewContainerView({ model: this.model }) );
    },

    setCriteriaSelections: function() {
        let criteria = this.app.mips_controller.criteriaSelections;
        if ( !criteria.length ) {
            $( '.advancing-care-container' ).html( '<h4>Error Loading. Please try again.</h4>' );
            return;
        }
        this.model.set({
            criteria: criteria,
            selected_criteria: criteria[ 0 ],
            secondary_selected_criteria: criteria[ 1 ],
        });
        this.model.getMeasureMetaData( this.model.get( 'selected_criteria' ).name );
        this.model.getMeasureData( this.model.get( 'selected_criteria' ).name );
        this.model.getMeasureMetaData( this.model.get( 'secondary_selected_criteria' ).name, true );
        this.model.getMeasureData( this.model.get( 'secondary_selected_criteria' ).name, true );
        this.changedSelectedCriteria();
    },

});
