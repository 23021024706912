// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import resetPasswordView from '../../../templates/resetPasswordView'

// Internal Dependencies - models/collections
import ResetPasswordModel from './models/ResetPasswordModel';

export default Marionette.ItemView.extend({

	events: {
		'click .submit-password-reset': 'submitReset',
		'change #password':'passwordChanged',
		'click .resend-reset-pwd-link':'submitRestRestLink'
	},

	initialize: function() {
		this.model = new ResetPasswordModel();
		this.channel = Backbone.Radio.channel('AppChannel');
		this.app = this.channel.request('app');
		this.listenTo( this.channel, 'triggerValidToken:failed', (error) => this.showError(error,true) );
		this.listenTo( this.channel, 'triggerResetChangePassword:failed', (error) => this.showValidationError(error) );

		const hash = window.location.hash.substr(window.location.hash.indexOf('?')+1);

		const result = hash.split('&').reduce(function (res, item) {
			const parts = item.split('=');
			res[parts[0]] = parts[1];
			return res;
		}, {});
		const token = result.token;
		this.model.set({token})
		if(token){
			this.app.controller.validateToken( token,(data)=>{
				if(!data){
					setTimeout(()=>{
						this.showError();
					},100);
				}
			});
		}else{
			setTimeout(()=>{
				this.showError();
			},100);

		}
	},

	getTemplate: function() {
		return resetPasswordView();
	},

	submitReset: function(e) {
		e && e.preventDefault();
		$('#passwordrulelabel').css( { 'color': '#505050' } );
		let password = this.$('#password').val();
		let confirmPassword = this.$('#confirmPassword').val();
		this.model.set({
			password,
			confirmPassword
		})
		if ( this.model.validate() ) {
			this.model.changePassword((data)=>{
				this.showSuccess(data);
			});
		} else {
			this.showValidationError({responseJSON:{
				message:'The password you entered does not match the confirmation password. Please re-enter your passwords and try again.'
				}});
		}
	},

	passwordChanged: function(e){
		$('.submit-password-reset').prop('disabled', false)
	},

	showError: function(error,disableSubmit) {
		let errorMessage = 'Invalid Token'
		if(error && error.responseJSON && error.responseJSON.message){
			errorMessage = error.responseJSON.message;
			if(disableSubmit) {
				$('.submit-password-reset').prop('disabled', true)
			}
		}
		$('.error-content').text(errorMessage);
		$('.error').show();
		//
		$('#passwordBox').hide();
		$('#confirmPasswordBox').hide();
		$('#submitButton').hide();
		$('#resendResetPwdLink').show();
		$('#emailAddress').focus();
	},

	showValidationError: function(error) {
		let errorMessage = 'Invalid Password'
		if(error && error.responseJSON && error.responseJSON.message){
			errorMessage = error.responseJSON.message;
		}
		$('#passwordrulelabel').css( { 'color': 'red' } );
		$('#passwordrulelabel').text(errorMessage);
	},

	showSuccess: function(data) {
		let errorMessage = 'Password has been changed successfully. You may now proceed to log in.';
		$('.error').hide();
		$('#passwordrulelabel').css( { 'display': 'none' } );
		$('.success-content').text(errorMessage);
		$('#passwordBox').css( { 'display': 'none' } );
		$('#confirmPasswordBox').css( { 'display': 'none' } );
		$('#submitButton').css( { 'display': 'none' } );
		$('.success').show();
	},
	submitRestRestLink: function (){
		this.app.router.navigate( '/forgot-password', true );
	}

});
