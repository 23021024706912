// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import providerApprovalContainerView  from '../../../../templates/CCM/ProviderApproval/providerApprovalContainerView';
import ProviderApprovalCompositeView from './ProviderApprovalCompositeView';
import PatientTotalsView from '../PatientList/PatientTotalsView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

  regions: {
    'providerApprovalContainer': '.provider-approval-container',
    'patientTotals': '.patient-totals-container'
  },

  initialize: function () {
    this.collection = new Backbone.Collection();
  },

  getTemplate: function() {
    return providerApprovalContainerView();
  },

  onShow: function() {
    this.showChildView( 'patientTotals', new PatientTotalsView({collection: this.collection}) );
    this.showChildView( 'providerApprovalContainer', new ProviderApprovalCompositeView({collection: this.collection}) );
  }

});
