import Backbone from 'backbone';

export const BreadcrumbModel = Backbone.Model.extend({

	initialize: function( options ) {
		this.app = options.app;
		this.channel = options.channel;
		this.setValues();
		this.listenTo( this.channel, 'set:subnavItem', this.setValues );
	},

	setValues: function( ) {
		let currentModule = this.app.controller.currentApp;
		if ( currentModule === 'CPC' ) {
			currentModule = 'CPC+';
		}
		if ( currentModule === 'MIPSGROUP' ) {
			currentModule = 'MIPS GROUP';
		}
		if ( currentModule === 'MIPSPROVIDER' ) {
			currentModule = 'MIPS PROVIDER';
		}
		if ( currentModule === 'PATIENTENROLLMENT' ) {
			currentModule = 'PATIENT ENROLLMENT';
		}
		const subnavItem = this.app.controller.subnavItem;
		this.set( 'currentModule', currentModule );
		this.set( 'subnavItem', subnavItem );
		this.set( 'subnavItemText', this.determineSubnavItemText( subnavItem ) );
	},

	determineSubnavItemText: function( subnavItem ) {
		switch( subnavItem ) {
			case 'Dashboard':
				return 'Dashboard';
				break;
			case 'Notifications':
			case 'ArchivedNotifications':
				return 'Notifications';
				break;
			case 'Enrolled':
				return 'Enrolled';
				break;
			case 'Suspended':
				return 'Suspended';
				break;
			case 'AutoEnroll':
				return 'Auto Enroll';
				break;
			case 'RapidReview':
				return 'Insurance';
				break;
			case 'RapidBill':
			case 'RapidBillReports':
			case 'ErrorQueue':
			case 'PendingReview':
				return 'Rapid Bill';
				break;
			case 'PendingUnenroll':
				return 'Pending Unenroll';
				break;
			case 'Unenrolled':
				return 'Unenrolled';
				break;
			case 'PatientList':
				return 'Patient List';
				break;
            case 'QualityMeasures':
            case 'QualityMeasureDiabetesPoorControl':
            case 'QualityMeasureAdvanceCarePlan':
            case 'QualityMeasurePneumococcalVaccination':
            case 'QualityMeasureFallRiskAssessment':
            case 'QualityMeasureFallCarePlan':
				return 'Quality Measures';
				break;
            case 'AdvancingCare':
            case 'AdvancingCareBaseScore':
            case 'AdvancingCarePerformanceScore':
            case 'AdvancingCareBonusScore':
                return 'Advancing Care Information';
                break;
            case 'ImprovementActivities':
                return 'Improvement Activities';
                break;
            case 'Reports':
                return 'Reports';
                break;
			case 'HistoricalBilling':
				return 'Historical Billing';
				break;
			case 'ManageUsers':
				return 'Manage Users';
				break;
			case 'Resources':
				return 'Resources';
				break;
			case 'Support':
				return 'Support';
				break;
			case 'ChangePassword':
				return 'Change Password';
				break;
            case 'ResetEnvironment':
                return 'Reset Environment';
                break;
			case 'BulkProviderChange':
				return 'Provider Change';
				break;
			case 'Upload':
				return 'Upload';
				break;
			case 'PeReporting':
				return 'Reporting';
				break;
			case 'FileManager':
				return 'File Manager';
				break;
			case 'AwaitingApproval':
				return 'Awaiting Approval';
				break;
            case 'Declined':
                return 'Declined Patients';
                break;
			default:
				return 'Patient List';
				break;
		}
	}

});
