// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  rapidBillItemView  from '../../../../../templates/CCM/RapidBill/Approval/rapidBillItemView';
import RejectBillingModalView from './RejectBillingModalView';
import EditIcd10CodesModalView from './EditIcd10CodesModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: function() {
		return 'table-row clearfix' + ( this.model.get( 'new_patient_for_billing' ) ? ' new-patient' : '' );
	},

	events: {
		'click .more': 'expandContent',
		'click .less': 'expandContent',
		'click .reject': 'rejectBilling',
		'click .undo': 'undoReject',
		'click [type="checkbox"]': 'selectPatient',
		'click .edit-icd10-codes': 'editIcd10Codes',
		'click .open-patient-plan': 'openPatientPlan',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model.set( 'rapidBilling', this.channel.request( 'rapidBilling' ) || false );
		this.listenTo( this.model, 'change', this.render );
		this.listenTo( this.model, 'change', this.model.triggerCheckSelected );
		let modules = this.app.controller && this.app.controller.modules;
		this.model.set( 'isRapid', modules && modules.rapid );
	},

	getTemplate: function() {
		return rapidBillItemView( this.model );
	},

	onShow: function() {
		this.isRejected();
	},

	onDomRefresh: function() {
		this.isRejected();
	},

	expandContent: function( e ) {
		this.$( '.edit-icd10-codes' ).toggleClass( 'active' );
		this.$( '.more' ).toggleClass( 'active' );
		this.$( '.less' ).toggleClass( 'active' );
		this.$( '.rapid-bill-patient-expanded-data' ).slideToggle( 200 );
	},

	rejectBilling: function() {
		let modalView = new RejectBillingModalView({ model: this.model });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalReject' ).openModal();
	},

	undoReject: function() {
		$( this.el ).removeClass( 'rejected' );
		this.model.set({
			rejected: false,
			selected: false,
			notes: '',
		});
		this.$( '.reject-container' ).addClass( 'active' );
		this.$( '.expand-container' ).addClass( 'active' );
		this.$( '.undo-container' ).removeClass( 'active' );
	},

	isRejected: function() {
		if ( this.model.get( 'rejected' ) ) {
			$( this.el ).addClass( 'rejected' );
			this.$( '.reject-container' ).removeClass( 'active' );
			this.$( '.expand-container' ).removeClass( 'active' );
			this.$( '.undo-container' ).addClass( 'active' );
			return;
		}
	},

	selectPatient: function() {
		this.model.set( 'selected', !this.model.get( 'selected' ) );
	},

	editIcd10Codes: function() {
		let modalView = new EditIcd10CodesModalView({ model: this.model });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#editIcd10Codes' ).openModal();
		this.channel.trigger( 'trigger:show' );
	},

    openPatientPlan: function() {
		console.log(this.model)
		console.log(this.model.get('patient_xref_id'))
        let patientId = this.model.get( 'patient_xref_id' );
        this.model.openPatientPlan( patientId );
	},

});
