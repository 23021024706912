// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import providerApprovalCompositeView  from '../../../../templates/CCM/ProviderApproval/providerApprovalCompositeView';
import ProviderApprovalItemView from './ProviderApprovalItemView';

// Internal Dependencies - models/collections
import { ProviderApprovalModel } from './models/ProviderApprovalModel';

export default Marionette.CompositeView.extend({

  childView: ProviderApprovalItemView,

  childViewContainer: '.provider-approval-collection-container',

  events: {
    'click .clear-search': 'resetSearch',
  },

  initialize: function() {
    this.channel = Backbone.Radio.channel( 'AppChannel' );
    this.app = this.channel.request( 'app' );
    this.currentPage = 0;
    this.pageSize = 20;

    let searchObj = {
      placeholder: 'Search first name, last name, or PID'
    }

    this.channel.trigger( 'show:search', searchObj, this.collection );

    this.app.ccm_controller.getProviderApproval();
    this.collection.loading = true;
    this.listenTo( this.channel, 'set:patientList', this.setProviderApprovalRequest );
    this.listenTo( this.channel, 'reset:currentPage', this.resetCurrentPage );
    this.listenTo( this.channel, 'rerender', this.render );
    this.listenTo( this.collection, 'update', this.render );
    this.listenTo( this.channel, 'set:selectedProviderId', this.getProviderApprovalRequest );

    this.channel.reply( 'currentPage', () => {
      return this.currentPage;
    });
    this.channel.reply( 'pageSize', () => {
      return this.pageSize;
    });
    $('.content-container').addClass('widen');
  },

  getTemplate: function() {
    return providerApprovalCompositeView( this.collection );
  },

  getProviderApprovalRequest: function() {
    this.collection.loading = true;
    this.render();
    this.app.ccm_controller.getProviderApproval( 'reset:currentPage' )
  },

  setProviderApprovalRequest: function(cachedPatientList = null ) {
    this.collection.loading = false;
    this.render();
    this.channel.trigger( 'show:contentSpinner' );
    this.collection.reset();
    let pendingList = this.app.ccm_controller.patientList;
    pendingList = _.map( pendingList, ( patient ) => new ProviderApprovalModel( patient ) );
    this.collection.renderNoResults = pendingList && pendingList.length ? false : true;
    let search = this.app.ccm_controller.searchInput
    this.collection.search = search ? search.substring( search.lastIndexOf("=") + 1 ) : '';
    this.collection.add( pendingList );
    this.channel.trigger( 'hide:contentSpinner' );
    !pendingList.length && this.render();
  },

  resetSearch: function() {
    this.channel.trigger( 'reset:search' );
  },

  resetCurrentPage: function() {
    this.currentPage = 0;
    this.app.ccm_controller.patients.currentPage = this.currentPage;
    this.channel.trigger( 'reset:totals' );
  },

  onDestroy: function() {
    this.channel.trigger( 'remove:search' );
    this.app.ccm_controller.clearValue( 'pendingUnenrolled' );
    this.app.ccm_controller.clearValue( 'sortedBy' );
    $('.content-container').removeClass('widen');
  },

});
