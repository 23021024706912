import Backbone from 'backbone';

import { AutoEnrollModel } from './AutoEnrollModel';

export const AutoEnrollCollection = Backbone.Collection.extend({

	model: AutoEnrollModel,

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'check:selected', this.checkSelected );
	},

	selectAll: function( shouldSelect ) {
		// shouldSelect will be a boolean value to determine whether all patients should be selected or not
		this.each( ( patient ) => {
			patient.set( 'selected', shouldSelect );
		});
	},

	checkSelected: function() {
		let allSelected = this.length !== 0;
		let noneSelected = true;
		let enrollSelectedButton = $( '#enrollSelectedButton' );
		this.each( ( patient ) => {
			if ( !patient.get( 'selected' ) ) {
				allSelected = false;
				return;
			}
			noneSelected = false;
		});

		// All patients are selected - check "select all" checkbox and enable "enroll selected" button
		if ( allSelected ) {
			$( '#selectAll' ).prop( 'checked', true );
			enrollSelectedButton.removeClass( 'disabled' );
			return;
		}

		// Not all patients are selected - remove check from "select all" checkbox
		if ( !allSelected ) {
			$( '#selectAll' ).prop( 'checked', false );
		}

		// No patients are selected - disable "enroll selected" button
		if ( noneSelected ) {
			enrollSelectedButton.addClass( 'disabled' );
			return;
		}

		// At least one patient is selected - enable "enroll selected" button
		if ( !noneSelected ) {
			enrollSelectedButton.removeClass( 'disabled' );
		}
	},

	selectedCount: function() {
		return this.filter( ( patient ) => patient.get( 'selected' ) ).length;
	},

	submitAutoEnroll: function() {
		const idsToEnroll = this.filter( ( patient ) => patient.get( 'selected' ) ).map( (child) => child.get( 'ccm_patient_id' ) );
		this.app.ccm_controller.submitAutoEnroll(idsToEnroll);
	},

	downloadCSV: function() {
		this.app.ccm_controller.getAutoPendingCSV();
	}

});
