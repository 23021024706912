import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';

import { Utils } from '../../utils';

import ErrorModalView from '../../ErrorModalView';

const channel = Backbone.Radio.channel('AppChannel');
const utils = new Utils();
const userCookie = 'user=';

export const Utilities_Controller = ( app ) => {

	const BASE_ENGAGE_URL = app.config.BASE_ENGAGE_URL;

	return {

		/**********************
		Global values
		**********************/

		billingDate: {
			monthMM: moment().format( 'MM' ),
			monthMMMM: moment().format( 'MMMM' ),
			year: moment().format( 'YYYY' ),
		},

		// Takes a valueName and data and creates or replaces a variable with that name and data
		setGlobalValue: function ( valueName, data ) {
			this[ valueName ] = data;
			channel.trigger( 'set:' + valueName );
		},

		// Takes a collection and a single updated model and replaces the old instance of the model with the new
		// Model must have all properties that were returned with initial fetch of collection
		updateModelInCollection: function ( collection, updatedItem ) {
			collection.splice(_.findIndex(collection, 'id', updatedItem.id), 1, updatedItem);
		},

		/**********************
		Utilities Specific Methods
		**********************/

		clearValue: function( value ) {
			this[ value ] = null;
		},

		// Support Help Desk methods

		sendSupportMessage: function( message ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/support';
			let payload = {
				name: message.name,
				message: message.message
			};
			let successTrigger = [ 'sent:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner' ];
			// Show spinner during HTTP request
			channel.trigger( 'toggle:globalSpinner' );
			utils.post( endpoint, payload, successTrigger, errorTrigger, null, true );
		},

		/**********************
		Manage Users Methods
		**********************/

		searchUsers: function( page, pageSize, searchInput ) {
			console.log( page, pageSize, searchInput );
		},

		saveUser: function( firstName, lastName, username, password, permissionLevel, associatedProviders ) {
			let endpoint = BASE_ENGAGE_URL + 'endpoint';
			let payload = {
				firstName,
				lastName,
				username,
				password,
				permissionLevel,
				associatedProviders
			};
			let successTrigger = [ 'saveUser:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner' ];
			// $('.global-spinner').show();
			// utils.post( endpoint, payload, successTrigger, errorTrigger, null, true, this.resolveDeleteUser.bind(this) );
		},

		deleteUser: function( id ) {
			let endpoint = BASE_ENGAGE_URL + 'endpoint';
			let payload = {
				id
			};
			let successTrigger = [ 'deleteUser:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner' ];
			// $('.global-spinner').show();
			// utils.post( endpoint, payload, successTrigger, errorTrigger, null, true, this.resolveDeleteUser.bind(this) );
		},

		resolveDeleteUser: function() {

		},

		/**********************
		Upload Methods
		**********************/

		uploadFile: function( file ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/engage/';
			let successTrigger = [ 'upload:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner' ];
			let payload = file;
			// $('.global-spinner').show();
			// utils.post( endpoint, payload, successTrigger, errorTrigger, null, true, this.resolveDeleteUser.bind(this) );
			console.log( file );
		},

		/**********************
		Change Password Methods
		**********************/

		changePassword: function( passwords ) {
			if ( passwords && passwords.newPassword === 'password' ) {
                const mockError = {
					status:200,
                    statusText: 'Bad Request',
                    responseJSON: { message: 'New password cannot be "password"' },
				};
				channel.trigger( 'changePassword:failure' );
                channel.trigger( 'failed:api', mockError );
                return;
			}
			let endpoint = BASE_ENGAGE_URL + 'v1/auth/changePwd';
			let successTrigger = [ 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner', 'changePassword:failure' ];
			let payload = {
				oldPassword: passwords.oldPassword,
				newPassword: passwords.newPassword
			};
			channel.trigger( 'toggle:globalSpinner' );
			utils.post( endpoint, payload, successTrigger, errorTrigger, null, true, this.resolveChangePassword.bind( this, passwords ) );
		},

		resolveChangePassword: function( passwords, data ) {
			if( data.success == 'false'){
				const mockError = {
					status:400,
					statusText: 'Bad Request',
					responseJSON: { message: 'Invalid current password' },
				};
				channel.trigger( 'failed:api', mockError );
				return;
			}
			channel.trigger( 'changePassword:success' );
			if ( app.controller.user ) {
				let user = app.controller.user;
				app.controller.user.changePassword = false;
				const loginModel = new Backbone.Model({
					username: user.username,
					password: passwords.newPassword,
				});
				app.controller.authenticateUser( loginModel );
			}
		},

		/***********************
		Historical Billing Methods
		***********************/

		updateBillingDate: function( data=null ) {
			if ( data && data.monthMM && data.monthMMMM && data.year ) {
				this.setGlobalValue( 'billingDate', data );
			}
		},

		resetDemoEnvironment: function () {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/demoreset/${ org_id }`;
			let successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.post( endpoint, {}, successTrigger, errorTrigger, null, true, this.resolveResetDemoEnvironment.bind( this ) );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.resetDemoEnvironment.bind( this ) );
		},

		resolveResetDemoEnvironment: function( data ) {
			if(data.message === 'Success'){
				this.setGlobalValue( 'demoResetProgressStatus', 'IN_PROGRESS');
			} else {
				const errorObj = {
					statusText: 'Error',
					responseJSON: {
						message: 'Error while submitting reset request.'
					}
				};
				app.controller.displayError(errorObj);
			}
		},


		getDemoResetProgressStatus: function() {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/demoreset/${ org_id }/in-progress/check`;
			let successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			};

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveDemoResetProgressStatus.bind( this ), null );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getDemoResetProgressStatus.bind( this ) );
		},

		resolveDemoResetProgressStatus: function( data ) {
			this.setGlobalValue( 'demoResetProgressStatus', data.is_reset_in_progress ? 'IN_PROGRESS': '' );
		},


		getBillingList: function() {
			let date = this.billingDate;
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/billing/summary?month=${ date.monthMM }&year=${ date.year }`;
			let successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetBillingList.bind( this ), null, undefined, 100000 );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getBillingList.bind( this ) );
		},

		resolveGetBillingList: function( data ) {
			let providerList;
			if ( data.length ) {
				providerList = data.map( ( provider ) => {
					return Object.assign( {}, provider );
				});
			}
			this.setGlobalValue( 'billingList', providerList );
		},

		downloadBilling: function( provider_data={} ) {
			const hasProviderData = !!provider_data.provider_id;
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint;
			if ( provider_data.provider_id ) {
				endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/billing/provider?id=${ provider_data.provider_id }&month=${ this.billingDate.monthMM }&year=${ this.billingDate.year }`;
			} else {
				endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/billing/complete?month=${ this.billingDate.monthMM }&year=${ this.billingDate.year }`;
			}
			let successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'headers:fail', 'hide:contentSpinner', 'failed:api' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData;
			if ( !hasProviderData ) {
				headerData = {
					accept: 'application/zip',
				}
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				if ( !hasProviderData ) {
					utils.get( endpoint, resultTriggers, this.resolveDownloadBulkBilling.bind( this, provider_data ), null, headerData );
				}
				utils.get( endpoint, resultTriggers, this.resolveDownloadIndividualBilling.bind( this, provider_data ), null );
				return;
			}
		},

		resolveDownloadIndividualBilling: function( provider_data={}, data ) {
			let name = provider_data.name || '';
			let file = new Blob( [ data ], { type: 'csv' } );
			let filename = `billing_report_${ this.billingDate.year }${ this.billingDate.monthMM }_${ name }.csv`;
			if (navigator.msSaveOrOpenBlob) {
				navigator.msSaveOrOpenBlob(file, filename);
			} else {
				let a = document.createElement('a');
				let url = URL.createObjectURL(file);
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				}, 0);
			}
		},

		resolveDownloadBulkBilling: function( data ) {
			let blob = new Blob( [ data ], {type: 'zip' } );
			let filename = `billing_report_${ this.billingDate.year }${ this.billingDate.monthMM }.zip`;
            if ( typeof window.navigator.msSaveBlob !== 'undefined' ) {
                // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                window.navigator.msSaveBlob( blob, filename );
            } else {
                let URL = window.URL || window.webkitURL || window.mozURL || window.msURL;
                let downloadUrl = URL.createObjectURL( blob );
                if ( filename ) {
                    // use HTML5 a[download] attribute to specify filename
                    let a = document.createElement( 'a' );
                    // safari doesn't support this yet
                    if ( typeof a.download === 'undefined' ) {
                        window.location = downloadUrl;
                    } else {
                        a.href = downloadUrl;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                } else {
                    window.location = downloadUrl;
                }
            }
		},
		getProviders: function(callback) {
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpoint = `${BASE_ENGAGE_URL}v2/organization/${org_id}/providers_light`;
			const successTrigger = ['getProviders:success', 'hide:contentSpinner'];
			const errorTrigger = ['getProviders:fail', 'hide:contentSpinner'];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveGetProviders.bind(this,callback) );
		},

		resolveGetProviders: function( callback,data ) {
			const sorted_providers = _.sortBy(data, 'name');
			const bulkChangeProviders = [{'name': 'Choose Provider', 'id': -1}].concat(sorted_providers);
			this.setGlobalValue( 'bulkChangeProviders', bulkChangeProviders );
			if(callback){
				callback(bulkChangeProviders)
			}
		},

		getUnsaspendProviderDetail: function(provider, callback) {
			if(provider.id == -1){
				callback({
					numberOfPatient:0,
					billingName:null,
					providerName:null
				});
				return;
			}
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpoint = `${BASE_ENGAGE_URL}v1/ccm/organization/${org_id}/providers/${provider.id}/unsuspended_patient_count`;
			const successTrigger = ['getProviders:success', 'hide:contentSpinner'];
			const errorTrigger = ['getUnsuspendCount:fail', 'hide:contentSpinner'];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, (data) => {
				// console.log(data);
				callback({
					numberOfPatient:data,
					billingName:provider.billingProviderName?provider.billingProviderName:
						provider.name,
					providerName:provider.name});
			} );
		},


		getProviderTransitionDetail: function(provider, callback) {
			if(provider.id == -1){
				callback({
					numberOfPatient:0,
					billingName:null,
					providerName:null
				});
				return;
			}
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpoint = `${BASE_ENGAGE_URL}v1/ccm/organization/${org_id}/providers/${provider.id}/provider_transition_patient_count`;
			const successTrigger = ['getProviders:success', 'hide:contentSpinner'];
			const errorTrigger = ['getUnsuspendCount:fail', 'hide:contentSpinner'];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, (data) => {
				// console.log(data);
				callback({
					numberOfPatient:data,
					billingName:provider.billingProviderName?provider.billingProviderName:
						provider.name,
					providerName:provider.name});
			} );
		},

		getProviderDetail: function(provider, callback) {
			//v1/ccm/organization/{org_id}/providers/{provider_id}/patient_count
			if(provider.id == -1){
				callback({
					numberOfPatient:0,
					billingName:null,
					providerName:null
				});
				return;
			}
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpoint = `${BASE_ENGAGE_URL}v1/ccm/organization/${org_id}/providers/${provider.id}/patient_count`;
			const successTrigger = ['getProviders:success', 'hide:contentSpinner'];
			const errorTrigger = ['getProviders:fail', 'hide:contentSpinner'];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, (data) => {
				// console.log(data);
				callback({
					numberOfPatient:data,
					billingName:provider.billingProviderName?provider.billingProviderName:
						provider.name,
					providerName:provider.name});
			} );
		},
		bulkProviderUpdate: function(oldProviderId, newProviderId, callback){
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpoint = `${BASE_ENGAGE_URL}v1/ccm/organization/${org_id}/bulk_provider_update/${oldProviderId}/${newProviderId}`;
			const successTrigger = ['getProviders:success', 'hide:contentSpinner'];
			const errorTrigger = ['getProviders:fail', 'hide:contentSpinner'];

			channel.trigger( 'show:contentSpinner' );
			utils.put( endpoint, null, successTrigger, errorTrigger, null, true ,(data)=>{
				callback(data);
			});
		},
		bulkProviderSuspension: function(providerId, callback){
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpoint = `${BASE_ENGAGE_URL}v1/ccm/organization/${org_id}/bulk_provider_patients_suspend/${providerId}`;
			const successTrigger = ['getProviders:success', 'hide:contentSpinner'];
			const errorTrigger = ['bulkProviderSuspension:fail', 'hide:contentSpinner'];

			channel.trigger( 'show:contentSpinner' );
			utils.put( endpoint, null, successTrigger, errorTrigger, null, true ,(data)=>{
				callback(data);
			});
		},
		bulkProviderTransition: function(providerId, selectedDate,callback){
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpoint = `${BASE_ENGAGE_URL}v1/ccm/organization/${org_id}/bulk_provider_transition/${providerId}`;
			const successTrigger = ['getProviders:success', 'hide:contentSpinner'];
			const errorTrigger = ['bulkProviderSuspension:fail', 'hide:contentSpinner'];

			channel.trigger( 'show:contentSpinner' );
			utils.put( endpoint, {effective_from:selectedDate}, successTrigger, errorTrigger, null, true ,(data)=>{
				callback(data);
			});
		}

	}
}
