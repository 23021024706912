import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import triageRecordsCompositeView  from '../../../../templates/CCM/PatientList/triageRecordsCompositeView';
import TriageRecordsItemView from './TriageRecordsItemView';

// Internal Dependencies - models/collections
import { FaxRecordModel } from './models/FaxRecordModel';

export default Marionette.CompositeView.extend({

	childViewContainer: '.triage-records-records-container',

	className: 'row',

	childView: TriageRecordsItemView,

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection = new Backbone.Collection();
		this.controller = this.app.ccm_controller;
		this.controller.getPatientTriageRecords( this.model.get( 'id' ) );
		this.collection.loading = true;
		this.listenTo( this.channel, 'set:patientTriageRecords', this.setCollection );
		this.listenTo( this.collection, 'update', this.render );
	},

	getTemplate: function() {
		return triageRecordsCompositeView( this.collection );
	},

	setCollection: function() {
		this.collection.loading = false;
		let records = this.controller.patientTriageRecords.map( ( record ) => new FaxRecordModel( record ) );
		this.collection.renderNoResults = !(records && records.length);
		this.collection.add(records);
		!records.length && this.render();
	},

});
