import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';
import Highcharts from 'highcharts';

import { Utils } from './utils';

import ErrorModalView from './ErrorModalView';
import ConfirmationModalView from './ConfirmationModalView';
import UnreadNotificationModalView from "./UnreadNotificationModalView";
import SuccessModalView from "./SuccessModalView";

const channel = Backbone.Radio.channel('AppChannel');
const utils = new Utils();
const userCookie = 'user=';
const engageToken = 'engage_token=';

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

export const AppController = ( app ) => {
	const BASE_ENGAGE_URL = app.config.BASE_ENGAGE_URL;

	return {

		/**********************
		Global values
		**********************/

		// Value for determining whether user can view a page - this is set by various authentication methods.
		isAuthorized: false,

		showingError: false,

		hasLoggedIn: false,

		forcedLogout: false,

		currentApp: 'MAIN',

		subnavItem: 'Dashboard',

		engageUrl: BASE_ENGAGE_URL,

		showSuccess: false,

        files: [],

		// Takes a valueName and data and creates or replaces a variable with that name and data
		setGlobalValue: function ( valueName, data ) {
			this[valueName] = data;
			channel.trigger( 'set:' + valueName );
		},

		// Takes a collection and a single updated model and replaces the old instance of the model with the new
		// Model must have all properties that were returned with initial fetch of collection
		updateModelInCollection: function ( collection, updatedItem ) {
			collection.splice(_.findIndex(collection, 'id', updatedItem.id), 1, updatedItem);
		},

		getToken: function( whichModule ) {
			let cookies = document.cookie.split( '; ' );
			let token = cookies.filter( ( cookie ) => {
				let tokenRemoval = '';
				switch( whichModule ) {
					case 'engage':
						tokenRemoval = engageToken;
						break;
					default:
						tokenRemoval = engageToken;
				}
				return cookie.indexOf( tokenRemoval ) !== -1;
			});
			token = token && token.length && token[0].replace( engageToken, '' );
			return token;
		},

		/**********************
		Global methods
		**********************/

		// Method called from login page to authenticate user - takes the login model populated via LoginView
		authenticateUser: function( loginModel ) {
			// Collect the endpoint and body data from the model and set success and error triggers that will trigger
			// callbacks via the Backbone.Radio based on the result of the HTTP request
			let endpoint = BASE_ENGAGE_URL + 'v1/auth';
			let body = {
				username: loginModel.get('username'),
				password: loginModel.get('password')
			}
			let successTrigger = [ 'login:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'login:fail', 'toggle:globalSpinner' ];

			// Show spinner during HTTP request
			channel.trigger( 'toggle:globalSpinner' );
			// Call the 'post' method, passing the requisite data
			utils.post( endpoint, body, successTrigger, errorTrigger, null, null, this.checkEnvironment.bind(this));
		},

		checkEnvironment: function( data ) {
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			};
			const demoEnvCheckEndPoint = BASE_ENGAGE_URL+'v1/demoreset/demoenv/check';
			utils.get(demoEnvCheckEndPoint, resultTriggers, this.setUserData.bind(this, data));
		},

		setUserData( data, environment ) {
		    const userObject = Object.assign({},data,{demoEnvironment: environment.is_demo_env});
			let userData = JSON.stringify(userObject);

			document.cookie = 'user = ' + userData;
			this.getUserDataFromCookie();
		},

		getUserDataFromCookie() {
			if ( !this.hasLoggedIn ) {
				let cookies = document.cookie.split( '; ' );
				let user = cookies.filter( ( cookie ) => {
					return cookie.indexOf( userCookie ) !== -1;
				});
				user = user && user.length && user[0].replace( userCookie, '' );
				if ( user ) {
					let userData = JSON.parse( user );
					this.setGlobalValue( 'user', userData );
					this.initialEngageMethods();
				}
			}
		},

		// Method called to validate a user prior to navigating to a route within the AppRouter
		// If successful, the user will navigate to the route provided as an argument
		checkUserAuthentication: function ( routeInfo = { route: 'Dashboard', module: 'Main' }, routerLoginCheck = false ) {
			this.getUserDataFromCookie();
			let endpoint = BASE_ENGAGE_URL + 'v1/auth';
			let successTrigger = [ 'nav:' + routeInfo.module + routeInfo.route, 'nav:success', 'hide:contentSpinner' ];
			let errorTrigger = routerLoginCheck ? [ 'hide:contentSpinner' ] : [ 'logout:user', 'toggle:forcedLogout', 'hide:contentSpinner' ];
			let resultTriggers = {
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
            if ( this.user && this.user.changePassword && routeInfo.route !== 'ChangePassword' ) {
                app.router.navigate( '/utilities/change-password', true );
                this.resolveTriggers( successTrigger );
                return;
            }
			this.setGlobalValue( 'currentApp', routeInfo.module.toUpperCase() );
			this.setGlobalValue( 'subnavItem', routeInfo.route );
			utils.get( endpoint, resultTriggers, this.resolveAuthentication.bind( this, routeInfo, successTrigger ) );
		},

		resolveAuthentication: function( routeInfo, triggers, data ) {
			if ( this.checkAllowedModules( routeInfo, triggers, data ) && this.checkPermissions( routeInfo, triggers ) ) {
				this.resolveTriggers( triggers );
			} else {
				// If the user has insufficient permissions or doesn't have access to a module, reroute them to dashboard
				app.router.navigate( 'dashboard', true );
			}
		},

		resolveTriggers: function( triggers ) {
			if ( triggers && typeof triggers !== 'string' ) {
				triggers.forEach( ( trigger ) => {
					channel.trigger( trigger );
				});
			} else {
				channel.trigger( triggers );
			}
		},

		checkAllowedModules: function( routeInfo = { route: '', module: '' }, triggers, data ) {
			let route = routeInfo.route;
			let module = routeInfo.module;
			// check the module a user is attempting to navigate to; if that module doesn't exist in allowable modules
			// return false
			if ( !this.modules ) {
				app.listenTo( this.channel, 'set:modules', this.resolveAuthentication.bind( this, routeInfo, triggers, data ) );
                return true;
			}

			if ( module == 'Ccm' && ( this.modules && !this.modules.ccm ) ) {
				return false;
			}
			if ( module == 'Cpc' && ( this.modules && !this.modules.cpc ) ) {
				return false;
			}
			if ( module == 'MipsGroup' && ( this.modules && !this.modules.mipsGroup ) ) {
				return false;
			}
			if ( module == 'MipsProvider' && ( this.modules && !this.modules.mipsProvider ) ) {
				return false;
			}
			if ( module == 'Mips' && ( this.modules && !this.modules.mips ) ) {
				return false;
			}
			// the module has been passed, now the route is checked; if it is one of a number of protected routes,
			// it will be caught in the switch statement below and checked against the allowable modules; if it is
			// not one of the allowable modules, return false
			switch( route ) {
				case 'ErrorQueue':
					if ( this.modules && !this.modules.rapid ) {
						return false;
					}
					return true;
					break;
                case 'QualityMeasures':
                case 'QualityMeasureDiabetesPoorControl':
				case 'QualityMeasureAdvanceCarePlan':
                case 'QualityMeasurePneumococcalVaccination':
                case 'QualityMeasureFallRiskAssessment':
                case 'QualityMeasureFallCarePlan':
					if ( app.mips_controller.mipsExclusions.exclude_quality ) {
						return false;
					}
					return true;
					break;
                case 'ImprovementActivities':
                    if ( app.mips_controller.mipsExclusions.exclude_improvement ) {
                        return false;
                    }
                    return true;
                    break;
				default:
					return true;
			}
		},

		checkPermissions: function( routeInfo, triggers ) {
			if ( this.user && this.user.changePassword && routeInfo.route !== 'ChangePassword' ) {
                app.router.navigate( '/utilities/change-password', true );
                return false;
            } else {
				app.listenTo(this.channel, 'set:user', this.checkPermissions.bind( this, routeInfo, triggers ))
			}
			const messenger = this.user && this.user.messenger;
			let route = routeInfo.route;
			if ( messenger ) {
				// White-listed routes for messenger role
				if ( route !== 'Dashboard'
						&& route !== 'Messaging'
						&& route !== 'Archive'
						&& route !== 'Sent'
						&& route !== 'Notifications'
						&& route !== 'PatientList'
						&& route !== 'Enrolled'
						&& route !== 'Suspended'
						&& route !== 'PendingUnenroll'
						&& route !== 'ChangePassword'
						&& route !== 'Resources'
						&& route !== 'Support'
						&& route !== 'Unenrolled'
            && route !== 'ProviderApproval') {
					return false;
				}
			}
			return true;
		},

		resetChangePassword: function(token,password,callback) {
			let endpoint = BASE_ENGAGE_URL + 'v1/auth/resetPassword?token='+token;
			let body = {
				password
			}
			let successTrigger = [ 'triggerResetChangePassword:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'triggerResetChangePassword:failed', 'toggle:globalSpinner' ];
			channel.trigger( 'toggle:globalSpinner' );
			utils.post( endpoint, body, successTrigger, errorTrigger, null, null,(data)=>{
				callback(data);
			});
		},

		validateToken:function(token,callback) {
			let endpoint = BASE_ENGAGE_URL + 'v1/auth/validateToken';
			let body = {
				token
			}
			let successTrigger = [ 'triggerResetPassword:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'triggerValidToken:failed', 'toggle:globalSpinner' ];
			channel.trigger( 'toggle:globalSpinner' );
			utils.post( endpoint, body, successTrigger, errorTrigger, null, null,(data)=>{
				callback(data);
			});
		},

		resetForgottenPassword: function( forgotPasswordModel,callback ) {
			let endpoint = BASE_ENGAGE_URL + 'v2/auth/forgotPassword';
			let body = {
				email: forgotPasswordModel.get('email'),
			}
			let successTrigger = [ 'triggerResetPassword:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'triggerResetPassword:success', 'toggle:globalSpinner' ];
			channel.trigger( 'toggle:globalSpinner' );
			utils.post( endpoint, body, successTrigger, errorTrigger, null, null,(data)=>{
				callback(data);
			} );
		},

		// Method for forcing expiration of cookies
		removeCookie: function() {
			document.cookie = 'engage_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
			document.cookie = 'user=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
			this.setGlobalValue( 'hasLoggedIn', false );
		},

		// Method called to toggle the all encompassing global spinner
		toggleGlobalSpinner: function() {
			// let spinner = $('.global-spinner');
			// if ( spinner.is(':visible') ) {
			// 	spinner.hide();
			// } else {
			// 	spinner.show();
			// }
		},

		// Method called to toggle the spinner that covers only the main content area of the SPA
		toggleContentSpinner: function() {
			// let spinner = $('.content-spinner');
			// if ( spinner.is(':visible') ) {
			// 	spinner.hide();
			// } else {
			// 	spinner.show();
			// }
		},

		hideContentSpinner: function() {
			// let spinner = $('.content-spinner');
			// spinner.hide();
		},

		showContentSpinner: function() {
			// let spinner = $('.content-spinner');
			// spinner.show();
		},

		// Method for displaying a modal with error message that was passed via API
		displayError: function( error ) {
			if ( !this.showingError ) {
				let modalModel = new Backbone.Model({ error });
				let modalView = new ErrorModalView({ model: modalModel });
				modalView.render();
				$( 'body' ).append( modalView.el );
				$( '#modalError' ).openModal();
				this.showingError = true;
			}
		},

		displaySuccess: function(message) {

			if ( !this.showSuccess ) {
				let modalModel = new Backbone.Model({ message });
				let modalView = new SuccessModalView({ model: modalModel });
				modalView.render();
				$( 'body' ).append( modalView.el );
				$( '#modalError' ).openModal();
				this.showSuccess = true;
			}
		},

		unreadNotification: function(count){
			let modalModel = new Backbone.Model({ messageCount:count});
			let modalView = new UnreadNotificationModalView({ model: modalModel });
			modalView.render();
			$( 'body' ).append( modalView.el );
			$( '#modalConfirmation' ).openModal();
		},

		confirmationModal: function( message, callback, data ) {
			let modalModel = new Backbone.Model({ message, callback, data });
			let modalView = new ConfirmationModalView({ model: modalModel });
			modalView.render();
			$( 'body' ).append( modalView.el );
			$( '#modalConfirmation' ).openModal();
		},

		validateData: function( dataType, value, key ) {
			let isRequired = dataType.required;
			if ( !isRequired ) {
				return;
			}

			switch( dataType.type ) {
				case 'string':
					if ( !value ) {
						return { key, error: 'Required input' };
					}
					if ( value.length < 3 ) {
						return { key, error: 'Minimum length of 3 characters required' };
					}
					return typeof value != 'string' ? { key, error: 'Invalid input' } : null;
					break;
				case 'number':
					if ( !value ) {
						return { key, error: 'Required input' };
					}
					return typeof Number( value ) != 'number' ? { key, error: 'Input must be a number' } : null;
					break;
				case 'email':
					if ( !value ) {
						return { key, error: 'Required input' };
					}
					break;
				case 'phone':
					if ( !value ) {
						return { key, error: 'Required input' };
					}
					let phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
					return !value.match( phoneNum ) ? { key, error: 'Please provide a number in the following format: XXX-XXX-XXXX' } : null;
					break;
				case 'date':
					if ( !value ) {
						return { key, error: 'Required input' };
					}
					// First check for the pattern
				    if ( !/(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d/.test( value ) ) {
				        return { key, error: 'Please provide a date in the following format: MM/DD/YYYY' };
				    }

				    // Parse the date parts to integers
				    let parts = dateString.split("-");
				    let day = parseInt(parts[1], 10);
				    let month = parseInt(parts[0], 10);
				    let year = parseInt(parts[2], 10);

				    // Check the ranges of month and year
				    if (year < 1000 || year > 3000 || month == 0 || month > 12) {
				        return { key, error: 'Please provide a date in the following format: MM/DD/YYYY' };
				    }

				    let monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

				    // Adjust for leap years
				    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0) ) {
				        monthLength[1] = 29;
				    }

				    // Check the range of the day
				    return ( day > 0 && day <= monthLength[month - 1] ) ? null : { key, error: 'Date cannot be in the future.' };;
					break;
				case 'boolean':
					if ( !value ) {
						return { key, error: 'Required input' };
					}
					return typeof value != 'boolean' ? { key, error: 'Required field' } : null;
				default:
					break;
			}
		},

		formatFixedDateForUI: function( date, format = 'MM/DD/YYYY' ) {
			return moment.utc( date ).format( format );
		},

		formatLocalDateForUI: function( date, format = 'MM/DD/YYYY' ) {
			return moment.utc( date ).local().format( format );
		},

		formatLocalDateAndTimeForUI: function( date, format = 'MM/DD/YYYY h:mm a' ) {
			return moment.utc( date ).local().format( format );
		},

		formatDateForServer: function( date ) {
			return moment( date ).format( 'YYYY-MM-DD' );
		},

		toggleForcedLogout: function() {
			this.setGlobalValue( 'forcedLogout', !this.forcedLogout );
		},

		/**********************
		App Specific Methods
		**********************/

		initialEngageMethods: function(  ) {
			this.setGlobalValue( 'hasLoggedIn', true );
			this.getOrganizations();
		},

		setCurrentApp: function( app = 'MAIN' ) {
			this.setGlobalValue( 'currentApp', app );
		},

		setSubnavItem: function( subnavItem = 'Dashboard' ) {
			this.setGlobalValue( 'subnavItem', subnavItem );
		},

		getOrganizations: function() {
			let endpoint = BASE_ENGAGE_URL + 'v2/user/headers';
			let successTrigger = ['getOrganizations:success', 'hide:contentSpinner'];
			let errorTrigger = ['getOrganizations:fail', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveGetOrganizations.bind(this) );
		},

		resolveGetOrganizations: function( data ) {
			const admin = this.user.user;
			const messenger = this.user.messenger;
			this.setModules( data[0].modules );
			this.setGlobalValue( 'locations', data[0].locations );
			this.setGlobalValue('revenueDashboardType',data[0].revenueDashboardType);
			this.setGlobalValue( 'rapid_eval', data[0].rapidEval );
			this.setGlobalValue( 'selectedLocationId', admin || messenger ? -1 : data[0].locations[0].id );
			this.setGlobalValue( 'selectedProviderId', admin || messenger ? -1 : data[0].locations[0].providers[0].id );
			this.setGlobalValue( 'selectedOrganization', data[0] );
			this.setGlobalValue("integratedNotificationsEnabled",data[0].integratedNotificationsEnabled);
			// if ( this.user && ( this.user.user || this.user.messenger ) && this.selectedOrganization && this.selectedOrganization.id ) {
			this.getProviders( data[0].id );
			// } else {
			// 	this.setGlobalValue( 'providers', [data[0].locations[0].providers[0]] );
			// }
			this.getUnreadNotificationsCount(24);
		},

		setModules: function( modules ) {
			this.setGlobalValue( 'modules', {
				ccm: _.indexOf( modules, 'CCM' ) > -1,
				cpc: _.indexOf( modules, 'cpc' ) > -1,
				mipsGroup: _.indexOf( modules, 'MIPSGROUP' ) > -1,
				mipsProvider: _.indexOf( modules, 'MIPSPROV' ) > -1,
				mips: _.indexOf( modules, 'MIPS' ) > -1,
				patientMessaging: _.indexOf( modules, 'SM' ) > -1,
				rapid: _.indexOf( modules, 'RBAUTO' ) > -1,
				hideRevenue: _.indexOf( modules, 'HIDEREV' ) > -1,
			});
		},

		getProviders: function( selectedOrganizationId ) {
			let endpoint = BASE_ENGAGE_URL + 'v2/organization/' + selectedOrganizationId + '/providers_light';
			let successTrigger = ['getProviders:success', 'hide:contentSpinner'];
			let errorTrigger = ['getProviders:fail', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveGetProviders.bind(this) );
		},

		resolveGetProviders: function( data ) {
			const activeProviders = _.filter( data, ( provider ) => {
				return provider.active;
			});
			this.setGlobalValue( 'providers', activeProviders );
			this.setGlobalValue( 'AllProviders', data );
		},


		getUnreadNotificationsCount: function(olderThanHrs='') {
			if(this.integratedNotificationsEnabled){
				return;
			}
			let endpoint = BASE_ENGAGE_URL;
			if ( this.user && (this.user.messenger || this.user.user) ) {
				if ( this.selectedLocationId == -1 && this.selectedProviderId == -1 ) {
					endpoint += 'v1/ccm/notifications/organization/' + this.selectedOrganization.id + '/unreadcount?type=organization&id=' + this.selectedOrganization.id;
				} else if ( this.selectedProviderId == -1 ) {
					endpoint += 'v1/ccm/notifications/organization/' + this.selectedOrganization.id + '/unreadcount?type=location&id=' + this.selectedLocationId;
				} else {
					endpoint += 'v1/ccm/notifications/organization/' + this.selectedOrganization.id + '/unreadcount?type=provider&id=' + this.selectedProviderId;
				}
				if(olderThanHrs){
					endpoint = endpoint + "&olderThanHrs="+olderThanHrs
				}
			} else {
				endpoint += 'v1/ccm/notifications/provider/' + this.selectedProviderId + '/unreadcount';
				if(olderThanHrs){
					endpoint = endpoint + "?olderThanHrs="+olderThanHrs
				}
			}

			let successTrigger = [ 'getMessages:success', 'hide:contentSpinner' ];
			let errorTrigger = ['getMessages:fail', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, (data)=>{
				if(olderThanHrs){
					if(data != '0') {
						this.unreadNotification(data);
					}
				}
				this.resolveGetUnreadNotificationsCount(data);
			} );
		},

		resolveGetUnreadNotificationsCount: function( data ) {
			this.setGlobalValue( 'unreadNotifications', data );
		},

		updateLocation: function( locationId ) {
			let providers = [];
			_.each(this.locations, (location) => {
				if ( location.id == locationId || locationId == -1 ) {
					providers = providers.concat( location.providers )
				}
			})
			this.setGlobalValue( 'selectedLocationId', locationId );
			this.setGlobalValue( 'selectedProviderId', -1 );
			this.setGlobalValue( 'providers', providers );
			this.updateFilterMethods();
		},

		updateProvider: function( providerId ) {
			this.setGlobalValue( 'selectedProviderId', providerId );
			this.updateFilterMethods();
		},

		updateFilterMethods: function() {
			this.getUnreadNotificationsCount();
		},

		getContacts: function( providerId, showSpinner=true ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/message/provider/' + providerId + '/contacts';
			let successTrigger = [ 'getContacts:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'falied:api', 'getContacts:fail', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			showSpinner && channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveContacts.bind( this ) );
		},

		resolveContacts: function( data ) {
			data = data.map( ( contact ) => {
				contact.birth_date = moment( contact.birth_date ).format( 'MM/DD/YYYY' );
				return contact;
			});
			this.setGlobalValue( 'contacts', data );
		},

		postRapidReview: function(data,callback){
			const endpoint = `${ BASE_ENGAGE_URL }v1/organization/${this.selectedOrganization.id}/rapidreview`;
			const successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			channel.trigger( 'show:contentSpinner' );
			utils.post( endpoint, Object.assign({},data,{organization_id:this.selectedOrganization.id}),
				successTrigger, errorTrigger, null, true, (result)=>{
				callback();
			});
		},
		putRapidReview: function(data){
			const endpoint = `${ BASE_ENGAGE_URL }v1/organization/${this.selectedOrganization.id}/rapidreview/${data.id}`;
			const successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			channel.trigger( 'show:contentSpinner' );
			utils.put( endpoint, data, successTrigger, errorTrigger, null, true, (result)=>{
				//do nothing
				this.displaySuccess("Insurance configuration submitted successfully.")
			});
		},

		saveFlatFileInsurances: function(orgId,payload){
			const endpoint = `${ BASE_ENGAGE_URL }v1/rincon/insurances/flatfileexcludenames/organizations/${orgId}`
			const successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			$('.content-spinner').show();
			utils.post( endpoint, payload, successTrigger, errorTrigger, null, true,(data) => {
				this.getInsurances();
			} );
		},

		saveIntegratedInsurances: function(orgId,payload) {
			const endpoint = `${ BASE_ENGAGE_URL }v1/rincon/insurances/organization/${orgId}`
			const successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			$('.content-spinner').show();
			utils.put( endpoint, payload, successTrigger, errorTrigger, null, true,(data) => {
				this.getInsurances();
			} );
		},

		getInsurances: function(){
			//rincon API
			const rinConEndpoint = `${ BASE_ENGAGE_URL }v1/organization/${this.selectedOrganization.id}/rincon`;
			const endpoint = `${ BASE_ENGAGE_URL }v1/organization/${this.selectedOrganization.id}/insuranceplans`;
			const successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			$('.content-spinner').show();
			utils.get( rinConEndpoint, resultTriggers, (data) => {
				this.resolveGetInsurances(data);
			} );

		},

		resolveGetInsurances: function(userData){
			userData.source = null;
			
				const endpoint = `${ BASE_ENGAGE_URL }v1/rincon/insurances/organization/${userData.id}`;	
				const successTrigger = 'hide:contentSpinner' ;
				const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
				const resultTriggers = {
					successTrigger,
					errorTrigger
				};
				utils.get( endpoint, resultTriggers, (dataList)=>{
					this.setGlobalValue( 'insurancesAndRapidReview', {"integratedIncludes":dataList,
						"userData":userData});
						$('.content-spinner').hide();	
				});

		},

        getFiles: function() {
            const endpoint = `${ BASE_ENGAGE_URL }v1/uploads/`;
            const successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
            const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
            const resultTriggers = {
                successTrigger,
                errorTrigger
            };

            channel.trigger( 'show:contentSpinner' );
            utils.get( endpoint, resultTriggers, this.resolveGetFiles.bind( this ) );
        },

        resolveGetFiles: function( data = [] ) {
            data = data.sort( ( a, b ) => a.uploaded_date < b.uploaded_date );
            let files = data.map( ( fileObj ) => {
                return Object.assign( {}, fileObj, {
                    upload_date: app.controller.formatLocalDateAndTimeForUI( fileObj.uploaded_date ),
                });
            });
            this.setGlobalValue( 'files', files );
        },

		getAllOrganizations: function() {
				const endpoint = `${ BASE_ENGAGE_URL }v1/organizations/_search?name=`;
				const successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
				const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
				const resultTriggers = {
					successTrigger,
					errorTrigger
				};
	
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveAllOrganiztions.bind( this ) );
		},
		resolveAllOrganiztions: function( data = [] ) {
            this.setGlobalValue( 'ActiveOrganizations', data );
        },

		switchOrganization: function(orgId) {
			//https://engage-api.dev.aws.chartspan.com/v1/user/organization/<SELECTED_ORG_ID>
			const endpoint = `${ BASE_ENGAGE_URL }v1/user/organization/`+orgId;
				const successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
				const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
				const resultTriggers = {
					successTrigger,
					errorTrigger
				};
	
				channel.trigger( 'show:contentSpinner' );
				utils.put( endpoint, {}, successTrigger, errorTrigger, null, true, (result)=>{
					//do nothing
					window.location.reload();
				});
		},

        uploadFile: function( fileObj, file, notes ) {
            const endpoint = `${BASE_ENGAGE_URL}v1/uploads/`;
            const successTrigger = ['headers:success', 'hide:contentSpinner'];
            const errorTrigger = ['failed:api', 'hide:contentSpinner' ];

            channel.trigger( 'show:contentSpinner' );
            utils.filePost( endpoint, fileObj, successTrigger, errorTrigger, null, true, this.resolveUploadFile.bind( this, file, notes ) );
        },

        resolveUploadFile: function( file, notes, data ) {
            if ( file ) {
                let files = this.files.map( ( file ) => {
                    return Object.assign( {}, file );
                });
                let newFile = Object.assign( {}, data, {
                    upload_date: moment( file.lastModified ).format( 'MM/DD/YYYY h:mm   a' ),
                    file_id: data,
                    uploaded_by: app.controller.user.name,
	                uploaded_by_id: app.controller.user.id,
                    file_name: file.name,
                    file_notes: notes,
                });
                files.unshift( newFile );
                this.setGlobalValue( 'files', files );
                channel.trigger( 'upload:success' );
            }
        },

        deleteFile: function( file ) {
            const endpoint = `${ BASE_ENGAGE_URL }v1/uploads/delete/${ file.id }/`;
            const successTrigger = ['headers:success', 'hide:contentSpinner'];
            const errorTrigger = ['failed:api', 'hide:contentSpinner' ];


            channel.trigger( 'show:contentSpinner' );
            utils.post( endpoint, null, successTrigger, errorTrigger, null, true, this.resolveDeleteFile.bind( this, file.id ) );
        },

        resolveDeleteFile: function( fileId, data ) {
            if ( data ) {
                let files = this.files.map( ( file ) => Object.assign( {}, file ) );
                files = files.filter( ( file ) => file.file_id !== fileId );
                this.setGlobalValue( 'files', files );
            }
        },

        downloadFile: function( fileId ) {
            let endpoint = `${ BASE_ENGAGE_URL }v1/uploads/url/${ fileId }/`;
            let successTrigger = ['headers:success', 'hide:contentSpinner'];
            let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
            let resultTriggers = {
                successTrigger,
                errorTrigger
            };

            channel.trigger( 'show:contentSpinner' );
            utils.get( endpoint, resultTriggers, this.resolveDownloadFile.bind( this ) );
        },

        resolveDownloadFile: function( data ) {
            if ( data.file_url ) {
                window.open(data.file_url)
            } else {
                // The ErrorModalView expects the error formatted as a standard http error
                const mockError = {
					status:400,
                    statusText: 'Document unavailable',
                    responseJSON: { message: 'There are currently no documents matching the one you\'ve selected.' },
                };
                channel.trigger( 'failed:api', mockError );
            }
        },

        updateNote: function( file_id, newNote ) {
            console.log( file_id, newNote );
        },

	}
}
