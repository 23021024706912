// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import supportContainerView  from '../../../../templates/Utilities/Support/supportContainerView';
import SupportContactView from './SupportContactView';
import SupportHelpDeskView from './SupportHelpDeskView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	regions: {
		'supportContactView': '.contact-container-view',
		'supportHelpDeskView': '.help-desk-container-view'
	},

	getTemplate: function() {
		return supportContainerView( this.model );
	},

	onShow: function() {
		this.showChildView( 'supportContactView', new SupportContactView() );
		this.showChildView( 'supportHelpDeskView', new SupportHelpDeskView() );
	}
});
