import Backbone from 'backbone';
import _ from 'underscore';

import { SupportModel } from './SupportModel';

export const SupportCollection = Backbone.Collection.extend({

	model: SupportModel,
	
	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

});