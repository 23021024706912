// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  errorContainerView  from '../../../../../templates/CCM/RapidBill/Error/errorContainerView';
import ErrorCompositeView from './ErrorCompositeView';
import PatientTotalsView from '../../PatientList/PatientTotalsView';
import ManualBillingModalView from './ManualBillingModalView';
import {ErrorCollection} from "./models/ErrorCollection";
import BillingSummaryModalView from "./BillingSummaryModalView";

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	events: {
		'click #submitBilling': 'launchBillingSummary',
		'click #manualBilling': 'launchManualBilling',
	},

	regions: {
		'rapidBillContainer': '.rapid-bill-container',
		'patientTotals': '.patient-totals-container'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.controller = this.app.controller;
		this.collection = new ErrorCollection();
		this.listenTo( this.channel, 'set:rapidBilling', this.setCollection );
		this.listenTo( this.channel, 'update', this.render );
	},

	getTemplate: function() {
		return errorContainerView( this.collection );
	},

	onShow: function() {
		this.showChildView( 'patientTotals', new PatientTotalsView( { sort: false, collection: this.collection } ) );
		this.showChildView( 'rapidBillContainer', new ErrorCompositeView( {collection: this.collection} ) );
	},

	setCollection: function( collection ) {
		this.collection = collection;
	},

	launchManualBilling: function() {
		let modalView = new ManualBillingModalView({ collection: this.collection });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalManualBilling' ).openModal();
	},
	launchBillingSummary: function(){
		let modalView = new BillingSummaryModalView({ collection: this.collection });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalSummary' ).openModal();

	}

});
