// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import errorModalView  from '../templates/errorModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'click .modal-close': 'closeModal'
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
	},

	getTemplate: function() {
		return errorModalView( this.model );
	},

	onShow: function() {
		$( '.spinner' ).hide();
	},

	onDestroy: function () {
		this.app.controller.showingError = false;
	},

	closeModal: function( e ) {
		e && e.preventDefault();
		$( '#modalError' ).closeModal();
		this.destroy();
	}

});
