import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import suspendPatientModalView  from '../../../../templates/CCM/PatientList/suspendPatientModalView';

export default Marionette.LayoutView.extend({
  events: {
    'click .modal-close': 'closeModal',
    'click .enroll-patient': 'suspendPatient',
    'change #suspend_reason_id': 'checkSelectedReason'
  },

  initialize: function( options ) {
    this.callback = options.callback;
    this.channel = Backbone.Radio.channel( 'AppChannel' );
    this.app = this.channel.request( 'app' );
    this.listenTo( this.channel, 'close:modal', this.closeModal );
    this.listenTo( this.channel, 'remove:errors', this.removeErrors );
  },

  getTemplate: function() {
    return suspendPatientModalView( this.model );
  },

  closeModal: function( e ) {
    e && e.preventDefault();
    $( '#modalReject' ).closeModal();
    this.destroy();
  },

  suspendPatient: function( e ) {
    e.preventDefault();
    const selectedReason = parseInt($( '#suspend_reason_id' ).val());
	const notes = $( '#suspension_notes' ).val().trim();
    $( '.id_validation' ).css({display: 'none'});
	$( '.note_validation' ).css({display: 'none'});
    $( '#suspend_reason_id' ).hasClass( 'invalid' ) && $( '#suspend_reason_id' ).removeClass( 'invalid' );
    $( '#suspension_notes' ).hasClass( 'invalid' ) && $( '#suspension_notes' ).removeClass( 'invalid' );
    if(!(selectedReason > -1)) {
        $('#suspend_reason_id').addClass('invalid');
	    $('.id_validation').css({display: 'inline-block'});
    }else if(selectedReason == 8 && !notes){
	    $('#suspension_notes').addClass('invalid');
	    $('.note_validation').css({display: 'inline-block'});
    }else{
      this.app.ccm_controller.suspendPatientWithReason( this.model.get( 'patient_id' ) , selectedReason, notes,this.callback);
      $( '#modalReject' ).closeModal();
      this.destroy();
    }


  },
  checkSelectedReason: function (e) {
    e.preventDefault();
    const selectedReason = $( '#suspend_reason_id' ).val();
    if(selectedReason == -1){
      $( '#suspend_reason_id' ).addClass( 'invalid' );
    }else{
      $( '#suspend_reason_id' ).hasClass( 'invalid' ) && $( '#suspend_reason_id' ).removeClass( 'invalid' )
    }
  }

});
