// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import patientListItemView  from '../../../../../templates/PatientEnrollment/PatientApproval/PatientApprovalComponents/patientListItemView';
import EditPatientInformationModalView from './EditPatientInformationModalView';

// Internal Dependencies - models/collections


export default Marionette.ItemView.extend({

	className: 'table-row clearfix',

	events: {
		'click .more': 'expandContent',
		'click .less': 'expandContent',
        'click .select-patient': 'selectPatient',
        'click .edit-patient-information': 'launchEditPatientModal',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.listenTo( this.model, 'change:selected', this.render );
	},

	getTemplate: function() {
		return patientListItemView( this.model );
	},

	expandContent: function() {
		this.$( '.more' ).toggleClass( 'active' );
		this.$( '.less' ).toggleClass( 'active' );
		this.$( '.patient-list-expanded-data' ).slideToggle( 200 );
	},

    selectPatient: function() {
	    this.model.set( 'selected', !this.model.get( 'selected' ) );
    },

    launchEditPatientModal: function() {
        let modalView = new EditPatientInformationModalView({ model: this.model });
        modalView.render();
        $( 'body' ).append( modalView.el );
        $( '#modalEditPatient' ).openModal();
        this.channel.trigger( 'trigger:show' );
    },

});
