// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import  confirmationModalView  from '../templates/confirmationModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'click .modal-close': 'preventNav',
		'click .confirm': 'confirmAction'
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'close:modal', this.destroy );
		this.model.set( 'user', this.app.controller.user );
	},

	getTemplate: function() {
		return confirmationModalView( this.model );
	},

	preventNav: function( e ) {
		e.preventDefault();
		this.destroy();
	},

	confirmAction: function() {
		$('.confirm').html('Confirming');
		let callback = this.model.get( 'callback' );
		let data = this.model.get( 'data' );
		callback && callback( data );
		this.destroy();
	},

});
