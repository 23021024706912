import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  declineProviderApprovalModalView  from '../../../../templates/CCM/ProviderApproval/declineProviderApprovalModalView';

export default Marionette.LayoutView.extend({
  events: {
    'click .modal-close': 'closeModal',
    'click .decline-request': 'declineRequest'
  },

  initialize: function( options ) {
    this.channel = Backbone.Radio.channel( 'AppChannel' );
    this.app = this.channel.request( 'app' );
    this.listenTo( this.channel, 'close:modal', this.closeModal );
    this.listenTo( this.channel, 'remove:errors', this.removeErrors );
  },

  getTemplate: function() {
    return declineProviderApprovalModalView( this.model );
  },

  closeModal: function( e ) {
    e && e.preventDefault();
    $( '#modalReject' ).closeModal();
    this.destroy();
  },

  declineRequest: function( e ) {
    //-----
    e.preventDefault();
    // const selectedReason = parseInt($( '#unenroll_reason_id' ).val());
    const notes = $( '#decline_reason' ).val().trim();
    // $( '.id_validation' ).css({display: 'none'});
    $( '.note_validation' ).css({display: 'none'});
    // $( '#unenroll_reason_id' ).hasClass( 'invalid' ) && $( '#unenroll_reason_id' ).removeClass( 'invalid' );
    $( '#decline_reason' ).hasClass( 'invalid' ) && $( '#decline_reason' ).removeClass( 'invalid' );
    // if(!(selectedReason > -1)) {
    //   $('#unenroll_reason_id').addClass('invalid');
    //   $('.id_validation').css({display: 'inline-block'});
    // }else
    if(!notes){
      $('#decline_reason').addClass('invalid');
      $('.note_validation').css({display: 'inline-block'});
    }else{
      //TODO - call API to decline
      this.app.ccm_controller.declineProviderChangeRequest( this.model.get( 'request_id' ) , notes);
      $( '#modalReject' ).closeModal();
      this.destroy();
    }


  },
  // checkSelectedReason: function (e) {
  //   e.preventDefault();
  //   const selectedReason = $( '#unenroll_reason_id' ).val();
  //   if(selectedReason == -1){
  //     $( '#unenroll_reason_id' ).addClass( 'invalid' );
  //   }else{
  //     $( '#unenroll_reason_id' ).hasClass( 'invalid' ) && $( '#unenroll_reason_id' ).removeClass( 'invalid' )
  //   }
  // }

});
