// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import resourcesContainerView  from '../../../../templates/Utilities/Resources/resourcesContainerView';
import ResourcesCompositeView from './ResourcesCompositeView';


// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	childView: ResourcesCompositeView,

	regions: {
		resources: '.resources-container'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new Backbone.Model({ 'rb_auto': this.app.controller.modules.rapid });
	},

	getTemplate: function() {
		return resourcesContainerView();
	},

	onShow: function() {
		this.showChildView( 'resources', new ResourcesCompositeView( { model: this.model } ) );
		$('.collapsible').collapsible();
		$('.materialboxed').materialbox();
	}

});
