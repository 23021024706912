// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import improvementActivitiesContainerView  from '../../../../templates/MIPS/ImprovementActivities/improvementActivitiesContainerView';
import ActivitySelectContainer from './ActivitySelectContainer';
import OverviewContainerView from './OverviewContainerView';
import ImprovementActivitiesSettingsModal from './ImprovementActivitiesSettingsModal';

// Internal Dependencies - models/collections
import { ImprovementActivitiesModel } from './models/ImprovementActivitiesModel';

export default Marionette.LayoutView.extend({

    regions: {
        'cardContainer': '.card-container',
        'overview': '.overview',
    },

    events: {
        'click .open-improvement-activities-settings': 'openImprovementActivitiesSettings',
    },

    initialize: function( options ) {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.model = new ImprovementActivitiesModel({
            criteriaSelections: this.app.mips_controller.criteriaSelections,
            measureData: this.app.mips_controller.measureData,
            selectedActivities: [],
            IASettings: this.app.mips_controller.IASettings,
        });
        this.listenTo( this.channel, 'set:criteriaSelections', this.setCriteriaSelections.bind( this ) );
        this.listenTo( this.channel, 'set:measureData', this.setMeasureData.bind( this ) );
        this.listenTo( this.channel, 'set:IASettings', this.setIASettings.bind( this ) );
        this.model.getCriteriaSelections( options.measure );
        this.model.getIAValues();
        this.model.getIASettings();
    },

    getTemplate: function() {
        return improvementActivitiesContainerView();
    },

    onShow: function() {
        $('.content-container').addClass('widen');
        this.showChildView( 'cardContainer', new ActivitySelectContainer({ model: this.model }) );
    },

    setCriteriaSelections: function() {
        const criteriaSelections = this.app.mips_controller.criteriaSelections;
        let selectedActivities = this.model.get( 'selectedActivities' );
        if ( selectedActivities && criteriaSelections.length ) {
            selectedActivities = this.parseNeededFieldsFromCriteriaSelections( criteriaSelections, selectedActivities );
        }
        this.model.set({
            criteriaSelections,
            choices: criteriaSelections,
            selectedActivities,
        });
    },

    setMeasureData: function() {
        let measureData = this.app.mips_controller.measureData;
        let selectedActivities = [];
        if ( measureData.historical_data && measureData.historical_data.length ) {
            selectedActivities = measureData.historical_data;
        }
        if ( this.model.get( 'criteriaSelections' ).length && selectedActivities.length ) {
            const criteriaSelections = this.model.get( 'criteriaSelections' );
            selectedActivities = this.parseNeededFieldsFromCriteriaSelections( criteriaSelections, selectedActivities );
        }
        this.model.set({
            measureData,
            selectedActivities,
        });
        this.showChildView( 'overview', new OverviewContainerView({ model: this.model }) );
    },

    setIASettings: function() {
        this.model.set( 'IASettings', this.app.mips_controller.IASettings );
    },

    parseNeededFieldsFromCriteriaSelections: function( criteriaSelections, selectedActivities ) {
        return selectedActivities.filter( ( activity ) => {
            if ( !activity.numerator ) {
                return;
            }
            criteriaSelections.forEach( ( criterium ) => {
                if ( activity.measure_id === criterium.id ) {
                    activity.additional_info_url = criterium.additional_info_url;
                    activity.title = criterium.title;
                    activity.possible_points = criterium.possible_points;
                }
            });
            return activity;
        });
    },

    openImprovementActivitiesSettings: function() {
        const modalView = new ImprovementActivitiesSettingsModal( { model: this.model } );
        modalView.render();
        $( 'body' ).append( modalView.el );
        $( '#improvementActivitiesSettingsModal' ).openModal();
    },

    onDestroy: function() {
        $('.content-container').removeClass('widen');
    },

});
