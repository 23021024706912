// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import navbarLinksView from '../../../templates/navbarLinksView';

// Internal Dependencies - models/collections
import { NavbarModel } from './models/NavbarModel';

export default Marionette.ItemView.extend({

	className: 'nav-options',

	tagName: 'ul',

	events: {
		'click .notifications-link': 'linkNotifications',
		'click .messaging-link': 'linkMessaging'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new NavbarModel({ app: this.app, channel: this.channel });
		this.listenTo( this.model, 'change', this.render );
	},

	getTemplate: function() {
		return navbarLinksView( this.model );
	},

	linkNotifications: function() {
		const controller = this.app.controller;
		controller.setCurrentApp( 'MAIN' );
		this.channel.trigger( 'subnavItem:changedLink', 'Notifications' );
	},

	linkMessaging: function() {
		const controller = this.app.controller;
		controller.setCurrentApp( 'MAIN' );
		this.channel.trigger( 'subnavItem:changedLink', 'Messaging' );
	}

});
