import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';

import { Utils } from '../../utils';

import ErrorModalView from '../../ErrorModalView';

const channel = Backbone.Radio.channel('AppChannel');
const utils = new Utils();
const userCookie = 'user=';

const inbox = 1, outbox = 0;
const today = new Date();
const current_month = today.getMonth() + 1;
const current_year = today.getFullYear();

export const MIPS_Controller = ( app ) => {

	const BASE_ENGAGE_URL = app.config.BASE_ENGAGE_URL;

	return {

		/**********************
		Global values
		**********************/

		// measureData: {
		// 	measure: '',
		// 	numerator: 0,
		// 	denominator: 0,
		// 	score_threshold: '',
		// 	historical_data: [],
		// },

		criteriaSelections: [],

		measureMetaData: {
		    title: '',
            name: '',
            description: '',
            numeratorText: '',
            denominatorText: '',
            numeratorInputText: '',
            denominatorInputText: '',
            additional_info_url: '',
        },

        secondaryMeasureMetaData: {
            title: '',
            name: '',
            description: '',
            numeratorText: '',
            denominatorText: '',
            numeratorInputText: '',
            denominatorInputText: '',
            additional_info_url: '',
        },

		measureData: {
			score: 0,
			numerator: 0,
			denominator: 0,
			score_raw: 0,
			score_threshold: 'FAIL',
			points_available: 0,
			points_earned: 0,
			historical_data: [],
		},

        secondaryMeasureData: {
            score: '',
            criteria: '',
            score_criteria_met: 0,
            score_criteria_total: 0,
            score_points_achieved: 0,
            score_points_available: 0,
            score_threshold: '',
            historical_data: [],
        },

		improvementActivities: [],

		mipsExclusions: {},

        IASettings: {},

		// Takes a valueName and data and creates or replaces a variable with that name and data
		setGlobalValue: function ( valueName, data ) {
			this[ valueName ] = data;
			channel.trigger( 'set:' + valueName );
		},

		// Takes a collection and a single updated model and replaces the old instance of the model with the new
		// Model must have all properties that were returned with initial fetch of collection
		updateModelInCollection: function ( collection, updatedItem ) {
			collection.splice(_.findIndex(collection, 'id', updatedItem.id), 1, updatedItem);
		},

        clearValue: function( value ) {
            this[ value ] = null;
        },

		addQueryParams: function( endpoint ) {
			const joiner = _.indexOf( endpoint, '?' ) > -1 ? '&' : '?';
			endpoint += `${joiner}provider=true`;
			const controller = app.controller;
			if ( controller.selectedProviderId > -1 ) {
				endpoint += `&provider_id=${controller.selectedProviderId}`
			} else if ( controller.selectedLocationId > -1 ) {
				endpoint += `&location_id=${controller.selectedLocationId}`
			}
			return endpoint;
		},

		/**********************
		MIPS Specific Methods
		**********************/

		triggerProviderModal: function() {
			const errorObj = {
				statusText: 'No provider selected',
				responseJSON: {
					message: 'Please select a provider to save MIPS data.'
				}
			};
			app.controller.displayError( errorObj );
		},

		getMipsSettings: function() {
            const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${BASE_ENGAGE_URL}v1/mips/${ org_id }/settings`;
            if ( app.controller && app.controller.currentApp === 'MIPSPROVIDER' ) {
				endpoint = this.addQueryParams( endpoint );
			}
            const successTrigger = [ 'hide:contentSpinner' ];
            const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
            const resultTriggers = {
                successTrigger,
                errorTrigger
            };
            channel.trigger( 'show:contentSpinner' );
            utils.get( endpoint, resultTriggers, this.resolveGetMipsSettings.bind( this ) );
        },

        resolveGetMipsSettings: function( data ) {
            const mipsExclusions = data;
            this.setGlobalValue( 'mipsExclusions', mipsExclusions );
        },

		setMipsSettings: function( exclude_quality, exclude_improvement ) {
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            const endpoint = `${BASE_ENGAGE_URL}v1/mips/${ org_id }/settings`;
			const successTrigger = [ 'hide:contentSpinner' ];
            const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
            const body = {
                exclude_quality,
                exclude_improvement,
            };
            channel.trigger( 'show:contentSpinner' );
            utils.post( endpoint, body, successTrigger, errorTrigger, null, true, this.resolveSetMipsSettings.bind(this) );
        },

		resolveSetMipsSettings: function( data ) {
            const mipsExclusions = data;
            this.setGlobalValue( 'mipsExclusions', mipsExclusions )
		},

        checkDateRange: function( startMonth, endMonth ) {
            if ( !startMonth || !endMonth ) {
                const dateSelectionError = {
                    statusText: 'Bad Date Selection',
                    responseJSON: { message: 'You must select a start and end month to apply a date range.' },
                };
                channel.trigger( 'failed:api', dateSelectionError );
                return false
            }
            if ( Number(startMonth) > Number(endMonth) ) {
                const dateSelectionError = {
                    statusText: 'Bad Date Range',
                    responseJSON: { message: 'The start month must be earlier, or equal to, the end month.' },
                };
                channel.trigger( 'failed:api', dateSelectionError );
                return false
            }
            return true
        },

		getDashboardData: function( dateRange, startMonth = 1, endMonth = current_month, year = current_year ) {
		    let proceed = true;
            if ( dateRange ) {
                proceed = this.checkDateRange( startMonth, endMonth );
            }
            if ( proceed ) {
                const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
                let endpoint = `${BASE_ENGAGE_URL}v1/mips/${org_id}/dashboard/main?start_month=${ startMonth }&end_month=${ endMonth }&year=${ year }`;
	            if ( app.controller && app.controller.currentApp === 'MIPSPROVIDER' ) {
		            endpoint = this.addQueryParams( endpoint );
	            }
                const successTrigger = [ 'hide:contentSpinner' ];
                const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
                const resultTriggers = {
                    successTrigger,
                    errorTrigger
                };
                channel.trigger( 'show:contentSpinner' );
                utils.get( endpoint, resultTriggers, this.resolveGetDashboardData.bind( this ) );
            }
        },

        resolveGetDashboardData: function( data ) {
		    const quality = data.quality;
		    const advancingCare = data.advancing_care;
		    const clinical_improvement = data.clinical_improvement;
            const dashboardTotals = {
                totalCompositeScore: parseFloat(data.score).toFixed(0),
                score_threshold: data.score_threshold,
            };
            const dashboardQuality = {
                overallScore: parseFloat(quality.overall_score).toFixed(0),
                score_threshold: quality.score_threshold,
                criteriaComplete: quality.complete_criteria,
                totalCriteria: quality.total_criteria,
                compositeScore: parseFloat(quality.composite_score).toFixed(0),
                compositePercent: quality.composite_available,
            };
            const dashboardAdvancingCare = {
                overallScore: parseFloat(advancingCare.overall_score).toFixed(0),
                score_threshold: advancingCare.score_threshold,
                criteriaComplete: advancingCare.complete_criteria,
                totalCriteria: advancingCare.total_criteria,
                compositeScore: parseFloat(advancingCare.composite_score).toFixed(0),
                compositePercent: advancingCare.composite_available,
            };
            const dashboardClinicalImprovement= {
                overallScore: parseFloat(clinical_improvement.overall_score).toFixed(0),
                score_threshold: clinical_improvement.score_threshold,
                criteriaComplete: clinical_improvement.complete_criteria,
                totalCriteria: clinical_improvement.total_criteria,
                compositeScore: parseFloat(clinical_improvement.composite_score).toFixed(0),
                compositePercent: clinical_improvement.composite_available,
            };
            this.setGlobalValue( 'dashboardTotals', dashboardTotals );
            this.setGlobalValue( 'dashboardQuality', dashboardQuality );
            this.setGlobalValue( 'dashboardAdvancingCare', dashboardAdvancingCare );
            this.setGlobalValue( 'dashboardClinicalImprovement', dashboardClinicalImprovement );
        },

        getAdvancingCareSummary: function( dateRange, startMonth = 1, endMonth = current_month, year = current_year ) {
		    let proceed = true;
		    if ( dateRange ) {
		        proceed = this.checkDateRange( startMonth, endMonth );
            }
            if ( proceed ) {
                const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
                let endpoint = `${BASE_ENGAGE_URL}v1/mips/${org_id}/dashboard/advancing_care?start_month=${ startMonth }&end_month=${ endMonth }&year=${ year }`;
	            if ( app.controller && app.controller.currentApp === 'MIPSPROVIDER' ) {
		            endpoint = this.addQueryParams( endpoint );
	            }
                const successTrigger = [ 'hide:contentSpinner' ];
                const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
                const resultTriggers = {
                    successTrigger,
                    errorTrigger
                };
                channel.trigger( 'show:contentSpinner' );
                utils.get( endpoint, resultTriggers, this.resolveGetAdvancingCareSummary.bind( this ) );
            }
        },

        resolveGetAdvancingCareSummary: function( data ) {
		    const bonusScore = data.bonus_score;
		    const baseScore = data.base_score;
		    const performanceScore = data.performance_score;
		    const criteria = performanceScore.criteria;
            const advancingSummaryTotals = {
                totalAdvancingScore: parseFloat(data.overall_score).toFixed(0),
                color: data.score_threshold,
            };
            const advancingSummaryBaseScore = {
                pointsAchieved: baseScore.points_earned,
                pointsAvailable: baseScore.points_available,
                color: baseScore.score_threshold,
                criteriaComplete: baseScore.criteria_complete,
                totalCriteria: baseScore.criteria_available,
            };
            const advancingSummaryBonusScore = {
                criteriaComplete: bonusScore.criteria_complete,
                totalCriteria: bonusScore.criteria_available,
                healthDataRegistryComplete: bonusScore.health_data_registry_complete,
                improvementComplete: bonusScore.improvement_complete,
            };
            const advancingSummaryPerformanceScore = {
                criteriaComplete: performanceScore.criteria_complete,
                totalCriteria: performanceScore.criteria_available,
                pointsAchieved: performanceScore.points_earned,
                pointsAvailable: performanceScore.points_available,
                color: performanceScore.score_threshold,
            };
            const advancingSummaryPerformanceCriteria = {
                patientAccessPoints: criteria.provide_patient_access.points_earned,
                patientAccessColor: criteria.provide_patient_access.score_threshold,
                healthInformationExchangePoints: criteria.health_info_exchange.points_earned,
                healthInformationExchangeColor: criteria.health_info_exchange.score_threshold,
                vdtPoints: criteria.view_download_transmit.points_earned,
                vdtColor: criteria.view_download_transmit.score_threshold,
                patientSpecificEducationPoints: criteria.patient_specific_education.points_earned,
                patientSpecificEducationColor: criteria.patient_specific_education.score_threshold,
                secureMessagingPoints: criteria.secure_messaging.points_earned,
                secureMessagingColor: criteria.secure_messaging.score_threshold,
                medicationReconciliationPoints: criteria.medication_reconciliation.points_earned,
                medicationReconciliationColor: criteria.medication_reconciliation.score_threshold,
                ImmunizationRegistryPoints: criteria.immunization_registry_submission.points_earned,
                ImmunizationRegistryColor: criteria.immunization_registry_submission.score_threshold,
            };
            this.setGlobalValue( 'advancingSummaryTotals', advancingSummaryTotals );
            this.setGlobalValue( 'advancingSummaryBaseScore', advancingSummaryBaseScore );
            this.setGlobalValue( 'advancingSummaryBonusScore', advancingSummaryBonusScore );
            this.setGlobalValue( 'advancingSummaryPerformanceScore', advancingSummaryPerformanceScore );
            this.setGlobalValue( 'advancingSummaryPerformanceCriteria', advancingSummaryPerformanceCriteria );
        },

        getQualitySummary: function( dateRange = false, startMonth = 1, endMonth = current_month, year = current_year ) {
            let proceed = true;
            if ( dateRange ) {
                proceed = this.checkDateRange( startMonth, endMonth );
            }
            if ( proceed ) {
                const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
                let endpoint = `${BASE_ENGAGE_URL}v1/mips/${org_id}/dashboard/quality?start_month=${ startMonth }&end_month=${ endMonth }&year=${ year }`;
	            if ( app.controller && app.controller.currentApp === 'MIPSPROVIDER' ) {
		            endpoint = this.addQueryParams( endpoint );
	            }
                const successTrigger = [ 'hide:contentSpinner' ];
                const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
                const resultTriggers = {
                    successTrigger,
                    errorTrigger
                };
                channel.trigger( 'show:contentSpinner' );
                utils.get( endpoint, resultTriggers, this.resolveGetQualitySummary.bind( this ) );
            }
        },

        resolveGetQualitySummary: function( data ) {
            const measures = data.measures;
            const advPlan = measures.advance_care_plan;
            const diabetes = measures.diabetes_poor_control;
            const falls_plan = measures.falls_care_plan;
            const falls_risk = measures.falls_risk_assessment;
            const tobacco_use = measures.tobacco_use;
            const pneum_vacc = measures.pneumococcal_vaccination_status;
            const qualitySummaryTotals = {
                totalQualityScore: parseFloat(data.overall_score).toFixed(0),
                score_threshold: data.score_threshold || 'WARN',
            };
            const qualitySummaryDiabetesPoorControl = {
                numerator: diabetes.numerator,
                denominator: diabetes.denominator,
                score_threshold: diabetes.score_threshold,
            };
            const qualitySummaryAdvanceCarePlan = {
                numerator: advPlan.numerator,
                denominator: advPlan.denominator,
                score_threshold: advPlan.score_threshold,
            };
            const qualitySummaryPneumococcalVaccine = {
                numerator: pneum_vacc.numerator,
                denominator: pneum_vacc.denominator,
                score_threshold: pneum_vacc.score_threshold,
            };
            const qualitySummaryTobacco = {
                numerator: tobacco_use.numerator,
                denominator: tobacco_use.denominator,
                score_threshold: tobacco_use.score_threshold,
            };
            const qualitySummaryFallRiskAssessment = {
                numerator: falls_risk.numerator,
                denominator: falls_risk.denominator,
                score_threshold: falls_risk.score_threshold,
            };
            const qualitySummaryFallCarePlan = {
                numerator: falls_plan.numerator,
                denominator: falls_plan.denominator,
                score_threshold: falls_plan.score_threshold,
            };
            this.setGlobalValue( 'qualitySummaryTotals', qualitySummaryTotals );
            this.setGlobalValue( 'qualitySummaryDiabetesPoorControl', qualitySummaryDiabetesPoorControl );
            this.setGlobalValue( 'qualitySummaryAdvanceCarePlan', qualitySummaryAdvanceCarePlan );
            this.setGlobalValue( 'qualitySummaryPneumococcalVaccine', qualitySummaryPneumococcalVaccine );
            this.setGlobalValue( 'qualitySummaryTobacco', qualitySummaryTobacco );
            this.setGlobalValue( 'qualitySummaryFallRiskAssessment', qualitySummaryFallRiskAssessment );
            this.setGlobalValue( 'qualitySummaryFallCarePlan', qualitySummaryFallCarePlan );
        },

        getCriteriaSelections: function( category ) {
            const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/mips/${ org_id }/measures?category=${ category }`;
	        if ( app.controller && app.controller.currentApp === 'MIPSPROVIDER' ) {
		        endpoint = this.addQueryParams( endpoint );
	        }
            const successTrigger = ['hide:contentSpinner'];
            const errorTrigger = ['hide:contentSpinner'];
            const resultTriggers = {
                successTrigger,
                errorTrigger
            };
            if ( org_id ) {
                channel.trigger( 'show:contentSpinner' );
                utils.get( endpoint, resultTriggers, this.resolveGetCriteriaSelections.bind( this, category ) );
                return;
            }
            channel.listenTo( channel, 'set:selectedOrganization', this.getCriteriaSelections( this, category ) );
        },

        resolveGetCriteriaSelections: function( category, data ) {
		    this.setGlobalValue( 'criteriaSelections', data );
        },

		getMeasureMetaData: function( measure ) {
            const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/mips/${ org_id }/measures/${ measure }`;
			if ( app.controller && app.controller.currentApp === 'MIPSPROVIDER' ) {
				endpoint = this.addQueryParams( endpoint );
			}
            const successTrigger = ['hide:contentSpinner'];
            const errorTrigger = ['hide:contentSpinner'];
            const resultTriggers = {
                successTrigger,
                errorTrigger
            };
            if ( org_id ) {
                channel.trigger( 'show:contentSpinner' );
                utils.get( endpoint, resultTriggers, this.resolveGetMeasureMetaData.bind( this, measure ) );
                return;
            }
            channel.listenTo( channel, 'set:selectedOrganization', this.getMeasureMetaData( this, measure ) );
        },

		resolveGetMeasureMetaData: function( measure, data ) {
            this.setGlobalValue( 'measureMetaData', data );
        },

		getMeasureData: function( measure_id, useSecondaryCriteria = false ) {
            const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/mips/${ org_id }/values/${ measure_id }`;
			if ( app.controller && app.controller.currentApp === 'MIPSPROVIDER' ) {
				endpoint = this.addQueryParams( endpoint );
			}
            const successTrigger = ['hide:contentSpinner'];
            const errorTrigger = ['hide:contentSpinner'];
            const resultTriggers = {
                successTrigger,
                errorTrigger
            };
            if ( org_id ) {
                channel.trigger( 'show:contentSpinner' );
                utils.get( endpoint, resultTriggers, this.resolveGetMeasureData.bind( this, useSecondaryCriteria ) );
                return;
            }
            channel.listenTo( channel, 'set:selectedOrganization', this.getMeasureData( this, useSecondaryCriteria ) );
		},

		resolveGetMeasureData: function( useSecondaryCriteria, data ) {
		    if ( useSecondaryCriteria ) {
		        this.setGlobalValue( 'secondaryMeasureData', data );
		        return;
            }
			this.setGlobalValue( 'measureData', data );

		},

        saveMeasureData: function( data, measure_id, useSecondaryCriteria = false ) {
            const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/mips/${ org_id }/values/${ measure_id }`;
	        if ( app.controller && app.controller.currentApp === 'MIPSPROVIDER' ) {
		        endpoint = this.addQueryParams( endpoint );
		        if ( app.controller.selectedProviderId < 0 ) {
			        this.triggerProviderModal();
			        return;
		        }
	        }
            const successTrigger = [ 'hide:contentSpinner', 'success:saveMeasureData' ];
            const errorTrigger = [ 'hide:contentSpinner' ];
            channel.trigger( 'show:contentSpinner' );
            utils.post( endpoint, data, successTrigger, errorTrigger, null, true, this.resolveSaveMeasureData.bind( this, measure_id, useSecondaryCriteria ) );
        },

        resolveSaveMeasureData: function( measure_id, useSecondaryCriteria, data ) {
            this.getMeasureData( measure_id, useSecondaryCriteria );
            if ( measure_id === 23 || measure_id === 24 || measure_id === 25 || measure_id === 26 ) {
                this.getAdvancingCareSummary();
            }
        },

        getImprovementActivities: function() {
            let endpoint = `${ BASE_ENGAGE_URL }v1/mips/activities`;
	        if ( app.controller && app.controller.currentApp === 'MIPSPROVIDER' ) {
		        endpoint = this.addQueryParams( endpoint );
	        }
            const successTrigger = ['hide:contentSpinner'];
            const errorTrigger = ['hide:contentSpinner'];
            const resultTriggers = {
                successTrigger,
                errorTrigger
            };
            channel.trigger( 'show:contentSpinner' );
            utils.get( endpoint, resultTriggers, this.resolveGetImprovementActivities.bind( this ) );
        },

        resolveGetImprovementActivities: function( data ) {
            // this.setGlobalValue( 'improvementActivities', [
            //     {
            //         id: 1,
            //         name: 'Activity 1',
            //     },
            //     {
            //         id: 2,
            //         name: 'Activity 2',
            //     },
            //     {
            //         id: 3,
            //         name: 'Activity 3',
            //     },
            //     {
            //         id: 4,
            //         name: 'Activity 4',
            //     },
            //     {
            //         id: 5,
            //         name: 'Activity 5',
            //     },
            //     {
            //         id: 6,
            //         name: 'Activity 6',
            //     },
            //     {
            //         id: 7,
            //         name: 'Activity 7',
            //     },
            //     {
            //         id: 8,
            //         name: 'Activity 8',
            //     },
            //     {
            //         id: 9,
            //         name: 'Activity 9',
            //     },
            //     {
            //         id: 10,
            //         name: 'Activity 10',
            //     },
            // ]);
            this.setGlobalValue( 'improvementActivities', data );
        },

        trackActivity: function( data, activity_id ) {
            const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/mips/${ org_id }/values/${ activity_id }`;
            if ( app.controller && app.controller.currentApp === 'MIPSPROVIDER' ) {
                endpoint = this.addQueryParams( endpoint );
                if ( app.controller.selectedProviderId < 0 ) {
                    this.triggerProviderModal();
                    return;
                }
            }
            const successTrigger = [ 'hide:contentSpinner', 'close:modal' ];
            const errorTrigger = [ 'hide:contentSpinner' ];
            channel.trigger( 'show:contentSpinner' );
            utils.post( endpoint, data, successTrigger, errorTrigger, null, true, this.resolveTrackActivity.bind( this, activity_id ) );
        },

        resolveTrackActivity: function( data ) {
		    // Fetch initial load data (which improvement activities are selected so far, points completed, etc...)
            this.getCriteriaSelections( 'improvement_activities' );
            this.getIAValues();
        },

        getIAValues: function() {
            const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${BASE_ENGAGE_URL}v1/mips/${ org_id }/iavalues`;
            if ( app.controller && app.controller.currentApp === 'MIPSPROVIDER' ) {
                endpoint = this.addQueryParams( endpoint );
            }
            const successTrigger = [ 'hide:contentSpinner' ];
            const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
            const resultTriggers = {
                successTrigger,
                errorTrigger
            };
            channel.trigger( 'show:contentSpinner' );
            utils.get( endpoint, resultTriggers, this.resolveGetIAValues.bind( this ) );
        },

        resolveGetIAValues: function( data ) {
		    this.setGlobalValue( 'measureData', data );
        },

        saveIASettings: function( settings ) {
            const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            const endpoint = `${BASE_ENGAGE_URL}v1/mips/${ org_id }/iasettings`;
            const successTrigger = [ 'close:modal', 'toggle:globalSpinner' ];
            const errorTrigger = [ 'failed:api', 'close:modal', 'toggle:globalSpinner' ];
            const body = settings;
            channel.trigger( 'toggle:globalSpinner' );
            utils.post( endpoint, body, successTrigger, errorTrigger, null, true, this.resolveSaveIASettings.bind(this) );
        },

        resolveSaveIASettings: function( data ) {
		    this.getCriteriaSelections( 'improvement_activities' );
		    this.getIASettings();
		    this.getIAValues();
        },

        getIASettings: function() {
            const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${BASE_ENGAGE_URL}v1/mips/${ org_id }/iasettings`;
            if ( app.controller && app.controller.currentApp === 'MIPSPROVIDER' ) {
                endpoint = this.addQueryParams( endpoint );
            }
            const successTrigger = [ 'hide:contentSpinner' ];
            const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
            const resultTriggers = {
                successTrigger,
                errorTrigger
            };
            channel.trigger( 'show:contentSpinner' );
            utils.get( endpoint, resultTriggers, this.resolveGetIASettings.bind( this ) );
        },

        resolveGetIASettings: function( data ) {
		    this.setGlobalValue( 'IASettings', data );
        },

		getProviders: function() {
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpoint = `${BASE_ENGAGE_URL}v2/organization/${org_id}/providers_light`;
			const successTrigger = ['getProviders:success', 'hide:contentSpinner'];
			const errorTrigger = ['getProviders:fail', 'hide:contentSpinner'];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveGetProviders.bind(this) );
		},

		resolveGetProviders: function( data ) {
			const sorted_providers = _.sortBy(data, 'name');
			const mipsProviders = [{'name': 'All Providers', 'id': -1}].concat(sorted_providers);
			this.setGlobalValue( 'mipsProviders', mipsProviders );
		},

		getMipsValueEndpoint: function( endpoint, provider_id_value, year ) {
			let new_endpoint = endpoint;
			let provider_id = parseInt(provider_id_value, 10);
			if ( provider_id === -1 ) {
				new_endpoint = `${endpoint}?year=${year}`;
			} else {
				new_endpoint = `${endpoint}/providers/${provider_id}?year=${year}`;
			}
			return new_endpoint;
		},

		getNewDashboardData: function( provider_id, year ) {
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const base_endpoint = `${BASE_ENGAGE_URL}v1/mips/dashboard/organizations/${org_id}`;
			const endpoint = this.getMipsValueEndpoint( base_endpoint, provider_id, year );
			const successTrigger = [ 'hide:contentSpinner' ];
			const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveGetNewDashboardData.bind( this ) );
		},

		resolveGetNewDashboardData: function(data) {
			this.setGlobalValue( 'mipsNewDashboardData', data );
		},

		setNewDashboardData: function( data, provider_id, year ) {
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const base_endpoint = `${BASE_ENGAGE_URL}v1/mips/dashboard/organizations/${org_id}`;
			const endpoint = this.getMipsValueEndpoint( base_endpoint, provider_id, year );
			const successTrigger = [ 'hide:contentSpinner' ];
			const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			channel.trigger( 'show:contentSpinner' );
			utils.post( endpoint, data, successTrigger, errorTrigger, null, true, this.resolveSetNewDashboardData.bind(this, provider_id, year) );
		},

		resolveSetNewDashboardData: function( provider_id, year ) {
			this.getNewDashboardData( provider_id, year );
		},

		getMipsWorksheetEndpoint: function( endpoint, provider_id_value, year ) {
			let new_endpoint = endpoint;
			const provider_id = parseInt(provider_id_value, 10);
			if ( provider_id === -1 ) {
				new_endpoint = `${endpoint}/worksheet?year=${year}`;
			} else {
				new_endpoint = `${endpoint}/providers/${provider_id}/worksheet?year=${year}`;
			}
			return new_endpoint;
		},

		checkForWorksheet: function( provider_id, year ) {
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const base_endpoint = `${BASE_ENGAGE_URL}v1/mips/dashboard/organizations/${org_id}`;
			const endpoint = this.getMipsWorksheetEndpoint( base_endpoint, provider_id, year );
			const successTrigger = [ 'hide:contentSpinner' ];
			const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveCheckForWorksheet.bind( this ) );
		},

		resolveCheckForWorksheet: function( data ) {
			this.setGlobalValue( 'worksheetStatus', data['exists'] )
		},

		uploadWorksheet: function( fileObj, file, provider_id, year ) {
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const base_endpoint = `${BASE_ENGAGE_URL}v1/mips/dashboard/organizations/${org_id}`;
			const endpoint = this.getMipsWorksheetEndpoint( base_endpoint, provider_id, year );
			const successTrigger = ['headers:success', 'hide:contentSpinner'];
			const errorTrigger = ['failed:api', 'hide:contentSpinner' ];

			channel.trigger( 'show:contentSpinner' );
			utils.filePost( endpoint, fileObj, successTrigger, errorTrigger, null, true, this.resolveUploadWorksheet.bind( this, provider_id, year ) );
		},

		resolveUploadWorksheet: function( provider_id, year ) {
			this.checkForWorksheet( provider_id, year );
			channel.trigger( 'success:uploadWorksheet' );
		},

		openWorksheet: function( provider_id, year ) {
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const base_endpoint = `${BASE_ENGAGE_URL}v1/mips/dashboard/organizations/${org_id}`;
			const endpoint = this.getMipsWorksheetEndpoint( base_endpoint, provider_id, year );
			const successTrigger = [ 'hide:contentSpinner' ];
			const errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveOpenWorksheet.bind( this ) );
		},

		resolveOpenWorksheet: function( data ) {
			window.open(data.link);
		},

	}
};
