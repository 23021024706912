import Backbone from 'backbone';
import _ from 'moment';
import moment from "moment/moment";

export const ApprovalModel = Backbone.Model.extend({

	defaults: {
		dateRange: false,
		month: moment().format('M'),
		year: moment().format('YYYY'),
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

});