//External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  bulkProviderSuspensionCompositeView  from '../../../../templates/Utilities/BulkProviderSuspension/bulkProviderSuspensionCompositeView';

// Internal Dependencies - models/collections
import BulkProviderSuspensionConfirmationModelView from './BulkProviderSuspensionConfirmationModelView'
import { BulkProviderSuspensionModel } from './models/BulkProviderSuspensionModel'

export default Marionette.ItemView.extend({

    className: 'change-password-composite-view',

    events: {
        'click #submitChange': 'submitChange',
        'change #selectedCurrentProvider': 'setSelectedCurrentProvider',
        'change #selectedTargetProvider': 'setSelectedTargetProvider'
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.controller = this.app.utilities_controller;
        this.model = new BulkProviderSuspensionModel()
        this.listenTo( this.model, 'set:bulkChangeProviders', this.refreshContent );
        this.listenTo( this.model, 'set:currentProviderDetail', this.refreshContent );

    },

    getTemplate: function() {
        return bulkProviderSuspensionCompositeView( this.model );
    },

    refreshContent: function() {
        this.render();
        const error = this.model.get("errorMessage");
        const currentNumberOfPatient = this.model.get("currentNumberOfPatient");
        const currentBillingName = this.model.get("currentBillingName");
        const currentProviderName = this.model.get("currentProviderName");
        if(error){
            $('#selectedProviderError').html(error);
        }else if(currentProviderName){
            $('#selectedProviderError').html('');
            $('#currentProviderName').html(currentProviderName);
            $('#currentProviderName').removeClass('blank-fill');

            $('#currentProviderBillingName').html('billing as '+currentBillingName);
            $('#currentProviderBillingName').removeClass('blank-fill');

            $('#currentProviderPatientCount').html(currentNumberOfPatient +' current patients');
            $('#currentProviderPatientCount').removeClass('blank-fill');

        }


        const selectedCurrentProvider = this.model.get('selectedCurrentProvider');

        if(currentNumberOfPatient != 0 && selectedCurrentProvider && selectedCurrentProvider != "-1"){
            $('#submitChange').removeClass('disabled');
        }else{
            $('#submitChange').addClass('disabled');
        }
    },

    refreshCurrentProviderDetail: function(){
        this.refreshContent();
        const currentNumberOfPatient = this.model.get("currentNumberOfPatient");
        const currentBillingName = this.model.get("currentBillingName");
        const currentProviderName = this.model.get("currentProviderName");
        if(currentProviderName){
            $('#currentProviderName').html(currentProviderName);
            $('#currentProviderName').removeClass('blank-fill');

            $('#currentProviderBillingName').html('billing as '+currentBillingName);
            $('#currentProviderBillingName').removeClass('blank-fill');

            $('#currentProviderPatientCount').html(currentNumberOfPatient +' current patients');
            $('#currentProviderPatientCount').removeClass('blank-fill');

        }

    },
    setSelectedCurrentProvider: function(e){
        $('#currentProviderName').html("");
        $('#currentProviderName').addClass('blank-fill');
        $('#currentProviderBillingName').html("");
        $('#currentProviderBillingName').addClass('blank-fill');
        $('#currentProviderPatientCount').html("");
        $('#currentProviderPatientCount').addClass('blank-fill');
        const selectedProvider = $('#selectedCurrentProvider').val();
        this.model.setSelectedCurrentProvider(selectedProvider);

    },
    submitChange: function(e){
        e.preventDefault();
        console.log("Test Submit");
        let modalView = new BulkProviderSuspensionConfirmationModelView({ model: this.model});
        modalView.render();
        $( 'body' ).append( modalView.el );
        $( '#modalReject' ).openModal();
    }

});