// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import autoEnrollSelectedModalView  from '../../../../templates/CCM/AutoEnroll/autoenrollSelectedModalView';

export default Marionette.ItemView.extend({

	events: {
		'click #submitAutoEnroll': 'submitAutoEnroll',
		'click .cancel': 'closeModal',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
	},

	getTemplate: function() {
		return autoEnrollSelectedModalView( this.collection );
	},

	closeModal: function() {
		$( '#autoEnrollSelectedModal' ).closeModal();
		this.destroy();
	},

	submitAutoEnroll: function() {
		this.collection.submitAutoEnroll();
	},

});
