// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import sideNav  from '../../../templates/sidenavView';
import ModuleView from './ModuleView';
import SubnavView from './SubnavView';
import SwitchOrgModalView from './SwitchOrgModalView';

// Internal Dependencies - models/collections
import { SideNavModel } from './models/SideNavModel';
import {SwitchOrgModel} from './models/SwitchOrgModel'

export default Marionette.LayoutView.extend({

	regions: {
		'subnavView': '.subnav-container',
		'moduleView': '.app-links-container',
	},
	events: {
		'click .side-nav-switch-org': 'switchOrgModal'
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		const controller = this.app.controller;
		this.model = new SideNavModel();
		this.model.set({ 'isCSAdmin': this.app.controller.user && this.app.controller.user.csadmin });
		controller.getAllOrganizations();
		this.switchOrgModelData = new SwitchOrgModel();
		this.listenTo( this.channel, 'set:ActiveOrganizations', this.setActiveOrganizations );
		this.model.set({
			currentApp: options.currentApp,
			modules: controller.modules,
			messenger: controller.user && controller.user.messenger,
			user: controller.user,
			
		});
		this.switchOrgModelData.set({
			user: controller.user,
			name:controller.user.name,
			selectedOrganization:controller.selectedOrganization,
			selectedOrgId:controller.selectedOrganization.id,
			activeOrganizations:controller.ActiveOrganizations
		});
		
		this.channel.reply( 'subnav-model', () => {
			return this.model;
		});
	},

	onShow: function() {
		this.showChildView( 'moduleView', new ModuleView({ model: this.model }) );
		this.showChildView( 'subnavView', new SubnavView({ model: this.model }) );
	},

	switchOrgModal: function( e ) {
		e.preventDefault();
		let modalView = new SwitchOrgModalView({ model: this.switchOrgModelData });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalEnrollPatient' ).openModal();
		this.channel.trigger( 'trigger:show' );
	},


	setActiveOrganizations: function(){
		this.switchOrgModelData.set({
			activeOrganizations:this.app.controller.ActiveOrganizations
		});
	},


	getTemplate: function() {
		return sideNav( this.model );
	},

});
