// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import moment from 'moment';

// Internal Dependencies - screens/views/templates
import billingListItemView  from '../../../../templates/Utilities/HistoricalBilling/billingListItemView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: 'table-row clearfix',

	events: {
		'click .download': 'downloadIndividualBilling'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.controller = this.app.utilities_controller;
		this.listenTo( this.model, 'change', this.render );
		this.listenTo( this.channel, 'set:billingData', this.setDate );
		this.setDate();
	},

	getTemplate: function() {
		return billingListItemView( this.model );
	},

	downloadIndividualBilling: function( e ) {
		this.controller.downloadBilling( this.model.attributes );
	},

	setDate: function() {
		let billingDate = this.controller.billingDate || {};
		let date = {
			month: billingDate.monthMM,
			year: billingDate.year,
		}
		let monthDisp = billingDate.monthMMMM;
		let yearDisp = billingDate.year;
		this.model.set({
			date,
			dateDisp: `${ monthDisp }, ${ yearDisp }`,
		});
	},

});
