import Backbone from 'backbone';

export const DashboardModel = Backbone.Model.extend({

    defaults: {
        dateRange: false,
        startMonth: null,
        endMonth: null,
        year: 2017,
        exclude_quality: null,
        exclude_improvement: null,
        dashboardTotals: {
            totalCompositeScore: '',
            score_threshold: '',
        },
        dashboardQuality: {
            overallScore: '',
            score_threshold: '',
            criteriaComplete: null,
            totalCriteria: null,
            compositeScore: '',
            compositePercent: '',
        },
        dashboardAdvancingCare: {
            overallScore: '',
            score_threshold: '',
            criteriaComplete: null,
            totalCriteria: null,
            compositeScore: '',
            compositePercent: '',
        },
        dashboardClinicalImprovement: {
            overallScore: '',
            score_threshold: '',
            criteriaComplete: null,
            totalCriteria: null,
            compositeScore: '',
            compositePercent: '',
        },
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.colors = {
            'FAIL': '#A01313',
            'WARN': '#f57b3e',
            'PASS': '#169E54',
            'blue': '#1080AF',
            'gray': '#C6C6C6',
        };
        this.listenTo( this.channel, 'set:mipsExclusions', this.setExcludes );
        this.listenTo( this.channel, 'set:dashboardClinicalImprovement', this.setDashboardData );
        const currentApp = this.app.controller.currentApp;
	    const url = currentApp === 'MIPSPROVIDER' ? 'mips-provider' : 'mips-group';
	    this.set( { url } );
    },

    setExcludes: function() {
        const exclusions = this.app.mips_controller.mipsExclusions;
        const exclude_quality = exclusions.exclude_quality;
        const exclude_improvement = exclusions.exclude_improvement;
        this.set( { exclude_quality, exclude_improvement } );
	    this.trigger( 'change:exclusions' )
    },

    setDashboardData: function() {
        const controller = this.app.mips_controller;
        const dashboardTotals = controller.dashboardTotals;
        const dashboardQuality = controller.dashboardQuality;
        const dashboardAdvancingCare = controller.dashboardAdvancingCare;
        const dashboardClinicalImprovement = controller.dashboardClinicalImprovement;
        this.set( { dashboardTotals, dashboardQuality, dashboardAdvancingCare, dashboardClinicalImprovement } );
        this.trigger( 'change:dashboardData' )
    },

    updateSettings: function( excludeQualityMeasures, excludeImprovementActivities ) {
        this.app.mips_controller.setMipsSettings( excludeQualityMeasures, excludeImprovementActivities );
    },

});