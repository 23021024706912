// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';

// Internal Dependencies - screens/views/templates
import  rejectBillingModalView  from '../../../../../templates/CCM/RapidBill/Approval/rejectBillingModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'change #rejectReason': 'checkOther',
		'click #confirmReject': 'confirmReject',
		'click .cancel': 'cancelReject'
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
		this.model.set( 'rejectReasons', this.app.ccm_controller.patients.rejectReasons );
	},

	getTemplate: function() {
		return rejectBillingModalView( this.model );
	},

	closeModal: function() {
		$( '#modalReject' ).closeModal();
		this.destroy();
	},

	checkOther: function() {
		let reasonEl = this.$( '#rejectReason' );
		let reason = reasonEl.val();
		if ( reason != '4' && reason > -1 ) {
			reasonEl.parent( '.browser-default-container' ).hasClass( 'validate' ) && reasonEl.parent( '.browser-default-container' ).removeClass( 'validate' );
		}
		if ( reason == '4' ) {
			this.$( '.other-reason' ).show();
			return;
		}
		this.$( '.other-reason' ).hide();
		this.$( '.other-reason' ).removeClass( 'validate' );
	},

	confirmReject: function() {
		let reason = this.$( '#rejectReason' );
		let otherReason = this.$( '#otherReason' );

		if ( this.isValid( reason.val(), otherReason.val() ) ) {
			this.model.set({
				rejected: true,
				reject_reason: reason.val() !== '4' ? this.model.get( 'rejectReasons' )[ reason.val() ] : otherReason.val(),
				selected: true
			});
			$( '.modal' ).closeModal();
			this.destroy();
			return;
		}

		if ( reason.val() == -1 ) {
			reason.parent( '.browser-default-container' ).addClass( 'validate' );
			reason.focus();
			return;
		}

		if ( reason.val() == '4' && !otherReason.val() ) {
			otherReason.parent('.other-reason').addClass( 'validate' );
			otherReason.focus();
		}
	},

	isValid: function( reason, otherReason ) {
		if ( reason == -1 ) {
			return false;
		}
		if ( reason == '4' && ( !otherReason || ( otherReason && otherReason.length < 3 ) ) ) {
			return false;
		}
		return true;
	},

	cancelReject: function() {
		$( '#modalReject' ).closeModal();
		this.destroy();
	}

});
