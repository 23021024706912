// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';

// Internal Dependencies - screens/views/templates
import  reportsChartsContainerView  from '../../../../../templates/CCM/RapidBill/Reports/reportsChartsContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: 'col l12 content-divider pvm',

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.model, 'change:billingBreakdown', this.renderCharts );
	},

	getTemplate: function() {
		return reportsChartsContainerView( this.model );
	},

	renderCharts: function() {
		this.render();
		this.patientsBilled();
	},

	patientsBilled: function() {
		let billingBreakdown = this.model.get('billingBreakdown');
		let index = 0;
		let data = [];
		$.each( billingBreakdown, ( key, value ) => {
			if( value.value > 0 ) {
				data.push({
					name: value.label,
					y: value.value,
					color: this.model.colors[index]
				});
				index++
			}
		});
	    var patientsWorkedChart = Highcharts.chart('patientsBilled', {
        	title: {
	            text: null
	        },
	        tooltip: {
	            pointFormat: '<b>{point.y}</b>'
	        },
	        plotOptions: {
	            pie: {
	                dataLabels: {
	                    enabled: true,
	                    distance: -20,
	                    format: '<b>{point.y}</b>',
	                },
	                center: ['50%', '55%'],
	                showInLegend: true
	            }
	        },
	        series: [{
	            type: 'pie',
	            data: data,
	        }],
	        credits: {
	        	enabled: false
	        }
    	});
    	if ( data.length == 0 ) {
    		$('#patientsBilled').html( 'No billing attempts during selected timeframe' )
    	}
	}

});
