// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import worksheetView  from '../../../../templates/MIPS/MIPS/worksheetView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: 'col s12',

	events: {
		'change #filePath': 'setFilePath',
		'click .upload': 'uploadFile',
		'click #openWorksheet': 'openWorksheet',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.model, 'change:filePath set:worksheetStatus', this.render );
	},

	getTemplate: function() {
		return worksheetView( this.model );
	},

	setFilePath: function() {
		let filePath = $( '#filePath' )[ 0 ].files[ 0 ] || null;
		this.model.set({ filePath });
	},

	uploadFile: function() {
		if ( this.model.get( 'filePath' ) ) {
			$('.upload .fa').removeClass('fa-cloud-upload').addClass('fa-refresh');
			this.model.uploadFile();
		}
	},

	openWorksheet: function() {
		this.model.openWorksheet();
	},

});
