import Backbone from 'backbone';
import _ from 'underscore';

export const AdminLevelFilteringModel = Backbone.Model.extend({
	
	initialize: function( options ) {
		this.app = options.app;
		this.channel = options.channel;
		this.listenTo( this.channel, 'set:selectedOrganization', this.setOrganization );
		this.listenTo( this.channel, 'set:locations', this.setLocations );
		this.listenTo( this.channel, 'set:providers', this.setProviders );
		this.initialValues();
	},

	initialValues: function() {
		const controller = this.app.controller;
		if ( controller.selectedOrganization ) {
			const org = controller.selectedOrganization;
			this.set( 'organization', org );				
		}
		if ( controller.locations ) {
			const locations = _.sortBy(controller.locations, 'name');
			this.set( 'locations', locations );
		}
		if ( controller.providers ) {
			const providers =  _.sortBy(controller.providers, 'name');
			this.set ( 'providers', providers );
		}
	},

	setOrganization: function( ) {
		const org = this.app.controller.selectedOrganization;
		this.set( 'organization', org );
	},

	setLocations: function ( ) {
		const locations = _.sortBy(this.app.controller.locations, 'name');
		this.set( 'locations', locations );
	},

	setProviders: function( ) {
		const providers = _.sortBy(this.app.controller.providers, 'name');
		this.set( 'providers', providers );
	}

});
