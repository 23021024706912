// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import moment from 'moment';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import  dateRangeContainerView  from '../../../../../templates/CCM/RapidBill/Reports/dateRangeContainerView';
import DateRangeDaySelectView from './DateRangeDaySelectView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	regions: {
		'startDayContainer': '.start-day-container',
		'endDayContainer': '.end-day-container',
	},

	className: 'col l12 content-divider pbm',

	events: {
		'change .report-date-select': 'updateDate',
		'click #applyDateRange': 'applyDateFilter',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'set:selectedProviderId', this.showLoadingText );
	},

	getTemplate: function() {
		return dateRangeContainerView( this.model );
	},

	onShow: function() {
		$( 'select' ).material_select();
		$('#startMonth option[value="' + this.model.get( 'startMonth' ) + '"]').attr("selected", "selected");
		$('#startYear option[value="' + this.model.get( 'startYear' ) + '"]').attr("selected", "selected");
		$('#endMonth option[value="' + this.model.get( 'endMonth' ) + '"]').attr("selected", "selected");
		$('#endYear option[value="' + this.model.get( 'endYear' ) + '"]').attr("selected", "selected");
		this.showChildView( 'startDayContainer', new DateRangeDaySelectView( { model: this.model, type: 'startDay' } ) );
		this.showChildView( 'endDayContainer', new DateRangeDaySelectView( { model: this.model, type: 'endDay' } ) );
	},

	showLoadingText: function() {
		$('#applyDateRange').html('Loading');
	},

	updateDate: function( e ) {
		let newAttributes = {};
		let key = e.target.id;
		newAttributes[key] = e.target.value;

		if ( key == 'startMonth' || key == 'endMonth' ) {
			let monthRangeKey = e.target.id + 'Range';
			let year = key == 'startMonth' ? 'startYear' : 'endYear';
			newAttributes[monthRangeKey] = _.range( moment( e.target.value + '-' + this.model.get( year ), 'MM-YYYY' ).daysInMonth() );
		}

		if ( ( key == 'startYear' && this.model.get( 'startMonth' ) == 2 ) || ( key == 'endYear' && this.model.get( 'endMonth' ) == 2 ) ) {
			let monthKey = e.target.id == 'startYear' ? 'startMonth' : 'endMonth';
			let monthRangeKey = monthKey + 'Range';
			newAttributes[monthRangeKey] = _.range( moment( this.model.get( monthKey ) + '-' + e.target.value, 'MM-YYYY' ).daysInMonth() );
		}

		this.model.set( newAttributes );
	},

	applyDateFilter: function() {
		this.model.updateStamps();
		$('#applyDateRange').html('Loading');
		this.app.ccm_controller.getBillableReportSummary( this.model.get( 'startStamp' ), this.model.get( 'endStamp' ) );
	}

});
