// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';

// Internal Dependencies - screens/views/templates
import overviewContainerView  from '../../../../../templates/MIPS/AdvancingCare/BaseScore/overviewContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.listenTo( this.model, 'change:summaryData', this.renderSummaryChart );
    },

    getTemplate: function() {
        return overviewContainerView( this.model );
    },

    onShow: function() {
        this.renderSummaryChart();
    },

    renderSummaryChart: function() {
        let data = [];
        if ( this.model.get( 'summaryData' ) ) {
            data = [
                {
                    name: 'Complete',
                    y: this.model.get('summaryData').criteriaComplete,
                    color: this.model.colors[this.model.get('summaryData').color],
                },
                {
                    name: 'Incomplete',
                    y: this.model.get('summaryData').totalCriteria - this.model.get('summaryData').criteriaComplete,
                    color: this.model.colors['gray'],
                },
            ];
        }
        if ( data.length == 0 ) {
            $('#progressChartContainer').html();
            return;
        }
        let progressChart = Highcharts.chart('progressChartContainer', {
            chart: {
                height: 250,
                marginTop: 0,
            },
            title: {
                text: `Base Criteria Complete`,
                style: {
                    color: '#505050',
                }
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -20,
                        format: '<b>{point.y}</b>',
                    },
                    startAngle: -91,
                    endAngle: 90,
                    center: ['50%', '80%'],
                    showInLegend: true,
                    size: 200
                }
            },
            legend: {
                floating: true,
            },
            series: [{
                type: 'pie',
                innerSize: '50%',
                data: data,
            }],
            credits: {
                enabled: false,
            },
        });
    },

});
