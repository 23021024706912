// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import uploadCompositeView  from '../../../../templates/PatientEnrollment/FileManager/uploadCompositeView';
import FileItemView from './FileItemView';

// Internal Dependencies - models/collections
import { FileModel } from './models/FileModel';
import { FileCollection } from "./models/FileCollection";

export default Marionette.CompositeView.extend({

    childView: FileItemView,

    childViewContainer: '.file-manager-collection-container',

	events: {
		'change #filePath': 'setFilePath',
		'change #noOfPatient':'setNumberOfPatients',
		'change #dateOfCnECallStart':'setDateOfCnECallStart',
		'click .upload': 'uploadFile',
        'click #showNoteInput': 'showNoteInput',
		'change @ui.fileNotes': 'updateFileNotes',
		'click #listReview': 'showFileReview',
	},

	ui: {
    	fileNotes: '#fileNotes',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new FileModel();
		this.model.set( 'showNoteInput', false );
		this.model.set( 'showFileReviewBlock', false );
		this.collection = new FileCollection();
		this.model.set( 'user', this.app.controller.user );

        this.listenTo( this.channel, 'set:files', this.setFileCollection );
        this.listenTo( this.channel, 'upload:success', this.clearForm );
        this.listenTo( this.model, 'change:showNoteInput change:showFileReviewBlock change:filePath change:renderNoResults', this.render );

		this.collection.getFiles();
        $('.content-container').addClass('widen');
	},

	getTemplate: function() {
		return uploadCompositeView( this.model );
	},

	setFilePath: function() {
		let filePath = $( '#filePath' )[ 0 ].files[ 0 ] || null;
		this.model.set({ filePath });
	},

	setNumberOfPatients: function(){
    	const noOfPatients = $( '#noOfPatient' ).val();
		this.model.set({ noOfPatients });
	},

	setDateOfCnECallStart: function(){
		const dateOfCnECallStart = $( '#dateOfCnECallStart' ).val();
		this.model.set({ dateOfCnECallStart });
	},

	uploadFile: function() {
		if ( this.model.get( 'filePath' ) ) {
			$('.upload .fa').removeClass('fa-cloud-upload').addClass('fa-refresh');
			this.model.uploadFile();
			return;
		}
	},

    setFileCollection: function() {
	    let files = this.app.controller.files.map( ( file ) => new FileModel( file ) );
	    this.collection.reset( files );
        this.model.set( 'renderNoResults', !files.length );
    },

    showNoteInput: function() {
        this.model.set( 'showNoteInput', !this.model.get( 'showNoteInput' ) );
    },

	showFileReview: function() {
		this.model.set( 'showFileReviewBlock', !this.model.get( 'showFileReviewBlock' ) );
	},

	updateFileNotes: function() {
    	this.model.set( 'fileNotes',  this.ui.fileNotes.val() );
	},

	clearForm: function() {
		this.model.set( 'showNoteInput', false );
		this.model.set( 'showFileReviewBlock', false );
		this.model.set({ "noOfPatients":'' });
		this.model.set({ "dateOfCnECallStart":"" });
		this.model.set({ "filePath":"" });
		this.model.set( 'user', this.app.controller.user );
	},

});
