import Backbone from 'backbone';

export const UserModel = Backbone.Model.extend({

	defaults: {
		firstName: '',
		lastName: '',
		username: '',
		permissionLevel: '',
	},

});