// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import patientListCompositeView  from '../../../../../templates/PatientEnrollment/PatientApproval/PatientApprovalComponents/patientListCompositeView';
import PatientListItemView from './PatientListItemView';

// Internal Dependencies - models/collections
import { PatientModel } from '../models/PatientModel';

export default Marionette.CompositeView.extend({

	childView: PatientListItemView,

	childViewContainer: '.patient-list-container',

	events: {
		'click #selectAll': 'selectAll',
	},

	initialize: function( options = {} ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.currentPage = 0;
		this.pageSize = 100;
		this.controller = this.app.pe_controller;

		this.listenTo( this.channel, 'set:patientList', this.setPatientList );
		this.listenTo( this.collection, 'update', this.render );
		this.listenTo( this.channel, 'reset:currentPage', this.resetCurrentPage );
		this.listenTo( this.channel, 'set:selectedProviderId', this.collection.getPatients.bind( this.collection.patientType ) );

        this.channel.reply( 'currentPage', () => {
			return this.currentPage;
		});
		this.channel.reply( 'pageSize', () => {
			return this.pageSize;
		});
		$('.content-container').addClass('widen');

        this.collection.getPatients( this.collection.patientType );
	},

	getTemplate: function() {
		return patientListCompositeView( this.collection );
	},

	setPatientList: function() {
		this.channel.trigger( 'show:contentSpinner' );
		let patients = this.app.pe_controller.patientList.map( ( patient ) => new PatientModel( patient ) );
		this.collection.renderNoResults = patients && patients.length ? false : true;
		this.collection.set( patients );
		this.channel.trigger( 'hide:contentSpinner' );
		!patients.length && this.render();
	},

	resetSearch: function() {
		this.channel.trigger( 'reset:search' );
	},

	resetCurrentPage: function() {
		this.currentPage = 0;
		this.controller.patients.currentPage = this.currentPage;
	},

	selectAll: function( e ) {
		const isChecked = $( e.target ).prop( 'checked' );
		this.collection.toggleSelectAll( isChecked );
	},

	downloadCSV: function() {
		this.collection.downloadCSV();
	},

	onDestroy: function() {
		this.channel.trigger( 'remove:search' );
		this.controller.clearValue( 'patientList' );
		this.controller.clearValue( 'sortedBy' );
		$('.content-container').removeClass('widen');
	},

});
