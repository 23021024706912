// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import reportsContainerView  from '../../../../../templates/CCM/RapidBill/Reports/reportsContainerView';
import DateRangeContainerView from './DateRangeContainerView';
import ReportsChartsContainerView from './ReportsChartsContainerView';
import DetailedReportsContainerView from './DetailedReportsContainerView';

// Internal Dependencies - models/collections
import { ReportsModel } from './models/ReportsModel';
import Highcharts from "highcharts";
import moment from "moment";

export default Marionette.LayoutView.extend({

	className: 'col m12',

	regions: {
		dateRangeContainerView: '.date-range-container',
		chartsContainer: '.charts-container',
		detailedReportsContainer: '.detailed-reports-container',

	},
	events: {
		'click .download-report': 'downloadReport',
		'click #applyDateRange':'applyDateRange',
		'change #endDateRapidID':'onEndDateChange',
		'change #startDateRapidID':'onStartDateChange'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new ReportsModel();
		this.model.set({
			startDate:`${moment().format('YYYY')}-${moment().format('MM')}-01`,
			endDate:moment().add(1,'day').format('YYYY-MM-DD')
		})
		this.getSummary();
		this.listenTo( this.channel, 'set:rapidBillingRevenue', this.updateModel );
		this.listenTo( this.channel, 'set:selectedProviderId', this.getSummary );
		this.listenTo( this.model, 'change:billingBreakdown', this.renderCharts );
	},

	getTemplate: function() {
		return reportsContainerView(this.model);
	},

	onShow: function() {
		// this.showChildView( 'dateRangeContainerView', new DateRangeContainerView( { model: this.model } ) );
		// this.showChildView( 'chartsContainer', new ReportsChartsContainerView( { model: this.model } ) );
		// this.showChildView( 'detailedReportsContainer', new DetailedReportsContainerView( { model: this.model } ) );
	},
	downloadReport: function() {
		let startStamp = moment(this.model.get('startDate'),'YYYY-MM-DD').valueOf();
		let endStamp = moment(this.model.get('endDate'),'YYYY-MM-DD').valueOf();
		this.app.ccm_controller.getRapidBillingDetailedReportCSV( startStamp, endStamp )
	},

	onStartDateChange:function(){
		let startDate = $('#startDateRapidID').val();
		console.log("startDate:",startDate)
		this.model.set({startDate})
	},
	onEndDateChange:function(){
		let endDate = $('#endDateRapidID').val();
		console.log("startDate:",endDate)
		this.model.set({endDate})
	},

	applyDateRange:function (){
		this.getSummary();
	},
	getSummary: function() {
		let startStamp = moment(this.model.get('startDate'),'YYYY-MM-DD').valueOf();
		let endStamp = moment(this.model.get('endDate'),'YYYY-MM-DD').valueOf();
		this.app.ccm_controller.getBillableReportSummary( startStamp, endStamp);
	},

	updateModel: function() {
		$('#applyDateRange').html('Apply Date Range');
		let billingBreakdown = this.app.ccm_controller.rapidBillingBreakdown;
		let estimatedRevenue = this.app.ccm_controller.rapidBillingRevenue && parseFloat(this.app.ccm_controller.rapidBillingRevenue).toFixed(2);
		let medicareCptCode = this.app.controller.selectedOrganization.medicareCptCode
		this.model.set( { estimatedRevenue, billingBreakdown,medicareCptCode } );
	},

	renderCharts: function() {
		this.render();
		this.patientsBilled();
	},
	onDomRefresh: function() {
		this.patientsBilled();
	},

	patientsBilled: function() {
		let billingBreakdown = this.model.get('billingBreakdown');
		let index = 0;
		let data = [];
		$.each( billingBreakdown, ( key, value ) => {
			if( value.value > 0 ) {
				data.push({
					name: value.label,
					y: value.value,
					color: this.model.colorMap[value.label]
				});
				index++
			}
		});
		var patientsWorkedChart = Highcharts.chart('patientsBilledId', {
			title: {
				text: null
			},
			tooltip: {
				pointFormat: '<b>{point.y}</b>'
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false,
						distance: -20,
						format: '<b>{point.y}</b>',
					},
					center: ['50%', '55%'],
					showInLegend: true,
				}
			},
			series: [{
				type: 'pie',
				data,
			}],
			credits: {
				enabled: false
			},
			chart:{
				backgroundColor:'transparent'
			},
			legend:{
				itemStyle:{color:'#B3B3B3',marginBottom:'10px'},
				itemHoverStyle:{color:'#B3B3B3',marginBottom:'10px'}
			}
		});
		if ( data.length == 0 ) {
			$('#patientsBilledId').html( `<div class="nobiilingdata">No Encounters</div>` )
		}
	}

});
