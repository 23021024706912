//External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  bulkProviderChangeCompositeView  from '../../../../templates/Utilities/BulkProviderChange/bulkProviderChangeCompositeView';

// Internal Dependencies - models/collections
import { BulkProviderChangeModel } from './models/BulkProviderChangeModel';
import BulkProviderConfirmationModelView from "./BulkProviderConfirmationModelView";

export default Marionette.ItemView.extend({

    className: 'change-password-composite-view',

    events: {
        'click #submitChange': 'submitChange',
        'change #selectedCurrentProvider': 'setSelectedCurrentProvider',
        'change #selectedTargetProvider': 'setSelectedTargetProvider'
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.model = new BulkProviderChangeModel();
        this.listenTo( this.model, 'set:bulkChangeProviders', this.refreshContent );
        this.listenTo( this.model, 'set:currentProviderDetail', this.refreshContent );
        this.listenTo( this.model, 'set:targetProviderDetail', this.refreshContent );

    },

    getTemplate: function() {
        return bulkProviderChangeCompositeView( this.model );
    },

    refreshContent: function() {
        this.render();
        const currentNumberOfPatient = this.model.get("currentNumberOfPatient");
        const currentBillingName = this.model.get("currentBillingName");
        const currentProviderName = this.model.get("currentProviderName");
        if(currentProviderName){
            $('#currentProviderName').html(currentProviderName);
            $('#currentProviderName').removeClass('blank-fill');

            $('#currentProviderBillingName').html('billing as '+currentBillingName);
            $('#currentProviderBillingName').removeClass('blank-fill');

            $('#currentProviderPatientCount').html(currentNumberOfPatient +' current patients');
            $('#currentProviderPatientCount').removeClass('blank-fill');

        }

        const targetNumberOfPatient = this.model.get("targetNumberOfPatient");
        const targetBillingName = this.model.get("targetBillingName");
        const targetProviderName = this.model.get("targetProviderName");
        if(targetProviderName){
            $('#targetProviderName').html(targetProviderName);
            $('#targetProviderName').removeClass('blank-fill');

            $('#targetBillingName').html('billing as '+targetBillingName);
            $('#targetBillingName').removeClass('blank-fill');

            $('#targetNumberOfPatient').html(targetNumberOfPatient +' current patients');
            $('#targetNumberOfPatient').removeClass('blank-fill');

        }

        const selectedTargetProvider = this.model.get('selectedTargetProvider');
        const selectedCurrentProvider = this.model.get('selectedCurrentProvider');

        if(currentNumberOfPatient != 0 && selectedCurrentProvider && selectedCurrentProvider != "-1"
            && selectedTargetProvider && selectedTargetProvider != "-1"
            &&  (selectedCurrentProvider !=  selectedTargetProvider)){
            $('#submitChange').removeClass('disabled');
        }else{
            $('#submitChange').addClass('disabled');
        }
    },

    refreshCurrentProviderDetail: function(){
        this.refreshContent();
        const currentNumberOfPatient = this.model.get("currentNumberOfPatient");
        const currentBillingName = this.model.get("currentBillingName");
        const currentProviderName = this.model.get("currentProviderName");
        if(currentProviderName){
            $('#currentProviderName').html(currentProviderName);
            $('#currentProviderName').removeClass('blank-fill');

            $('#currentProviderBillingName').html('billing as '+currentBillingName);
            $('#currentProviderBillingName').removeClass('blank-fill');

            $('#currentProviderPatientCount').html(currentNumberOfPatient +' current patients');
            $('#currentProviderPatientCount').removeClass('blank-fill');

        }

    },

    refreshTargetProviderDetail: function(){
        this.refreshContent();
        const targetNumberOfPatient = this.model.get("targetNumberOfPatient");
        const targetBillingName = this.model.get("targetBillingName");
        const targetProviderName = this.model.get("targetProviderName");
        if(targetProviderName){
            $('#targetProviderName').html(targetProviderName);
            $('#targetProviderName').removeClass('blank-fill');

            $('#targetBillingName').html('billing as '+targetBillingName);
            $('#targetBillingName').removeClass('blank-fill');

            $('#targetNumberOfPatient').html(targetNumberOfPatient +' current patients');
            $('#targetNumberOfPatient').removeClass('blank-fill');

        }
    },

    setSelectedCurrentProvider: function(e){
        $('#currentProviderName').html("");
        $('#currentProviderName').addClass('blank-fill');
        $('#currentProviderBillingName').html("");
        $('#currentProviderBillingName').addClass('blank-fill');
        $('#currentProviderPatientCount').html("");
        $('#currentProviderPatientCount').addClass('blank-fill');
        const selectedProvider = $('#selectedCurrentProvider').val();
        this.model.setSelectedCurrentProvider(selectedProvider);
        // if(selectedProvider != "-1" && this.model.get('selectedTargetProvider')
        //     &&  (selectedProvider !=  this.model.get('selectedTargetProvider'))){
        //     $('#submitChange').removeClass('disabled');
        // }else{
        //     $('#submitChange').addClass('disabled');
        // }
    },

    setSelectedTargetProvider: function(e){
        $('#targetProviderName').html("");
        $('#targetProviderName').addClass('blank-fill');
        $('#targetBillingName').html("");
        $('#targetBillingName').addClass('blank-fill');
        $('#targetNumberOfPatient').html("");
        $('#targetNumberOfPatient').addClass('blank-fill');
        const selectedProvider = $('#selectedTargetProvider').val();
        this.model.setSelectedTargetProvider(selectedProvider);
        // if(selectedProvider != "-1" && this.model.get('selectedCurrentProvider') &&
        //     (selectedProvider !=  this.model.get('selectedCurrentProvider'))){
        //     $('#submitChange').removeClass('disabled');
        // }else{
        //     $('#submitChange').addClass('disabled');
        // }
    },
    submitChange: function(e){
        e.preventDefault();
        let modalView = new BulkProviderConfirmationModelView({ model: this.model});
        modalView.render();
        $( 'body' ).append( modalView.el );
        $( '#modalReject' ).openModal();

        // this.model.bulkProviderUpdate(()=>{
        //
        //
        // });
    }



});
