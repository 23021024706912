import Backbone from 'backbone';
import Marionette from 'backbone.marionette';
import _ from 'underscore';

import { RapidBillModel } from '../../models/RapidBillModel';

export const PendingReviewCollection = Backbone.Collection.extend({
	
	model: RapidBillModel,

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	}
});