// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import  unreadNotificationModalView  from '../templates/unreadNotificationModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'click .modal-close': 'preventNav',
		'click .confirm': 'confirmAction'
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'close:modal', this.destroy );
	},

	getTemplate: function() {
		return unreadNotificationModalView( this.model );
	},

	preventNav: function( e ) {
		e.preventDefault();
		this.destroy();
	},

	confirmAction: function() {
		this.app.router.navigate( '/main/notifications', true );
		this.destroy();
	},

});
