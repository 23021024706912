// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import userDropdownFiltersView  from '../../../templates/Filtering/userDropdownFiltersView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: 'row',

	getTemplate: function() {
		return userDropdownFiltersView( );
	},

});
