// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import addPatientModalView  from '../../../../templates/CCM/PatientList/addPatientModalView';
import ValidationsContainerView from '../../Validations/ValidationsContainerView';

// Internal Dependencies - models/collections
import { ValidationsModel } from '../../Validations/models/ValidationsModel';

export default Marionette.LayoutView.extend({

	regions: {
		'validationContainer': '.validations-container'
	},

	events: {
		'click .modal-close': 'closeModal',
		'click .add-patient': 'addPatient',
		'change #icd10code_1': 'checkSelectedCode',
		'change #icd10code_2': 'checkSelectedCode',
		'change #ccm_provider_id': 'onProviderSelected'
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model.set( 'provider-options', this.app.controller.providers );
		this.listenTo( this.model, 'change', this.displayValidations );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
		this.listenTo( this.channel, 'remove:errors', this.removeErrors );
		this.listenTo( this.channel, 'trigger:show', this.showTriggered );
		this.listenTo( this.channel, 'enroll:failure', this.flipText );
	},

	checkSelectedCode: function( e ) {
		if ( e.target.id == 'icd10code_1' ) {
			this.selectedCode1 = e.target.value;
			return;
		}
		if ( e.target.id == 'icd10code_2' ) {
			this.selectedCode2 = e.target.value;
			return;
		}
	},

	onProviderSelected: function(e) {
		const selectedProviderId = e.target.value;
		let providerObject = _.find( this.app.controller.providers, ( provider ) => provider.id == selectedProviderId );
		if(selectedProviderId == "-1"){
			$('#billingProviderNameId').text("--");
		}else{
			$('#billingProviderNameId').text(providerObject.billingProviderName ?  providerObject.billingProviderName : providerObject.name);
		}

	},

	flipText: function() {
		$('.add-patient').html('Enroll');
	},

	showTriggered: function() {
		const self = this;
		this.selectedCode1 = '';
		this.selectedCode2 = '';
		$( 'select' ).material_select();
		$('#icd10code_1').devbridgeAutocomplete({
			serviceUrl: `${ this.app.controller.engageUrl }v1/codelookup/icd10?start=0`,
			onSelect: function( suggestion ) {
				self.selectedCode1 = suggestion.id;
			},
			paramName: 'search',
	    	maxHeight: 150,
	    	ajaxSettings: {
	    		headers: {
	    			'X-AUTH-TOKEN': this.app.controller.getToken()
	    		}
	    	},
	    	transformResult: function( data ) {
	    		let response = _.reject( JSON.parse( data ), ( item ) => item.id == self.selectedCode2 );
	    		return {
	    			suggestions: response.map( ( item ) => {
	    				return { value: `${ item.id } - ${ item.desc }`, id: item.id }
	    			})
	    		}
	    	},
	    	autoSelectFirst: true,
	    	noCache: true,
	    });
	    $('#icd10code_2').devbridgeAutocomplete({
	    	serviceUrl: `${ this.app.controller.engageUrl }v1/codelookup/icd10?start=0`,
			onSelect: function( suggestion ) {
				self.selectedCode2 = suggestion.id;
			},
			paramName: 'search',
	    	maxHeight: 150,
	    	ajaxSettings: {
	    		headers: {
	    			'X-AUTH-TOKEN': this.app.controller.getToken()
	    		}
	    	},
	    	transformResult: function( data ) {
	    		let response = _.reject( JSON.parse( data ), ( item ) => item.id == self.selectedCode1 );
	    		return {
	    			suggestions: response.map( ( item ) => {
	    				return { value: `${ item.id } - ${ item.desc }`, id: item.id }
	    			})
	    		}
	    	},
	    	autoSelectFirst: true,
	    	noCache: true,
	    });
	},

	getTemplate: function() {
		return addPatientModalView( this.model );
	},

	closeModal: function( e ) {
		e && e.preventDefault();
		$( '#modalAddPatient' ).closeModal();
		this.destroy();
	},

	addPatient: function( e ) {
		e.preventDefault();
		let primary_phone = $( '#primary_phone' );
		let secondary_phone = $( '#secondary_phone' );
		let email = $( '#email' ).val() || null;
		let preferredLanguage = '';
		if($('#preferred_english').is(':checked')){
			preferredLanguage = 'English';
		}else if($('#preferred_spanish').is(':checked')){
			preferredLanguage = 'Spanish';
		}
		this.model.set({
			first_name: this.$( '#first_name' ).val() || null,
			last_name: this.$( '#last_name' ).val() || null,
			provider_id: Number( this.$( '#ccm_provider_id' ).val() ) || null,
			pid: this.$( '#pid' ).val() || null,
			explained_consent: $( '#explained_consent' ).is( ':checked' ),
			explained_records_request: $( '#explained_records_request' ).is( ':checked' ),
			// The next value needs to be approved by Deuben, Armen, or someone similar
			signed_consent: true,
			birthdate: $( '#birthdate' ).val() || null,
			preferred_language:preferredLanguage,
			email,
			no_email: !Boolean(email),
			icd10code_1: this.parseICD10Code( $( '#icd10code_1' ).val() ) || null,
			icd10code_2: this.parseICD10Code( $( '#icd10code_2' ).val() ) || null,
			phone: primary_phone.val() || null,
			home_phone: primary_phone.val() || null,
			cell_phone: secondary_phone.val() || null,
			existing_patient: $( '#existing_patient' ).prop( 'checked' ),
			preferred_phone: primary_phone.val(),
		});
		this.model.addPatient();
	},

	displayValidations: function() {
		if ( this.model.get( 'errors' ) && this.model.get( 'errors' ).length ) {
			let validationsModel = new ValidationsModel({ errors: this.model.get( 'errors' ) });
			this.showChildView( 'validationContainer', new ValidationsContainerView({ model: validationsModel }) );
			$( '.modal' ).scrollTop( 0 );
		}
	},

	removeErrors: function() {
		this.$( 'input' ).each( ( index, value ) => {
			let selector = $( value );
			selector.hasClass( 'invalid' ) && selector.removeClass( 'invalid' );
		});
		this.$( 'select' ).each( ( index, value ) => {
			let selector = $( value );
			selector.hasClass( 'invalid' ) && selector.removeClass( 'invalid' );
		});
	},

	parseICD10Code: function( value ) {
		if ( value ) {
			if ( value.indexOf( ' - ' ) > -1 ) {
				return value.slice( 0, value.indexOf( ' - ' ) ).trim();
			} else {
				return value.toUpperCase();
			}
		}
		return;
	},

});
