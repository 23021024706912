import Backbone from 'backbone';
import _ from 'underscore';

import { EnrolledModel } from './EnrolledModel';

export const EnrolledCollection = Backbone.Collection.extend({

	model: EnrolledModel,
	
	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	downloadCSV: function() {
		alert('download CSV');
	}

});