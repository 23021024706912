// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import patientListContainerView  from '../../../../templates/CCM/PatientList/patientListContainerView';
import PatientListCompositeView from './PatientListCompositeView';
import PatientTotalsView from './PatientTotalsView';
import AddPatientModalView from './AddPatientModalView';

// Internal Dependencies - models/collections
import { EnrollPatientModel } from './models/EnrollPatientModel';
import {PatientListCollection} from "./models/PatientListCollection";

export default Marionette.LayoutView.extend({

	events: {
		'click #addPatient': 'launchAddPatient'
	},

	regions: {
		patientList: '.patient-list-container',
		patientTotals: '.patient-totals-container'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection = new PatientListCollection();
	},

	getTemplate: function() {
		return patientListContainerView( this.model );
	},

	onShow: function() {
		this.showChildView( 'patientTotals', new PatientTotalsView( {collection: this.collection} ) );
		this.showChildView( 'patientList', new PatientListCompositeView( {collection: this.collection} ) );
	},

	launchAddPatient: function( e ) {
		e.preventDefault();
		let addPatientModel = new EnrollPatientModel();
		let modalView = new AddPatientModalView({ model: addPatientModel });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalAddPatient' ).openModal();
		this.channel.trigger( 'trigger:show' );
	}

});
