// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import patientListCompositeView  from '../../../../templates/CCM/PatientList/patientListCompositeView';
import PatientListItemView from './PatientListItemView';
import EnrollPatientModalView from './EnrollPatientModalView';

// Internal Dependencies - models/collections
import { PatientListCollection } from './models/PatientListCollection';
import { PatientListModel } from './models/PatientListModel';

export default Marionette.CompositeView.extend({

	className: 'patient-list-composite-view table',

	childView: PatientListItemView,

	childViewContainer: '.patient-list-container',

	events: {
		'click .clear-search': 'resetSearch',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.currentPage = 0;
		this.pageSize = 20;
		this.controller = this.app.ccm_controller;

		let searchObj = {
			placeholder: 'Search first name, last name, or PID'
		};

		this.channel.trigger( 'show:search', searchObj, this.collection );

		this.listenTo( this.channel, 'set:patientList', this.setPatientList );
		this.listenTo( this.collection, 'update', this.render );
		this.listenTo( this.channel, 'rerender', this.render);
		this.listenTo( this.channel, 'reset:currentPage', this.resetCurrentPage );
		// this.listenTo( this.channel, 'set:selectedProviderId', this.controller.getPatientList.bind( this.controller ) );
		this.collection.loading = true;
        this.controller.getPatientList();

		this.channel.reply( 'currentPage', () => {
			return this.currentPage;
		});
		this.channel.reply( 'pageSize', () => {
			return this.pageSize;
		});
		$('.content-container').addClass('widen');
	},

	getTemplate: function() {
		return patientListCompositeView( this.collection );
	},

	setPatientList: function() {
		this.collection.loading = false;
		this.collection.reset();
		let patients = this.controller.patientList.map( ( patient ) => new PatientListModel( patient ) );
		this.collection.renderNoResults = patients && patients.length ? false : true;
		let search = this.controller.searchInput;
		this.collection.search = search ? search.substring( search.lastIndexOf("=") + 1 ) : '';
		this.collection.add( patients );
		!patients.length && this.render();
	},

	resetSearch: function() {
		this.channel.trigger( 'reset:search' );
	},

	resetCurrentPage: function() {
		this.currentPage = 0;
		this.controller.patients.currentPage = this.currentPage;
	},


	onDestroy: function() {
		this.channel.trigger( 'remove:search' );
		this.controller.clearValue( 'patientList' );
		this.controller.clearValue( 'sortedBy' );
		$('.content-container').removeClass('widen');
	},

});
