// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import changePasswordContainerView  from '../../../../templates/Utilities/ChangePassword/changePasswordContainerView';
import ChangePasswordCompositeView from './ChangePasswordCompositeView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	regions: {
		'changePasswordCompositeView': '.change-password-composite-container'
	},

	getTemplate: function() {
		return changePasswordContainerView( );
	},

	onShow: function() {
		this.showChildView( 'changePasswordCompositeView', new ChangePasswordCompositeView() );
	}

});
