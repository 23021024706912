// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import addActivityModalView  from '../../../../templates/MIPS/ImprovementActivities/addActivityModalView';
import ActivityFilterView from './ActivityFilterView';
import ExpandedActivityView from './ExpandedActivityView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

    regions: {
        'activityFilter': '.activity-filter',
        'activityExpanded': '.activity-expanded',
    },

    events: {
        'click .cancel': 'closeModal',
        'keyup #improvementActivity': 'runFilters',
        'click .activity': 'selectActivity',
        'change #highPointValues': 'runFilters',
        'change #lowPointValues': 'runFilters',
        'click #trackActivity': 'trackActivity',
    },

    ui: {
        improvementActivity: '#improvementActivity',
        highPointValues: '#highPointValues',
        lowPointValues: '#lowPointValues',
        trackActivity: '#trackActivity',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.listenTo( this.channel, 'close:modal', this.closeModal );
        this.listenTo( this.channel, 'trigger:show', this.showFilter );
        this.listenTo( this.model, 'change:selectedActivity', this.determineShowExpanded );
    },

    getTemplate: function() {
        return addActivityModalView( this.model );
    },

    showFilter: function() {
        this.showChildView( 'activityFilter', new ActivityFilterView({ model: this.model }) );
    },

    runFilters: function() {
        let userInput = this.ui.improvementActivity.val();
        let highPointValues = this.ui.highPointValues.prop('checked');
        let lowPointValues = this.ui.lowPointValues.prop('checked');
        this.model.filterActivities( userInput, highPointValues, lowPointValues );
    },

    selectActivity: function( e ) {
        this.model.selectActivity( e );
    },

    closeModal: function() {
        $( '#modalAddActivity' ).closeModal();
        this.destroy();
        this.model.resetFilters();
    },

    determineShowExpanded: function() {
        if ( this.model.get( 'selectedActivity' ) && Object.keys( this.model.get( 'selectedActivity' ) ).length ) {
            this.showChildView( 'activityExpanded', new ExpandedActivityView({ model: this.model }) );
            this.ui.trackActivity.prop( 'disabled', false );
            return;
        }
        this.getRegion( 'activityExpanded' ).empty();
        this.ui.trackActivity.prop( 'disabled', true );
    },

    trackActivity: function() {
        this.model.set( 'data', {
            numerator: 1,
            denominator: 0,
            month: 0,
            year: 0,
            text_value: '',
        });
        this.model.trackActivity();
    },

});
