// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  mipsProviderSubNavView  from '../../../templates/MIPS/mipsProviderSubNavView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'set:mipsExclusions', this.setMipsExclusions );
		this.model.set( 'mipsExclusions', this.app.mips_controller.mipsExclusions );
		this.listenTo( this.model, 'change', this.addActive );
	},

	getTemplate: function() {
		return mipsProviderSubNavView( this.model );
	},

	onShow: function() {
		this.addActive();
	},

	onDomRefresh: function() {
		this.addActive();
	},

	setMipsExclusions: function() {
		this.model.set( 'mipsExclusions', this.app.mips_controller.mipsExclusions );
		this.render();
	},

	addActive: function() {
		// Remove active class from any subnav-items
		$( '.subnav-item a' ).removeClass( 'active' );

		// Get the URL hash and add the active class to the anchor linking to current page
		let currentLocation = window.location.hash;
		$('a[href="' + currentLocation + '"]').addClass('active');

		// Handling quality measures
		let qualityMeasures = $( '.quality-measures' );
		let advancingCarePanels = $( '.advancing-care-panels' );

		// If 'quality' is in the URL hash, add active class to the 'Quality' subnav-item
		// and find active qaulity measure route
		if ( currentLocation.indexOf( 'provider/quality' ) !== -1 ) {
			$('#MipsProviderQualityMeasures').addClass('active');
			this.whichQualityRoute();

			// If 'quality' is not in the URL hash, but the measure is visible (aka, the user just
			// navigated out of quality), slide up the folder so it is no longer visible
		} else if ( qualityMeasures.is(':visible') ) {
			qualityMeasures.slideUp();
		}

		if ( currentLocation.indexOf( 'provider/advancing-care' ) !== -1 ) {
			$('#MipsProviderAdvancingCare').addClass('active');
			this.whichAdvancingCareRoute();
		} else if ( advancingCarePanels.is(':visible') ) {
			advancingCarePanels.slideUp();
		}
	},

	whichQualityRoute: function() {
		// Remove active from all quality routes
		$( '.quality-measures-measure a' ).removeClass( 'active' );

		// Get the active quality measure
		let qualityMeasures = $( '.quality-measures' );
		let currentLocation = window.location.hash;
		let currentMeasure = currentLocation.slice( currentLocation.lastIndexOf( '/' ) + 1 );
		let activeMeasure = this.determineActiveQuality( currentMeasure );
		qualityMeasures.slideDown();
		$( '#' + activeMeasure ).addClass( 'active' );
	},

	whichAdvancingCareRoute: function() {
		// Remove active from all quality routes
		$( '.advancing-care-panels a' ).removeClass( 'active' );

		// Get the active quality measure
		let advancingCarePanels = $( '.advancing-care-panels' );
		let currentLocation = window.location.hash;
		let currentAdvancingCarePanel = currentLocation.slice( currentLocation.lastIndexOf( '/' ) + 1 );
		let activeAdvancingCarePanel = this.determineAdvancingCare( currentAdvancingCarePanel );
		advancingCarePanels.slideDown();
		$( '#' + activeAdvancingCarePanel ).addClass( 'active' );
	},

	determineActiveQuality: function( currentFolder ) {
		// Takes the last segment of the url and maps it to the ID of the corresponding route
		switch( currentFolder ) {
			case 'summary':
				return 'QualityMeasuresSummary';
			case 'diabetes-poor-control':
				return 'DiabetesPoorControl';
			case 'tobacco-Use':
				return 'TobaccoUse';
			case 'advance-care-plan':
				return 'AdvanceCarePlan';
			case 'pneumococcal-vaccination':
				return 'PneumococcalVaccination';
			case 'fall-risk-assessment':
				return 'FallRiskAssessment';
			case 'fall-care-plan':
				return 'FallCarePlan';
			default:
				return 'Summary';
		}
	},

	determineAdvancingCare: function( currentFolder ) {
		// Takes the last segment of the url and maps it to the ID of the corresponding route
		switch( currentFolder ) {
			case 'summary':
				return 'AdvancingCareSummary'
			case 'base-score':
				return 'BaseScore';
			case 'performance-score':
				return 'PerformanceScore';
			case 'bonus-score':
				return 'BonusScore';
			default:
				return 'Summary';
		}
	},

});
