import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import unenrollPatientModalView  from '../../../../templates/CCM/PatientList/unenrollPatientModalView';

export default Marionette.LayoutView.extend({
  events: {
    'click .modal-close': 'closeModal',
    'click .enroll-patient': 'enrollPatient',
    'change #unenroll_reason_id': 'checkSelectedReason',
    'click #confirm_check': 'confirmCheckSelection'
  },

  initialize: function( options ) {
    this.callback = options.callback;
    this.channel = Backbone.Radio.channel( 'AppChannel' );
    this.app = this.channel.request( 'app' );
    this.listenTo( this.channel, 'close:modal', this.closeModal );
    this.listenTo( this.channel, 'remove:errors', this.removeErrors );
  },

  getTemplate: function() {
    return unenrollPatientModalView( this.model );
  },

  closeModal: function( e ) {
    e && e.preventDefault();
    $( '#modalReject' ).closeModal();
    this.destroy();
  },

  enrollPatient: function( e ) {
    //-----
    e.preventDefault();
    const selectedReason = parseInt($( '#unenroll_reason_id' ).val());
    const notes = $( '#unenroll_notes' ).val().trim();
    $( '.id_validation' ).css({display: 'none'});
    $( '.note_validation' ).css({display: 'none'});
    $( '#unenroll_reason_id' ).hasClass( 'invalid' ) && $( '#unenroll_reason_id' ).removeClass( 'invalid' );
    $( '#unenroll_notes' ).hasClass( 'invalid' ) && $( '#unenroll_notes' ).removeClass( 'invalid' );
    if(!(selectedReason > -1)) {
      $('#unenroll_reason_id').addClass('invalid');
      $('.id_validation').css({display: 'inline-block'});
    }else if(selectedReason == 6 && !notes){
      $('#unenroll_notes').addClass('invalid');
      $('.note_validation').css({display: 'inline-block'});
    }else{
      this.app.ccm_controller.unenrollPatient( this.model.get( 'patient_id' ) , selectedReason, notes,this.callback);
      $( '#modalReject' ).closeModal();
      this.destroy();
    }


  },
  checkSelectedReason: function (e) {
    e.preventDefault();
    const selectedReason = $( '#unenroll_reason_id' ).val();
    if(selectedReason == -1){
      $( '#unenroll_reason_id' ).addClass( 'invalid' );
    }else{
      $( '#unenroll_reason_id' ).hasClass( 'invalid' ) && $( '#unenroll_reason_id' ).removeClass( 'invalid' )
    }
  },
  confirmCheckSelection: function(e){
    const checked = e.target.checked;
    if(checked){
    $('#UnenrollButtonId').removeClass('disabled');
    }else{
      $('#UnenrollButtonId').addClass('disabled');
    }
  }

});
