// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import forgotPasswordView from '../../../templates/forgotPasswordView'

// Internal Dependencies - models/collections
import ForgotPasswordModel from './models/ForgotPasswordModel';

export default Marionette.ItemView.extend({

	events: {
		'click .submit-password-reset': 'submitReset',
	},

	initialize: function() {
		this.model = new ForgotPasswordModel();
		this.channel = Backbone.Radio.channel('AppChannel');
		this.app = this.channel.request('app');
	},

	getTemplate: function() {
		return forgotPasswordView();
	},

	submitReset: function(e) {
		e && e.preventDefault();
		let email = this.$('#emailAddress').val();
		this.model.set({
			email
		})
		if ( this.model.validate() ) {
			this.model.resetPassword((data)=>{
				this.showSuccess(data);
			});
		} else {
			this.showError();
		}
	},

	showError: function() {
		let errorMessage = 'Please enter a valid email address';
		$('.error-content').text(errorMessage);
		$('.error').show();
		$('#emailAddress').focus();
	},

	showSuccess: function(data) {
		let errorMessage = data.reason || 'If you provided a valid user email address, you will receive a password reset email shortly.';
		$('.error').hide();
		$('.success-content').text(errorMessage);
		$('.success').show();
		$('.submit-password-reset').prop( 'disabled', true )
	}

});
