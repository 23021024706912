// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import enrollPatientModalView  from '../../../../templates/CCM/PatientList/enrollPatientModalView';
import ValidationsContainerView from '../../Validations/ValidationsContainerView';

// Internal Dependencies - models/collections
import { ValidationsModel } from '../../Validations/models/ValidationsModel';
import ProviderChangeConfirmationModelView  from './models/ProviderChangeConfirmationModelView';

export default Marionette.LayoutView.extend({

	regions: {
		'validationContainer': '.validations-container',
	},

	events: {
		'click .modal-close': 'closeModal',
		'click .enroll-patient': 'enrollPatient',
		'click .edit-patient': 'editPatient',
		'change #icd10code_1': 'checkSelectedCode',
		'change #icd10code_2': 'checkSelectedCode',
		'change #ccm_provider_id': 'onProviderSelected'
	},

	initialize: function( options ) {
		this.callback = options.callback;
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model.set( 'provider-options', this.app.controller.providers );
		this.listenTo( this.model, 'change', this.displayValidations );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
		this.listenTo( this.channel, 'remove:errors', this.removeErrors );
		this.listenTo( this.channel, 'trigger:show', this.showTriggered );
	},

	checkSelectedCode: function( e ) {
		if ( e.target.id == 'icd10code_1' ) {
			this.selectedCode1 = e.target.value;
			return;
		}
		if ( e.target.id == 'icd10code_2' ) {
			this.selectedCode2 = e.target.value;
			return;
		}
	},

	showTriggered: function() {
		const self = this;
		this.selectedCode1 = '';
		this.selectedCode2 = '';

		const selectedProviderId = this.model.get('ccm_provider_id');
		let providerObject = _.find( this.app.controller.providers, ( provider ) => provider.id == selectedProviderId );
		if(selectedProviderId == "-1"){
			$('#billingProviderNameId').text("--");
			$('#providerNameId').text("--");
			this.model.set('current_provider',null);
		}else{
			$('#billingProviderNameId').text(providerObject.billingProviderName ?  providerObject.billingProviderName : providerObject.name);
			$('#providerNameId').text(providerObject.name);
			this.model.set('current_provider',providerObject.name);
			this.model.set('current_billing_provider',providerObject.billingProviderName ?  providerObject.billingProviderName : providerObject.name);
		}

		$( 'select' ).material_select();
		$('#icd10code_1').devbridgeAutocomplete({
			serviceUrl: `${ this.app.controller.engageUrl }v1/codelookup/icd10?start=0`,
			onSelect: function( suggestion ) {
				self.selectedCode1 = suggestion.id;
			},
			paramName: 'search',
	    	maxHeight: 150,
	    	ajaxSettings: {
	    		headers: {
	    			'X-AUTH-TOKEN': this.app.controller.getToken()
	    		}
	    	},
	    	transformResult: function( data ) {
	    		let response = _.reject( JSON.parse( data ), ( item ) => item.id == self.selectedCode2 );
	    		return {
	    			suggestions: response.map( ( item ) => {
	    				return { value: `${ item.id } - ${ item.desc }`, id: item.id }
	    			})
	    		}
	    	},
	    	autoSelectFirst: true,
	    	noCache: true,
	    });
	    $('#icd10code_2').devbridgeAutocomplete({
	    	serviceUrl: `${ this.app.controller.engageUrl }v1/codelookup/icd10?start=0`,
			onSelect: function( suggestion ) {
				self.selectedCode2 = suggestion.id;
			},
			paramName: 'search',
	    	maxHeight: 150,
	    	ajaxSettings: {
	    		headers: {
	    			'X-AUTH-TOKEN': this.app.controller.getToken()
	    		}
	    	},
	    	transformResult: function( data ) {
	    		let response = _.reject( JSON.parse( data ), ( item ) => item.id == self.selectedCode1 );
	    		return {
	    			suggestions: response.map( ( item ) => {
	    				return { value: `${ item.id } - ${ item.desc }`, id: item.id }
	    			})
	    		}
	    	},
	    	autoSelectFirst: true,
	    	noCache: true,
	    });
	},

	getTemplate: function() {
		return enrollPatientModalView( this.model );
	},

	closeModal: function( e ) {
		e && e.preventDefault();
		$( '#modalEnrollPatient' ).closeModal();
		this.destroy();
	},

	enrollPatient: function( e ) {
		e.preventDefault();
		let primary_phone = $( '#primary_phone' );
		let secondary_phone = $( '#secondary_phone' );
		this.model.set({
			ccm_provider_id: this.$( '#ccm_provider_id' ).val(),
			pid: this.model.get( 'patient_id' ),
			patient_id: this.model.get( 'id' ),
			provider_id: this.$( '#ccm_provider_id' ).val(),
			explained_consent: $( '#explained_consent' ).prop( 'checked' ),
			explained_records_request: $( '#explained_records_request' ).prop( 'checked' ),
			signed_consent: $( '#signed_consent' ).prop( 'checked' ),
			birthdate: this.app.controller.formatDateForServer( this.model.get( 'birthdate' ) ),
			email: $( '#email' ).val(),
			no_email: !!email,
			icd10code_1: this.parseICD10Code( $( '#icd10code_1' ).val() ) || null,
			icd10code_2: this.parseICD10Code( $( '#icd10code_2' ).val() ) || null,
			phone: primary_phone.val(),
			home_phone: primary_phone.val(),
			mobile_phone: secondary_phone.val(),
			preferred_number: $( '[name="preferred_number"]:checked' ).prop( 'id' ),
			existing_patient: $( '#existing_patient' ).prop( 'checked' ),
			do_not_text: $( '#no_text' ).prop( 'checked' ),
			notes: $( '#notes' ).val(),
		});
		this.model.enrollPatient(this.callback);
	},

	editPatient: function( e ) {
		e.preventDefault();
		if(this.model.get('requested_provider')) {
			let primary_phone = $( '#primary_phone' );
			let secondary_phone = $( '#secondary_phone' );
			this.model.set({
				organization_id:null,
				patient_id: this.model.get( 'patient_id' ) || null,
				ccm_provider_id: this.$( '#ccm_provider_id' ).val() || this.model.get( 'ccm_provider_id' ) || null,
				birthdate: this.app.controller.formatDateForServer( this.model.get( 'birthdate' ) ) || null,
				email: $( '#email' ).val() || null,
				no_email: !!email,
				icd10code_1: this.parseICD10Code( $( '#icd10code_1' ).val() ) || null,
				icd10code_2: this.parseICD10Code( $( '#icd10code_2' ).val() ) || null,
				phone: primary_phone.val() || null,
				home_phone: primary_phone.val() || null,
				mobile_phone: secondary_phone.val() || null,
				existing_patient: $( '#existing_patient' ).prop( 'checked' ),
				do_not_text: $( '#no_text' ).prop( 'checked' ),
			});
			let modalView = new ProviderChangeConfirmationModelView({model: this.model});
			modalView.render();
			$('body').append(modalView.el);
			$('#modalReject').openModal();
		}else{
			this.onEdit();
		}

	},

	onEdit: function(){
		let primary_phone = $( '#primary_phone' );
		let secondary_phone = $( '#secondary_phone' );
		this.model.set({
			patient_id: this.model.get( 'patient_id' ) || null,
			organization_id:null,
			ccm_provider_id: this.$( '#ccm_provider_id' ).val() || this.model.get( 'ccm_provider_id' ) || null,
			birthdate: this.app.controller.formatDateForServer( this.model.get( 'birthdate' ) ) || null,
			email: $( '#email' ).val() || null,
			no_email: !!email,
			icd10code_1: this.parseICD10Code( $( '#icd10code_1' ).val() ) || null,
			icd10code_2: this.parseICD10Code( $( '#icd10code_2' ).val() ) || null,
			phone: primary_phone.val() || null,
			home_phone: primary_phone.val() || null,
			mobile_phone: secondary_phone.val() || null,
			existing_patient: $( '#existing_patient' ).prop( 'checked' ),
			do_not_text: $( '#no_text' ).prop( 'checked' ),
		});
		this.model.editPatient();
	},

	displayValidations: function() {
		let validationsModel = new ValidationsModel({ errors: this.model.get( 'errors' ) });
		this.showChildView( 'validationContainer', new ValidationsContainerView({ model: validationsModel }) );
	},

	removeErrors: function() {
		this.$( 'input' ).each( ( index, value ) => {
			let selector = $( value );
			selector.hasClass( 'invalid' ) && selector.removeClass( 'invalid' );
		});
		this.$( 'select' ).each( ( index, value ) => {
			let selector = $( value );
			selector.hasClass( 'invalid' ) && selector.removeClass( 'invalid' );
		});
	},
	onProviderSelected: function(e) {
		const selectedProviderId = e.target.value;
		let providerObject = _.find( this.app.controller.providers, ( provider ) => provider.id == selectedProviderId );
		if(selectedProviderId == "-1"){
			$('#billingProviderNameId').text("--");
			$('#providerNameId').text("--");
			this.model.set('requested_provider',null);
		}else{
			$('#billingProviderNameId').text(providerObject.billingProviderName ?  providerObject.billingProviderName : providerObject.name);
			$('#providerNameId').text(providerObject.name);
			this.model.set('requested_provider',providerObject.name);
			this.model.set('requested_billing_provider',providerObject.billingProviderName ?  providerObject.billingProviderName : providerObject.name);
		}

	},

	parseICD10Code: function( value ) {
		if ( value ) {
			if ( value.indexOf( ' - ' ) > -1 ) {
				return value.slice( 0, value.indexOf( ' - ' ) ).trim();
			} else {
				return value.toUpperCase();
			}
		}
		return;
	},

});
