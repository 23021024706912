// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import pendingReviewContainerView  from '../../../../../templates/CCM/RapidBill/PendingReview/pendingReviewContainerView';
import PendingReviewCompositeView from './PendingReviewCompositeView';
import PatientTotalsView from '../../PatientList/PatientTotalsView';
import {PendingReviewCollection} from "./models/PendingReviewCollection";

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	events: {
		'click #submitBilling': 'launchBillingSummary'
	},

	regions: {
		'pendingReviewContainer': '.pending-review-container',
		'patientTotals': '.patient-totals-container'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection = new PendingReviewCollection();
	},

	getTemplate: function() {
		return pendingReviewContainerView();
	},

	onShow: function() {
		this.showChildView( 'patientTotals', new PatientTotalsView( { sort: false, collection: this.collection } ) );
		this.showChildView( 'pendingReviewContainer', new PendingReviewCompositeView( { collection: this.collection } ) );
	}

});
