// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import  bonusScoreCardView  from '../../../../../templates/MIPS/AdvancingCare/BonusScore/bonusScoreCardView';
import DataEntryView from './DataEntryView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

    regions: {
        'fivePercentBonusContainer': '.five-percent-bonus-container',
        'tenPercentBonusContainer': '.ten-percent-bonus-container',
    },

    events: {
        'click @ui.saveEntry': 'saveEntry',
    },

    ui: {
        flipper: '.flipper',
        front: '.front',
        saveEntry: '#saveEntry',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );

        this.listenTo( this.channel, 'set:improvementActivities', this.setImprovementActivities );
    },

    onShow: function() {
        this.showChildView( 'fivePercentBonusContainer', new DataEntryView({ selected_criteria: this.model.get( 'selected_criteria' ), measureData: this.model.get( 'measureData' ) }) );
        if ( this.model.get( 'improvementActivities' ) ) {
            this.showChildView( 'tenPercentBonusContainer', new DataEntryView({
                selected_criteria: this.model.get( 'secondary_selected_criteria' ),
                measureData: this.model.get( 'secondaryMeasureData' ),
                improvementActivities: this.model.get( 'improvementActivities' ),
            }));
        }
    },

    getTemplate: function() {
        return bonusScoreCardView( this.model );
    },

    saveEntry: function() {
        let secondary_associated_text = _.find( this.app.mips_controller.improvementActivities, ( activity ) => Number( $('#improvementActivity').val() ) === activity.id );
        this.model.set({
            data: {
                numerator: Number($('#' + this.model.get('selected_criteria').name + '_completed').val()) || 0,
                denominator: 0,
                month: 0,
                year: 0,
                text_value: $('#registryName').val() || '',
            },
            secondary_data: {
                numerator: Number($('#' + this.model.get('secondary_selected_criteria').name + '_completed').val()) || 0,
                denominator: 0,
                month: 0,
                year: 0,
                text_value: secondary_associated_text ? secondary_associated_text.activity : '',
            },
        });

        this.model.saveMeasureData();
    },

});
