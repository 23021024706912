// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import NotificationConversationItemView from './NotificationConversationItemView';

// Internal Dependencies - models/collections

export default Marionette.CollectionView.extend({

	childView: NotificationConversationItemView,

	className: 'row',

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection.sort();
		if (!this.model.get('differentProvider') && !this.model.get( 'read' ) ) {
			this.markAsRead();
		}
		this.listenTo( this.collection, 'add', this.focusOnLast );
	},

	onShow: function() {
		$(".notification-items-collection").scrollTop($(".notification-items-collection")[0].scrollHeight)
	},

	onDomRefresh: function() {
		if( this.collection.previousLastNotification ) {
			let offset = ($( '#' + this.collection.previousLastNotification ).offset().top);
			let previousOffset = this.collection.previousLastNotificationOffset;
			$(".notification-items-collection").scrollTop( offset - previousOffset );
		}
	},

	markAsRead: function() {
		let patientId = '';
		for(let i=0; i< this.collection.models.length; i++){
			const notification = this.collection.models[i];
			if ( !notification.get( 'read' ) && notification.get( 'Send_type' ) == 'CCM' ) {
				patientId = notification.get('patient_id');
				break;
			};
		}
		this.app.main_controller.markPatientNotificationRead( patientId);
	},

});