import Backbone from 'backbone';

export const RapidReviewModel = Backbone.Model.extend({
    defaults: {
        recent_provider_changes: '',
        excluded_pids: '',
        additional_note: '',
        other_primary_insurances:'',
        other_secondary_insurances:''
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
    },

});
