import Backbone from 'backbone';

export const DeclineProviderApprovalModel = Backbone.Model.extend({

  defaults: {
    patient_id: ''
  },

  initialize: function( options ) {
    this.channel = Backbone.Radio.channel( 'AppChannel' );
    this.app = this.channel.request( 'app' );
  }
});