import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import providerchangeconfirmationmodalview  from '../../../../../templates/CCM/PatientList/providerChangeConfirmationModalView';

export default Marionette.LayoutView.extend({
  events: {
    'click .modal-close': 'closeModal',
    'click #confirmButton': 'confirmProviderChange'
  },

  initialize: function( options ) {
    this.channel = Backbone.Radio.channel( 'AppChannel' );
    this.app = this.channel.request( 'app' );
    this.listenTo( this.channel, 'close:modal', this.closeModal );
    this.listenTo( this.channel, 'remove:errors', this.removeErrors );
  },

  getTemplate: function() {
    return providerchangeconfirmationmodalview( this.model );
  },

  closeModal: function( e ) {
    e && e.preventDefault();
    $( '#modalReject' ).closeModal();
    this.destroy();
  },

  confirmProviderChange: function( e ) {
    e.preventDefault();
    this.model.editPatient();
    $( '#modalReject' ).closeModal();
    this.destroy();
  },

});
