//External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import changePasswordCompositeView  from '../../../../templates/Utilities/ChangePassword/changePasswordCompositeView';

// Internal Dependencies - models/collections
import { ChangePasswordModel } from './Models/ChangePasswordModel';

export default Marionette.ItemView.extend({

	className: 'change-password-composite-view',

	events: {
		'click #submitPassword': 'submitPasswordReset'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new ChangePasswordModel({ user: this.app.controller.user });
		this.listenTo ( this.channel, 'changePassword:success', this.changePasswordSucccess );
		this.listenTo ( this.channel, 'changePassword:failure', this.flipText );
	},

	getTemplate: function() {
		return changePasswordCompositeView( this.model );
	},

	onShow: function() {
		if ( this.model.get( 'user' ) && this.model.get( 'user' ).changePassword ) {
			this.app.controller.confirmationModal( 'For security reasons, we ask that you change your password.' );
		}
	},

	submitPasswordReset: function( e ) {
		e && e.preventDefault();
		let passwords = {
				oldPassword: $('#oldPassword').val(),
				newPassword: $('#newPassword').val(),
				confirmPassword: $('#confirmPassword').val(),
				id: this.app.controller.user.id
			}
		let isValidMatch = this.model.isValidMatch( passwords );
		let pwMatchError = $('#pwMatchError');

		if ( isValidMatch ){
			if ( pwMatchError.is(':visible') ){
				pwMatchError.addClass('hide')
			}
		} else {
			if ( !pwMatchError.is(':visible') ){
				pwMatchError.removeClass('hide')
			}
		}

		if ( isValidMatch ) {
			$('#submitPassword').html('Submitting');
			this.model.changePassword( passwords );
			return;
		}
	},

	flipText: function() {
		$('#submitPassword').html('Submit');
	},

	changePasswordSucccess: function() {
		$('.password').val('');
		this.flipText();
		$('.change-success').removeClass('hide');
		setTimeout(function(){
            $('.change-success').addClass('hide');
    	}, 3500);
	}
});
