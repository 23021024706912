// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import improvementActivitiesContainerContainerView  from '../../../../templates/MIPS/ImprovementActivities/improvementActivitiesContainerContainerView';
import ImprovementActivitiesContainerView from './ImprovementActivitiesContainerView';
import ProviderPlaceholderView from '../ProviderPlaceholderView';

// Internal Dependencies - models/collections
import { ContainerModel } from '../models/ContainerModel';

export default Marionette.LayoutView.extend({

    regions: {
        'improvementActivitiesContainer': '#improvementActivitiesContainerView'
    },

    initialize: function( options ) {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.model = new ContainerModel();
        this.score = options.score;
        this.title = options.title;

        if ( this.model.get( 'currentApp' ) === 'MIPSPROVIDER' ) {
            this.listenTo( this.model, 'change:showPlaceholder', this.checkPlaceholder );
        }
    },

    getTemplate: function() {
        return improvementActivitiesContainerContainerView( this.model );
    },

    onShow: function() {
        if ( this.model.get('showPlaceholder') ) {
            this.showChildView( 'improvementActivitiesContainer', new ProviderPlaceholderView( { route: this.title } ) );
        } else {
            this.showChildView( 'improvementActivitiesContainer', new ImprovementActivitiesContainerView( { measure: this.score } ) );
        }
    },

    checkPlaceholder: function() {
        if ( this.model.get('showPlaceholder') ) {
            this.showChildView( 'improvementActivitiesContainer', new ProviderPlaceholderView( { route: this.title } ) );
        } else {
            this.showChildView( 'improvementActivitiesContainer', new ImprovementActivitiesContainerView( { measure: this.score } ) );
        }
    },

});
