// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  resourcesCompositeView  from '../../../../templates/Utilities/Resources/resourcesCompositeView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	tagName: 'div',

	className: 'resources-composite-view',

	getTemplate: function() {
		return resourcesCompositeView( this.model );
	}

});
