import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import providerTransitionModalView  from '../../../../templates/CCM/PatientList/providerTransitionModalView';

export default Marionette.LayoutView.extend({
  events: {
    'click .modal-close': 'closeModal',
    'click .enroll-patient': 'saveProvider',
    'change #selectedCurrentProvider': 'checkSelectedProvider'
  },

  initialize: function( options ) {
    this.callback = options.callback;
    this.channel = Backbone.Radio.channel( 'AppChannel' );
    this.app = this.channel.request( 'app' );
    this.listenTo( this.channel, 'close:modal', this.closeModal );
    this.listenTo( this.channel, 'remove:errors', this.removeErrors );
    this.listenTo( this.model, 'set:bulkChangeProviders', ()=>{this.render()} );
  },

  getTemplate: function() {
    return providerTransitionModalView( this.model );
  },

  closeModal: function( e ) {
    e && e.preventDefault();
    $( '#modalReject' ).closeModal();
    this.destroy();
  },

  saveProvider: function( e ) {
    e.preventDefault();
    const selectedProvider = parseInt($( '#selectedCurrentProvider' ).val());
    $( '.id_validation' ).css({display: 'none'});
    $( '#selectedCurrentProvider' ).hasClass( 'invalid' ) && $( '#selectedCurrentProvider' ).removeClass( 'invalid' );
    if(!(selectedProvider > -1)) {
        $('#selectedCurrentProvider').addClass('invalid');
	    $('.id_validation').css({display: 'inline-block'});
    }else{
      this.app.ccm_controller.providerTransitionPatient( this.model.get( 'ccm_patient_id' ) , selectedProvider,this.callback);
      $( '#modalReject' ).closeModal();
      this.destroy();
    }


  },
  checkSelectedProvider: function (e) {
    e.preventDefault();
    const selectedProvider = $( '#selectedCurrentProvider' ).val();
    if(selectedProvider == -1){
      $( '#selectedProvider' ).addClass( 'invalid' );
    }else{
      $( '#selectedProvider' ).hasClass( 'invalid' ) && $( '#selectedProvider' ).removeClass( 'invalid' )
    }
  }

});
