import Backbone from 'backbone';

const ResetPasswordModel = Backbone.Model.extend({

    defaults: {
        password: '',
        confirmPassword:''
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );

    },

    validate: function() {
        let password = this.get( 'password' ).trim();
        let confirmPassword = this.get( 'confirmPassword' ).trim();
        if ( password && confirmPassword && password == confirmPassword) {
            return true;
        }
        return false;
    },

    changePassword: function(callback) {
        this.app.controller.resetChangePassword( this.get('token'),this.get('password'),callback );
    }

});

export default ResetPasswordModel;