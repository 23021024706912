// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import unenrolledItemView  from '../../../../templates/CCM/Unenrolled/unenrolledItemView';
import {EnrollPatientModel} from "../PatientList/models/EnrollPatientModel";
import EnrollPatientModalView from "../PatientList/EnrollPatientModalView";
import SpecialistRecordsModalView from "../PatientList/SpecialistRecordsModalView";

export default Marionette.ItemView.extend({

	className: 'table-row clearfix',

	events: {
		'click .more': 'expandContent',
		'click .less': 'expandContent',
        'click .open-patient-plan': 'viewCarePlan',
        'click .open-ccda': 'viewCcda',
		'click .enroll': 'launchEnrollPatient',
		'click .specialist-records':'openRecordsModal'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	getTemplate: function() {
		return unenrolledItemView( this.model );
	},

	viewCarePlan: function() {
		let patientId = this.model.get( 'id' );
		this.model.viewCarePlan( patientId );
	},

    viewCcda: function() {
        let patientId = this.model.get( 'id' );
        this.model.viewCcda( patientId );
    },

	expandContent: function( e ) {
		this.$( '.more' ).toggleClass( 'active' );
		this.$( '.less' ).toggleClass( 'active' );
		this.$( '.patient-list-expanded-data' ).slideToggle( 200 );
	},

	launchEnrollPatient: function( e ) {
		let enrollPatientModel = new EnrollPatientModel(Object.assign({},this.model.attributes,{edit_action:false} ) );
		let modalView = new EnrollPatientModalView({ model: enrollPatientModel,callback:()=>{
			    // alert('success');
				this.app.ccm_controller.getUnverifiedList();
			} });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#modalEnrollPatient' ).openModal();
		this.channel.trigger( 'trigger:show' );
	},
	openRecordsModal: function() {
		let modalView = new SpecialistRecordsModalView({model: this.model});
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#specialistRecordsModal' ).openModal();
		this.channel.trigger( 'trigger:records_modal' );
	},

});
