import Backbone from 'backbone';

import FileModel from './FileModel';

export const FileCollection = Backbone.Collection.extend({

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
    },

    getFiles: function() {
        this.app.controller.getFiles();
    },

});