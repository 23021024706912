// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import activitySelectContainerView  from '../../../../templates/MIPS/ImprovementActivities/activitySelectContainerView';
import AddActivityModalView from './AddActivityModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    events: {
        'click .launch-add-activity-modal': 'launchAddActivityModal',
        'click .fa-minus-circle': 'launchConfirmRemoveActivity',
        'mouseover .fa-minus-circle': 'addStrikeThrough',
        'mouseout .fa-minus-circle': 'removeStrikeThrough',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );

        this.listenTo( this.model, 'change', this.render );
    },

    getTemplate: function() {
        return activitySelectContainerView( this.model );
    },

    launchAddActivityModal: function() {
        if ( $( '#modalAddActivity' ).length ) {
            $( '#modalAddActivity' ).remove();
        }
        let modalView = new AddActivityModalView({ model: this.model });
        modalView.render();
        $( 'body' ).append( modalView.el );
        $( '#modalAddActivity' ).openModal();
        this.channel.trigger( 'trigger:show' );
    },

    launchConfirmRemoveActivity: function( e ) {
        let activity = e.target.id.slice( e.target.id.indexOf( '-' ) + 1 );
        this.model.confirmRemoveActivity( activity );
    },

    addStrikeThrough: function( e ) {
        $( `#${ e.target.id.slice( e.target.id.indexOf( '-' ) + 1 ) }` ).addClass( 'strike' );
    },

    removeStrikeThrough: function( e ) {
        $( `#${ e.target.id.slice( e.target.id.indexOf( '-' ) + 1 ) }` ).removeClass( 'strike' );
    },

});
