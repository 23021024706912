import Backbone from 'backbone';
import _ from 'underscore';

import { UnenrolledModel } from './UnenrolledModel';

export const UnenrolledCollection = Backbone.Collection.extend({

	model: UnenrolledModel,
	
	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

});