// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import dataEntryView  from '../../../../templates/MIPS/Quality/dataEntryView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    events: {
        'click @ui.saveEntry': 'saveEntry',
        'click @ui.editableToggle': 'toggleEdit',
    },

    ui: {
        saveEntry: '#saveEntry',
        numeratorInput: '#numeratorInput',
        denominatorInput: '#denominatorInput',
        monthInput: '#month',
        yearInput: '#year',
        editableToggle: '.edit-data',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.listenTo( this.model, 'change:monthValue change:yearValue change:editable', this.render );
    },

    getTemplate: function() {
        return dataEntryView( this.model );
    },

    toggleEdit: function() {
        this.model.set( 'editable', true );
    },

});
