// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import pendingUnenrollContainerView  from '../../../../templates/CCM/PendingUnenroll/pendingUnenrollContainerView';
import PendingUnenrollCompositeView from './PendingUnenrollCompositeView';
import PatientTotalsView from '../PatientList/PatientTotalsView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	regions: {
		'pendingUnenrollContainer': '.pending-unenroll-container',
		'patientTotals': '.patient-totals-container'
	},

	initialize: function () {
		this.collection = new Backbone.Collection();
	},

	getTemplate: function() {
		return pendingUnenrollContainerView();
	},

	onShow: function() {
		this.showChildView( 'patientTotals', new PatientTotalsView({collection: this.collection}) );
		this.showChildView( 'pendingUnenrollContainer', new PendingUnenrollCompositeView({collection: this.collection}) );
	}

});
