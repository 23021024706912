// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import prescriptionCardView  from '../../../../../templates/MIPS/AdvancingCare/BaseScore/prescriptionCardView';
import DataEntryView from './DataEntryView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

    regions: {
        'dataEntryContainer': '.data-entry-container',
    },

    events: {
        'click @ui.reverse': 'flipCard',
        'click @ui.saveEntry': 'saveEntry',
        'change @ui.monthInput': 'updateDataInput',
        'change @ui.yearInput': 'updateDataInput',
        'change @ui.criteriaSelect': 'updateCriteria',
    },

    ui: {
        reverse: '#reverse',
        flipper: '.flipper',
        front: '.front',
        back: '.back',
        flipContainer: '.flip-container',
        saveEntry: '#saveEntry',
        numeratorInput: '#numeratorInput',
        denominatorInput: '#denominatorInput',
        monthInput: '#month',
        yearInput: '#year',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.model.set( 'editable', true );
    },

    onShow: function() {
        this.ui.flipper.css({ 'min-height': this.ui.front.height() });
        this.showChildView( 'dataEntryContainer', new DataEntryView({ model: this.model }) );
        if ( this.model.get( 'measureData' ).historical_data.length && this.model.get( 'selected_criteria' ).name === 'e_prescription' ) {
            this.renderHistoricalDataChart();
        }
        this.updateDataInput();
    },

    onDomRefresh: function() {
        let minHeight = this.ui.front.height() > this.ui.back.height ? this.ui.front.height() : this.ui.back.height();
        this.ui.flipper.css({ 'min-height': minHeight });
        if ( this.model.get( 'measureData' ).historical_data.length && this.model.get( 'selected_criteria' ).name === 'e_prescription' ) {
            this.renderHistoricalDataChart();
        }
        this.updateDataInput();
    },

    getTemplate: function() {
        return prescriptionCardView( this.model );
    },

    flipCard: function( e ) {
        e.preventDefault();
        this.ui.flipContainer.toggleClass( 'active' );
        // Adjust height of container if necessary based on height of Front or Back, whichever is visible
    },

    updateDataInput: function() {
        const ui = this.ui;
        let monthValue = ui.monthInput.val();
        let yearValue = ui.yearInput.val();
        if ( this.checkHasFullDate( monthValue, yearValue ) && this.hasMonthData() ) {
            const measureData = this.model.get( 'measureData' );
            let matchingDataPoint = _.find( measureData.historical_data, (dataPoint ) => dataPoint.month && dataPoint.month === Number( monthValue ) );
            if ( matchingDataPoint ) {
                this.model.set({
                    numerator: matchingDataPoint.numerator,
                    denominator: matchingDataPoint.denominator,
                    editable: false,
	                monthValue,
	                yearValue,
                });
            } else {
                this.model.set({
	                numerator: '',
	                denominator: '',
	                editable: true,
	                monthValue,
	                yearValue,
                });
                this.model.trigger('change');
            }
        }
    },

    checkHasFullDate: function( month, year ) {
        return !!( month && year );
    },

    hasMonthData: function() {
        let measureData = this.model.get( 'measureData' );
        return measureData && measureData.historical_data && measureData.historical_data.length;
    },

    saveEntry: function() {
        let ui = this.ui;
        this.model.set( 'data', {
            numerator: Number( $('#numeratorInput').val() ) || 0,
            denominator: 0,
            month: ui.monthInput.val() || ( ( new Date() ).getMonth() + 1).toString(),
            year: ui.yearInput.val() || ( ( new Date() ).getYear() + 1900 ).toString(),
            text_value: '',
        });
        this.model.saveMeasureData( this.model.get( 'selected_criteria' ) );
    },

    renderHistoricalDataChart: function() {
        let data = [];
        if ( this.model.get( 'measureData' ).historical_data.length ) {
            this.model.get( 'measureData' ).historical_data.forEach( ( dataPoint, idx ) => {
                if ( idx !== dataPoint.month - 1 ) {
                    data.push({
                        x: idx,
                        y: 0,
                        color: this.model.colors['FAIL'],
                    });
                }
                const y = isNaN(dataPoint.score_raw) ? 0 : parseFloat(dataPoint.score_raw).toFixed(0)/1;
                data.push({
                    x: dataPoint.month - 1,
                    y: y,
                    color: this.model.colors[ dataPoint.score_threshold ] || this.model.colors[ 'blue' ] ,
                });
            });
        }
        if ( !data.length ) {
            if ( data.length == 0 ) {
                $('#historicalDataContainer').html( `<div class="center-align"><h5>No historical data is available</h5></div>` );
                return;
            }
        }
        Highcharts.chart('historicalDataContainer', {
            title: {
                text: null
            },
            chart: {
                type: 'column',
                height: 300,
            },
            credits: {
                enabled: false,
            },
            xAxis: {
                categories: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May','Jun','Jul','Aug','Sep','Oct','Nov','Dec' ],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Patients',
                },
                allowDecimals: false,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                color: 'white',
                name: 'Monthly Reported Data',
                data,
            }],
        });
    },

});
