// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import suspendedContainerView  from '../../../../templates/CCM/Suspended/suspendedContainerView';
import SuspendedCompositeView from './SuspendedCompositeView';
import PatientTotalsView from '../PatientList/PatientTotalsView';

// Internal Dependencies - models/collections
import { SuspendedCollection } from './models/SuspendedCollection';

export default Marionette.LayoutView.extend({

	regions: {
		'suspendedContainerView': '.suspended-container-view',
		'patientTotals': '.patient-totals-container'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection = new SuspendedCollection();
	},

	getTemplate: function() {
		return suspendedContainerView( this.model );
	},

	onShow: function() {
		this.showChildView( 'suspendedContainerView', new SuspendedCompositeView({collection: this.collection}) );
		this.showChildView( 'patientTotals', new PatientTotalsView({collection: this.collection}) );
	}

});
