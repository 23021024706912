import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';
import rapidReviewCompositeView from '../../../../templates/PatientEnrollment/RapidReview/rapidReviewCompositeView';
import { RapidReviewModel } from './models/RapidReviewModel';
import { InsuranceCollection } from "./models/InsuranceCollection";
import { InsuranceModel } from "./models/InsuranceModel";
import { EditInsuranceModel } from "./models/EditInsuranceModel"
import { BulkChangeInsuranceModel } from "./models/BulkChangeInsuranceModel"
import EditInsuranceModalView from './EditInsuranceModalView';
import BulkChangeInsuranceModalView from './BulkChangeInsuranceModalView';
import moment from 'moment';

export default Marionette.LayoutView.extend({

    events: {
        'change #sortBy': 'onSortByChanged',
        'change #filterBy':'onFilterSelected',
        'click [name="editInsurance"]':'editInsurance',
        'click [name="viewInsurance"]':'viewInsurance',
        'click #pageRight':'onNextPage',
        'click #pageLeft':'onPreviousPage',
        'click #downloadCSV':'downloadCSV',
        'change #pageSize':'pageSizeChanged',
        'click #insuranceNameColumn':'soryByInsuranceName',
        'click #bulkChange':'bulkChange',
        'change #select-all': 'selectAll'
    },
    initialize: function () {
        this.channel = Backbone.Radio.channel('AppChannel');
        this.app = this.channel.request('app');
        this.model = new RapidReviewModel();
        this.model.set({ 'isCSAdmin': this.app.controller.user && this.app.controller.user.csadmin });
        this.model.set('filterBy',1);
        let searchObj = {
			placeholder: 'Search by Insurance Name'
		}
		this.channel.trigger( 'show:search', searchObj,this.model );

        this.collection = new InsuranceCollection();
        this.listenTo(this.channel, 'set:insurancesAndRapidReview', this.setInsuranceCollection);
        this.listenTo(this.model, 'change:renderNoResults', this.render);
        this.listenTo(this.channel, 'change:reRender', this.render);
        this.listenTo(this.channel,'reset:searchInsuranceValue',this.search)

        this.collection.getInsurances();
    },
    getTemplate: function () {
        return rapidReviewCompositeView(this.model);
    },
    setInsuranceCollection: function () {
        const insuranceRapidReviewData = this.app.controller.insurancesAndRapidReview;
        const integratedIncludes = insuranceRapidReviewData.integratedIncludes;
        const rinConUserData = insuranceRapidReviewData.userData;
        this.model.set("rinConUserData", rinConUserData);

        let insurances = integratedIncludes.map((insurance) => {
            const createdAt = insurance.createdAt;
            const updatedAt = insurance.updatedAt;
            
            let updatedDateTime = updatedAt ? updatedAt : createdAt;
            updatedDateTime = moment(updatedDateTime).format('MM/DD/YYYY HH:mm')

            return new InsuranceModel(Object.assign({}, insurance, {
                includeForEligibilityPrimary: insurance && insurance.includeForEligibilityPrimary  ? insurance.includeForEligibilityPrimary : false ,
                includeForEligibilitySecondary: insurance && insurance.includeForEligibilitySecondary  ? insurance.includeForEligibilitySecondary : false,
                updatedDateTime
            }));
        });
        insurances = _.sortBy(insurances, (o) => {
            return o.get('name');
        })
        this.model.set("sortedBy", 1);
        this.model.set('maxSize',insurances.length)
        this.model.set('itemCount',insurances.length)
        this.model.set('originalInsurances', insurances);
        this.model.set('insurances', insurances);
        this.model.set('renderNoResults', !insurances.length);
        this.onSortByChanged();

    },
    pageSizeChanged: function(){
        let selectedPageSize = $('#pageSize').val();
        if(selectedPageSize == 'All'){
            this.model.set('itemCount',parseInt(this.model.get('maxSize')+''));
        }else{
            this.model.set('itemCount',parseInt(selectedPageSize));
        }
        
        this.onSortByChanged();
    },
    onFilterSelected: function(){
        let filterBy = $('#filterBy').val();
        this.model.set('filterBy',parseInt(filterBy));
        this.onSortByChanged();
    },
    setPagination: function(insurances,currentPage){
        
        let itemCount = this.model.get('itemCount');
        itemCount = itemCount ? itemCount : 20;
        const totalInsurance = insurances.length;
        const totalPages = Math.ceil(totalInsurance/2);
        this.model.set('totalPages',totalPages);
        const beginIndex = currentPage == 0 ? 1 : currentPage * itemCount + 1;
        const endIndex = ( currentPage + 1 ) * itemCount >= totalInsurance ? totalInsurance : ( currentPage + 1 ) * itemCount;

        this.model.set({
			totalInsurance,
			currentPage,
			beginIndex,
			endIndex
		});
        const currentList = insurances.slice(beginIndex - 1, endIndex);
        this.model.set('renderNoResults', !currentList.length);
        this.model.set('currentList', currentList);
    },
    onNextPage: function(){
        let itemCount = this.model.get('itemCount');
        itemCount = itemCount ? itemCount : 20;
        const insurances = this.model.get('insurances');
        const totalInsurance = insurances.length;
        const totalPages = Math.ceil(totalInsurance/2);
        this.model.set('totalPages',totalPages);
        let currentPage = this.model.get('currentPage');
        let endIndex = this.model.get('endIndex');
        if(endIndex >= totalInsurance ){
            return;
        }
        currentPage = currentPage + 1;
        const beginIndex = currentPage == 0 ? 1 : currentPage * itemCount + 1;
        endIndex = ( currentPage + 1 ) * itemCount >= totalInsurance ? totalInsurance : ( currentPage + 1 ) * itemCount;
       
        this.model.set({
			totalInsurance,
			currentPage,
			beginIndex,
			endIndex
		});
        const currentList = insurances.slice(beginIndex - 1, endIndex);
        this.model.set('currentList', currentList);
        this.render();
    },
    onPreviousPage: function(){
        const insurances = this.model.get('insurances');
        let itemCount = this.model.get('itemCount');
        itemCount = itemCount ? itemCount : 20;
        const totalInsurance = insurances.length;
        const totalPages = Math.ceil(totalInsurance/2);
        this.model.set('totalPages',totalPages);
        let currentPage = this.model.get('currentPage');
        if(currentPage == 0){
            return;
        }
        currentPage = currentPage - 1;
        const beginIndex = currentPage == 0 ? 1 : currentPage * itemCount + 1;
        const endIndex = ( currentPage + 1 ) * itemCount >= totalInsurance ? totalInsurance : ( currentPage + 1 ) * itemCount;

        this.model.set({
			totalInsurance,
			currentPage,
			beginIndex,
			endIndex
		});
        const currentList = insurances.slice(beginIndex - 1, endIndex);
        this.model.set('currentList', currentList);
        this.render();
    },
    soryByInsuranceName: function(){
        const currentSortyBy = this.model.get('sortedBy');
        let newSortBy = '1';
        if(currentSortyBy == '1'){
            newSortBy = '2';
        }
        this.model.set('sortedBy',newSortBy);
        this.onSortByChanged();
    },
    onSortByChanged: function (e) {
        const selectedSortBy = this.model.get('sortedBy');
        let insurances = this.model.get('originalInsurances');
        const filterBy = this.model.get('filterBy');
        const searchFilter = this.app.ccm_controller.searchInsuranceValue;
        if(filterBy == 2){
             insurances = _.filter(insurances,(insurance) => {
                return insurance.get('includeForEligibilityPrimary') && 
                insurance.get('includeForEligibilitySecondary')
            })
        }else if(filterBy == 3){
            insurances = _.filter(insurances,(insurance) => {
               return insurance.get('includeForEligibilityPrimary') && 
               !insurance.get('includeForEligibilitySecondary')
           })
       }else if(filterBy == 4){
            insurances = _.filter(insurances,(insurance) => {
            return !insurance.get('includeForEligibilityPrimary') && 
            insurance.get('includeForEligibilitySecondary')
            })
        }else if(filterBy == 5){
            insurances = _.filter(insurances,(insurance) => {
            return !insurance.get('includeForEligibilityPrimary') && 
                !insurance.get('includeForEligibilitySecondary')
            })
        }
        if(searchFilter && searchFilter.trim()){
            insurances = _.filter(insurances,(insurance) => {
                return ((insurance.get('name')).toLowerCase()).startsWith((searchFilter.trim()).toLowerCase())
            });
        }
        switch (selectedSortBy) {
            case '1': {
                insurances = _.sortBy(insurances, (o) => {
                    return (o.get('name')+"").toLowerCase();
                })
                this.model.set("sortedBy", 1);
                this.model.set('insurances', insurances);
                this.setPagination(insurances,0);
                this.render();
                break;
            }
            case '2': {
                insurances = _.sortBy(insurances, (o) => {
                    return  (o.get('name')+"").toLowerCase();
                })
                this.model.set("sortedBy", 2);
                this.model.set('insurances', insurances.reverse());
                this.setPagination(insurances,0);
                this.render();
                break;
            }
            case '3': {
                insurances = _.sortBy(insurances, (o) => {
                    return (o.get('ehrID')+"").toLowerCase();
                })
                this.model.set("sortedBy", 3);
                this.model.set('insurances', insurances);
                this.setPagination(insurances,0);
                this.render();
                break;
            }
            case '4': {
                insurances = _.sortBy(insurances, (o) => {
                    return (o.get('ehrID')+"").toLowerCase();
                })
                this.model.set("sortedBy", 4);
                this.model.set('insurances', insurances.reverse());
                this.setPagination(insurances,0);
                this.render();
                break;
            }
            default: {
                insurances = _.sortBy(insurances, (o) => {
                    return (o.get('name')+"").toLowerCase();
                })
                this.model.set("sortedBy", 1);
                this.model.set('insurances', insurances);
                this.setPagination(insurances,0);
                this.render();
                break;
            }
        }
    },
    search: function() {
        this.onSortByChanged();
    },
    downloadCSV: function(){
        const insurances = this.model.get('insurances');
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += "Insurance Name,Plan ID,Include as Primary,Include as Secondary, Address"+"\n";
        _.each(insurances,(insurance) => {
            let addressStr = '';
            const address = insurance.get('address');
            if(address){
                if(address.address1){
                    addressStr += address.address1 +' '
                }
                if(address.address2){
                    addressStr += address.address2 +' '
                }
                if(address.city){
                    addressStr += address.city+' '
                }
                if(address.state){
                    addressStr += address.state+' '
                }
                if(address.zip){
                    addressStr += address.zip+' '
                }
                
                if(address.county){
                    addressStr += address.county+' '
                }
                if(address.country){
                    addressStr += address.country+' '
                }
            }
            if(!addressStr){
                addressStr = 'Not Available'
            }
            csvContent += insurance.get('name') +","+insurance.get('ehrID')  + "," + insurance.get('includeForEligibilityPrimary') + ","+ insurance.get('includeForEligibilitySecondary')  + "," + addressStr + "\n";
        });
        csvContent = csvContent.replaceAll("#"," ");
        const link = document.createElement("a");
        const encodedUri = encodeURI(csvContent);
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "insurances.csv");
        document.body.appendChild(link); // Required for FF
        link.click(); 
    },
    viewInsurance: function(e) {
        const insurances = this.model.get('insurances');
        const filteredResult =  _.filter(insurances,(insurance) => {
             return insurance.id == e.target.id
         })
         const rinConUserData = this.model.get("rinConUserData");
         const clonedModel = JSON.parse(JSON.stringify(filteredResult[0]));
         console.log("clonedModel:",clonedModel);
         const editmodel = new EditInsuranceModel();
         editmodel.set(clonedModel);
         editmodel.set('rinConUserId',rinConUserData.id)
         editmodel.set('viewOnly',true)
         let modalView = new EditInsuranceModalView({model:editmodel});
         
         modalView.render();
         $('body').append(modalView.el);
         $('#modalReview').openModal();
         this.channel.trigger('trigger:show');
    },
    editInsurance: function (e) {
        
       const insurances = this.model.get('insurances');
       const filteredResult =  _.filter(insurances,(insurance) => {
            return insurance.id == e.target.id
        })
        const rinConUserData = this.model.get("rinConUserData");
        const clonedModel = JSON.parse(JSON.stringify(filteredResult[0]));
        console.log("clonedModel:",clonedModel);
        const editmodel = new EditInsuranceModel();
        editmodel.set(clonedModel);
        editmodel.set('rinConUserId',rinConUserData.id)
        let modalView = new EditInsuranceModalView({model:editmodel});
        
        modalView.render();
        $('body').append(modalView.el);
        $('#modalReview').openModal();
        this.channel.trigger('trigger:show');
    },
    bulkChange: function() {
        const insurances = this.model.get('insurances');
        const selectedInsurances = _.filter(insurances, (insurance) => {
            return $('#select-' + insurance.get('id')).is(':checked');
        });
    
        if (selectedInsurances.length === 0) {
            alert('Please select at least one insurance to change.');
            return;
        }
    
        const rinConUserData = this.model.get("rinConUserData");
        const bulkEditModel = new BulkChangeInsuranceModel();
        bulkEditModel.set('rinConUserId', rinConUserData.id);

        let bulkChangeModalView = new BulkChangeInsuranceModalView({
            selectedInsurances: selectedInsurances,
            model: bulkEditModel
        });
    
        bulkChangeModalView.render();
        $('body').append(bulkChangeModalView.el);
        $('#bulkChangeModal').openModal();
    
        this.listenTo(bulkChangeModalView, 'bulk:change:completed', function() {
            this.collection.fetch();
        });
    },
    selectAll: function(e){
        const insurances = this.model.get('insurances');
        _.each(insurances,(insurance) => {
            $('#select-' + insurance.get('id')).prop('checked',e.target.checked);
        });
    }
})
