// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import  securityCardView  from '../../../../../templates/MIPS/AdvancingCare/BaseScore/securityCardView';
import { ADVANCING_CARE_SCORES, MONTH_OPTIONS, YEAR_OPTIONS } from '../../constants';

// Internal Dependencies - models/collections
import { AdvancingCareModel } from '../models/AdvancingCareModel';

export default Marionette.LayoutView.extend({

    regions: {
        'cardContainer': '.card-container',
    },

    events: {
        'click @ui.saveEntry': 'saveEntry',
        'change @ui.monthInput': 'updateDataInput',
        'change @ui.yearInput': 'updateDataInput',
        'change @ui.criteriaSelect': 'updateCriteria',
        'click @ui.editableToggle': 'toggleEdit',
    },

    ui: {
        front: '.front',
        flipper: '.flipper',
        flipContainer: '.flip-container',
        saveEntry: '#saveEntry',
        criteriaCompleted: '#criteriaCompleted',
        analysisDate: '#analysisDate',
        editableToggle: '.edit-data',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );

        this.setEditable();

        this.listenTo( this.model, 'change', this.render );
        this.listenTo( this.model, 'change:measureData', this.setEditable );
    },

    onShow: function() {
        this.ui.flipper.css({ 'min-height': this.ui.front.height() });
    },

    onDomRefresh: function() {
        this.ui.flipper.css({ 'min-height': this.ui.front.height() });
    },

    getTemplate: function() {
        return securityCardView( this.model );
    },

    setEditable: function() {
        let measureData = this.model.get( 'measureData' );
        if ( measureData && measureData.historical_data && measureData.historical_data.length ) {
            this.model.set({
                editable: false,
                fieldData: measureData.historical_data[0],
            });
        }

    },

    saveEntry: function( e ) {
        e.preventDefault();
        this.model.set( 'data', {
            numerator: Number( this.ui.criteriaCompleted.val() ) || 0,
            denominator: 0,
            month: 0,
            year: 0,
            text_value: this.ui.analysisDate.val(),
        });
        this.model.saveMeasureData( this.model.get( 'score' ) );
    },

    toggleEdit: function() {
        this.model.set( 'editable', true );
    },

});
