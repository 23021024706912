// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import dataEntryView  from '../../../../../templates/MIPS/AdvancingCare/BonusScore/dataEntryView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

    events: {
        'click @ui.editableToggle': 'toggleEdit',
        'change .criteria-completed': 'checkInputStatus',
    },

    ui: {
        editableToggle: '.edit-data',
    },

    initialize: function( options ) {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.model = new Backbone.Model( options );
        this.controller = this.app.mips_controller;
        this.setEditable();
        if ( this.model.get( 'selected_criteria' ).name === 'improvement_activity_bonus' && !this.controller.improvementActivities.length ) {
            this.controller.getImprovementActivities();
        } else if ( this.controller.improvementActivities.length ) {
            this.model.set( 'improvementActivities', this.controller.improvementActivities )
        }
        this.listenTo( this.model, 'change', this.render );
        this.listenTo( this.channel, 'set:improvementActivities', this.setImprovementActivities );
    },

    getTemplate: function() {
        return dataEntryView( this.model );
    },

    toggleEdit: function() {
        this.model.set( 'editable', true );
        this.checkInputStatus();
    },

    setEditable: function() {
        let measureData = this.model.get( 'measureData' );
        if ( measureData && measureData.historical_data && measureData.historical_data.length ) {
            this.model.set({
                editable: false,
                fieldData: measureData.historical_data[0],
            });
        } else {
            this.model.set( 'editable', true );
        }
    },

    setImprovementActivities: function() {
        this.model.set( 'improvementActivities', this.controller.improvementActivities );
    },

    checkInputStatus: function() {
        if ( this.$('.criteria-completed').val() === "0" ) {
            if ( this.model.get( 'selected_criteria' ).name === 'improvement_activity_bonus' ) {
                const idSelector = $('#improvementActivity');
                idSelector.find('option:eq(0)').prop('selected', true);
                idSelector.attr('disabled', true);
            } else {
                const idSelector = $('#registryName');
                idSelector.val('');
                idSelector.attr('disabled', true);
            }
        } else {
            if ( this.model.get( 'selected_criteria' ).name === 'improvement_activity_bonus' ) {
                const idSelector = $('#improvementActivity');
                idSelector.attr('disabled', false);
            } else {
                const idSelector = $('#registryName');
                idSelector.attr('disabled', false);
            }
        }

    }

});
