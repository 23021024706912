// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  notificationsCompositeView  from '../../../../templates/Main/Notifications/notificationsCompositeView';
import NotificationItemView from './NotificationItemView';
import NewNotificationModalView from './NewNotificationModalView';

// Internal Dependencies - models/collections

export default Marionette.CompositeView.extend({

	className: 'notifications-composite-view',

	childViewContainer: '.notifications-collection',

	childView: NotificationItemView,

	events: {
		'click #pageRight': 'pageRight',
		'click #pageLeft': 'pageLeft',
		'click #queryNurse':'toggleNurse',
		'click #queryRefill':'toggleRefill',
		'click #queryAppointment':'toggleAppointment',
		'click .new-notification-button': 'launchNewNotification',
		'click .mark-read-button':'markUnreadNotification',
		'click .refresh-notification-button': 'refreshNotification',
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection = new Backbone.Collection();
		this.currentPage = 0;
		this.collection.queryNurse = false;
		this.collection.queryRefill = false;
		this.collection.queryAppointment = false;
		this.app.main_controller.setGlobalValue( 'notification_type', options.type );
		this.app.main_controller.getNotifications( this.currentPage );
		let searchObj = {
			placeholder: 'Search first name, last name, or PID'
		};

		this.channel.trigger( 'show:search', searchObj, this.collection );
		this.listenTo( this.channel, 'set:notifications', this.setNotifications );
		this.listenTo( this.channel, 'rerender', this.render);
		this.listenTo( this.channel, 'set:selectedProviderId', this.getNotifications );
		this.listenTo( this.collection, 'update', this.render );
		this.collection.loading = true;
		$('.content-container').addClass('widen');
	},

	getTemplate: function() {
		return notificationsCompositeView( this.collection );
	},

	getNotifications: function() {
		this.collection.loading = true;
		this.render();
		this.app.main_controller.getNotifications(0,this.collection.queryNurse,this.collection.queryRefill,
			this.collection.queryAppointment);
	},

	setNotifications: function( data ) {
		this.collection.loading = false;
		let controller = this.app.main_controller;
		this.collection.reset();
		this.collection.metadata = controller.notificationMetadata;
		this.collection.renderNoResults = controller.notifications && controller.notifications.length ? false : true;
		let search = controller.searchInput;
		this.collection.search = search ? search.substring( search.lastIndexOf("=") + 1 ) : '';
		this.collection.add( controller.notifications );
		!controller.notifications.length && this.render();
	},

	launchNewNotification: function(  ) {
		let modalView = new NewNotificationModalView( );
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#newNotificationModal' ).openModal();
		this.channel.trigger( 'NewNotificationModal:show' )
	},
	markUnreadNotification: function() {
		let models = this.collection.models;
		let patientIds = [];
		for(let i=0; i<models.length; i++){
			const model = models[i];
			if(model.get('selected')){
				patientIds.push(model.get('patient_id'))
			}
		}
		patientIds = patientIds+"";
		if(patientIds){
			let controller = this.app.main_controller;
			controller.markUnreadThread(patientIds);
		}
	},

	refreshNotification: function() {
		this.collection.loading = true;
		this.collection.queryNurse = false;
		this.collection.queryRefill = false;
		this.collection.queryAppointment = false;
		this.render();
		this.app.main_controller.getNotifications();
	},

	pageRight: function() {
		if (!$('#pageRight').hasClass('disabled')) this.collection.loading = true; this.render();
		let controller = this.app.main_controller;
		let currentPage = controller.notificationMetadata.currentPage;
		let totalPages = controller.notificationMetadata.totalPages;
		let searchInput = controller.searchInput;
		if ( currentPage + 1 < totalPages ) {
			if ( searchInput ) {
				controller.searchNotifications( currentPage + 1, searchInput,this.collection.queryNurse,this.collection.queryRefill,
					this.collection.queryAppointment );
			} else {
				controller.getNotifications( currentPage + 1 ,this.collection.queryNurse,this.collection.queryRefill,
					this.collection.queryAppointment);
			}
		}
	},

	pageLeft: function() {
		if (!$('#pageLeft').hasClass('disabled')) this.collection.loading = true; this.render();
		let controller = this.app.main_controller;
		let currentPage = controller.notificationMetadata.currentPage;
		let totalPages = controller.notificationMetadata.totalPages;
		let searchInput = controller.searchInput;
		if ( currentPage > 0 ) {
			if ( searchInput ) {
				controller.searchNotifications( currentPage - 1, searchInput,this.collection.queryNurse,this.collection.queryRefill,
					this.collection.queryAppointment );
			} else {
				controller.getNotifications( currentPage - 1 ,this.collection.queryNurse,this.collection.queryRefill,
					this.collection.queryAppointment);
			}
		}
	},

	onDestroy: function() {
		this.channel.trigger( 'remove:search' );
		this.app.main_controller.setGlobalValue( 'notification_type', null );
		$('.content-container').removeClass('widen');
	},

	toggleNurse: function() {
		this.collection.queryNurse = !this.collection.queryNurse;
		this.render();
		let controller = this.app.main_controller;
		let searchInput = controller.searchInput;
		controller.searchNotifications( 0, searchInput,this.collection.queryNurse,this.collection.queryRefill,
			this.collection.queryAppointment);
	},
	toggleRefill: function() {
		this.collection.queryRefill = !this.collection.queryRefill;
		this.render();
		let controller = this.app.main_controller;
		let searchInput = controller.searchInput;
		controller.searchNotifications( 0, searchInput,this.collection.queryNurse,this.collection.queryRefill,
			this.collection.queryAppointment);
	},
	toggleAppointment: function() {
		this.collection.queryAppointment = !this.collection.queryAppointment;
		this.render();
		let controller = this.app.main_controller;
		let searchInput = controller.searchInput;
		controller.searchNotifications( 0, searchInput,this.collection.queryNurse,this.collection.queryRefill,
			this.collection.queryAppointment);
	},
});
