// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import moment from 'moment';

// Internal Dependencies - screens/views/templates
import billingListContainerView  from '../../../../templates/Utilities/HistoricalBilling/billingListContainerView';
import BillingListCompositeView from './BillingListCompositeView';

// Internal Dependencies - models/collections
import {BillingListCollection} from "./models/BillingListCollection";

export default Marionette.LayoutView.extend({

	events: {
		'click #bulkDownload': 'bulkDownload',
		'change #billingMonth': 'updateMonth',
		'change #billingYear': 'updateYear',
	},

	regions: {
		billingList: '.billing-list-container',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new Backbone.Model();
		this.controller = this.app.utilities_controller;
		this.collection = new BillingListCollection();
		this.controller.getBillingList( this.collection.data );
		this.collection.loading = true;
		this.updateBillingDate();
		this.listenTo( this.channel, 'set:billingDate', this.updateBillingDate );
	},

	getTemplate: function() {
		return billingListContainerView( this.model );
	},

	onShow: function() {
		this.showChildView( 'billingList', new BillingListCompositeView( {collection: this.collection} ) );
	},

	bulkDownload: function() {
		this.controller.downloadBilling();
	},

	updateBillingDate: function() {
		this.model.set( 'billingDate', this.controller.billingDate );
	},

	updateMonth: function() {
		let userInputMonth = $( '#billingMonth' ).val() || 1;
		const data = {
			monthMM: moment( userInputMonth ).format( 'MM' ),
			monthMMMM: moment( userInputMonth ).format( 'MMMM' ),
			year: this.controller.billingDate.year,
		};
		this.collection.loading = true;
		this.channel.trigger('rerender');
		this.controller.updateBillingDate( data );
		this.controller.getBillingList();
	},

	updateYear: function() {
		let userInputYear = $( '#billingYear' ).val() || 2017;
		const data = {
			monthMM: this.controller.billingDate.monthMM,
			monthMMMM: this.controller.billingDate.monthMMMM,
			year: moment( userInputYear ).utc().format( 'YYYY' ),
		};
		this.collection.loading = true;
		this.channel.trigger('rerender');
		this.controller.updateBillingDate( data );
		this.controller.getBillingList();
	},

});
