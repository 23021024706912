 import Backbone from 'backbone';

 export const ImprovementActivitiesModel = Backbone.Model.extend({

     defaults: {
         selectedActivity: null,
         showHighValues: true,
         showLowValues: true,
     },

     initialize: function() {
         this.channel = Backbone.Radio.channel( 'AppChannel' );
         this.app = this.channel.request( 'app' );
         this.colors = {
             'FAIL': '#A01313',
             'WARN': '#f57b3e',
             'blue': '#1080AF',
             'PASS': '#169E54',
             'gray': '#C6C6C6',
         };
     },

     getMeasureMetaData: function( criteria, useSecondaryCriteria = false ) {
         this.app.mips_controller.getMeasureMetaData( this.get( 'selected_criteria' ).name, useSecondaryCriteria );
     },

     getMeasureData: function( measure ) {
         this.app.mips_controller.getMeasureData( this.get( 'selected_criteria' ).id );
     },

     saveMeasureData: function() {
         this.app.mips_controller.saveMeasureData( this.get( 'data' ), this.get( 'selected_criteria' ).id );

         if ( this.get( 'secondary_selected_criteria' ) ) {
             let useSecondaryCriteria = true;
             this.app.mips_controller.saveMeasureData( this.get( 'secondary_data' ), this.get( 'secondary_selected_criteria' ).id, useSecondaryCriteria );
         }
     },

     getCriteriaSelections: function( category ) {
         this.app.mips_controller.getCriteriaSelections( category );
     },

     getSummaryData: function() {
         this.app.mips_controller.getAdvancingCareSummary( false );
     },

     setSummaryData: function() {
         const summaryData = this.app.mips_controller.advancingSummaryBaseScore;
         this.set({ summaryData });
     },

     filterActivities: function( userInput = '', highPointValues = false, lowPointValues = false ) {
         let criteriaSelections = this.get( 'criteriaSelections' ) || [];
         let IASettings = this.get( 'IASettings' );
         let choices = criteriaSelections.filter( ( activity ) => activity.title.toLowerCase().indexOf( userInput.toLowerCase() ) !== -1 );
         let lowValue = IASettings && IASettings.double_points ? 20 : 10;
         choices = choices.filter( ( activity ) => {
             if ( highPointValues && activity.possible_points > lowValue ) {
                 return activity;
             }
             if ( lowPointValues && activity.possible_points === lowValue ) {
                 return activity;
             }
         });
         this.set({ choices, highPointValues, lowPointValues });
         this.ensureSelectedActivityNotFiltered();
     },

     ensureSelectedActivityNotFiltered: function() {
         if ( this.get( 'selectedActivity' ) ) {
             let containsSelectedActivity = false;
             let choices = this.get( 'choices' );
             choices.forEach( ( choice ) => {
                 if ( choice.id === this.get( 'selectedActivity' ).id ) {
                     containsSelectedActivity = true;
                 }
             });
             if ( !containsSelectedActivity ) this.set( 'selectedActivity', null );
         }
     },

     selectActivity: function( e ) {
         let choices = this.get( 'choices' );
         let selectedActivity = {};
         choices.forEach( ( choice ) => {
             if ( choice.id === Number( e.target.id ) ) {
                 selectedActivity = choice;
             }
         });
         this.set({ selectedActivity });
     },

     deselectActivity: function() {
         this.set( 'selectedActivity', null );
     },

     trackActivity: function() {
         this.get( 'selectedActivity' ) && this.app.mips_controller.trackActivity( this.get( 'data' ), this.get( 'selectedActivity' ).id );
     },

     resetFilters: function() {
         this.set({
             choices: this.get( 'criteriaSelections' ),
             selectedActivity: null,
         });
     },

     getIAValues: function() {
         this.app.mips_controller.getIAValues();
     },

     getIASettings: function() {
         this.app.mips_controller.getIASettings();
     },

     saveIASettings: function( settings = {} ) {
         this.app.mips_controller.saveIASettings( settings );
     },

     confirmRemoveActivity: function( activity_id ) {
         const message = 'Clicking \"confirm\"" will remove this activity from being tracked.';
         const data = {
             numerator: 0,
             denominator: 0,
             month: 0,
             year: 0,
             text_value: '',
         };
         let callback = this.app.mips_controller.trackActivity.bind( this.app.mips_controller, data, activity_id );
         this.app.controller.confirmationModal( message, callback, null );
     },
 });