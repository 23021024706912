import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import triageRecordsModalView  from '../../../../templates/CCM/PatientList/triageRecordsModalView';
import TriageRecordsCompositeView from './TriageRecordsCompositeView'

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	regions: {
		recordsList: '.record-list-container',
	},

	events: {
		'click .modal-close': 'closeModal',
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.controller = this.app.ccm_controller;
		this.collection = new Backbone.Collection();
		this.listenTo( this.channel, 'close:modal', this.closeModal );
		this.listenTo( this.channel, 'trigger:triage_records_modal', this.showChildren );
	},

	getTemplate: function() {
		return triageRecordsModalView( this.model );
	},

	showChildren: function() {
		this.showChildView( 'recordsList', new TriageRecordsCompositeView( {collection: this.collection, model: this.model} ) );
	},

	closeModal: function( e ) {
		e && e.preventDefault();
		$( '#triageRecordsModal' ).closeModal();
		this.destroy();
	},

});
