//External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import  bulkProviderTransitionCompositeView  from '../../../../templates/Utilities/BulkProviderTransition/bulkProviderTransitionCompositeView';

// Internal Dependencies - models/collections
import BulkProviderTransitionConfirmationModelView from './BulkProviderTransitionConfirmationModelView'
import { BulkProviderTransitionModel } from './models/BulkProviderTransitionModel'
import moment from "moment";
import DateRangeDaySelectView from "../../CCM/RapidBill/Reports/DateRangeDaySelectView";

export default Marionette.ItemView.extend({
    className: 'change-password-composite-view',

    events: {
        'click #submitChange': 'submitChange',
        'change #selectedCurrentProvider': 'setSelectedCurrentProvider',
        'change #selectedDate': 'setSelectedDate',
        'change .report-date-select': 'updateDate',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.controller = this.app.utilities_controller;
        this.model = new BulkProviderTransitionModel()
        this.listenTo( this.model, 'set:bulkChangeProviders', this.refreshContent );
        this.listenTo( this.model, 'set:currentProviderDetail', this.refreshContent );

    },

    onShow: function() {
        $( 'select' ).material_select();
        $('#startMonth option[value="' + this.model.get( 'startMonth' ) + '"]').attr("selected", "selected");
        $('#startYear option[value="' + this.model.get( 'startYear' ) + '"]').attr("selected", "selected");
    },

    getTemplate: function() {
        return bulkProviderTransitionCompositeView( this.model );
    },

    refreshContent: function() {
        this.render();
        const error = this.model.get("errorMessage");
        const currentNumberOfPatient = this.model.get("currentNumberOfPatient");
        const currentBillingName = this.model.get("currentBillingName");
        const currentProviderName = this.model.get("currentProviderName");
        if(error){
            $('#selectedProviderError').html(error);
        }else if(currentProviderName){
            $('#selectedProviderError').html('');
            $('#currentProviderName').html(currentProviderName);
            $('#currentProviderName').removeClass('blank-fill');

            $('#currentProviderBillingName').html('billing as '+currentBillingName);
            $('#currentProviderBillingName').removeClass('blank-fill');

            $('#currentProviderPatientCount').html(currentNumberOfPatient +' current patients');
            $('#currentProviderPatientCount').removeClass('blank-fill');

        }


        const selectedCurrentProvider = this.model.get('selectedCurrentProvider');
        const selectedDate = this.model.get('selectedDate')

        if(currentNumberOfPatient != 0 && selectedCurrentProvider && selectedCurrentProvider != "-1" && selectedDate){
            $('#submitChange').removeClass('disabled');
        }else{
            $('#submitChange').addClass('disabled');
        }
    },

    refreshCurrentProviderDetail: function(){
        this.refreshContent();
        const currentNumberOfPatient = this.model.get("currentNumberOfPatient");
        const currentBillingName = this.model.get("currentBillingName");
        const currentProviderName = this.model.get("currentProviderName");
        if(currentProviderName){
            $('#currentProviderName').html(currentProviderName);
            $('#currentProviderName').removeClass('blank-fill');

            $('#currentProviderBillingName').html('billing as '+currentBillingName);
            $('#currentProviderBillingName').removeClass('blank-fill');

            $('#currentProviderPatientCount').html(currentNumberOfPatient +' current patients');
            $('#currentProviderPatientCount').removeClass('blank-fill');

        }

    },
    setSelectedCurrentProvider: function(e){
        $('#currentProviderName').html("");
        $('#currentProviderName').addClass('blank-fill');
        $('#currentProviderBillingName').html("");
        $('#currentProviderBillingName').addClass('blank-fill');
        $('#currentProviderPatientCount').html("");
        $('#currentProviderPatientCount').addClass('blank-fill');
        const selectedProvider = $('#selectedCurrentProvider').val();
        this.model.setSelectedCurrentProvider(selectedProvider);

    },
    setSelectedDate: function(){
        const selectedDate = $('#selectedDate').val();
        console.log("selectedDate:",selectedDate);
        this.model.set({"selectedDate":selectedDate});
        this.refreshContent();
    },
    submitChange: function(e){
        e.preventDefault();
        console.log("Test Submit");
        let modalView = new BulkProviderTransitionConfirmationModelView({ model: this.model});
        modalView.render();
        $( 'body' ).append( modalView.el );
        $( '#modalReject' ).openModal();
    },
    updateDate: function( e ) {
        let newAttributes = {};
        let key = e.target.id;
        newAttributes[key] = e.target.value;

        if ( key == 'startMonth' || key == 'endMonth' ) {
            let monthRangeKey = e.target.id + 'Range';
            let year = key == 'startMonth' ? 'startYear' : 'endYear';
            newAttributes[monthRangeKey] = _.range( moment( e.target.value + '-' + this.model.get( year ), 'MM-YYYY' ).daysInMonth() );
        }

        if ( ( key == 'startYear' && this.model.get( 'startMonth' ) == 2 ) || ( key == 'endYear' && this.model.get( 'endMonth' ) == 2 ) ) {
            let monthKey = e.target.id == 'startYear' ? 'startMonth' : 'endMonth';
            let monthRangeKey = monthKey + 'Range';
            newAttributes[monthRangeKey] = _.range( moment( this.model.get( monthKey ) + '-' + e.target.value, 'MM-YYYY' ).daysInMonth() );
        }

        this.model.set( newAttributes );
    },

});