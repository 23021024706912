import Backbone from 'backbone';

const engageToken = 'engage_token=';

export const Utils = () => {
	return {

		// Method called when attempting to make a POST request
		post: function( endpoint = '', body = {}, successTrigger = '', errorTrigger = '', tokenRemoval = '', requiresToken = false, callback = false) {
			// Instantiate the AppChannel in order to trigger callbacks based on result of HTTP request
			let channel = Backbone.Radio.channel('AppChannel');
			// AJAX call populated with data passed as arguments, along with header information. Also JSON.stringify()
			// is called in order to play nicely with our servers, which take JSON payloads.
			let headers = {
				'Accept': 'application/json',
               	'Content-Type': 'application/json',
			};

			if(requiresToken) {
				let cookies = document.cookie.split( '; ' );
				let token = cookies.filter( ( cookie ) => {
					return cookie.indexOf( engageToken ) !== -1;
				});
				token = token && token.length && token[0].replace( engageToken, '' );
				headers = {
             		'Accept': 'application/json',
                	'Content-Type': 'application/json; charset=UTF-8',
                	'X-AUTH-TOKEN': token
				}
			}
			let request = $.ajax({
				type: 'POST',
				url: endpoint,
				headers: headers,
            	data: JSON.stringify(body),
                timeout: 120000,
			});
			// This is called if the request is successful, triggering whichever callback is fired utilizing 'successTrigger'
			request.done( ( response, status, xhr ) => {
				if ( status == 'success' ) {
					let token;
					if ( xhr && xhr.getResponseHeader('X-AUTH-TOKEN') ) {
						token = xhr.getResponseHeader('X-AUTH-TOKEN');
						document.cookie = 'engage_token = ' + token;
						this.isAuthorized = true;
					}

					if ( callback ) {
						callback( response );
					}
					// Check to see if the successTrigger value is only one trigger - if so, trigger it - or more than one.
					// If it is more than one, trigger each (this should be in the form of an array of strings).
					if ( typeof successTrigger !== 'string' ) {
						successTrigger.forEach( ( trigger ) => {
							channel.trigger(trigger);
						});
					} else {
						channel.trigger(successTrigger);
					}
				}
			});

			// This is called if the request is unsuccessful, triggering whichever callback is fired utilizing 'errorTrigger'
			request.fail( ( error ) => {
                if ( error.statusText === 'timeout' ) {
                    channel.trigger( 'error:timeout' );
                }

                if ( typeof errorTrigger !== 'string' ) {
                    errorTrigger.forEach( ( trigger ) => {
                        channel.trigger(trigger, error);
                    });
                } else {
                    channel.trigger(errorTrigger, error);
                }
			});
		},

        // Method called when attempting to make a POST request
        filePost: function( endpoint = '', body = {}, successTrigger = '', errorTrigger = '', tokenRemoval = '', requiresToken = false, callback = false) {
            // Instantiate the AppChannel in order to trigger callbacks based on result of HTTP request
            let channel = Backbone.Radio.channel('AppChannel');
            // AJAX call populated with data passed as arguments, along with header information. Also JSON.stringify()
            // is called in order to play nicely with our servers, which take JSON payloads.
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };

            if ( requiresToken ) {
                let cookies = document.cookie.split( '; ' );
                let token = cookies.filter( ( cookie ) => {
                    return cookie.indexOf( engageToken ) !== -1;
                });
                token = token && token.length && token[0].replace( engageToken, '' );
                headers = {
                    'X-AUTH-TOKEN': token
                }
            }

            let request = $.ajax({
                type: 'POST',
                url: endpoint,
                headers: headers,
                data: body,
				processData: false,
				contentType: false,
                mimeType: 'multipart/form-data',
            });

            // This is called if the request is successful, triggering whichever callback is fired utilizing 'successTrigger'
            request.done( ( response, status, xhr ) => {
                if ( status == 'success' ) {
                    let token;
                    if ( xhr && xhr.getResponseHeader('X-AUTH-TOKEN') ) {
                        token = xhr.getResponseHeader('X-AUTH-TOKEN');
                        document.cookie = 'engage_token = ' + token;
                        this.isAuthorized = true;
                    }

                    if ( callback ) {
                        callback( response );
                    }
                    // Check to see if the successTrigger value is only one trigger - if so, trigger it - or more than one.
                    // If it is more than one, trigger each (this should be in the form of an array of strings).
                    if ( typeof successTrigger !== 'string' ) {
                        successTrigger.forEach( ( trigger ) => {
                            channel.trigger(trigger);
                        });
                    } else {
                        channel.trigger(successTrigger);
                    }
                }
            });

            // This is called if the request is unsuccessful, triggering whichever callback is fired utilizing 'errorTrigger'
            request.fail( ( error ) => {
                console.log( 'error:', error );
                if ( typeof errorTrigger !== 'string' ) {
                    errorTrigger.forEach( ( trigger ) => {
                        channel.trigger(trigger, error);
                    });
                } else {
                    channel.trigger(errorTrigger, error);
                }
            });
        },

		// Method called when attempting to make a PUT request
		put: function( endpoint = '', body = {}, successTrigger = '', errorTrigger = '', tokenRemoval = '', requiresToken = false, callback = false) {
			// Instantiate the AppChannel in order to trigger callbacks based on result of HTTP request
			let channel = Backbone.Radio.channel('AppChannel');
			// AJAX call populated with data passed as arguments, along with header information. Also JSON.stringify()
			// is called in order to play nicely with our servers, which take JSON payloads.
			let headers = {
				'Accept': 'application/json',
               	'Content-Type': 'application/json',
			}
			if(requiresToken) {
				let cookies = document.cookie.split( '; ' );
				let token = cookies.filter( ( cookie ) => {
					return cookie.indexOf( engageToken ) !== -1;
				});
				token = token && token.length && token[0].replace( engageToken, '' );
				headers = {
             		'Accept': 'application/json',
                	'Content-Type': 'application/json; charset=UTF-8',
                	'X-AUTH-TOKEN': token
				}
			}
			let request = $.ajax({
				type: 'PUT',
				url: endpoint,
				headers: headers,
            	data: JSON.stringify(body),
                timeout: 120000,
			});
			// This is called if the request is successful, triggering whichever callback is fired utilizing 'successTrigger'
			request.done( ( response, status, xhr ) => {
				if ( status == 'success' ) {
					let token;
					if ( xhr && xhr.getResponseHeader('X-AUTH-TOKEN') ) {
						token = xhr.getResponseHeader('X-AUTH-TOKEN');
						document.cookie = 'engage_token = ' + token;
						this.isAuthorized = true;
					}

					if ( callback ) {
						callback( response );
					}

					// Check to see if the successTrigger value is only one trigger - if so, trigger it - or more than one.
					// If it is more than one, trigger each (this should be in the form of an array of strings).
					if ( typeof successTrigger !== 'string' ) {
						successTrigger.forEach( ( trigger ) => {
							channel.trigger(trigger);
						});
					} else {
						channel.trigger(successTrigger);
					}
				}
			});

			// This is called if the request is unsuccessful, triggering whichever callback is fired utilizing 'errorTrigger'
			request.fail( ( error ) => {
                if ( error.statusText === 'timeout' ) {
                    channel.trigger( 'error:timeout' );
                }
                if ( typeof errorTrigger !== 'string' ) {
                    errorTrigger.forEach( ( trigger ) => {
                        channel.trigger(trigger, error);
                    });
                } else {
                    channel.trigger(errorTrigger, error);
                }
			});
		},

		get: function( 	endpoint = '',
						resultTriggers = { successTrigger: '', errorTrigger: '' },
						callback = null,
						id = null,
						headerData = { page: 0, pageSize: 20, contentType: 'application/json; charset=UTF-8', accept: '*/*' },
						xhrFields = null,
	                    timeout = 120000,
						) {
			// Instantiate the AppChannel in order to trigger callbacks based on result of HTTP request
			let channel = Backbone.Radio.channel( 'AppChannel' );
			// AJAX call populated with data passed as arguments, along with header information. Also JSON.stringify()
			// is called in order to play nicely with our servers, which take JSON payloads.
			let cookies = document.cookie.split( '; ' );
			let token = cookies.filter( ( cookie ) => {
				return cookie.indexOf( engageToken ) !== -1;
			});
			token = token && token.length && token[0].replace( engageToken, '' );
			let request = $.ajax({
				type: 'GET',
				url: endpoint,
				headers: {
                	'Content-Type': headerData.contentType,
                	'X-AUTH-TOKEN': token,
                	'X-Page': headerData.page,
                	'X-Page-Size': headerData.pageSize,
                	'Accept': headerData.accept
            	},
				xhrFields: xhrFields,
				timeout: timeout,
			});

			let successTrigger = resultTriggers.successTrigger;
			let errorTrigger = resultTriggers.errorTrigger;

			// This is called if the request is successful, triggering whichever callback is fired utilizing 'successTrigger'
			request.done( ( response, status, xhr ) => {
				if ( status == 'success' && (!response.message || (response && response.message && response.message.toLowerCase() !== 'invalid token')) ) {
					this.isAuthorized = true;

					// If you need to use the data that is returned to trigger another event, use a callback
					if ( callback ) {
						callback( response, id );
					}

					// Check to see if the successTrigger value is only one trigger - if so, trigger it - or more than one.
					// If it is more than one, trigger each (this should be in the form of an array of strings).
					if ( successTrigger && typeof successTrigger !== 'string' ) {
						successTrigger.forEach( ( trigger ) => {
							channel.trigger( trigger );
						});
					} else {
						channel.trigger( successTrigger );
					}
				} else {
					if ( errorTrigger && typeof errorTrigger !== 'string' ) {
						errorTrigger.forEach( ( trigger ) => {
							channel.trigger( trigger );
						});
					} else {
						channel.trigger( errorTrigger );
					}
				}
			});

			// This is called if the request is successful, triggering whichever callback is fired utilizing 'errorTrigger'
			request.fail( ( error ) => {
				if ( error.statusText === 'timeout' ) {
					channel.trigger( 'error:timeout' );
				}
                if ( typeof errorTrigger !== 'string' ) {
                    errorTrigger.forEach( ( trigger ) => {
                        channel.trigger(trigger, error);
                    });
                } else {
                    channel.trigger(errorTrigger, error);
                }
			});
		}
	}
}
