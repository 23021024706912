// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  patientTotalsView  from '../../../../templates/CCM/PatientList/patientTotalsView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'click #pageRight': 'pageUp',
		'click #pageLeft': 'pageDown',
		'change #ccm_provider': 'sortResults',
		'click .download-header': 'downloadCSV',
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		if ( options.sort == undefined ) {
			this.model = new Backbone.Model( { sort: true } );
		} else {
			this.model = new Backbone.Model( { sort: options.sort } );
		}
		this.model.set( 'subnavItem', this.app.controller.subnavItem );
		console.log(this.app.controller.subnavItem);
		this.listenTo( this.model, 'change', this.render );
		this.listenTo( this.channel, 'set:patients', this.setTotals );
		this.listenTo( this.channel, 'reset:totals', this.setTotals );
	},

	getTemplate: function() {
		return patientTotalsView( this.model );
	},

	setTotals: function() {
		let controller = this.app.ccm_controller;
		let itemCount = this.determineItemCount();
		let currentPage = controller.patients.currentPage;
		let totalPatients = controller.patients.totalPatients;
		this.model.set({
			sortedBy: controller.sortedBy || '',
			totalPatients: totalPatients,
			currentPage: currentPage,
			beginIndex: currentPage == 0 ? 1 : currentPage * itemCount + 1,
			endIndex: ( currentPage + 1 ) * itemCount >= totalPatients ?
										totalPatients :
										( currentPage + 1 ) * itemCount
		});
	},

	determineItemCount: function() {
		switch( this.app.controller.subnavItem ) {
			case 'RapidBill':
			case 'ErrorQueue':
			case 'PendingReview':
				return 100;
				break;
			default:
				return 20;
		}
	},

	pageUp: function() {
		if (!$('#pageRight').hasClass('disabled')) this.collection.loading = true; this.channel.trigger('rerender');
		this.app.ccm_controller.patientListPageUp();
	},

	pageDown: function() {
		if (!$('#pageLeft').hasClass('disabled')) this.collection.loading = true; this.channel.trigger('rerender');
		this.app.ccm_controller.patientListPageDown();
	},

	sortResults: function() {
		this.collection.loading = true;
		this.channel.trigger('rerender');
		let searchType = this.app.controller.subnavItem;
		let searchInput = $( '#search' ).val();
		let sortType = this.whichSort( $( '#ccm_provider' ).val() );
		this.determineSearchType( searchType, searchInput, sortType );
	},

	determineSearchType: function( searchType, searchInput, sortType ) {
		searchInput = searchInput ? sortType + '&search=' + searchInput : sortType;
		let searchField;
		switch( searchType ) {
			case 'PatientList':
				searchField = searchInput;
				this.app.ccm_controller.searchPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'PendingUnenroll':
				searchField = searchInput;
				this.app.ccm_controller.searchPendingUnenrolledPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'Unenrolled':
				searchField = searchInput;
				this.app.ccm_controller.searchUnenrolledPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'Unverified':
				searchField = searchInput;
				this.app.ccm_controller.searchUnverifiedPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'OptedOut':
				searchField = searchInput;
				this.app.ccm_controller.searchOptedoutPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'Support':
				searchField = searchInput;
				this.app.ccm_controller.searchSupportPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'Enrolled':
				searchField = searchInput;
				this.app.ccm_controller.searchEnrolledPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'Suspended':
				searchField = searchInput;
				this.app.ccm_controller.searchSuspendedPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'ProviderTransition':
				searchField = searchInput;
				this.app.ccm_controller.searchProviderTransition( this.currentPage, this.pageSize, searchField );
				break;
			case 'AutoEnroll':
				searchField = searchInput;
				this.app.ccm_controller.searchAutoPendingPatients( this.currentPage, this.pageSize, searchField );
				break;
		  case 'ProviderApproval':
			searchField = searchInput;
			this.app.ccm_controller.searchProviderApproval( this.currentPage, this.pageSize, searchField );
			break;
			default:
				console.log('THIS SHOULD NOT HAPPEN. CHECK PATIENT TOTALS VIEW');
				searchField = searchInput;
				this.app.ccm_controller.searchEnrolledPatients( this.currentPage, this.pageSize, searchField );
		}
	},

	whichSort: function( value ) {
		switch ( value ) {
			case '1':
				this.app.ccm_controller.setGlobalValue( 'sortedBy', '1' );
				return 'sort_order=asc&sort_field=patientId';
				break;
			case '2':
				this.app.ccm_controller.setGlobalValue( 'sortedBy', '2' );
				return 'sort_order=desc&sort_field=patientId';
				break;
			case '3':
				this.app.ccm_controller.setGlobalValue( 'sortedBy', '3' );
				return 'sort_order=asc&sort_field=last_name';
				break;
			case '4':
				this.app.ccm_controller.setGlobalValue( 'sortedBy', '4' );
				return 'sort_order=desc&sort_field=last_name';
				break;
			case '5':
				this.app.ccm_controller.setGlobalValue( 'sortedBy', '5' );
				return 'sort_order=asc&sort_field=first_name';
				break;
			case '6':
				this.app.ccm_controller.setGlobalValue( 'sortedBy', '6' );
				return 'sort_order=desc&sort_field=first_name';
				break;
			case '7':
				this.app.ccm_controller.setGlobalValue( 'sortedBy', '7' );
				return 'sort_order=asc&sort_field=birthdate';
				break;
			case '8':
				this.app.ccm_controller.setGlobalValue( 'sortedBy', '8' );
				return 'sort_order=desc&sort_field=birthdate';
				break;
			case '9':
				this.app.ccm_controller.setGlobalValue( 'sortedBy', '9' );
				return 'sort_order=asc&sort_field=ccm_provider_id';
				break;
			case '10':
				this.app.ccm_controller.setGlobalValue( 'sortedBy', '10' );
				return 'sort_order=desc&sort_field=ccm_provider_id';
				break;
			case '11':
				break;
			default:
				return '';
		}
	},

	onDestroy: function() {
		this.app.ccm_controller.patients.currentPage = 0;
	},

	downloadCSV: function() {
		let searchType = this.app.controller.subnavItem;
		let searchInput = $( '#search' ).val();
		let sortType = this.whichSort( $( '#ccm_provider' ).val() );
		this.determineDownloadCSVType(searchType, searchInput, sortType);
	},
	determineDownloadCSVType: function( searchType, searchInput, sortType ) {
		searchInput = searchInput ? sortType + '&search=' + searchInput : sortType;
		let searchField;
		switch( searchType ) {
			case 'PatientList':
				searchField = searchInput;
				this.app.ccm_controller.getPatientListCSV(this.currentPage, this.pageSize, searchField);
				// this.app.ccm_controller.searchPatients( this.currentPage, this.pageSize, searchField );
				break;
			case 'PendingUnenroll':
				searchField = searchInput;
				this.app.ccm_controller.getPendingUnenrollCSV(this.currentPage, this.pageSize, searchField);
				break;
			case 'Unenrolled':
				searchField = searchInput;
				this.app.ccm_controller.getUnenrolledCSV(this.currentPage, this.pageSize, searchField);
				break;
			case 'Unverified':
				searchField = searchInput;
				this.app.ccm_controller.getUnverifiedCSV(this.currentPage, this.pageSize, searchField);
				break;
			case 'OptedOut':
				searchField = searchInput;
				this.app.ccm_controller.getOptedOutCSV(this.currentPage, this.pageSize, searchField);
				break;
			case 'Support':
				searchField = searchInput;
				this.app.ccm_controller.getSupportCSV(this.currentPage, this.pageSize, searchField);
				break;
			case 'Enrolled':
				searchField = searchInput;
				this.app.ccm_controller.getEnrolledCSV(this.currentPage, this.pageSize, searchField);
				break;
			case 'Suspended':
				searchField = searchInput;
				this.app.ccm_controller.getSuspendedCSV(this.currentPage, this.pageSize, searchField);
				break;
			case 'ProviderTransition':
				searchField = searchInput;
				this.app.ccm_controller.getProviderTransitionCSV(this.currentPage, this.pageSize, searchField);
				break;
			case 'AutoEnroll':
				searchField = searchInput;
				this.app.ccm_controller.getPatientListCSV(this.currentPage, this.pageSize, searchField);
				break;
			case 'ProviderApproval':
				searchField = searchInput;
				this.app.ccm_controller.getProviderApprovalCSV(this.currentPage, this.pageSize, searchField);
				break;
			default:
				console.log('THIS SHOULD NOT HAPPEN. CHECK PATIENT TOTALS VIEW');
				searchField = searchInput;
				this.app.ccm_controller.getPatientListCSV(this.currentPage, this.pageSize, searchField);
		}
	}

});
