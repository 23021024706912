// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import fileManagerContainerView  from '../../../../templates/PatientEnrollment/FileManager/fileManagerContainerView';
import UploadCollectionView from './UploadCompositeView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	regions: {
		'patientUpload': '.upload-patient-container'
	},

	getTemplate: function() {
		return fileManagerContainerView( this.model );
	},

	onShow: function() {
		this.showChildView( 'patientUpload', new UploadCollectionView() );
	}

});
