// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import supportContactView  from '../../../../templates/Utilities/Support/supportContactView';
// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	tagName: 'div',

	className: 'support-contact-view',

	getTemplate: function() {
		return supportContactView( this.model );
	}
});
