// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';

// Internal Dependencies - screens/views/templates
import  editIcd10CodesModalView  from '../../../../../templates/CCM/RapidBill/Approval/editIcd10CodesModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'click .cancel': 'closeModal',
		'click .confirm-edit': 'confirmEdit',
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
		this.listenTo( this.channel, 'trigger:show', this.showTriggered );
	},

	getTemplate: function() {
		return editIcd10CodesModalView( this.model );
	},

	showTriggered: function() {
		const self = this;
		this.selectedCode1 = '';
		this.selectedCode2 = '';
		$( 'select' ).material_select();
		$('#icd10code_1').devbridgeAutocomplete({
			serviceUrl: `${ this.app.controller.engageUrl }v1/codelookup/icd10?start=0`,
			onSelect: function( suggestion ) {
				self.selectedCode1 = suggestion.id;
			},
			paramName: 'search',
	    	maxHeight: 150,
	    	ajaxSettings: {
	    		headers: {
	    			'X-AUTH-TOKEN': this.app.controller.getToken()
	    		}
	    	},
	    	transformResult: function( data ) {
	    		let response = _.reject( JSON.parse( data ), ( item ) => item.id == self.selectedCode2 );
	    		return {
	    			suggestions: response.map( ( item ) => {
	    				return { value: `${ item.id } - ${ item.desc }`, id: item.id }
	    			})
	    		}
	    	},
	    	autoSelectFirst: true,
	    	noCache: true,
	    });
	    $('#icd10code_2').devbridgeAutocomplete({
	    	serviceUrl: `${ this.app.controller.engageUrl }v1/codelookup/icd10?start=0`,
			onSelect: function( suggestion ) {
				self.selectedCode2 = suggestion.id;
			},
			paramName: 'search',
	    	maxHeight: 150,
	    	ajaxSettings: {
	    		headers: {
	    			'X-AUTH-TOKEN': this.app.controller.getToken()
	    		}
	    	},
	    	transformResult: function( data ) {
	    		let response = _.reject( JSON.parse( data ), ( item ) => item.id == self.selectedCode1 );
	    		return {
	    			suggestions: response.map( ( item ) => {
	    				return { value: `${ item.id } - ${ item.desc }`, id: item.id }
	    			})
	    		}
	    	},
	    	autoSelectFirst: true,
	    	noCache: true,
	    });
	},

	confirmEdit: function() {
        this.model.updateBillingInfo( 'APPROVE' );
    },

	parseICD10Code: function( value ) {
		if ( value ) {
			if ( value.indexOf( ' - ' ) > -1 ) {
				return value.slice( 0, value.indexOf( ' - ' ) ).trim();
			} else {
				return value.toUpperCase();
			}
		}
	},

	closeModal: function() {
		$( '#editIcd10Codes' ).closeModal();
		this.destroy();
	},

});
