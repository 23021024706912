// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import dashboardContainerContainerView  from '../../../../../templates/MIPS/Dashboard/dashboardContainerContainerView';
import SummaryContainerView from './SummaryContainerView';
import ProviderPlaceholderView from '../../ProviderPlaceholderView';

// Internal Dependencies - models/collections
import { ContainerModel } from '../../models/ContainerModel';

export default Marionette.LayoutView.extend({

	regions: {
		'dashboardContainer': '#dashboardContainerView'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.model = new ContainerModel();
		if ( this.model.get( 'currentApp' ) === 'MIPSPROVIDER' ) {
			this.listenTo( this.model, 'change:showPlaceholder', this.checkPlaceholder );
		}
	},

	getTemplate: function() {
		return dashboardContainerContainerView( this.model );
	},

	onShow: function() {
		if ( this.model.get('showPlaceholder') ) {
			this.showChildView( 'dashboardContainer', new ProviderPlaceholderView( { route: 'Quality Measures Summary' } ) );
		} else {
			this.showChildView( 'dashboardContainer', new SummaryContainerView() );
		}
	},

	checkPlaceholder: function() {
		if ( this.model.get('showPlaceholder') ) {
			this.showChildView( 'dashboardContainer', new ProviderPlaceholderView( { route: 'Quality Measures Summary' } ) );
		} else {
			this.showChildView( 'dashboardContainer', new SummaryContainerView() );
		}
	},

});
