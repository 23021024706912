// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import tempFn from '../../../templates/navbarView';
import AdminLevelFilteringView from '../Filtering/AdminLevelFilteringView';
import AppFilteringContainerView from '../Filtering/AppFilteringContainerView';
import NavbarLinksView from './NavbarLinksView';

// Internal Dependencies - models/collections
import { NavbarModel } from './models/NavbarModel';

export default Marionette.LayoutView.extend({

	regions: {
		'subnav': '.subnav-navbar-container',
		'adminLevel': '.admin-level',
		'links': '.nav-list-container',
	},

	events: {
		'click .toggle-menu': 'toggleMenu',
		'click .logout': 'logout'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel('AppChannel');
		this.app = this.channel.request( 'app' );
		this.model = new NavbarModel({ app: this.app, channel: this.channel });
	},

	getTemplate: function() {
		return tempFn(this.model);
	},

	onShow: function() {
		$(".dropdown-button").dropdown({
			alignment: 'right',
			constrain_width: false,
			hover: true
		});
		this.showChildView( 'adminLevel', new AdminLevelFilteringView() );
		this.showChildView( 'subnav', new AppFilteringContainerView() );
		this.showChildView( 'links', new NavbarLinksView() );
	},

	toggleMenu: function(e) {
		e.preventDefault();
		this.channel.trigger('toggle:menu');
	},

	logout: function(e) {
		e.preventDefault();
		this.channel.trigger('logout:user');
	}

});
