// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from "highcharts";

// Internal Dependencies - screens/views/templates
import scoreView  from '../../../../templates/MIPS/MIPS/scoreView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'click #saveChanges': 'saveChanges',
		'change .mips-input': 'setPoints',
		'change .mips-filter': 'setFilter',
		'click #applySelection': 'applySelection',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.model, 'set:mipsNewDashboardData', this.refreshContent );
		this.listenTo( this.channel, 'renderMipsNewDashboardCharts', this.renderCharts );
		this.listenTo( this.model, 'change:mipsProviders', this.refreshContent );
	},

	getTemplate: function() {
		return scoreView( this.model );
	},

	refreshContent: function() {
		$('#applySelection').html('Apply Selection');
		this.render();
		this.renderCharts();
	},

	applySelection: function() {
		$('#applySelection').html('Loading');
		$('#saveChanges').html('Save Changes');
		this.model.getData();
		this.model.resetFilePath();
	},

	setFilePath: function() {
		let filePath = $( '#filePath' )[ 0 ].files[ 0 ] || null;
		this.model.set({ filePath });
	},

	setFilter: function( e ) {
		this.model.setFilter( e.target.id, e.target.value );
	},

	saveChanges: function() {
		$('#saveChanges').html('Saving');
		this.model.saveDashboard();
	},

	setPoints: function( e ) {
		this.model.setMipsValue( e.target.id, e.target.value );
	},

	renderCharts: function() {
		this.qualityChart();
		this.costChart();
		this.advancingChart();
		this.improvementChart();
	},

	advancingChart: function() {
		const advancingCareObject = this.model.get( 'aciTotalScore' );
		const selectedYear = this.model.get('selectedYear');
		const text = selectedYear == 2018 ? 'ACI Percent Success' : 'PI Percent Achieved';
		Highcharts.chart('advancingChart', {
			title: {
				text: text,
				style: {
					color: '#529fc0'
				},
			},
			chart: {
				height: 200,
				spacingBottom: 30,
			},
			tooltip: {
				pointFormat: '<b>{point.y}</b>'
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: true,
						distance: -20,
						format: '<b>{point.y}%</b>'
					},
					startAngle: -90,
					endAngle: 90,
					center: ['50%', '80%'],
					size: 160,
				}
			},
			legend: {
				floating: true,
			},
			series: [{
				type: 'pie',
				innerSize: '50%',
				data: [
					{
						name: 'Achieved',
						y: parseFloat(advancingCareObject.value),
						color: '#529fc0'
					}, {
						name: 'Not Achieved',
						y: 25 - parseFloat(advancingCareObject.value),
						color: 'rgb(170,170,170)'
					}
				]
			}],
			credits: {
				enabled: false
			}
		});
	},

	qualityChart: function() {
		const qualityObject = this.model.get( 'qualityTotalScore' );
		Highcharts.chart('qualityChart', {
			title: {
				text: `Quality Percent Achieved`,
				style: {
					color: '#529fc0'
				},
			},
			chart: {
				height: 200,
				spacingBottom: 30,
			},
			tooltip: {
				pointFormat: '<b>{point.y}</b>'
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: true,
						distance: -20,
						format: '<b>{point.y}%</b>'
					},
					startAngle: -90,
					endAngle: 90,
					center: ['50%', '80%'],
					size: 160,
				}
			},
			legend: {
				floating: true,
			},
			series: [{
				type: 'pie',
				innerSize: '30%',
				data: [
					{
						name: 'Achieved',
						y: parseFloat(qualityObject.value),
						color: '#529fc0'
					}, {
						name: 'Not Achieved',
						y: 30 - parseFloat(qualityObject.value),
						color: 'rgb(170,170,170)'
					}
				]
			}],
			credits: {
				enabled: false
			}
		});
	},

	costChart: function() {
		const costObject = this.model.get( 'costTotalScore' );
		Highcharts.chart('costChart', {
			title: {
				text: `Cost Percent Achieved`,
				style: {
					color: '#529fc0'
				},
			},
			chart: {
				height: 200,
				spacingBottom: 30,
			},
			tooltip: {
				pointFormat: '<b>{point.y}</b>'
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: true,
						distance: -20,
						format: '<b>{point.y}%</b>'
					},
					startAngle: -90,
					endAngle: 90,
					center: ['50%', '80%'],
					size: 160,
				}
			},
			legend: {
				floating: true,
			},
			series: [{
				type: 'pie',
				innerSize: '30%',
				data: [
					{
						name: 'Achieved',
						y: parseFloat(costObject.value),
						color: '#529fc0'
					}, {
						name: 'Not Achieved',
						y: 30 - parseFloat(costObject.value),
						color: 'rgb(170,170,170)'
					}
				]
			}],
			credits: {
				enabled: false
			}
		});
	},

	improvementChart: function() {
		const improvementObject = this.model.get( 'improvementTotalScore' );
		Highcharts.chart('improvementChart', {
			title: {
				text: `IA Percent Achieved`,
				style: {
					color: '#529fc0'
				},
			},
			chart: {
				height: 200,
				spacingBottom: 30,
			},
			tooltip: {
				pointFormat: '<b>{point.y}</b>'
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: true,
						distance: -20,
						format: '<b>{point.y}%</b>'
					},
					startAngle: -90,
					endAngle: 90,
					center: ['50%', '80%'],
					size: 160,
				}
			},
			legend: {
				floating: true,
			},
			series: [{
				type: 'pie',
				innerSize: '50%',
				data: [
					{
						name: 'Achieved',
						y: parseFloat(improvementObject.value),
						color: '#529fc0'
					}, {
						name: 'Not Achieved',
						y: 15 - parseFloat(improvementObject.value),
						color: 'rgb(170,170,170)'
					}
				]
			}],
			credits: {
				enabled: false
			}
		});
	},

	onDestroy: function() {
		$('.content-container').removeClass('widen');
	},

});
