import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';

import { Utils } from '../../utils';

import ErrorModalView from '../../ErrorModalView';

const channel = Backbone.Radio.channel('AppChannel');
const utils = new Utils();
const userCookie = 'user=';

export const CCM_Controller = ( app ) => {

	const BASE_ENGAGE_URL = app.config.BASE_ENGAGE_URL;

	return {

		/**********************
		Global values
		**********************/

		patients: {
			totalPages: 0,
			currentPage: 0,
			totalPatients: 0,
			itemCount: 0,
		},

		// Takes a valueName and data and creates or replaces a variable with that name and data
		setGlobalValue: function ( valueName, data ) {
			this[ valueName ] = data;
			channel.trigger( 'set:' + valueName );
		},

		// Takes a collection and a single updated model and replaces the old instance of the model with the new
		// Model must have all properties that were returned with initial fetch of collection
		updateModelInCollection: function ( collection, updatedItem ) {
			collection.splice(_.findIndex(collection, 'id', updatedItem.id), 1, updatedItem);
		},

		/**********************
		CCM Specific Methods
		**********************/

		clearValue: function( value ) {
			this[ value ] = null;
		},

		patientListPageUp: function() {
			if ( this.patients.currentPage + 1 == this.patients.totalPages ) {
				return;
			}
			this.patients.currentPage++;
			channel.trigger( 'set:patients' );

			if( !this.searchInput ) {
				this.determineNonSearchedGetType( this.patients.currentPage );
				return;
			}
			this.determineSearchedGetType( this.patients.currentPage, 20, this.searchInput );
		},

		patientListPageDown: function() {
			if ( this.patients.currentPage == 0 ) {
				return;
			}
			this.patients.currentPage--;
			channel.trigger( 'set:patients' );

			if( !this.searchInput ) {
				this.determineNonSearchedGetType( this.patients.currentPage );
				return;
			}
			this.determineSearchedGetType( this.patients.currentPage, 20, this.searchInput );
		},

		determineNonSearchedGetType: function( currentPage ) {
			let currentScreen = app.controller.subnavItem;
			switch( currentScreen ) {
				case 'PatientList':
					this.getPatientList( null, currentPage );
					break;
				case 'PendingUnenroll':
					this.getPendingUnenrolled( null, currentPage );
					break;
				case 'Unenrolled':
					this.getUnenrolledList( null, currentPage );
					break;
				case 'Enrolled':
					this.getEnrolledList( null, currentPage );
					break;
				case 'Suspended':
					this.getSuspendedList( null, currentPage );
					break;
				case 'AutoEnroll':
					this.getAutoPendingList( null, currentPage );
					break;
				case 'RapidBill':
					this.getRapidBilling( null, currentPage );
					break;
				case 'PendingReview':
					this.getPendingReview( null, currentPage );
					break;
				case 'ErrorQueue':
					this.getErrorList( null, currentPage );
					break;
				case 'ProviderApproval':
				  this.getProviderApproval( null, currentPage );
				  break;
				case 'OptedOut':
					this.getOptedoutList(null, currentPage);
					break;
				case 'Support':
					this.getSupportPatientList(null, currentPage);
					break;
				case 'Unverified':
					this.getUnverifiedList(null, currentPage);
					break;
				case 'ProviderTransition':{
					this.getProviderTransitionList(null, currentPage);
					break;
				}
				default:
					this.getPatientList( null, currentPage );
			}
		},

		determineSearchedGetType: function( currentPage, pageSize, searchInput ) {
			let currentScreen = app.controller.subnavItem;
			switch( currentScreen ) {
				case 'PatientList':
					this.searchPatients( currentPage, pageSize, searchInput );
					break;
				case 'PendingUnenroll':
					this.searchPendingUnenrolledPatients( currentPage, pageSize, searchInput );
					break;
				case 'Unenrolled':
					this.searchUnenrolledPatients( currentPage, pageSize, searchInput );
					break;
				case 'Unverified':
					this.searchUnverifiedPatients( currentPage, pageSize, searchInput );
					break;
				case 'Enrolled':
					this.searchEnrolledPatients( currentPage, pageSize, searchInput );
					break;
				case 'Suspended':
					this.searchSuspendedPatients( currentPage, pageSize, searchInput );
					break;
				case 'AutoEnroll':
					this.searchAutoPendingPatients( currentPage, pageSize, searchInput );
					break;
				case 'RapidBill':
					this.getRapidBilling( currentPage );
					break;
				case 'PendingReview':
					this.getPendingReview( currentPage );
					break;
				case 'ErrorQueue':
					this.getErrorList( null, currentPage );
					break;
				case 'OptedOut':
					this.searchOptedoutPatients(currentPage, pageSize, searchInput);
					break;
				case 'Support':
					this.searchSupportPatients(currentPage, pageSize, searchInput);
					break;
				case 'Unverified':
					this.searchUnverifiedPatients(currentPage, pageSize, searchInput);
					break;
				case 'ProviderApproval':
				  this.searchProviderApproval( currentPage, pageSize, searchInput );
				  break;
				default:
					this.searchPatients( currentPage, pageSize, searchInput );
			}
		},

		getPatientList: function( trigger = null, page = 0, pageSize = 20 ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients?sort_order=asc&sort_field=last_name';
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8',
			};

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, trigger ), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getPatientList.bind( this ) );
		},

    resolveProviderChangeRequestData: function(trigger, data) {
      let hasProviders = app.controller.providers && app.controller.providers.length;
      let changeRequests = [];
      if ( data && data.items && data.items.length ) {
        changeRequests = data.items.map( ( changeRequest ) => {
          if ( hasProviders ) {
            let oldProviderObj = _.find( app.controller.providers, ( provider ) => provider.id == changeRequest.old_provider_id );
            let newProviderObj = _.find( app.controller.providers, ( provider ) => provider.id == changeRequest.new_provider_id );
            return Object.assign( {}, changeRequest, {
              current_provider: ( oldProviderObj && oldProviderObj.name ) || '',
              requested_provider:( newProviderObj && newProviderObj.name ) || '',
			  current_billing_provider:(oldProviderObj && oldProviderObj.billingProviderName) || (oldProviderObj && oldProviderObj.name) || '',
			  requested_billing_provider:(newProviderObj && newProviderObj.billingProviderName) || (newProviderObj && newProviderObj.name) || '',
              birthdate: app.controller.formatFixedDateForUI( changeRequest.birthdate )
            });
          }
          return Object.assign( {}, changeRequest, {
            birthdate: app.controller.formatFixedDateForUI( changeRequest.birthdate )
          });
        });
      }

      let triggerSetPatients = false;

      if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
        this.patients.totalPages = data.total_pages;
        triggerSetPatients = true;
      }
      if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
        this.patients.totalPatients = data.total_items;
        triggerSetPatients = true;
      }
      if ( !this.patients.currentPage ) {
        this.patients.currentPage = channel.request( 'currentPage' );
        triggerSetPatients = true;
      }
      if ( !this.patients.itemCount || this.patients.itemCount != data.count ) {
        this.patients.itemCount = data.count;
        triggerSetPatients = true;
      }
      if ( !hasProviders ) {
        channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
      }
      this.setGlobalValue( 'patientList', changeRequests );
      triggerSetPatients && channel.trigger( 'set:patients' );

      // if ( changeRequests && changeRequests.length ) {
      //   this.getICD10Codes( changeRequests );
      // }
      trigger && channel.trigger( trigger );
    },
		resolveGetPatientList: function( trigger, data ) {
			let hasProviders = app.controller.providers && app.controller.providers.length;
			let patients = [];
			if ( data && data.items && data.items.length ) {
				patients = data.items.map( ( patient ) => {
					if ( hasProviders ) {
						let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
						return Object.assign( {}, patient, {
							provider: ( providerObj && providerObj.name ) || '',
							birthdate: app.controller.formatFixedDateForUI( patient.birthdate ),
							ccm_status: this.determineCCMStatus( patient ),
							status_change_display: app.controller.formatLocalDateAndTimeForUI( patient.status_change_ts ),
							provider_transition_effective_date:patient.provider_transition_effective_date ?
								app.controller.formatFixedDateForUI( patient.provider_transition_effective_date ) : '',
						});
					}
					return Object.assign( {}, patient, {
						birthdate: app.controller.formatFixedDateForUI( patient.birthdate ),
						ccm_status: this.determineCCMStatus( patient ),
						status_change_display: app.controller.formatLocalDateAndTimeForUI( patient.status_change_ts ),
					});
				});
			}

			let triggerSetPatients = false;

			if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
				this.patients.totalPages = data.total_pages;
				triggerSetPatients = true;
			}
			if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
				this.patients.totalPatients = data.total_items;
				triggerSetPatients = true;
			}
			if ( !this.patients.currentPage ) {
				this.patients.currentPage = channel.request( 'currentPage' );
				triggerSetPatients = true;
			}
			if ( !this.patients.itemCount || this.patients.itemCount != data.count ) {
				this.patients.itemCount = data.count;
				triggerSetPatients = true;
			}
			if ( !hasProviders ) {
				channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
			}
			this.setGlobalValue( 'patientList', patients );
			triggerSetPatients && channel.trigger( 'set:patients' );

			if ( patients && patients.length ) {
				this.getICD10Codes( patients );
			}
			trigger && channel.trigger( trigger );
		},

		getUnverifiedCSV: function(page, pageSize, searchInput){
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients_unverified?${ searchInput }`+this.getListQueries();

			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				accept: 'text/csv'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientListCSV.bind( this,'unverified' ), null, headerData );
				return;
			}
		},
		getUnenrolledCSV: function(page, pageSize, searchInput){
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients_unenrolled?${ searchInput }`+this.getListQueries();

			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				accept: 'text/csv'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientListCSV.bind( this,'unenrolled' ), null, headerData );
				return;
			}
		},

		getPendingUnenrollCSV: function(page, pageSize, searchInput) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients_pending_unenroll?${ searchInput }`+this.getListQueries();

			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				accept: 'text/csv'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientListCSV.bind( this , 'pending_unenroll'), null, headerData );
				return;
			}
		},

		getOptedOutCSV: function(page, pageSize, searchInput){
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients_opted_out?${ searchInput }`+this.getListQueries();

			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				accept: 'text/csv'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientListCSV.bind( this,'opted_out' ), null, headerData );
				return;
			}
		},

		getSupportCSV: function(page, pageSize, searchInput){
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients_support?${ searchInput }`+this.getListQueries();

			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				accept: 'text/csv'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientListCSV.bind( this,'support' ), null, headerData );
				return;
			}
		},

		getEnrolledCSV: function(page, pageSize, searchInput){
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients_enrolled?${ searchInput }`+this.getListQueries();

			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				accept: 'text/csv'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientListCSV.bind( this,'enrolled' ), null, headerData );
				return;
			}
		},

		getSuspendedCSV: function(page, pageSize, searchInput){
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients_suspended?${ searchInput }`+this.getListQueries();

			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				accept: 'text/csv'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientListCSV.bind( this,'suspended' ), null, headerData );
				return;
			}
		},

		getProviderTransitionCSV: function(page, pageSize, searchInput){
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients_provider_transition?${ searchInput }`+this.getListQueries();

			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				accept: 'text/csv'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientListCSV.bind( this,'provider_transition' ), null, headerData );
				return;
			}
		},

		getProviderApprovalCSV: function(page, pageSize, searchInput){
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients?${ searchInput }`+this.getListQueries();

			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				accept: 'text/csv'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientListCSV.bind( this,'provider_approval' ), null, headerData );
				return;
			}
		},

		getPatientListCSV: function( page, pageSize, searchInput) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients?${ searchInput }`;

			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				accept: 'text/csv'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientListCSV.bind( this ,'patient-list'), null, headerData );
				return;
			}
		},

		resolveGetPatientListCSV: function( fileName,data ) {
			let file = new Blob( [ data ], { type: 'csv' } );
			let searchInput = this.searchInput ? this.searchInput.replace( /search=/gi, '' ) : '';
			let filename = `${fileName}_${ searchInput }_${moment().format('MM/DD/YYYY')}.csv`;
			if (navigator.msSaveOrOpenBlob) {
				navigator.msSaveOrOpenBlob(file, filename);
			} else {
				let a = document.createElement('a');
				let url = URL.createObjectURL(file);
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				}, 0);
			}
		},

		getAutoPendingCSV: function( page = 0, pageSize = 20 ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			let searchInput = this.searchInput ? this.searchInput.replace( /search=/gi, '' ) : '';
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients_pending_unenroll?search=${ searchInput }`;
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				accept: 'text/csv'
			}
			if ( org_id ) {
				$( '.paginated-spinner' ).show();
				utils.get( endpoint, resultTriggers, this.resolveGetAutoPendingCSV.bind( this ), null, headerData );
				return;
			}
		},

		resolveGetAutoPendingCSV: function( data ) {
			let file = new Blob( [ data ], { type: 'csv' } );
			let searchInput = this.searchInput ? this.searchInput.replace( /search=/gi, '' ) : '';
			let filename = `auto-enroll-list_${ searchInput }_${moment().format('MM/DD/YYYY')}.csv`;
			if (navigator.msSaveOrOpenBlob) {
				navigator.msSaveOrOpenBlob(file, filename);
			} else {
				let a = document.createElement('a');
				let url = URL.createObjectURL(file);
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				}, 0);
			}
		},

		openPatientPlan: function( patient_id ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patient_id }/planmeta`;
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.viewCarePlan.bind( this, patient_id, org_id ), null );
		},

		viewCarePlan: function( patient_id, org_id, data ) {
			if ( data.available ) {
				let token = app.controller.getToken( 'engage' );
				window.open( `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patient_id }/planpdf?X-AUTH-TOKEN=${ token }` );
				return;
			}
			// The ErrorModalView expects the error formatted as a standard http error
			const mockError = {
				status:400,
				statusText: 'PHS Unavailable',
				responseJSON: { message: 'No PHS available for patient.' },
			};
			channel.trigger( 'failed:api', mockError );
		},

        openCcda: function( patient_id ) {
            let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patient_id }/latest_ccdameta`;
            let successTrigger = ['headers:success', 'hide:contentSpinner'];
            let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
            let resultTriggers = {
                successTrigger,
                errorTrigger
            }
            channel.trigger( 'show:contentSpinner' );
            utils.get( endpoint, resultTriggers, this.viewCcda.bind( this, patient_id, org_id ), null );
        },

        viewCcda: function( patient_id, org_id, data ) {
            if ( data.available ) {
                let token = app.controller.getToken( 'engage' );
                window.open( `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patient_id }/latest_ccdapdf?X-AUTH-TOKEN=${ token }` );
                return;
            }
            // The ErrorModalView expects the error formatted as a standard http error
            const mockError = {
				status:400,
                statusText: 'CCDA Unavailable',
                responseJSON: { message: 'No CCDA available for patient.' },
            };
            channel.trigger( 'failed:api', mockError );
        },

		openPatientHistory: function( patient_id ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patient_id }/worksummarymeta`;
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.viewPatientHistory.bind( this, patient_id, org_id ), null );
		},

		viewPatientHistory: function( patient_id, org_id, data ) {
			if ( data.available ) {
				let token = app.controller.getToken( 'engage' );
				window.open( `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patient_id }/worksummarypdf?X-AUTH-TOKEN=${ token }` )
				return;
			}
			// The ErrorModalView expects the error formatted as a standard http error
			const mockError = {
				status:400,
				statusText: 'Patient History Unavailable',
				responseJSON: { message: 'No Patient History available for patient.' },
			};
			channel.trigger( 'failed:api', mockError );
		},

		getICD10Codes: function( data ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/codelookup/icd10/by_id?';
			let getAllCodes = [];
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			if ( data && data.length ) {
				_.each( data, ( item ) => {
	                if( !_.isEmpty( item.icd10code_1 ) && !_.isEmpty( item.icd10code_2 ) ) {
	                    getAllCodes.push( this.parseICD10Code( item.icd10code_1 ) );
	                    getAllCodes.push( this.parseICD10Code( item.icd10code_2 ) );

	                } else if( !_.isEmpty( item.icd10code_1 ) && _.isEmpty( item.icd10code_2 ) ) {
	                    getAllCodes.push(  this.parseICD10Code( item.icd10code_1 )  );

	                } else if( _.isEmpty( item.icd10code_1 ) && !_.isEmpty( item.icd10code_2 )  ) {
	                    getAllCodes.push(  this.parseICD10Code( item.icd10code_2 )  );
	                }
	            });
			} else {
				getAllCodes.push(  this.parseICD10Code( data.icd10code_1 ) );
				getAllCodes.push(  this.parseICD10Code( data.icd10code_2 ) );
			}

            let params = "id=" + getAllCodes.join( "&id=" );
            params = params.replace( ".", "%2E" );
            channel.trigger( 'show:contentSpinner' );
            utils.get( endpoint + params, resultTriggers, this.resolveICDCodes.bind( this, data ) );
		},

		parseICD10Code: function( value ) {
			if ( value && value.indexOf( ' - ' ) > -1 ) {
				return value.slice( 0, value.indexOf( ' - ' ) ).trim();
			}
			return value;
		},

		resolveICDCodes: function ( data, prevData ) {
			if ( prevData ) {
				let previousDataCopy = Object.assign( {}, prevData );
				_.each( previousDataCopy.items, ( currItem ) => {
	                if( currItem.icd10code_1 && currItem.icd10code_1.trim() != "" ) {
	                    currItem.icd10code_1 = {
	                        id: currItem.icd10code_1,
	                        code: currItem.icd10code_1,
	                        desc: values[currItem.icd10code_1],
	                    };
	                }
	                if( currItem.icd10code_2 && currItem.icd10code_2.trim() != "" ) {
	                    currItem.icd10code_2 = {
	                        id: currItem.icd10code_2,
	                        code: currItem.icd10code_2,
	                        desc: values[currItem.icd10code_2],
	                    };
	                }
	            });
	            let updatedPatientList;
	            if ( this.patientList && this.patientList.length ) {
		            updatedPatientList = this.patientList.map( ( patient ) => {
		            	return Object.assign( {}, patient );
		            });
	            } else {
	            	channel.listenToOnce( channel, 'set:patientList', this.resolveICDCodes.bind( this, data, prevData ) );
	            	return;
	            }
	            if ( updatedPatientList && updatedPatientList.length ) {
		            this.setGlobalValue( 'patientList', updatedPatientList.map( ( patient ) => {
		            	if ( patient.icd10code_1 && patient.icd10code_1.indexOf( ' - ' ) < 0 ) {
		            		patient.icd10code_1_code = patient.icd10code_1;
		            		patient.icd10code_1 += ' - ' + ( previousDataCopy[ patient.icd10code_1 ] || 'Invalid' );
		            	}
		            	if ( patient.icd10code_2 && patient.icd10code_2.indexOf( ' - ' ) < 0 ) {
                            patient.icd10code_2_code = patient.icd10code_2;
                            patient.icd10code_2 += ' - ' + ( previousDataCopy[ patient.icd10code_2 ] || 'Invalid' );
		            	}
		            	return patient;
		            }));
	            }

			}
		},

		addProvidersToPatients: function() {
			let patientList = app.ccm_controller.patientList;
			patientList = patientList && patientList.map( ( patient ) => {
				let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
				patient.provider = ( providerObj && providerObj.name ) || '' ;
				return patient;
			});
			this.setGlobalValue( 'patientList', patientList );
		},

		addProvidersToAllEnrolledPatients: function() {
			let patientList = app.ccm_controller.allEnrolledPatients;
			patientList = patientList && patientList.map( ( patient ) => {
				let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
				patient.provider = ( providerObj && providerObj.name ) || '' ;
				return patient;
			});
			this.setGlobalValue( 'allEnrolledPatients', patientList );
		},

		searchPatients: function( page, pageSize, searchInput ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			if ( !this.searchInput || this.searchInput != searchInput ) {
				this.setGlobalValue( 'searchInput', searchInput );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients?' + searchInput;
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveSearchPatients.bind( this ), null, headerData );
				return;
			}
		},

		resolveSearchPatients: function( data ) {
			let hasProviders = app.controller.providers && app.controller.providers.length;
			let patients = [];
			if ( data.items && data.items.length ) {
				patients = data.items.map( ( patient ) => {
					if ( hasProviders ) {
						let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
						return Object.assign( {}, patient, {
							provider: ( providerObj && providerObj.name ) || '',
							birthdate: app.controller.formatFixedDateForUI( patient.birthdate ),
							ccm_status: this.determineCCMStatus( patient )
						});
					}
					return Object.assign( {}, patient, {
						birthdate: app.controller.formatFixedDateForUI( patient.birthdate ),
						ccm_status: this.determineCCMStatus( patient )
					});
				});
			}

			let triggerSetPatients = false;

			if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
				this.patients.totalPages = data.total_pages;
				triggerSetPatients = true;
			}
			if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
				this.patients.totalPatients = data.total_items;
				triggerSetPatients = true;
			}
			if ( !this.patients.currentPage ) {
				this.patients.currentPage = channel.request( 'currentPage' );
				triggerSetPatients = true;
			}
			if ( !hasProviders ) {
				channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
			}
			this.setGlobalValue( 'patientList', patients );
			triggerSetPatients && channel.trigger( 'set:patients' );

			if ( patients && patients.length ) {
				this.getICD10Codes( patients );
			}
		},

		searchEnrolledPatients: function( page, pageSize, searchInput ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			if ( !this.searchInput || this.searchInput != searchInput ) {
				this.setGlobalValue( 'searchInput', searchInput );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_enrolled?' + searchInput + '&' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'failed:api', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, null ), null, headerData );
				return;
			}
		},

		searchSuspendedPatients: function( page, pageSize, searchInput ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			if ( !this.searchInput || this.searchInput != searchInput ) {
				this.setGlobalValue( 'searchInput', searchInput );
			}
			if ( page !== this.patients.currentPage ) {
				this.patients.currentPage = page;
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_suspended?' + searchInput + '&' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'failed:api', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, null ), null, headerData );
				return;
			}
		},

		determineCCMStatus: function( patient ) {
			if ( patient.patient_status == 1 ||  patient.patient_status == 2) {
				if(patient.suspended){
					return 'Suspended';
				}
				return 'Enrolled';
			}
			if ( patient.patient_status == 3) {
				return 'Pending Unenroll';
			}
			if ( patient.patient_status == 4) {
				return 'Unenrolled';
			}
			if ( patient.patient_status == 10) {
				return 'Support';
			}
			if ( patient.patient_status == 100) {
				return 'Pending';
			}
			if ( patient.patient_status == 101) {
				return 'Rejected';
			}
			if ( patient.patient_status == 103 || patient.patient_status == 106 
				|| patient.patient_status == 107 || patient.patient_status == 108
			) {
				return 'Opted Out';
			}
			if ( patient.patient_status == 104) {
				return 'Unverified';
			}

			// if(patient.patient_status == 107){
			// 	return "Remove from Queue";
			// }
			// if(patient.patient_status == 106){
			// 	return "Do Not Call";
			// }
			if(patient.patient_status == 102){
				return "AE-Approved";
			}
			if(patient.patient_status == 105){
				return "Needs Attention";
			}

			return 'Unenrolled';
		},

		unenrollPatient: function( patientId , selectedReason, notes, callback ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patient/' + patientId + '/un-enroll';
			let successTrigger = [ 'unenroll:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			// Show spinner during HTTP request
			channel.trigger( 'show:contentSpinner' );
			// Set active patient to reopen collapsed table row if successful
			this.setGlobalValue( 'activePatient', patientId );
			// Call the 'post' method, passing the requisite data
			utils.post( endpoint, {unenroll_reason_id:selectedReason, notes: notes}, successTrigger, errorTrigger, null, true,
				callback? callback : (data)=>{
					this.updatePatientList( data,4 );
				} );
		},

        sendPatientToSupport: function( data ) {
			const patientId = data.patientId;
            let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patientId }/support`;
            let successTrigger = [ 'support:success', 'hide:contentSpinner' ];
            let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];

            if ( org_id ) {
                // Show spinner during HTTP request
                channel.trigger( 'show:contentSpinner' );

                // Set active patient to reopen collapsed table row if successful
                this.setGlobalValue( 'activePatient', patientId );

                // Call the 'post' method, passing the requisite data
                utils.put( endpoint, null, successTrigger, errorTrigger, null, true, this.updatePatientList.bind( this ) );
                return;
			}

            channel.listenTo( channel, 'set:selectedOrganization', this.sendPatientToSupport.bind( this, data ) );
		},

		updatePatientList: function( data,status ) {
			let hasProviders = app.controller.providers && app.controller.providers.length;
			let updatedPatientList = this.patientList && this.patientList.map( ( patient ) => {
				if ( data.id == patient.id ) {
					patient = data;
					( data.icd10code_1 || data.icd10code_2 ) && this.getICD10Codes( patient );
					if(status){
						patient.patient_status = status;
					}
				}
				if ( hasProviders ) {
					let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
					patient.provider = ( providerObj && providerObj.name ) || '' ;
				}
				patient.birthdate = app.controller.formatFixedDateForUI( patient.birthdate );

				patient.ccm_status = this.determineCCMStatus( patient );
				return patient;
			});
			this.setGlobalValue( 'patientList', updatedPatientList );
		},

		suspendPatient: function( patientId ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patient/' + patientId + '/suspend';
			let successTrigger = [ 'suspend:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			// Show spinner during HTTP request
			channel.trigger( 'show:contentSpinner' );
			// Set active patient to reopen collapsed table row if successful
			this.setGlobalValue( 'activePatient', patientId );
			// Call the 'post' method, passing the requisite data
			utils.post( endpoint, null, successTrigger, errorTrigger, null, true, this.updatePatientList.bind( this ) );
		},

    suspendPatientWithReason: function( patientId, reasonId, notes,callback ) {
      let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
      let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patient/' + patientId + '/suspend';
      let successTrigger = [ 'suspend:success', 'hide:contentSpinner' ];
      let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
      // Show spinner during HTTP request
      channel.trigger( 'show:contentSpinner' );
      // Set active patient to reopen collapsed table row if successful
      this.setGlobalValue( 'activePatient', patientId );
      // Call the 'post' method, passing the requisite data
      utils.post( endpoint, {suspension_reason_id:reasonId, notes: notes}, successTrigger, errorTrigger, null, true, callback ?
		  callback : this.updatePatientList.bind( this ) );
    },

		providerTransitionPatient: function( patientId, providerTransitionId,callback ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patient/' + patientId + '/provider_transition/'+providerTransitionId;
			let successTrigger = [ 'suspend:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			// Show spinner during HTTP request
			channel.trigger( 'show:contentSpinner' );
			// Set active patient to reopen collapsed table row if successful
			this.setGlobalValue( 'activePatient', patientId );
			// Call the 'post' method, passing the requisite data
			utils.put( endpoint, null, successTrigger, errorTrigger, null, true, callback ?
				callback : this.updatePatientList.bind( this ) );
		},

		unsuspendPatient: function( patientId ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patient/' + patientId + '/unsuspend';
			let successTrigger = [ 'unsuspend:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			// Show spinner during HTTP request
			channel.trigger( 'show:contentSpinner' );
			// Set active patient to reopen collapsed table row if successful
			this.setGlobalValue( 'activePatient', patientId );
			// Call the 'post' method, passing the requisite data
			utils.post( endpoint, null, successTrigger, errorTrigger, null, true, this.updatePatientList.bind( this ) );
		},

		unsuspendPatientFromSuspendedQueue: function( patientId ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patient/' + patientId + '/unsuspend';
			let successTrigger = [ 'unsuspend:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			// Show spinner during HTTP request
			channel.trigger( 'show:contentSpinner' );
			// Set active patient to reopen collapsed table row if successful
			this.setGlobalValue( 'activePatient', patientId );
			// Call the 'post' method, passing the requisite data
			let new_page = ( this.patients.itemCount > 1 || this.patients.currentPage === 0 ) ? this.patients.currentPage : this.patients.currentPage - 1;
			utils.post( endpoint, null, successTrigger, errorTrigger, null, true, this.searchSuspendedPatients.bind( this, new_page, 20, this.searchInput || 'sort_order=asc&sort_field=last_name' ) );
		},

		enrollPatient: function( patientData,callback ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/enroll';
			let successTrigger = [ 'enroll:success', 'close:modal', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner' ];
			// Show spinner during HTTP request
			channel.trigger( 'toggle:globalSpinner' );
			utils.post( endpoint, patientData, successTrigger, errorTrigger, null, true,
				callback ? callback : (data)=>{
					this.updatePatientList( data,1 );
				} );
		},

		editPatient: function( patientData ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/update_patient';
			let successTrigger = [ 'enroll:success', 'close:modal', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner' ];
			// Show spinner during HTTP request
			channel.trigger( 'toggle:globalSpinner' );
			utils.put( endpoint, patientData, successTrigger, errorTrigger, null, true, this.updatePatientList.bind( this ) );
		},

		addPatient: function( patientData ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/add_patient';
			let successTrigger = [ 'enroll:success', 'close:modal', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner', 'enroll:failure' ];
			// Show spinner during HTTP request
			channel.trigger( 'toggle:globalSpinner' );
			utils.post( endpoint, patientData, successTrigger, errorTrigger, null, true, this.resolveAddPatient.bind( this ) );
		},

		resolveAddPatient: function( data ) {
			this.getPatientList();
		},

		getListQueries: function() {
			let selectedLocation = app.controller.selectedLocationId || -1;
			let selectedProvider = app.controller.selectedProviderId || -1;
            if ( selectedProvider != -1 ) {
                return `&provider_id=${ selectedProvider }`
            } else if ( selectedLocation != -1 ) {
                return `&location_id=${ selectedLocation }`
            } else {
            	return ''
            }
		},

		getUnenrolledList: function( trigger = null, page = 0, pageSize = 20, searchInput = '' ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_unenrolled?sort_order=asc&sort_field=last_name' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, trigger ), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getUnenrolledList.bind( this ) );
		},

		searchUnenrolledPatients: function( page = 0, pageSize = 20, searchInput = '' ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			if ( !this.searchInput || this.searchInput != searchInput ) {
				this.setGlobalValue( 'searchInput', searchInput );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_unenrolled?' + searchInput + '&' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, null ), null, headerData );
				return;
			}
		},

		getUnverifiedList: function( trigger = null, page = 0, pageSize = 20, searchInput = '' ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_unverified?sort_order=asc&sort_field=last_name' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, trigger ), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getUnverifiedList.bind( this ) );
		},

		searchUnverifiedPatients: function( page = 0, pageSize = 20, searchInput = '' ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			if ( !this.searchInput || this.searchInput != searchInput ) {
				this.setGlobalValue( 'searchInput', searchInput );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_unverified?' + searchInput + '&' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, null ), null, headerData );
				return;
			}
		},

		getSupportPatientList: function( trigger = null, page = 0, pageSize = 20, searchInput = '' ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_support?sort_order=asc&sort_field=last_name' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, trigger ), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getSupportPatientList.bind( this ) );
		},

		searchSupportPatients: function( page = 0, pageSize = 20, searchInput = '' ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			if ( !this.searchInput || this.searchInput != searchInput ) {
				this.setGlobalValue( 'searchInput', searchInput );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_support?' + searchInput + '&' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, null ), null, headerData );
				return;
			}
		},

		getOptedoutList: function( trigger = null, page = 0, pageSize = 20, searchInput = '' ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_opted_out?sort_order=asc&sort_field=last_name' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, trigger ), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getOptedoutList.bind( this ) );
		},

		searchOptedoutPatients: function( page = 0, pageSize = 20, searchInput = '' ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			if ( !this.searchInput || this.searchInput != searchInput ) {
				this.setGlobalValue( 'searchInput', searchInput );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_opted_out?' + searchInput + '&' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, null ), null, headerData );
				return;
			}
		},


		getPendingUnenrolled: function( trigger = null, page = 0, pageSize = 20, searchInput = '' ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_pending_unenroll?sort_order=asc&sort_field=last_name' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, trigger ), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getPendingUnenrolled.bind( this ) );
		},

    getProviderApproval: function (trigger = null, page = 0, pageSize = 20, searchInput = '') {
      let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
      let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/provider_change_requests?sort_order=asc&sort_field=last_name' + this.getListQueries();
      let successTrigger = ['headers:success', 'hide:contentSpinner'];
      let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
      let resultTriggers = {
        successTrigger,
        errorTrigger
      }
      let headerData = {
        page,
        pageSize,
        contentType: 'application/json; charset=UTF-8'
      }

      if ( org_id ) {
        channel.trigger( 'show:contentSpinner' );
        utils.get( endpoint, resultTriggers, this.resolveProviderChangeRequestData.bind( this, trigger ), null, headerData );
        return;
      }
      channel.listenTo( channel, 'set:selectedOrganization', this.getPendingUnenrolled.bind( this ) );
    },

		confirmUnenroll: function( patient_id ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patient/' + patient_id + '/approve_unenroll'
			let successTrigger = [ 'unenroll:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:unenroll', 'hide:contentSpinner' ];
			// Show spinner during HTTP request
			channel.trigger( 'show:contentSpinner' );
			// Call the 'post' method, passing the requisite data
			utils.post( endpoint, null, successTrigger, errorTrigger, null, true, this.resolveUnenroll.bind(this) );
		},
    declineProviderChangeRequest: function(request_id,notes){
      let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
      let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/provider_change_requests/' + request_id + '/reject'
      let successTrigger = [ 'unenroll:success', 'hide:contentSpinner' ];
      let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
      // Show spinner during HTTP request
      channel.trigger( 'show:contentSpinner' );
      // Call the 'post' method, passing the requisite data
      utils.post( endpoint, {
        reason:notes
      }, successTrigger, errorTrigger, null, true, this.resolveApproved.bind(this,request_id) );
    },
    approveProviderChangeRequest: function(request_id,callback) {
      let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
      let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/provider_change_requests/' + request_id + '/approve'
      let successTrigger = [ 'unenroll:success', 'hide:contentSpinner' ];
      let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
      // Show spinner during HTTP request
      channel.trigger( 'show:contentSpinner' );
      // Call the 'post' method, passing the requisite data
      utils.post( endpoint, null, successTrigger, errorTrigger, null, true, this.resolveApproved.bind(this,request_id,callback) );
    },
    resolveApproved: function( request_id,callback ) {
      this.setGlobalValue( 'patientList', this.patientList.filter( ( patient ) => patient.id != request_id ) );
		callback();
    },
		cancelUnenroll: function( patient_id ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patient/' + patient_id + '/cancel_unenroll'
			let successTrigger = [ 'unenroll:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			// Show spinner during HTTP request
			channel.trigger( 'show:contentSpinner' );
			// Call the 'post' method, passing the requisite data
			utils.post( endpoint, null, successTrigger, errorTrigger, null, true, this.resolveUnenroll.bind(this) );
		},

		resolveUnenroll: function( data ) {
			this.setGlobalValue( 'patientList', this.patientList.filter( ( patient ) => patient.id != data.id ) );
		},

		searchPendingUnenrolledPatients: function( page = 0, pageSize = 20, searchInput = '' ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			if ( !this.searchInput || this.searchInput != searchInput ) {
				this.setGlobalValue( 'searchInput', searchInput );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_pending_unenroll?' + searchInput + '&' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, null ), null, headerData );
				return;
			}
		},
    searchProviderApproval: function( page = 0, pageSize = 20, searchInput = '' ) {
      if ( page == 0 ) {
        this.clearValue( 'patientList' );
        this.clearValue( 'totalPages' );
      }
      if ( !this.searchInput || this.searchInput != searchInput ) {
        this.setGlobalValue( 'searchInput', searchInput );
      }
      let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
      let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/provider_change_requests?' + searchInput + '&' + this.getListQueries();
      let successTrigger = ['headers:success', 'hide:contentSpinner'];
      let errorTrigger = ['headers:fail', 'hide:contentSpinner'];
      let resultTriggers = {
        successTrigger,
        errorTrigger
      }
      let headerData = {
        page,
        pageSize,
        contentType: 'application/json; charset=UTF-8'
      }
      if ( org_id ) {
        channel.trigger( 'show:contentSpinner' );
        utils.get( endpoint, resultTriggers, this.resolveProviderChangeRequestData.bind( this, null ), null, headerData );
        return;
      }
    },
		getProviderTransitionList: function( trigger = null, page = 0, pageSize = 20, searchInput = '' ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_provider_transition?sort_order=asc&sort_field=last_name' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, trigger ), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getProviderTransitionList.bind( this ) );
		},

		searchProviderTransition: function( page = 0, pageSize = 20, searchInput = '' ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			if ( !this.searchInput || this.searchInput != searchInput ) {
				this.setGlobalValue( 'searchInput', searchInput );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_provider_transition?' + searchInput + '&' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, null ), null, headerData );
				return;
			}
		},

		getEnrolledList: function( trigger = null, page = 0, pageSize = 20, searchInput = '' ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_enrolled?sort_order=asc&sort_field=last_name' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, trigger ), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getEnrolledList.bind( this ) );
		},

		getSuspendedList: function( trigger = null, page = 0, pageSize = 20, searchInput = '' ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_suspended?sort_order=asc&sort_field=last_name' + this.getListQueries();
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetPatientList.bind( this, trigger ), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getSuspendedList.bind( this ) );
		},

		resolveEnrolledList: function( trigger, data ) {
			let hasProviders = app.controller.providers && app.controller.providers.length;
			let patients = data.items || [];
			if ( patients.length ) {
				patients = patients.map( ( patient ) => {
					if ( hasProviders ) {
						let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
						patient.provider = ( providerObj && providerObj.name ) || '' ;
					}

					patient.birthdate = app.controller.formatFixedDateForUI( patient.birthdate );
					patient.ccm_status = this.determineCCMStatus( patient );
					return patient;
				});
			}

			this.setGlobalValue( 'patientList', patients );
			let triggerSetPatients = false;

			if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
				this.patients.totalPages = data.total_pages;
				triggerSetPatients = true;
			}
			if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
				this.patients.totalPatients = data.total_items;
				triggerSetPatients = true;
			}
			if ( !this.patients.currentPage ) {
				this.patients.currentPage = channel.request( 'currentPage' );
				triggerSetPatients = true;
			}
			if ( !hasProviders ) {
				channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
			}
			this.setGlobalValue( 'patientList', patients );
			triggerSetPatients && channel.trigger( 'set:patients' );
		},

		getRapidBilling: function( trigger = null, page = 0, pageSize = 100 ) {
			if ( typeof page != 'number' ) {
				page = 0;
			}
			if ( page !== this.patients.currentPage ) {
				this.patients.currentPage = page;
			}
			let location_id = ( app && app.controller && app.controller.selectedLocationId ) || null;
			let provider_id = ( app && app.controller && app.controller.selectedProviderId ) || null;
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/rapidbill/' + org_id + '/APPROVE?';
			if ( this.approvalDateRange ) {
				endpoint += `&month=${this.approvalMonth}&year=${this.approvalYear}`;
			}
			if ( provider_id > -1 ) {
				endpoint += `&provider_Id=${provider_id}`;
			} else if ( location_id > -1 ) {
				endpoint += `&location_Id=${location_id}`;
			}

			if(this.cptCodeFilter){
				endpoint += `&cpt_code=${this.cptCodeFilter}`;
			}
			let successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveRapidBilling.bind( this, trigger ), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getRapidBilling.bind( this ) );
		},
    resolveRapidBillingPendingReview: function(trigger, data) {
      let billablePatients = [];
      let hasProviders = app.controller.providers && app.controller.providers.length;
      // let rejectReasons = [];
      if ( data && data.items && data.items.length ) {
        // rejectReasons = data.items[0].reject_reasons;
        billablePatients = data.items.map( ( patientWrapper ) => {
          return Object.assign( {}, patientWrapper,
              {birthdate: app.controller.formatFixedDateForUI( patientWrapper.birthdate )});
        });
      }
      if ( billablePatients.length && hasProviders ) {
        billablePatients = billablePatients.map( ( patient ) => {
          let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
          patient.provider = ( providerObj && providerObj.name ) || '' ;
          patient.billing_provider = ( providerObj && providerObj.name ) || '' ;
          if ( patient.billing_provider_id ) {
            let billingProviderObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.billing_provider_id );
            patient.billing_provider = ( billingProviderObj && billingProviderObj.name ) || '' ;
          }
          return patient;
        });
      }

      if ( !hasProviders ) {
        channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
      }
      let triggerSetPatients = false;

      // if ( !this.patients.rejectReasons || this.patients.rejectReasons != rejectReasons ) {
      //   this.patients.rejectReasons = rejectReasons;
      //   triggerSetPatients = true;
      // }
      if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
        this.patients.totalPages = data.total_pages;
        triggerSetPatients = true;
      }
      if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
        this.patients.totalPatients = data.total_items;
        triggerSetPatients = true;
      }
      if ( !this.patients.currentPage ) {
        this.patients.currentPage = channel.request( 'currentPage' );
        triggerSetPatients = true;
      }
      if ( !this.patients.itemCount || this.patients.itemCount != data.count ) {
        this.patients.itemCount = data.count;
        triggerSetPatients = true;
      }
      triggerSetPatients && channel.trigger( 'set:patients' );

      this.setGlobalValue( 'rapidBilling', ( data && data.items ) || {} );
      this.setGlobalValue( 'patientList', billablePatients );
      if ( billablePatients && billablePatients.length ) {
        this.getICD10Codes( billablePatients );
      }
      if ( trigger ) {
        channel.trigger( trigger );
      }
    },
		resolveRapidBilling: function( trigger, data ) {
			let billablePatients = [];
			let hasProviders = app.controller.providers && app.controller.providers.length;let rejectReasons = [];
			if ( data && data.items && data.items.length ) {
				rejectReasons = data.items[0].reject_reasons;
				billablePatients = data.items.map( ( patientWrapper ) => {
					return Object.assign( {}, patientWrapper.patient, {
						billing_id: patientWrapper.id,
						dos_provider_id: patientWrapper.provider_id,
						billing_event_ts: patientWrapper.billing_event_ts ? moment.utc( patientWrapper.billing_event_ts ).local().format( 'MM/DD/YYYY h:mm A' ) : '',
						billing_provider_id: patientWrapper.billing_provider_id,
						birthdate: app.controller.formatFixedDateForUI( patientWrapper.patient.birthdate ),
						new_patient_for_billing: patientWrapper.new_patient_for_billing,
						month: patientWrapper.month,
						api_response: patientWrapper.api_response || null,
						time_worked: patientWrapper.time_worked,
						cpt_code: patientWrapper.cpt_code
					});
				});
			}
			if ( billablePatients.length && hasProviders ) {
				billablePatients = billablePatients.map( ( patient ) => {
					let providerObj = _.find( app.controller.AllProviders, ( provider ) => provider.id == patient.ccm_provider_id );
					patient.provider = ( providerObj && providerObj.name ) || '' ;
					patient.billing_provider = ( providerObj && providerObj.name ) || '' ;
					if ( patient.billing_provider_id ) {
						let billingProviderObj = _.find( app.controller.AllProviders, ( provider ) => provider.id == patient.billing_provider_id );
						patient.billing_provider = ( billingProviderObj && billingProviderObj.name ) || '' ;
					}
					if(patient.dos_provider_id){
						let dosProviderObj = _.find( app.controller.AllProviders, ( provider ) => provider.id == patient.dos_provider_id );
						patient.dos_provider = ( dosProviderObj && dosProviderObj.name ) || '' ;
					
					}
					return patient;
				});
			}

			if ( !hasProviders ) {
				channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
			}
			let triggerSetPatients = false;

			if ( !this.patients.rejectReasons || this.patients.rejectReasons != rejectReasons ) {
				this.patients.rejectReasons = rejectReasons;
				triggerSetPatients = true;
			}
			if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
				this.patients.totalPages = data.total_pages;
				triggerSetPatients = true;
			}
			if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
				this.patients.totalPatients = data.total_items;
				triggerSetPatients = true;
			}
			if ( !this.patients.currentPage ) {
				this.patients.currentPage = channel.request( 'currentPage' );
				triggerSetPatients = true;
			}
			if ( !this.patients.itemCount || this.patients.itemCount != data.count ) {
				this.patients.itemCount = data.count;
				triggerSetPatients = true;
			}
			triggerSetPatients && channel.trigger( 'set:patients' );

			this.setGlobalValue( 'rapidBilling', ( data && data.items ) || {} );
			this.setGlobalValue( 'patientList', billablePatients );
			if ( billablePatients && billablePatients.length ) {
				this.getICD10Codes( billablePatients );
			}
			if ( trigger ) {
				channel.trigger( trigger );
			}
		},

		getPendingReview: function( trigger = null, page = 0, pageSize = 100 ) {
			if ( typeof page != 'number' ) {
				page = 0;
			}
			let location_id = ( app && app.controller && app.controller.selectedLocationId ) || null;
			let provider_id = ( app && app.controller && app.controller.selectedProviderId ) || null;
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/rapidbill/' + org_id + '/review_queue';
			if ( provider_id > -1 ) {
				endpoint += `?provider_Id=${provider_id}`;
			} else if ( location_id > -1 ) {
				endpoint += `?location_Id=${location_id}`;
			}
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveRapidBillingPendingReview.bind( this, trigger ), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getPendingReview.bind( this ) );
		},

		submitBilling: function( patients, currentQueue = 'APPROVE' ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/rapidbill/billable';
			let body = _.map( patients, ( patient ) => {
				patient.patient.birthdate = app.controller.formatDateForServer( patient.patient.birthdate );
				return patient;
			});
			let successTrigger = [ 'update:success', 'toggle:globalSpinner', 'close:modal', 'submitBilling:success' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner', 'submitBilling:failure' ];
			let callback = currentQueue == 'APPROVE' ? this.getRapidBilling : this.getErrorList;
			// Show spinner during HTTP request
			channel.trigger( 'toggle:globalSpinner' );
			// Call the 'post' method, passing the requisite data
			if ( org_id ) {
				utils.post( endpoint, body, successTrigger, errorTrigger, null, true, callback.bind( this ) );
			}
		},

		getAutoPendingList: function( page = 0, pageSize = 20 ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;



			// TODO: To check for pagination and no-results template need more data. Can test with '/patients_enrolled'

			// let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_enrolled';
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_auto_pending';



			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}

			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetAutoPendingList.bind(this), null, headerData );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getAutoPendingList.bind( this ) );
		},

		resolveGetAutoPendingList: function( data ) {
			let hasProviders = app.controller.providers && app.controller.providers.length;
			let patients = data.items || [];
			if ( patients.length ) {
				patients = patients.map( ( patient ) => {
					if ( hasProviders ) {
						let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
						patient.provider = ( providerObj && providerObj.name ) || '' ;
					}
					patient.birthdate = app.controller.formatFixedDateForUI( patient.birthdate );
					return patient;
				});
			}

			let triggerSetPatients = false;

			if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
				this.patients.totalPages = data.total_pages;
				triggerSetPatients = true;
			}
			if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
				this.patients.totalPatients = data.total_items;
				triggerSetPatients = true;
			}
			if ( !this.patients.currentPage ) {
				this.patients.currentPage = channel.request( 'currentPage' );
				triggerSetPatients = true;
			}
			if ( !hasProviders ) {
				channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
			}
			this.setGlobalValue( 'patientList', patients );
			triggerSetPatients && channel.trigger( 'set:patients' );
		},

		searchAutoPendingPatients: function( page, pageSize, searchInput ) {
			if ( page == 0 ) {
				this.clearValue( 'patientList' );
				this.clearValue( 'totalPages' );
			}
			if ( !this.searchInput || this.searchInput != searchInput ) {
				this.setGlobalValue( 'searchInput', searchInput );
			}
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;



			// TODO: To check for pagination and no-results template need more data. Can test with '/patients_enrolled'

			// let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_enrolled?' + searchInput;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_auto_pending?' + searchInput;



			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}
			let headerData = {
				page,
				pageSize,
				contentType: 'application/json; charset=UTF-8'
			}
			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveSearchAutoPendingPatients.bind( this ), null, headerData );
				return;
			}
		},

		resolveSearchAutoPendingPatients: function( data ) {
			let hasProviders = app.controller.providers && app.controller.providers.length;
			let patients = data.items || [];
			let extendPatientList = channel.request( 'currentPage' ) > 0;
			if ( patients.length ) {
				patients = patients.map( ( patient ) => {
					if ( hasProviders ) {
						let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
						patient.provider = ( providerObj && providerObj.name ) || '' ;
					}
					patient.birthdate = app.controller.formatFixedDateForUI( patient.birthdate );
					return patient;
				});
			}

			let triggerSetPatients = false;

			if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
				this.patients.totalPages = data.total_pages;
				triggerSetPatients = true;
			}
			if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
				this.patients.totalPatients = data.total_items;
				triggerSetPatients = true;
			}
			if ( !this.patients.currentPage ) {
				this.patients.currentPage = channel.request( 'currentPage' );
				triggerSetPatients = true;
			}
			if ( !hasProviders ) {
				channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
			}
			this.setGlobalValue( 'patientList', patients );
			triggerSetPatients && channel.trigger( 'set:patients' );
		},

		updateBillingInfo: function( patient, currentQueue ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/rapidbill/' + org_id + '/patient';
			let body = patient;
			let successTrigger = [ 'update:success', 'toggle:globalSpinner' ];
			let errorTrigger = [ 'failed:api', 'toggle:globalSpinner', 'updateBillingInfo:failure' ];
			// Show spinner during HTTP request
			channel.trigger( 'toggle:globalSpinner' );
			// Call the 'post' method, passing the requisite data
			if ( org_id ) {
				utils.post( endpoint, body, successTrigger, errorTrigger, null, true, this.resolveUpdateBillingInfo.bind( this, currentQueue ) );
			}
		},

		resolveUpdateBillingInfo: function( currentQueue, data ) {
      channel.trigger( 'close:modal' );
      currentQueue == 'APPROVE' ? this.getRapidBilling() : this.getPendingReview();

		},

		submitAutoEnroll: function( patientIds ) {
			const endpoint = `${BASE_ENGAGE_URL}v1/ccm/patients/approve_bulk`;
			const successTrigger = ['headers:success', 'hide:contentSpinner'];
			const errorTrigger = ['failed:api', 'hide:contentSpinner' ];
			channel.trigger( 'show:contentSpinner' );
			utils.post( endpoint, patientIds, successTrigger, errorTrigger, null, true, this.resolveSubmitAutoEnroll.bind( this ) );
		},

		resolveSubmitAutoEnroll: function (response) {
			// If the call fails because a patient is not in auto enrolled pending
			// status we receive a 200 response code
			if (response.status === 'Failure') {
				// The ErrorModalView expects the error formatted as a standard http error
				const mockError = {
					status:400,
					statusText: 'Bad Request',
					responseJSON: { message: error.reason || 'Unknown error' },
				};
				channel.trigger( 'failed:api', mockError );
			} else {
				channel.trigger( 'close:modal' );
				this.getAutoPendingList();
			}
		},

		getRapidBillingURL: function( endpointRoot ) {
			let userRole = ( app.controller.user && app.controller.user.user ) ? 'user' : 'admin';
			let selectedLocationId = app.controller.selectedLocationId || -1;
			let selectedProviderId = app.controller.selectedProviderId || -1;
			let organizationId = ( app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;

		    let endpoint = endpointRoot;
		    switch(userRole.toLowerCase()) {
		        case 'messenger':
		        case 'user':
				case 'admin':
		            if( selectedLocationId == -1 && selectedProviderId == -1 ) {
		                return endpoint;
		            } else if( selectedProviderId != -1 ) {
		                return endpoint = endpoint + `&provider_Id=${ selectedProviderId }`;
		            } else if( selectedLocationId != -1 ) {
		                return endpoint = endpoint + `&location_Id=${ selectedLocationId }`;
		            }
		            break;
		        default:
		            return endpoint;
		    }
		},

		getRapidBillingQueueCSV: function( billable_status = 'APPROVE' ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpointRoot = `${BASE_ENGAGE_URL}v1/ccm/rapidbill/${org_id}/${billable_status}/csv?`;
			let endpoint =  this.getRapidBillingURL( endpointRoot );
			if ( this.approvalDateRange ) {
				endpoint += `&month=${this.approvalMonth}&year=${this.approvalYear}`;
			}
			if(this.cptCodeFilter){
				endpoint += `&cpt_code=${this.cptCodeFilter}`;
			}
			let successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			}


			if ( org_id ) {
				channel.trigger( 'show:contentSpinner' );
				utils.get( endpoint, resultTriggers, this.resolveGetRapidBillingQueueCSV.bind( this, billable_status ) );
				return;
			}
			channel.listenTo( channel, 'set:selectedOrganization', this.getRapidBillingQueueCSV.bind( this, billable_status ) );

		},

		resolveGetRapidBillingQueueCSV: function( billable_status, data ) {
			let currentQueue = billable_status == 'APPROVE' ? 'approval-queue' : 'error-queue';
			let file = new Blob( [ data ], { type: 'csv' } );
			let filename = `rapid-billing_${currentQueue}_${moment().format('MM/DD/YYYY')}.csv`;
			if (navigator.msSaveOrOpenBlob) {
				navigator.msSaveOrOpenBlob(file, filename);
			} else {
				let a = document.createElement('a');
				let url = URL.createObjectURL(file);
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				}, 0);
			}
		},

		getBillableReportSummary: function( startStamp, endStamp ) {
			if ( startStamp >= endStamp ) {
				const dateSelectionError = {
					statusText: 'Bad Date Range',
					responseJSON: { message: 'The first date must be earlier than the second' },
				};
				channel.trigger( 'failed:api', dateSelectionError );
				return
			};

			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpointRoot = `${BASE_ENGAGE_URL}v1/ccm/rapidbill/report/summary/${org_id}?start_date=${startStamp}&end_date=${endStamp}`;

			let endpoint = this.getRapidBillingURL( endpointRoot, startStamp, endStamp );
			let successTrigger = ['getBillableReportSummary:success', 'hide:contentSpinner'];
			let errorTrigger = ['getBillableReportSummary:fail', 'hide:contentSpinner'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveGetBillableReportSummary.bind( this ) );
		},

		resolveGetBillableReportSummary: function( data ) {
			if ( data ) {
				const cptCode = (app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.medicareCptCode) || null;
				let breakdown = {
					billed_99490: { label: '99490', value: data.billed_99490 },
					billed_G0511: { label: 'G0511', value: data.billed_G0511 },
					failedAndRejected: { label: 'Failed', value: +data.failed + data.rejected },
					incomplete: { label: 'Incomplete', value: data.incomplete },
					cptCode:cptCode
				};
				this.setGlobalValue( 'rapidBillingBreakdown', breakdown );
				this.setGlobalValue( 'rapidBillingRevenue', data.estimated_total );
			}
		},

		getRapidBillingDetailedReportCSV: function( startStamp, endStamp ) {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpointRoot = `${BASE_ENGAGE_URL}v1/ccm/rapidbill/billed/csv/${org_id}?start_date=${startStamp}&end_date=${endStamp}`;

			let endpoint =  this.getRapidBillingURL( endpointRoot, startStamp, endStamp );
			let successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
			let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveGetRapidBillingDetailedReportCSV.bind(this, startStamp, endStamp) );

		},

		resolveGetRapidBillingDetailedReportCSV: function( startStamp, endStamp, data ) {
			let file = new Blob( [ data ], { type: 'csv' } );
			if (navigator.msSaveOrOpenBlob) {
				navigator.msSaveOrOpenBlob(file, `rapid-billing-charges_${moment( startStamp ).format('MM/DD/YYYY')}-${moment( endStamp - 1 ).format('MM/DD/YYYY')}.csv`);
			} else {
				let filename = `rapid-billing-charges_${moment( startStamp ).format('MM/DD/YYYY')}-${moment( endStamp - 1 ).format('MM/DD/YYYY')}.csv`;
				let a = document.createElement( 'a' );
				let url = URL.createObjectURL( file );
				a.href = url;
				a.download = filename;
				document.body.appendChild( a );
				a.click();
				setTimeout( () => {
					document.body.removeChild( a );
					window.URL.revokeObjectURL( url );
				}, 0 );
			}
		},

		getErrorList: function( trigger = null,page = 0, pageSize = 100 ) {
            if ( typeof page != 'number' ) {
                page = 0;
            }
            let location_id = ( app && app.controller && app.controller.selectedLocationId ) || null;
            let provider_id = ( app && app.controller && app.controller.selectedProviderId ) || null;
            let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = BASE_ENGAGE_URL + 'v1/ccm/rapidbill/' + org_id + '/ERROR';
            if ( provider_id > -1 ) {
                endpoint += `?provider_Id=${provider_id}`;
            } else if ( location_id > -1 ) {
                endpoint += `?location_Id=${location_id}`;
            }
            let successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
            let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
            let resultTriggers = {
                successTrigger,
                errorTrigger
            }
            let headerData = {
                page,
                pageSize,
                contentType: 'application/json; charset=UTF-8'
            }
            if ( org_id ) {
                channel.trigger( 'show:contentSpinner' );
                utils.get( endpoint, resultTriggers, this.resolveRapidBilling.bind( this, trigger ), null, headerData );
                return;
            }
            channel.listenTo( channel, 'set:selectedOrganization', this.getErrorList.bind( this ) );
		},

		getPhsBulkDownload: function() {
			let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			let endpoint = `${BASE_ENGAGE_URL}v1/ccm/organization/${org_id}/phs`;
			let successTrigger = ['headers:success', 'hide:contentSpinner'];
			let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
			let resultTriggers = {
				successTrigger,
				errorTrigger
			};
			channel.trigger( 'show:contentSpinner' );
			utils.get( endpoint, resultTriggers, this.resolveGetPhsBulkDownload.bind( this ), null );
		},

		resolveGetPhsBulkDownload: function( data ) {
			if ( data.exists ) {
				window.open(data.link)
			} else {
				// The ErrorModalView expects the error formatted as a standard http error
				const mockError = {
					status:400,
					statusText: 'PHS Bulk Download Unavailable',
					responseJSON: { message: 'There are currently no applicable PHS to be downloaded.' },
				};
				channel.trigger( 'failed:api', mockError );
			}
		},

        getSuspendReasons: function( callback ) {
			let endpoint = BASE_ENGAGE_URL + 'v1/ccm/suspension/reasons';
            let successTrigger = [ 'unenroll:success', 'hide:contentSpinner' ];
            let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
			channel.trigger( 'show:contentSpinner' );
			let resultTriggers = {
				successTrigger,
				errorTrigger
			};
			utils.get( endpoint, resultTriggers, (data) => {
				callback(data);
            });
        },
    getUnenrollReasons: function( callback ) {
      let endpoint = BASE_ENGAGE_URL + 'v1/ccm/unenroll/reasons';
      let successTrigger = [ 'unenroll:success', 'hide:contentSpinner' ];
      let errorTrigger = [ 'failed:api', 'hide:contentSpinner' ];
      channel.trigger( 'show:contentSpinner' );
      let resultTriggers = {
        successTrigger,
        errorTrigger
      };
      utils.get( endpoint, resultTriggers, (data) => {
        callback(data);
      });
    },
		getPatientRecords: function( patient_id ) {
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpoint = `${BASE_ENGAGE_URL}v1/ccm/organization/${org_id}/patients/${patient_id}/records`;
			const successTrigger = [ 'getPatientRecords:success' ];
			const errorTrigger = [ 'failed:api' ];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			utils.get( endpoint, resultTriggers, this.resolveGetPatientRecords.bind(this, patient_id), null );
		},

		resolveGetPatientRecords: function( patient_id, data ) {
			const records = data.map( ( record ) => {
				record.formatted_date = app.controller.formatFixedDateForUI(record.encounter_date);
				record.patient_id = patient_id;
				return record
			});
			this.setGlobalValue( 'patientRecords', records );
		},

		getPatientRecord: function( patient_id, record_id ) {
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpoint = `${BASE_ENGAGE_URL}v1/ccm/organization/${org_id}/patients/${patient_id}/records/${record_id}`;
			const successTrigger = [ 'getPatientRecord:success' ];
			const errorTrigger = [ 'failed:api' ];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			utils.get( endpoint, resultTriggers, this.resolveGetPatientRecord.bind(this), null );

		},

		resolveGetPatientRecord: function( data ) {
			let token = app.controller.getToken( 'engage' );
			// window.location = `${data.link}`;
			window.open( `${data.link}`, '_blank' );
		},

		getAllPatientRecords: function( patient_id ) {
			const org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
			const endpoint = `${BASE_ENGAGE_URL}v1/ccm/organization/${org_id}/patients/${patient_id}/records/zip`;
			const successTrigger = [ 'getAllPatientRecords:success' ];
			const errorTrigger = [ 'failed:api' ];
			const resultTriggers = {
				successTrigger,
				errorTrigger
			};
			utils.get( endpoint, resultTriggers, this.resolveGetAllPatientRecrods.bind(this), null );
		},

		resolveGetAllPatientRecrods: function( data ) {
			if ( data.exists ) {
				window.location = `${data.link}`;
			} else {
				const mockError = {
					status:400,
					statusText: 'Bulk Files Unavailable',
					responseJSON: { message: 'Bulk file download is not available yet. Please check again tomorrow.' },
				};
				channel.trigger( 'failed:api', mockError );
			}
			// window.open( `${data.link}`, '_blank' );
		},
	}
};
