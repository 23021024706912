import Backbone from 'backbone';


export const InsuranceCollection = Backbone.Model.extend({

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
    },
    getInsurances: function() {
        this.app.controller.getInsurances();
    },
});
