// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  detailedReportsContainerView  from '../../../../../templates/CCM/RapidBill/Reports/detailedReportsContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	className: 'col l12 pvm',

	events: {
		'click .download-report': 'downloadReport',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	getTemplate: function() {
		return detailedReportsContainerView();
	},

	downloadReport: function() {
		this.app.ccm_controller.getRapidBillingDetailedReportCSV( this.model.get('startStamp'), this.model.get('endStamp') )
	}

});
