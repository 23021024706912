// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import moment from 'moment';

// Internal Dependencies - screens/views/templates
import billingListCompositeView  from '../../../../templates/Utilities/HistoricalBilling/billingListCompositeView';
import billingListItemView from './BillingListItemView';

// Internal Dependencies - models/collections

export default Marionette.CompositeView.extend({

	className: 'billing-list-composite-view table',

	childView: billingListItemView,

	childViewContainer: '.billing-list-container',

	events: {

	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.controller = this.app.utilities_controller;
		this.listenTo( this.channel, 'set:billingList', this.setBillingList );
		this.listenTo( this.collection, 'update', this.render );
		this.listenTo( this.channel, 'rerender', this.render);
		$('.content-container').addClass('widen');
	},

	getTemplate: function() {
		return billingListCompositeView( this.collection );
	},

	setBillingList: function() {
		let providers = [];
		this.collection.loading = false;
		if ( this.controller.billingList && this.controller.billingList.length ) {
			providers = this.controller.billingList.map( ( provider ) => provider );
			this.collection.renderNoResults = providers && providers.length ? false : true;
			this.collection.set( providers );
		}
		!providers.length && this.render();
	},

	onDestroy: function() {
		this.controller.clearValue( 'billingList' );
		$('.content-container').removeClass('widen');
	},

});
