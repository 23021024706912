// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import unenrolledContainerView  from '../../../../templates/CCM/Unenrolled/unenrolledContainerView';
import UnenrolledCompositeView from './UnenrolledCompositeView';
import PatientTotalsView from '../PatientList/PatientTotalsView';

// Internal Dependencies - models/collections
import { UnenrolledCollection } from './models/UnenrolledCollection';

export default Marionette.LayoutView.extend({

	regions: {
		'unenrolledContainerView': '.unenrolled-container-view',
		'patientTotals': '.patient-totals-container'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection = new UnenrolledCollection();
	},

	getTemplate: function() {
		return unenrolledContainerView( this.model );
	},

	onShow: function() {
		this.showChildView( 'unenrolledContainerView', new UnenrolledCompositeView({collection: this.collection}) );
		this.showChildView( 'patientTotals', new PatientTotalsView({collection: this.collection}) );
	}

});
