// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import pendingUnenrollItemView  from '../../../../templates/CCM/PendingUnenroll/pendingUnenrollItemView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	tagName: 'tr',

	events: {
		'click #confirmReject': 'confirm',
		'click #cancelReject': 'cancel'
	},

	getTemplate: function() {
		return pendingUnenrollItemView( this.model );
	},

	confirm: function( e ) {
		this.model.confirmUnenroll();
	},

	cancel: function() {
		this.model.cancelUnenroll();
	}

});
