/**********************
	Container has been modified to display patient list placeholder.
	TODO: Remove reference to placeholders && restore commented code to view patient list
**********************/

// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import patientListPlaceholder  from '../../../../templates/CPC/PatientList/patientListPlaceholder';
// import { patientListContainerView } from '../../../../templates/CPC/PatientList/patient-list-container-view';
// import PatientListCompositeView from './PatientListCompositeView';
// import PatientTotalsView from './PatientTotalsView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	// regions: {
	// 	patientList: '.patient-list-container',
	// 	patientTotals: '.patient-totals-container'
	// },

	getTemplate: function() {
		return patientListPlaceholder( );
		// return patientListContainerView( );
	},

	// onShow: function() {
		// this.showChildView( 'patientTotals', new PatientTotalsView() );
		// this.showChildView( 'patientList', new PatientListCompositeView() );
	// },

});
