import Backbone from 'backbone';
import moment from 'moment';
import _ from 'underscore';

export const NewNotificationModel = Backbone.Model.extend({
	
	defaults: {
		date: moment().format( 'MMM DD, YYYY' ),
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		let locations = this.app.controller && this.app.controller.locations;
		this.set( 'locations', locations );
	},

	sortPatients: function( a, b ) {
		if ( a.last_name < b.last_name ) {
			return -1;
		}
		if ( a.last_name > b.last_name ) {
			return 1;
		}
		return 0;
	},

	determinePatientIdFromInput: function( patientInput ) {
		let selectedPatient = _.find( this.get( 'patients' ), ( patient ) => patient.value == patientInput );
		return selectedPatient && selectedPatient.data;
	},

	isValid: function( notification ) {
		if ( !notification.patient_id ) {
			return false;
		}
		if ( !notification.provider_id ) {
			return false;
		}
		if ( !notification.location_id ) {
			return false;
		}
		if ( !notification.body || notification.body.trim().length < 1 ) {
			return false;
		}
		return true;		
	},

	determineMissingData: function( requiredData ) {
		let missingData = [];
		!requiredData.patient_id && missingData.push( 'notificationFilterPatient' );
		( !requiredData.provider_id || requiredData.provider_id == -1 ) && missingData.push( 'provider_id' );
		!requiredData.location_id && missingData.push( 'location_id' );
		( !requiredData.body || requiredData.body.trim().length < 1 ) && missingData.push( 'notificationNewMessage' );
		return missingData;
	},

	sendNotification: function( notification ) {
		this.app.main_controller.submitNewNotification( notification );
	}

});