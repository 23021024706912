import Backbone from 'backbone';
import _ from 'underscore';
import moment from 'moment';

import { Utils } from '../../utils';

import ErrorModalView from '../../ErrorModalView';

const channel = Backbone.Radio.channel('AppChannel');
const utils = new Utils();
const userCookie = 'user=';

export const PE_Controller = ( app ) => {

    const BASE_ENGAGE_URL = app.config.BASE_ENGAGE_URL;

    return {

        /**********************
         Global values
         **********************/

        patients: {
            totalPages: 0,
            currentPage: 0,
            totalPatients: 0,
            itemCount: 0,
        },

        patientList: [],

        // Takes a valueName and data and creates or replaces a variable with that name and data
        setGlobalValue: function ( valueName, data ) {
            this[ valueName ] = data;
            channel.trigger( 'set:' + valueName );
        },

        // Takes a collection and a single updated model and replaces the old instance of the model with the new
        // Model must have all properties that were returned with initial fetch of collection
        updateModelInCollection: function ( collection, updatedItem ) {
            collection.splice(_.findIndex(collection, 'id', updatedItem.id), 1, updatedItem);
        },

        /**********************
         Patient Enrollment Specific Methods
         **********************/

        clearValue: function( value ) {
            this[ value ] = null;
        },

        patientListPageUp: function() {
            if ( this.patients.currentPage + 1 == this.patients.totalPages ) {
                return;
            }
            this.patients.currentPage++;
            channel.trigger( 'set:patients' );

            if( !this.searchInput ) {
                this.determineNonSearchedGetType( this.patients.currentPage );
                return;
            }
            this.determineSearchedGetType( this.patients.currentPage, 20, this.searchInput );
        },

        patientListPageDown: function() {
            if ( this.patients.currentPage == 0 ) {
                return;
            }
            this.patients.currentPage--;
            channel.trigger( 'set:patients' );

            if( !this.searchInput ) {
                this.determineNonSearchedGetType( this.patients.currentPage );
                return;
            }
            this.determineSearchedGetType( this.patients.currentPage, 20, this.searchInput );
        },

        determineNonSearchedGetType: function( currentPage ) {
            let currentScreen = app.controller.subnavItem;
            switch( currentScreen ) {
                case 'PatientList':
                    this.getPatientList( null, currentPage );
                    break;
                case 'PendingUnenroll':
                    this.getPendingUnenrolled( null, currentPage );
                    break;
                case 'Unenrolled':
                    this.getUnenrolledList( null, currentPage );
                    break;
                case 'Enrolled':
                    this.getEnrolledList( null, currentPage );
                    break;
                case 'Suspended':
                    this.getSuspendedList( null, currentPage );
                    break;
                case 'AutoEnroll':
                    this.getAutoPendingList( null, currentPage );
                    break;
                case 'RapidBill':
                    this.getRapidBilling( null, currentPage );
                    break;
                case 'PendingReview':
                    this.getPendingReview( null, currentPage );
                    break;
                case 'ErrorQueue':
                    this.getErrorList( null, currentPage );
                    break;
                default:
                    this.getPatientList( null, currentPage );
            }
        },

        determineSearchedGetType: function( currentPage, pageSize, searchInput ) {
            let currentScreen = app.controller.subnavItem;
            switch( currentScreen ) {
                case 'PatientList':
                    this.searchPatients( currentPage, pageSize, searchInput );
                    break;
                case 'PendingUnenroll':
                    this.searchPendingUnenrolledPatients( currentPage, pageSize, searchInput );
                    break;
                case 'Unenrolled':
                    this.searchUnenrolledPatients( currentPage, pageSize, searchInput );
                    break;
                case 'Enrolled':
                    this.searchEnrolledPatients( currentPage, pageSize, searchInput );
                    break;
                case 'Suspended':
                    this.searchSuspendedPatients( currentPage, pageSize, searchInput );
                    break;
                case 'AutoEnroll':
                    this.searchAutoPendingPatients( currentPage, pageSize, searchInput );
                    break;
                case 'RapidBill':
                    this.getRapidBilling( currentPage );
                    break;
                case 'PendingReview':
                    this.getPendingReview( currentPage );
                    break;
                case 'ErrorQueue':
                    this.getErrorList( null, currentPage );
                    break;
                default:
                    this.searchPatients( currentPage, pageSize, searchInput );
            }
        },


        getAutoPendingCSV: function( page = 0, pageSize = 20 ) {
            if ( page == 0 ) {
                this.clearValue( 'patientList' );
                this.clearValue( 'totalPages' );
            }
            let searchInput = this.searchInput ? this.searchInput.replace( /search=/gi, '' ) : '';
            let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patients_pending_unenroll?search=${ searchInput }`;
            let successTrigger = ['headers:success', 'hide:contentSpinner'];
            let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
            let resultTriggers = {
                successTrigger,
                errorTrigger
            }
            let headerData = {
                page,
                pageSize,
                accept: 'text/csv'
            }
            if ( org_id ) {
                $( '.paginated-spinner' ).show();
                utils.get( endpoint, resultTriggers, this.resolveGetAutoPendingCSV.bind( this ), null, headerData );
                return;
            }
        },

        resolveGetAutoPendingCSV: function( data ) {
            let file = new Blob( [ data ], { type: 'csv' } );
	        let searchInput = this.searchInput ? this.searchInput.replace(/search=/gi, '') : '';
	        let filename = `auto-enroll-list_${ searchInput }_${moment().format('MM/DD/YYYY')}.csv`;
	        if (navigator.msSaveOrOpenBlob) {
		        navigator.msSaveOrOpenBlob(file, filename);
	        } else {
		        let a = document.createElement('a');
		        let url = URL.createObjectURL(file);
		        a.href = url;
		        a.download = filename;
		        document.body.appendChild(a);
		        a.click();
		        setTimeout(() => {
			        document.body.removeChild(a);
			        window.URL.revokeObjectURL(url);
		        }, 0);
	        }
        },

        openPatientPlan: function( patient_id ) {
            let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patient_id }/planmeta`;
            let successTrigger = ['headers:success', 'hide:contentSpinner'];
            let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
            let resultTriggers = {
                successTrigger,
                errorTrigger
            }
            channel.trigger( 'show:contentSpinner' );
            utils.get( endpoint, resultTriggers, this.viewCarePlan.bind( this, patient_id, org_id ), null );
        },

        viewCarePlan: function( patient_id, org_id, data ) {
            if ( data.available ) {
                let token = app.controller.getToken( 'engage' );
                window.open( `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patient_id }/planpdf?X-AUTH-TOKEN=${ token }` );
                return;
            }
            // The ErrorModalView expects the error formatted as a standard http error
            const mockError = {
                status:400,
                statusText: 'PHS Unavailable',
                responseJSON: { message: 'No PHS available for patient.' },
            };
            channel.trigger( 'failed:api', mockError );
        },

        openCcda: function( patient_id ) {
            let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patient_id }/latest_ccdameta`;
            let successTrigger = ['headers:success', 'hide:contentSpinner'];
            let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
            let resultTriggers = {
                successTrigger,
                errorTrigger
            }
            channel.trigger( 'show:contentSpinner' );
            utils.get( endpoint, resultTriggers, this.viewCcda.bind( this, patient_id, org_id ), null );
        },

        viewCcda: function( patient_id, org_id, data ) {
            if ( data.available ) {
                let token = app.controller.getToken( 'engage' );
                window.open( `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patient_id }/latest_ccdapdf?X-AUTH-TOKEN=${ token }` );
                return;
            }
            // The ErrorModalView expects the error formatted as a standard http error
            const mockError = {
                status:400,
                statusText: 'CCDA Unavailable',
                responseJSON: { message: 'No CCDA available for patient.' },
            };
            channel.trigger( 'failed:api', mockError );
        },

        openPatientHistory: function( patient_id ) {
            let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patient_id }/worksummarymeta`;
            let successTrigger = ['headers:success', 'hide:contentSpinner'];
            let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
            let resultTriggers = {
                successTrigger,
                errorTrigger
            }
            channel.trigger( 'show:contentSpinner' );
            utils.get( endpoint, resultTriggers, this.viewPatientHistory.bind( this, patient_id, org_id ), null );
        },

        viewPatientHistory: function( patient_id, org_id, data ) {
            if ( data.available ) {
                let token = app.controller.getToken( 'engage' );
                window.open( `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/patient/${ patient_id }/worksummarypdf?X-AUTH-TOKEN=${ token }` )
                return;
            }
            // The ErrorModalView expects the error formatted as a standard http error
            const mockError = {
                status:400,
                statusText: 'Patient History Unavailable',
                responseJSON: { message: 'No Patient History available for patient.' },
            };
            channel.trigger( 'failed:api', mockError );
        },

        getICD10Codes: function( data ) {
            let endpoint = BASE_ENGAGE_URL + 'v1/codelookup/icd10/by_id?';
            let getAllCodes = [];
            let successTrigger = ['headers:success', 'hide:contentSpinner'];
            let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
            let resultTriggers = {
                successTrigger,
                errorTrigger
            };
            if ( data && data.length ) {
                _.each( data, ( item ) => {
                    if( !_.isEmpty( item.icd10code_1 ) && !_.isEmpty( item.icd10code_2 ) ) {
                        getAllCodes.push( this.parseICD10Code( item.icd10code_1 ) );
                        getAllCodes.push( this.parseICD10Code( item.icd10code_2 ) );

                    } else if( !_.isEmpty( item.icd10code_1 ) && _.isEmpty( item.icd10code_2 ) ) {
                        getAllCodes.push(  this.parseICD10Code( item.icd10code_1 )  );

                    } else if( _.isEmpty( item.icd10code_1 ) && !_.isEmpty( item.icd10code_2 )  ) {
                        getAllCodes.push(  this.parseICD10Code( item.icd10code_2 )  );
                    }
                });
            } else {
                getAllCodes.push(  this.parseICD10Code( data.icd10code_1 ) );
                getAllCodes.push(  this.parseICD10Code( data.icd10code_2 ) );
            }

            let params = "id=" + getAllCodes.join( "&id=" );
            params = params.replace( ".", "%2E" );
            channel.trigger( 'show:contentSpinner' );
            utils.get( endpoint + params, resultTriggers, this.resolveICDCodes.bind( this, data ) );
        },

        parseICD10Code: function( value ) {
            if ( value && value.indexOf( ' - ' ) > -1 ) {
                return value.slice( 0, value.indexOf( ' - ' ) ).trim();
            }
            return value;
        },

        resolveICDCodes: function ( data, prevData ) {
            if ( prevData ) {
                let previousDataCopy = Object.assign( {}, prevData );
                let updatedPatientList;
                if ( this.patientList && this.patientList.length ) {
                    updatedPatientList = this.patientList.map( ( patient ) => {
                        return Object.assign( {}, patient );
                    });
                } else {
                    channel.listenToOnce( channel, 'set:patientList', this.resolveICDCodes.bind( this, data, prevData ) );
                    return;
                }
                if ( updatedPatientList && updatedPatientList.length ) {
                    this.setGlobalValue( 'patientList', updatedPatientList.map( ( patient ) => {
                        let icd10code_1 = this.parseICD10Code(patient.icd10code_1);
                        let icd10code_2 = this.parseICD10Code(patient.icd10code_2);
                        if ( previousDataCopy[ icd10code_1 ] ) {
                            patient.icd10code_1 = `${ icd10code_1 } - ${ previousDataCopy[ icd10code_1 ] }`;
                        }
                        if ( previousDataCopy[ icd10code_2 ] ) {
                            patient.icd10code_2 = `${ icd10code_2 } - ${ previousDataCopy[ icd10code_2 ] }`;
                        }
                        return patient;
                    }));
                }
            }
        },

        addProvidersToPatients: function() {
            let patientList = app.pe_controller.patientList;
            patientList = patientList && patientList.map( ( patient ) => {
                let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
                patient.provider = ( providerObj && providerObj.name ) || '' ;
                return patient;
            });
            this.setGlobalValue( 'patientList', patientList );
        },

        updatePatientList: function( data ) {
            let hasProviders = app.controller.providers && app.controller.providers.length;
            let updatedPatientList = this.patientList && this.patientList.map( ( patient ) => {
                if ( data.id == patient.id ) {
                    patient = data;
                    ( data.icd10code_1 || data.icd10code_2 ) && this.getICD10Codes( patient );
                }
                if ( hasProviders ) {
                    let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
                    patient.provider = ( providerObj && providerObj.name ) || '' ;
                }
                patient.birthdate = app.controller.formatFixedDateForUI( patient.birthdate );
                patient.ccm_status = this.determineCCMStatus( patient );
                return patient;
            });
            this.setGlobalValue( 'patientList', updatedPatientList );
        },

        getListQueries: function() {
            let selectedLocation = app.controller.selectedLocationId || -1;
            let selectedProvider = app.controller.selectedProviderId || -1;
            if ( selectedProvider != -1 ) {
                return `&provider_id=${ selectedProvider }`
            } else if ( selectedLocation != -1 ) {
                return `&location_id=${ selectedLocation }`
            } else {
                return ''
            }
        },

        getAutoPendingList: function( page = 0, pageSize = 20 ) {
            let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;

            // TODO: To check for pagination and no-results template need more data. Can test with '/patients_enrolled'

            let endpoint = `${ BASE_ENGAGE_URL }v1/patientenrollment/awaiting-approval`;

            let successTrigger = ['headers:success', 'hide:contentSpinner'];
            let errorTrigger = ['failed:api', 'hide:contentSpinner' ];
            let resultTriggers = {
                successTrigger,
                errorTrigger
            }
            let headerData = {
                page,
                pageSize,
                contentType: 'application/json; charset=UTF-8'
            }

            if ( org_id ) {
                channel.trigger( 'show:contentSpinner' );
                utils.get( endpoint, resultTriggers, this.resolveGetAutoPendingList.bind(this), null, headerData );
                return;
            }
            channel.listenTo( channel, 'set:selectedOrganization', this.getAutoPendingList.bind( this ) );
        },

        resolveGetAutoPendingList: function( data ) {
            let hasProviders = app.controller.providers && app.controller.providers.length;
            let patients = data.items || [];
            if ( patients.length ) {
                patients = patients.map( ( patient ) => {
                    if ( hasProviders ) {
                        let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
                        patient.provider = ( providerObj && providerObj.name ) || '' ;
                    }
                    patient.birthdate = app.controller.formatFixedDateForUI( patient.birthdate );
                    return patient;
                });
            }

            let triggerSetPatients = false;

            if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
                this.patients.totalPages = data.total_pages;
                triggerSetPatients = true;
            }
            if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
                this.patients.totalPatients = data.total_items;
                triggerSetPatients = true;
            }
            if ( !this.patients.currentPage ) {
                this.patients.currentPage = channel.request( 'currentPage' );
                triggerSetPatients = true;
            }
            if ( !hasProviders ) {
                channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
            }
            this.setGlobalValue( 'patientList', patients );
            triggerSetPatients && channel.trigger( 'set:patients' );
        },

        searchAutoPendingPatients: function( page, pageSize, searchInput ) {
            if ( page == 0 ) {
                this.clearValue( 'patientList' );
                this.clearValue( 'totalPages' );
            }
            if ( !this.searchInput || this.searchInput != searchInput ) {
                this.setGlobalValue( 'searchInput', searchInput );
            }
            let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;



            // TODO: To check for pagination and no-results template need more data. Can test with '/patients_enrolled'

            // let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_enrolled?' + searchInput;
            let endpoint = BASE_ENGAGE_URL + 'v1/ccm/organization/' + org_id + '/patients_auto_pending?' + searchInput;



            let successTrigger = ['headers:success', 'hide:contentSpinner'];
            let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
            let resultTriggers = {
                successTrigger,
                errorTrigger
            }
            let headerData = {
                page,
                pageSize,
                contentType: 'application/json; charset=UTF-8'
            }
            if ( org_id ) {
                channel.trigger( 'show:contentSpinner' );
                utils.get( endpoint, resultTriggers, this.resolveSearchAutoPendingPatients.bind( this ), null, headerData );
                return;
            }
        },

        resolveSearchAutoPendingPatients: function( data ) {
            let hasProviders = app.controller.providers && app.controller.providers.length;
            let patients = data.items || [];
            let extendPatientList = channel.request( 'currentPage' ) > 0;
            if ( patients.length ) {
                patients = patients.map( ( patient ) => {
                    if ( hasProviders ) {
                        let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
                        patient.provider = ( providerObj && providerObj.name ) || '' ;
                    }
                    patient.birthdate = app.controller.formatFixedDateForUI( patient.birthdate );
                    return patient;
                });
            }

            let triggerSetPatients = false;

            if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
                this.patients.totalPages = data.total_pages;
                triggerSetPatients = true;
            }
            if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
                this.patients.totalPatients = data.total_items;
                triggerSetPatients = true;
            }
            if ( !this.patients.currentPage ) {
                this.patients.currentPage = channel.request( 'currentPage' );
                triggerSetPatients = true;
            }
            if ( !hasProviders ) {
                channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
            }
            this.setGlobalValue( 'patientList', patients );
            triggerSetPatients && channel.trigger( 'set:patients' );
        },

        submitAutoEnroll: function( patientIds ) {
            const endpoint = `${BASE_ENGAGE_URL}v1/ccm/patients/approve_bulk`;
            const successTrigger = ['headers:success', 'hide:contentSpinner'];
            const errorTrigger = ['failed:api', 'hide:contentSpinner' ];
            channel.trigger( 'show:contentSpinner' );
            utils.post( endpoint, patientIds, successTrigger, errorTrigger, null, true, this.resolveSubmitAutoEnroll.bind( this ) );
        },

        resolveSubmitAutoEnroll: function (response) {
            // If the call fails because a patient is not in auto enrolled pending
            // status we receive a 200 response code
            if (response.status === 'Failure') {
                // The ErrorModalView expects the error formatted as a standard http error
                const mockError = {
                    status:400,
                    statusText: 'Bad Request',
                    responseJSON: { message: error.reason || 'Unknown error' },
                };
                channel.trigger( 'failed:api', mockError );
            } else {
                channel.trigger( 'close:modal' );
                this.getAutoPendingList();
            }
        },

        getAwaitingApprovalPatients: function( page = 0, pageSize = 100, searchInput = '' ) {
            if ( page === 0 ) {
                this.clearValue( 'patientList' );
                this.clearValue( 'totalPages' );
            }
            if ( !this.searchInput || this.searchInput != searchInput ) {
                this.setGlobalValue( 'searchInput', searchInput );
            }
            let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/patientenrollment/awaiting-approval?${ searchInput }`;
            let successTrigger = ['headers:success', 'hide:contentSpinner'];
            let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
            let resultTriggers = {
                successTrigger,
                errorTrigger
            };
            let headerData = {
                page,
                pageSize,
                contentType: 'application/json; charset=UTF-8'
            };

            if ( org_id ) {
                channel.trigger( 'show:contentSpinner' );
                utils.get( endpoint, resultTriggers, this.resolveGetPatientEnrollmentPatients.bind( this ), null, headerData );
                return;
            }

            channel.listenTo( channel, 'set:selectedOrganization', this.getAwaitingApprovalPatients.bind( this ) );
        },

        resolveGetPatientEnrollmentPatients: function( data = [] ) {
            let hasProviders = app.controller.providers && app.controller.providers.length;
            let patients = [];
            if ( data && data.items && data.items.length ) {
                patients = data.items.map( ( patient ) => {
                    let newPatient = Object.assign( {}, patient, {
                        birthdate: app.controller.formatFixedDateForUI( patient.birthdate ),
                    });
                    ( patient.icd10code_1 || patient.icd10code_2 ) && this.getICD10Codes( patient );
                    if ( hasProviders ) {
                        let providerObj = _.find( app.controller.providers, ( provider ) => provider.id == patient.ccm_provider_id );
                        newPatient.provider = ( providerObj && providerObj.name ) || '' ;
                    }

                    return newPatient;
                });
            }

            let triggerSetPatients = false;

            if ( !this.patients.totalPages || this.patients.totalPages != data.total_pages ) {
                this.patients.totalPages = data.total_pages;
                triggerSetPatients = true;
            }
            if ( !this.patients.totalPatients || this.patients.totalPatients != data.total_items ) {
                this.patients.totalPatients = data.total_items;
                triggerSetPatients = true;
            }
            if ( !this.patients.currentPage ) {
                this.patients.currentPage = channel.request( 'currentPage' );
                triggerSetPatients = true;
            }
            if ( !hasProviders ) {
                channel.listenTo( channel, 'set:providers', this.addProvidersToPatients.bind( this ) );
            }
            this.setGlobalValue( 'patientList', patients );
            triggerSetPatients && channel.trigger( 'set:patients' );
        },

        getDeclinedPatients: function( page = 0, pageSize = 100, searchInput = '' ) {
            if ( page === 0 ) {
                this.clearValue( 'patientList' );
                this.clearValue( 'totalPages' );
            }
            if ( !this.searchInput || this.searchInput != searchInput ) {
                this.setGlobalValue( 'searchInput', searchInput );
            }
            let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/patientenrollment/awaiting-approval?${ searchInput }`;
            let successTrigger = ['headers:success', 'hide:contentSpinner'];
            let errorTrigger = ['headers:fail', 'hide:contentSpinner', 'failed:api'];
            let resultTriggers = {
                successTrigger,
                errorTrigger
            };
            let headerData = {
                page,
                pageSize,
                contentType: 'application/json; charset=UTF-8'
            };

            if ( org_id ) {
                channel.trigger( 'show:contentSpinner' );
                utils.get( endpoint, resultTriggers, this.resolveGetPatientEnrollmentPatients.bind( this ), null, headerData );
                return;
            }

            channel.listenTo( channel, 'set:selectedOrganization', this.getDeclinedPatients.bind( this ) );
        },

        approvePatients: function( patients = [] ) {
            const endpoint = `${BASE_ENGAGE_URL}v1/patientenrollment/approve`;
            const successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
            const errorTrigger = [ 'failed:api', 'hide:contentSpinner', 'close:modal' ];
            channel.trigger( 'show:contentSpinner' );
            utils.post( endpoint, patients, successTrigger, errorTrigger, null, true, this.getAwaitingApprovalPatients.bind( this, patients ) );
        },

        declinePatients: function( patients = [] ) {
            const endpoint = `${BASE_ENGAGE_URL}v1/patientenrollment/decline`;
            const successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
            const errorTrigger = [ 'failed:api', 'hide:contentSpinner', 'close:modal' ];
            channel.trigger( 'show:contentSpinner' );
            utils.post( endpoint, patients, successTrigger, errorTrigger, null, true, this.getAwaitingApprovalPatients.bind( this, patients ) );
        },

        sendToApproval: function( patients = [] ) {
            const endpoint = `${BASE_ENGAGE_URL}v1/patientenrollment/send-to-approval`;
            const successTrigger = [ 'headers:success', 'hide:contentSpinner' ];
            const errorTrigger = [ 'failed:api', 'hide:contentSpinner', 'close:modal' ];
            channel.trigger( 'show:contentSpinner' );
            utils.post( endpoint, patients, successTrigger, errorTrigger, null, true, this.getDeclinedPatients.bind( this, patients ) );
        },

        editPatient: function( patient = {} ) {
            let org_id = ( app && app.controller && app.controller.selectedOrganization && app.controller.selectedOrganization.id ) || null;
            let endpoint = `${ BASE_ENGAGE_URL }v1/ccm/organization/${ org_id }/update_patient`;
            let successTrigger = [ 'enroll:success', 'close:modal', 'toggle:globalSpinner' ];
            let errorTrigger = [ 'failed:api', 'toggle:globalSpinner' ];
            // Show spinner during HTTP request
            channel.trigger( 'toggle:globalSpinner' );
            utils.put( endpoint, patient, successTrigger, errorTrigger, null, true, this.getAwaitingApprovalPatients.bind( this ) );
        },

    }
};
