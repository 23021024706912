import Backbone from 'backbone';
import _ from 'underscore';

export const EnrollPatientModel = Backbone.Model.extend({

	defaults: {
		patient_id: '',
		last_name: '',
		first_name: '',
		birthdate: '',
		email: '',
		cell_phone: '',
		home_phone: '',
		icd10code_1: '',
		icd10code_2: '',
		explained_consent: false,
		explained_records_request: false
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
	},

	requiredEnrollFields: [
		'explained_consent',
		'explained_records_request',
		'provider_id',
		'last_name',
		'first_name',
		'pid',
		'birthdate',
		'phone'
	],

	requiredEditFields: [
		'last_name',
		'first_name',
		'patient_id',
		'birthdate',
		'phone',
		'ccm_provider_id'
	],

	triggerCheckSelected: function() {
		this.channel.trigger( 'check:selected' );
	},

	checkFields: function( actionType ) {
		let errors = [];
		let modelAttrs = this.attributes;
		let keys = _.keys( modelAttrs );
		let requiredFields = actionType == 'enroll' ? this.requiredEnrollFields : this.requiredEditFields;
		_.each( requiredFields, ( field ) => {
			if ( field == 'phone' ) {
				if ( !this.get( 'home_phone' ) ) {
					errors.push( this.handleError( field ) );
				}
			} else if ( !_.contains( keys, field ) || !this.get( field ) || ( Number( this.get( field ) ) < 1 ) ) {
				errors.push( this.handleError( field ) );
			} else {
				let error = this.validateData( this.get( field ), field );
				error && errors.push( error );
			}
		});
		const icd10code_1Value = this.get( 'icd10code_1' );
		const icd10code_2Value = this.get( 'icd10code_2' );
		const email = this.get('email');
		if(icd10code_1Value){
			let error = this.validateData( this.get( 'icd10code_1' ), 'icd10code_1' );
			error && errors.push( error );
		}

		if(icd10code_2Value){
			let error = this.validateData( this.get( 'icd10code_2' ), 'icd10code_2' );
			error && errors.push( error );
		}

		if(email){
			let error = this.validateData( this.get( 'email' ), 'email' );
			error && errors.push( error );
		}

		this.set({ errors });
	},

	determineValueType: function( key ) {
		switch( key ) {
			case 'last_name':
				return 'Last Name';
				break;
			case 'first_name':
				return 'First Name';
				break;
			case 'birthdate':
				return 'Date of Birth';
				break;
			case 'pid':
				return 'EHR Patient ID'
				break;
			case 'ccm_provider_id':
			case 'provider_id':
				return 'Provider';
				break;
			case 'phone':
				return 'Phone Number';
				break;
			case 'home_phone':
				return 'Primary Phone';
				break;
			case 'cell_phone':
				return 'Secondary Phone';
				break;
			case 'explained_consent':
				return 'Explain Consent';
				break;
			case 'explained_records_request':
				return 'Explain Records Request';
				break;
			case 'icd10code_1':
				return 'CD10 Code 1';
				break;
			case 'icd10code_2':
				return 'CD10 Code 2';
				break;
			case 'email':
				return 'Email';
				break;
		}
	},

	handleError: function( field ) {
		switch( field ) {
			case 'explained_consent':
			case 'explained_records_request':
				return {
					field,
					error: `Must complete the following task: ${ this.determineValueType( field ) }`
				}
				break;
			case 'pid':
				return {
					field,
					error: `Must provide a valid ${ this.determineValueType( field ) }`
				}
			case 'ccm_provider_id':
			case 'provider_id':
				return {
					field,
					error: `Must select a ${ this.determineValueType( field ) }`
				}
				break;
			// case 'last_name':
			// case 'first_name':
			// case 'patient_id':
			case 'phone':
			case 'home_phone':
				return {
					field: 'primary_phone',
					error: `Must provide a valid ${this.determineValueType( field ) }`
				}
				break;
			case 'birthdate':
				return {
					field,
					error: `Must provide a valid ${ this.determineValueType( field ) } in the following format: MM/DD/YYYY`
				}
				break;
			default:
				return {
					field,
					error: `Must provide a valid ${ this.determineValueType( field ) }`
				}
		}
	},

	validateData: function( value, key ) {
		value = value+'';
		switch( key ) {
			case 'last_name':
			case 'first_name':
				if ( !value || ( value && value.length < 2 ) ) {
					return {
						field: key,
						error: `${ this.determineValueType( key ) } must be at least 2 characters in length`
					}
				}else if(value && (value.charAt(0) === '=' || value.charAt(0) === '+'
				|| value.charAt(0) === '-' ||  value.charAt(0) === '@')){
					return {
						field: key,
						error: `${ this.determineValueType( key ) } must not be start with =, +, - and @`
					}
				}
				break;
			case 'pid':
				if ( !value || ( value && value.length < 2 ) ) {
					return {
						field: key,
						error: `${ this.determineValueType( key ) } must be at least 2 characters in length`
					}
				}else if(value && (value.charAt(0) === '=' || value.charAt(0) === '+'
					|| value.charAt(0) === '-' ||  value.charAt(0) === '@')){
					return {
						field: key,
						error: `${ this.determineValueType( key ) } must not be start with =,  +, - and  @`
					}
				}
				break;
			case 'birthdate':
				if ( !value || ( value && value.replace( /[-/]/g, '' ).length < 8 ) ) {
					return {
						field: key,
						error: `Must provide a valid ${ this.determineValueType( key ) } (MM/DD/YYYY)`
					}
				}else if(value && (value.charAt(0) === '=' || value.charAt(0) === '+'
					|| value.charAt(0) === '-' ||  value.charAt(0) === '@')){
					return {
						field: key,
						error: `${ this.determineValueType( key ) } must not be start with =,  +, - and  @`
					}
				}
				break;
			case 'email':
			case 'icd10code_2':
			case 'icd10code_1':
				if(value && (value.charAt(0) === '=' || value.charAt(0) === '+'
					|| value.charAt(0) === '-' ||  value.charAt(0) === '@')){
					return {
						field: key,
						error: `${ this.determineValueType( key ) } must not be start with =,  +, - and  @`
					}
				}
				break;
			default:
				return null;
		}
	},

	addPatient: function() {
		this.checkFields( 'enroll' );
		let formData = this.attributes;
		if ( !this.get( 'errors' ) || ( this.get( 'errors' ) && !this.get( 'errors' ).length ) ) {
			this.app.ccm_controller.addPatient( formData );
			$('.add-patient').html('Enrolling');
		}
	},

	enrollPatient: function(callback) {
		this.checkFields( 'enroll' );
		let formData = this.attributes;
		( !this.get( 'errors' ) || ( this.get( 'errors' ) && !this.get( 'errors' ).length ) ) && this.app.ccm_controller.enrollPatient( formData,callback );
	},

	editPatient: function() {
		this.checkFields( 'edit' );
		let formData = this.attributes;
		( !this.get( 'errors' ) || ( this.get( 'errors' ) && !this.get( 'errors' ).length ) ) && this.app.ccm_controller.editPatient( formData );
	},

});
