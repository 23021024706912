// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  manualBillingModalView  from '../../../../../templates/CCM/RapidBill/Error/manualBillingModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'click #confirmDownload': 'confirmDownload',
		'click .cancel': 'closeModal'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
	},

	getTemplate: function() {
		return manualBillingModalView( this.collection );
	},

	closeModal: function() {
		$( '#modalManualBilling' ).closeModal();
		this.destroy();
	},

	confirmDownload: function() {
		this.collection.submitBilling();
		this.closeModal();
	}

});
