// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import baseScoreContainerView  from '../../../../../templates/MIPS/AdvancingCare/BaseScore/baseScoreContainerView';
import CriteriaSelectionContainerView from '../CriteriaSelectionContainerView';
import OverviewContainerView from './OverviewContainerView';
import SecurityCard from './SecurityCardView';
import PrescriptionCard from './PrescriptionCardView';
import HealthInfoExchangeProvidePatientAccessCard from './HealthInfoExchangeProvidePatientAccessCard';
import { ADVANCING_CARE_SCORES, MONTH_OPTIONS, YEAR_OPTIONS } from '../../constants';

// Internal Dependencies - models/collections
import { AdvancingCareModel } from '../models/AdvancingCareModel';

export default Marionette.LayoutView.extend({

    regions: {
        'criteriaSelectionContainer': '.criteria-selection-container',
        'cardContainer': '.card-container',
        'overviewContainer': '.overview-container',
    },

    events: {
        'change @ui.criteriaSelect': 'updateCriteria',
    },

    ui: {
        criteriaSelect: '#criteriaSelect',
    },

    initialize: function( options ) {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );

        this.model = new AdvancingCareModel();
        // Set the variable data used to populate reusable score template with relevant constants
        // let scoreMeta = ADVANCING_CARE_SCORES[ options.score ];
        this.model.set({
            score: this.app.mips_controller.measureMetaData,
            month_options: MONTH_OPTIONS,
            year_options: YEAR_OPTIONS,
            measureData: this.app.mips_controller.measureData,
            editable: true,
            criteriaSelections: this.app.mips_controller.criteriaSelections,
            current_year:  ( ( new Date() ).getYear() + 1900 ).toString(),
            current_month: ( new Date() ).getMonth(),
        });

        // Adding listeners for data that will be utilized render criteria dropdown and all dynamic data
        this.listenTo( this.channel, 'set:criteriaSelections', this.setCriteriaSelections );
        this.listenTo( this.channel, 'set:measureData', this.setMeasureData );
        this.listenTo( this.channel, 'set:measureMetaData', this.setMeasureMetaData );
        this.listenTo( this.model, 'change:selected_criteria', this.changedSelectedCriteria );

        // Fetch the criteria to populate the criteria dropdown passing in the "score" provided in ContentView
        this.model.getCriteriaSelections( options.score );
	    if ( this.app.controller.currentApp === 'MIPSPROVIDER' ) {
		    this.listenTo( this.channel, 'set:selectedProviderId', this.fetchData );
	    }
    },

    getTemplate: function() {
        return baseScoreContainerView( this.model );
    },

    onShow: function() {
        // Add widen to prevent data heavy layout from collapsing too far
        $('.content-container').addClass('widen');

        // Check that there is historical data and that the selected criteria isn't security analysis (which has no historical data to plot
        // before rendering historical chart
        // if ( this.model.get( 'measureData' ).historical_data && this.model.get( 'measureData' ).historical_data.length && this.model.get( 'selected_criteria' ) && this.model.get( 'selected_criteria' ).name !== 'security_analysis' ) {
        //     this.renderHistoricalDataChart();
        // }
    },

	fetchData: function() {
		this.model.getMeasureMetaData( this.model.get( 'selected_criteria' ).name );
		this.model.getMeasureData( this.model.get( 'selected_criteria' ).name );
		this.model.getSummaryData();
	},

    // Method for determining which view should be utilized for displaying user provided data and receiving user input. This varies
    // based on the criteria type.
    determineChildView: function( name ) {
        switch( name ) {
            case 'security_analysis':
                return new SecurityCard({ model: this.model });
                break;
            case 'e_prescription':
                return new PrescriptionCard({ model: this.model });
                break;
            case 'provide_patient_access':
            case 'health_info_exchange':
                return new HealthInfoExchangeProvidePatientAccessCard({ model: this.model });
                break;
            default:
                return new SecurityCard({ model: this.model });
        }
    },

    // Called when measureData has been set at controller level to give access to data to local views
    setMeasureData: function() {
        this.model.set( 'measureData', this.app.mips_controller.measureData );
        this.model.get( 'selected_criteria' ) && this.changedSelectedCriteria();
    },

    // Called when measureMetaData has been set at controller level to give access to meta data for selected criteria to local views
    setMeasureMetaData: function() {
        this.model.set( 'score', this.app.mips_controller.measureMetaData );
        this.model.get( 'selected_criteria' ) && this.changedSelectedCriteria();
        // $( '.measure-title' ).text( this.model.get( 'score' ).title );
    },

    // Called when criteriaSelections has been set at controller level to give access to criteria options to local views.
    // If there is a network issue or somehow no results return, we display a temporary error until better error handling
    // can be employed.
    setCriteriaSelections: function() {
        let criteria = this.app.mips_controller.criteriaSelections;
        if ( !criteria.length ) {
            $( '.advancing-care-container' ).html( '<h4>Error Loading. Please try again.</h4>' );
            return;
        }
        this.model.set({
            criteria: criteria,
            selected_criteria: criteria[ 0 ],
        });
        this.showChildView( 'criteriaSelectionContainer', new CriteriaSelectionContainerView({ model: this.model }) );
        this.model.getMeasureMetaData( this.model.get( 'selected_criteria' ).name );
        this.model.getMeasureData( this.model.get( 'selected_criteria' ).name );
        this.model.getSummaryData();
        this.changedSelectedCriteria();
    },

    renderHistoricalDataChart: function() {
        let data = [];
        if ( this.model.get( 'measureData' ).historical_data.length ) {
            this.model.get( 'measureData' ).historical_data.forEach( ( dataPoint, idx ) => {
                if ( idx !== dataPoint.month - 1 ) {
                    data.push({
                        x: idx,
                        y: 0,
                        color: this.model.colors['FAIL'],
                    });
                }
                const y = isNaN(dataPoint.score_raw) ? 0 : parseFloat(dataPoint.score_raw).toFixed(0)/1;
                data.push({
                    x: dataPoint.month - 1,
                    y: y,
                    color: this.model.colors[ dataPoint.score_threshold ] || this.model.colors[ 'blue' ] ,
                });
            });
        }
        if ( !data.length ) {
            if ( data.length == 0 ) {
                $('#historicalDataContainer').html( `<div class="center-align"><h5>No historical data is available</h5></div>` );
                return;
            }
        }
        Highcharts.chart('historicalDataContainer', {
            title: {
                text: null
            },
            chart: {
                type: 'column',
                height: 300,
            },
            credits: {
                enabled: false,
            },
            xAxis: {
                categories: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May','Jun','Jul','Aug','Sep','Oct','Nov','Dec' ],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Patients',
                },
                allowDecimals: false,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                color: 'white',
                name: 'Monthly Reported Data',
                data,
            }],
        });
    },

    onDestroy: function() {
        $('.content-container').removeClass('widen');
        this.app.mips_controller.measureData = {
            score: 0,
            numerator: 0,
            denominator: 0,
            score_raw: 0,
            score_threshold: 'FAIL',
            points_available: 0,
            points_earned: 0,
            historical_data: [],
        };
    },

    // When an update has been triggered via the dropdown menu selection being changed by user input, update the selected_criteria
    // in order to then fetch associated measureData and measureMetaData. measureData is any data that has been supplied by a user
    // of a given org while measureMetaData is any data pertaining to the measure itself, such as title and description.
    updateCriteria: function( e ) {
        let selectedCriteria = e.target.value;
        this.model.set( 'selected_criteria', _.find( this.model.get( 'criteria' ), ( criterion ) => criterion.name === selectedCriteria ) );
        this.model.getMeasureData( this.model.get( 'selected_criteria' ).name );
        this.model.getMeasureMetaData( this.model.get( 'selected_criteria' ).name );
        this.model.getSummaryData();
    },

    // Called whenever a criteria has been selected or data has been altered by return trips from server to update views.
    changedSelectedCriteria: function() {
        this.model.get( 'selected_criteria' ) && this.showChildView( 'cardContainer', this.determineChildView( this.model.get( 'selected_criteria' ).name ) );
        this.model.get( 'selected_criteria' ) && this.showChildView( 'overviewContainer', new OverviewContainerView({ model: this.model }) );
        // if ( this.model.get( 'measureData' ).historical_data.length && this.model.get( 'selected_criteria' ).name !== 'security_analysis' ) {
        //     this.renderHistoricalDataChart();
        // }
    },

});
