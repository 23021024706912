// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import bulkProviderChangeContainerView  from '../../../../templates/Utilities/BulkProviderChange/bulkProviderChangeContainerView';
import BulkProviderChangeCompositeView from './BulkProviderChangeCompositeView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

    regions: {
        'bulkProviderChangeCompositeView': '.bulk-provider-change-composite-container'
    },

    getTemplate: function() {
        return bulkProviderChangeContainerView( );
    },

    onShow: function() {
        this.showChildView( 'bulkProviderChangeCompositeView', new BulkProviderChangeCompositeView() );
    }

});
