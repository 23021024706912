// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import enrolledContainerView  from '../../../../templates/CCM/Enrolled/enrolledContainerView';
import EnrolledCompositeView from './EnrolledCompositeView';
import PatientTotalsView from '../PatientList/PatientTotalsView';

// Internal Dependencies - models/collections
import { EnrolledCollection } from './models/EnrolledCollection';

export default Marionette.LayoutView.extend({

	regions: {
		'enrolledContainerView': '.enrolled-container-view',
		'patientTotals': '.patient-totals-container'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.collection = new EnrolledCollection();
	},

	getTemplate: function() {
		return enrolledContainerView( this.model );
	},

	onShow: function() {
		this.showChildView( 'enrolledContainerView', new EnrolledCompositeView({collection: this.collection}) );
		this.showChildView( 'patientTotals', new PatientTotalsView({collection: this.collection}) );
	}

});
