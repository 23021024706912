import Backbone from 'backbone';
import moment from 'moment';

export const RevenueDashboardModel = Backbone.Model.extend({

	defaults: {
		month: moment().format('MM'),
		year: moment().format('YYYY'),
		enrolled: null,
		eligible: null,
		worked: null,
		totalEnrolled: null,
		unverified: null,
		optout: null,
		consented: null,
		hide_revenue: true,
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel('AppChannel');
		this.app = this.channel.request( 'app' );
		let modules = this.app.controller && this.app.controller.modules;
		this.hide_revenue = modules.hideRevenue;
		this.colors = [
			'#169E54',
			'rgb(41, 171, 221)',
			'#A01313',
			'#8084D6',
			'#4FACAF',
		]

	}

});
