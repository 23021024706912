// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Highcharts from 'highcharts';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import qualityMeasureContainerView  from '../../../../templates/MIPS/Quality/qualityMeasureContainerView';
import OverviewContainerView from './OverviewContainerView';
import PrimaryInputCardView from './PrimaryInputCardView';
import { MONTH_OPTIONS, YEAR_OPTIONS } from '../constants';

// Internal Dependencies - models/collections
import { QualityMeasureModel } from './models/QualityMeasureModel';

export default Marionette.LayoutView.extend({

	regions: {
		'overviewContainer': '.overview-container',
		'cardContainer': '.card-container',
	},

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );

		this.model = new QualityMeasureModel();

        // Set base level values that allow for rendering to begin without trip to server.
        this.model.set({
			measure: this.app.mips_controller.measureMetaData,
            month_options: MONTH_OPTIONS,
            year_options: YEAR_OPTIONS,
            measure_type: options.measure,
            measureData: this.app.mips_controller.measureData,
            editable: true,
            current_year:  ( ( new Date() ).getYear() + 1900 ).toString(),
            current_month: ( new Date() ).getMonth(),
        });

        // Set listeners for receiving measure data and meta data from server. Once received at controller level,
        // local methods are called to set it for accessibility in local views.
		this.listenTo( this.channel, 'set:measureData', this.setMeasureData );
		this.listenTo( this.channel, 'set:measureMetaData', this.setMeasureMetaData );

		// Listener for flipping data display/input card.
		// this.listenTo( this.channel, 'flip:card', this.flipCard );

		// Set listeners for when measure data or meta data is set locally to show proper child views.
		this.listenTo( this.model, 'change:measure set:updateMeasureData', this.renderRegions );

        this.model.getMeasureMetaData( options.measure );
		if ( this.app.controller.currentApp === 'MIPSPROVIDER' ) {
			this.listenTo( this.channel, 'set:selectedProviderId', this.fetchData.bind(this, options.measure) );
		}
	},

	getTemplate: function() {
		return qualityMeasureContainerView( this.model );
	},

	onShow: function() {
        $('.content-container').addClass('widen');
        this.renderRegions();
	},

	fetchData: function( measure ) {
		const selectedProviderId = this.app.controller.selectedProviderId;
		this.model.set( { selectedProviderId } );
		this.model.getMeasureMetaData( measure );
	},

	// Method for displaying child views
	renderRegions: function() {
        this.showChildView( 'cardContainer', new PrimaryInputCardView({ model: this.model }) );
        this.showChildView( 'overviewContainer', new OverviewContainerView({ model: this.model }) );
        this.renderHistoricalDataChart();
	},

    // Called when measureData has been set at controller level to give access to data to local views
	setMeasureData: function() {
        this.model.set({
            measureData: this.app.mips_controller.measureData,
        });
        this.model.trigger('set:updateMeasureData');
	},

    // Called when measureMetaData has been set at controller level to give access to meta data for selected criteria to local views
	setMeasureMetaData: function() {
		this.model.set({
            measure: this.app.mips_controller.measureMetaData,
        });
		$( '.measure-title' ).text( this.model.get( 'measure' ).title );
        this.model.getMeasureData();
    },

	flipCard: function( e ) {
		e.preventDefault();
		this.ui.flipContainer.toggleClass( 'active' );
	},

	renderHistoricalDataChart: function() {
	    let data = [];
	    if ( this.model.get( 'measureData' ).historical_data.length ) {
	        this.model.get( 'measureData' ).historical_data.forEach( ( dataPoint, idx ) => {
	            if ( idx !== dataPoint.month - 1 ) {
	                data.push({
	                    x: idx,
                        y: 0,
                        color: this.model.colors['FAIL'],
                    });
                }
	            const y = isNaN(dataPoint.score_raw) ? 0 : parseFloat(dataPoint.score_raw).toFixed(0)/1;
                data.push({
                    x: dataPoint.month - 1,
                    y: y,
                    color: this.model.colors[ dataPoint.score_threshold ] || this.model.colors[ 'blue' ] ,
                });
            });
        }
        if ( !data.length ) {
            $('#historicalDataContainer').html( `<div class="center-align"><h5>No historical data is available</h5></div>` );
            return;
        }
        Highcharts.chart('historicalDataContainer', {
            title: {
                text: null
            },
            chart: {
                type: 'column'
            },
			credits: {
                enabled: false,
            },
            xAxis: {
                categories: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
                crosshair: true
            },
            yAxis: {
                min: 0,
				max: 100,
				title: {
                	text: 'Percentage',
				},
            },
            tooltip: {
                pointFormat: '<b>{point.y}%</b>'
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
            	color: 'white',
                name: 'Monthly Reported Data',
                data,
            }],
        });
	},

	onDestroy: function() {
        $('.content-container').removeClass('widen');
        this.app.mips_controller.measureData = {
                                                    score: 0,
                                                    numerator: 0,
                                                    denominator: 0,
                                                    score_raw: 0,
                                                    score_threshold: 'FAIL',
                                                    points_available: 0,
                                                    points_earned: 0,
                                                    historical_data: [],
                                                };
	},

});
