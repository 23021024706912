// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependecies - screens/views/templates
import autoEnrollContainerView  from '../../../../templates/CCM/AutoEnroll/autoenrollContainerView';
import AutoEnrollCompositeView from './AutoEnrollCompositeView';
import AutoEnrollSelectedModal from './AutoEnrollSelectedModal';
import PatientTotalsView from '../PatientList/PatientTotalsView';

export default Marionette.LayoutView.extend({

	events: {
		'click #enrollSelectedButton': 'showEnrollSelectedModal',
	},

	regions: {
		autoEnroll: '.auto-enroll-container',
		'patientTotals': '.patient-totals-container',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.listenTo( this.channel, 'set:autoEnroll', this.setCollection );
		this.app = this.channel.request( 'app' );
	},

	getTemplate: function() {
		return autoEnrollContainerView();
	},

	onShow: function() {
		this.showChildView( 'autoEnroll', new AutoEnrollCompositeView() );
		this.showChildView( 'patientTotals', new PatientTotalsView() );
	},

	setCollection: function( collection ) {
		this.collection = collection;
	},

	showEnrollSelectedModal: function() {
		let modalView = new AutoEnrollSelectedModal({ collection: this.collection });
		modalView.render();
		$( 'body' ).append( modalView.el );
		$( '#autoEnrollSelectedModal' ).openModal();
	},

});
