import Backbone from 'backbone';

export const ContainerModel = Backbone.Model.extend({

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		const currentApp = this.app.controller.currentApp;
		const showPlaceholder = this.app.controller.selectedProviderId < 0 && currentApp === 'MIPSPROVIDER';
		this.set( { showPlaceholder, currentApp } );
		if ( currentApp === 'MIPSPROVIDER' ) {
			this.listenTo( this.channel, 'set:selectedProviderId', this.updateProvider );
		}
	},

	updateProvider: function() {
		const showPlaceholder = this.app.controller.selectedProviderId < 0 && this.get( 'currentApp' ) === 'MIPSPROVIDER';
		this.set( { showPlaceholder } );
	},

});