// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import utilitiesSubNavView  from '../../../templates/Utilities/utilitiesSubNavView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	initialize: function( options ) {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.listenTo( this.model, 'change', this.addActive );
	},

	getTemplate: function() {
		return utilitiesSubNavView( this.model );
	},

	onShow: function() {
		this.addActive();
	},

	addActive: function() {
		$( '.subnav-item a' ).removeClass( 'active' );
		let currentLocation = window.location.hash;
		$('a[href="' + window.location.hash + '"]').addClass('active');
	},

});
