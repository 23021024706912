import Backbone from 'backbone';

export const ProviderApprovalModel = Backbone.Model.extend({

  initialize: function( options ) {
    this.channel = Backbone.Radio.channel('AppChannel');
    this.app = this.channel.request( 'app' );
  },

  approveProvider: function(callback) {
    let request_id = this.get( 'id' );
    this.app.ccm_controller.approveProviderChangeRequest( request_id ,()=>{
      callback();
    });
  },

  declineProvider: function() {
    let request_id = this.get( 'id' );
    this.app.ccm_controller.cancelUnenroll( request_id );
  }

});