
import rapidReviewContainerView from '../../../../templates/PatientEnrollment/RapidReview/rapidReviewContainerView'

import RapidReviewCompositeView from './RapidReviewCompositeView';
import Marionette from "backbone.marionette";

export default Marionette.LayoutView.extend({
    regions: {
        'rapidReview': '.rapid-review-container'
    },
    getTemplate: function() {
        return rapidReviewContainerView(this.model);
    },
    onShow: function() {
        this.showChildView('rapidReview', new RapidReviewCompositeView());
    }
});
