// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import patientListContainerView  from '../../../../../templates/PatientEnrollment/PatientApproval/AwaitingApproval/patientListContainerView';
import PatientListCompositeView from '../PatientApprovalComponents/PatientListCompositeView';
import PatientTotalsView from '../PatientApprovalComponents/PatientTotalsView';
import ActionsContainerView from '../PatientApprovalComponents/ActionsContainerView';

// Internal Dependencies - models/collections
import { PatientCollection } from '../models/PatientCollection';

export default Marionette.LayoutView.extend({

    regions: {
        'patientTotals': '.patient-totals-container',
        'patientList': '.patient-list-container',
        'actionsContainer': '.actions-container',
    },

    initialize: function() {
        this.channel = Backbone.Radio.channel( 'AppChannel' );
        this.app = this.channel.request( 'app' );
        this.collection = new PatientCollection( 'declined' );
    },

    getTemplate: function() {
        return patientListContainerView( this.collection );
    },

    onShow: function() {
        this.showChildView( 'patientTotals', new PatientTotalsView() );
        this.showChildView( 'patientList', new PatientListCompositeView({ collection: this.collection }) );
        this.showChildView( 'actionsContainer', new ActionsContainerView({ collection: this.collection }) );
    },

});
