// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  billingSummaryModalView  from '../../../../../templates/CCM/RapidBill/Error/billingSummaryModalView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	events: {
		'click #confirmSummary': 'confirmSummary',
		'click .cancel': 'closeModal'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.listenTo( this.channel, 'close:modal', this.closeModal );
		this.listenTo( this.channel, 'submitBilling:failure', this.flipText );
	},

	getTemplate: function() {
		return billingSummaryModalView( this.collection );
	},

	closeModal: function() {
		$( '#modalSummary' ).closeModal();
		this.destroy();
	},

	flipText: function() {
		$('#confirmSummary').html('Confirm');
	},

	confirmSummary: function() {
		this.collection.submitBilling();
	}

});
