// External Dependencies
import Marionette from 'backbone.marionette';

// Internal Dependencies - screens/views/templates
import providerPlaceholderContainerView  from './../../../templates/MIPS/providerPlaceholderContainerView';

// Internal Dependencies - models/collections

export default Marionette.ItemView.extend({

	initialize: function( options ) {
		this.route = options.route;
	},

	getTemplate: function() {
		return providerPlaceholderContainerView( { route: this.route } );
	},

});
