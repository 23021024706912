// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
// NAMING CONVENTION: PREFIX ALL NAMES WITH MODULE (ex. Ccm, MipsGroup, etc.)
import  contentView  from '../templates/contentView';
import MainHomeView from './Components/Main/HomeView';
import MainDashboardContainerView from './Components/Main/Dashboard/DashboardContainerView';
import MainNotificationsContainerView from './Components/Main/Notifications/NotificationsContainerView';
import CcmPatientListContainerView from './Components/CCM/PatientList/PatientListContainerView';
import CcmAutoEnrollContainerView from './Components/CCM/AutoEnroll/AutoEnrollContainerView';
import CcmRapidBillContainerView from './Components/CCM/RapidBill/Approval/RapidBillContainerView';
import CcmErrorContainerView from './Components/CCM/RapidBill/Error/ErrorContainerView';
import CcmPendingReviewContainerView from './Components/CCM/RapidBill/PendingReview/PendingReviewContainerView';
import CcmRapidBillReportsContainerView from './Components/CCM/RapidBill/Reports/ReportsContainerView';
import CcmEnrolledContainerView from './Components/CCM/Enrolled/EnrolledContainerView';
import CcmSuspendedContainerView from './Components/CCM/Suspended/SuspendedContainerView';
import CcmProviderTransitionContainerView from './Components/CCM/ProviderTransition/ProviderTransitionContainerView';
import CcmSupportConainerView from './Components/CCM/Support/SupportContainerView';
import CcmOptedOutContainerView from './Components/CCM/OptedOut/OptedOutContainerView';
import CccmUnverifiedContainerView from './Components/CCM/Unverified/UnverifiedContainerView';
import CcmPendingUnenrollContainerView from './Components/CCM/PendingUnenroll/PendingUnenrollContainerView';
import CcmProviderApprovalContainerView from './Components/CCM/ProviderApproval/ProviderApprovalContainerView';
import CcmUnenrolledContainerView from './Components/CCM/Unenrolled/UnenrolledContainerView';
import CpcPatientListView from './Components/CPC/PatientList/PatientListContainerView';
import CpcEcqmContainerView from './Components/CPC/Ecqm/EcqmContainerView';
import CpcDocumentsContainerView from './Components/CPC/Documents/DocumentsContainerView';
import UtilitiesHistoricalBillingContainerView from './Components/Utilities/HistoricalBilling/BillingListContainerView';
import UtilitiesManageUsersContainerView from './Components/Utilities/ManageUsers/ManageUsersContainerView';
import UtilitiesResourcesContainerView from './Components/Utilities/Resources/ResourcesContainerView';
import UtilitiesSupportContainerView from './Components/Utilities/Support/SupportContainerView';
import UtilitiesChangePasswordContainerView from './Components/Utilities/ChangePassword/ChangePasswordContainerView';
import UtilitiesUploadContainerView from './Components/PatientEnrollment/FileManager/FileManagerContainerView';
import UtilitiesBulkProviderChangeContainerView from './Components/Utilities/BulkProviderChange/BulkProviderChangeContainerView';
import UtilitiesBulkProviderSuspensionContainerView from './Components/Utilities/BulkProviderSuspension/BulkProviderSuspensionContainerView';
import MipsNewDashboardContainerView from './Components/MIPS/MIPS/MipsNewDashboardContainerView';
import MipsQualityMeasureContainerView from './Components/MIPS/Quality/QualityMeasureContainerContainerView';
import MipsAdvancingCareBaseScoreContainerView from './Components/MIPS/AdvancingCare/BaseScore/BaseScoreContainerContainerView';
import MipsAdvancingCarePerformanceScoreContainerView from './Components/MIPS/AdvancingCare/PerformanceScore/PerformanceScoreContainerContainerView';
import MipsAdvancingCareBonusScoreContainerView from './Components/MIPS/AdvancingCare/BonusScore/BonusScoreContainerContainerView';
import MipsAdvancingCareSummaryContainerView from './Components/MIPS/AdvancingCare/Summary/SummaryContainerContainerView';
import MipsQualitySummaryContainerView from './Components/MIPS/Quality/Summary/SummaryContainerContainerView';
import MipsDashboardContainerView from './Components/MIPS/Dashboard/DashboardContainerContainerView';
import MipsImprovementActivitiesContainerView from './Components/MIPS/ImprovementActivities/ImprovementActivitiesContainerContainerView';
import PEFileManagerContainerView from './Components/PatientEnrollment/FileManager/FileManagerContainerView';
import PERapidReviewContainerView from './Components/PatientEnrollment/RapidReview/RapidReviewContainerView';
import PEReportingContainerView from './Components/PatientEnrollment/Reporting/ReportingContainerView';
import PEAwaitingApprovalContainerView from './Components/PatientEnrollment/PatientApproval/AwaitingApproval/AwaitingApprovalContainerView';
import PEDeclinedContainerView from './Components/PatientEnrollment/PatientApproval/Declined/DeclinedContainerView';
import PlaceholderContentView from './PlaceholderContentView';
import UtilitiesResetEnvironmentView from './Components/Utilities/ResetEnvironment/RestEnvironmentContainerView';
import RevenueDashboardContainerView from './Components/CCM/RevenueDashboard/RevenueDashboardContainerView'
import BulkProviderTransitionContainerView from './Components/Utilities/BulkProviderTransition/BulkProviderTransitionContainerView';

// Internal Dependencies - models/collections

export default Marionette.LayoutView.extend({

	regions: {
		mainContent: '.main-content-container'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel('AppChannel');
		this.app = this.channel.request( 'app' );
		this.listenTo(this.channel, 'change:content', this.whichContent);
	},

	getTemplate: function() {
		return contentView(this.model);
	},

	// Method for determining which content to show based on tab selected + route to which you've navigated
	whichContent: function( content = '') {
		switch ( content ) {
			case 'MAIN_DASHBOARD':
				this.showChildView('mainContent', new MainDashboardContainerView());
				break;
			case 'MAIN_NOTIFICATIONS':
				this.showChildView('mainContent', new MainNotificationsContainerView( { type: 'active' }));
				break;
			case 'MAIN_ARCHIVED_NOTIFICATIONS':
				this.showChildView('mainContent', new MainNotificationsContainerView( { type: 'archived' }));
				break;
			case 'MAIN_PRIORITY_NOTIFICATIONS':
				this.showChildView('mainContent', new MainNotificationsContainerView( { type: 'priority' }));
				break;
			case 'CCM_PATIENT_LIST':
				this.showChildView('mainContent', new CcmPatientListContainerView());
				break;
			case 'CCM_AUTO_ENROLL':
				this.showChildView('mainContent', new CcmAutoEnrollContainerView());
				break;
			case 'CCM_ENROLLED':
				this.showChildView('mainContent', new CcmEnrolledContainerView());
				break;
			case 'CCM_SUSPENDED':
				this.showChildView('mainContent', new CcmSuspendedContainerView());
				break;
			case 'CCM_SUPPORT':
				this.showChildView('mainContent', new CcmSupportConainerView());
				break;
			case 'CCM_UNVERIFIED':
				this.showChildView('mainContent', new CccmUnverifiedContainerView());
				break;
			case 'CCM_OPTED_OUT':
				this.showChildView('mainContent', new CcmOptedOutContainerView());
				break;
			case 'CCM_RAPID_BILL':
				this.showChildView('mainContent', new CcmRapidBillContainerView());
				break;
			case 'CCM_ERROR_QUEUE':
				this.showChildView('mainContent', new CcmErrorContainerView());
				break;
			case 'CCM_PENDING_REVIEW':
				this.showChildView('mainContent', new CcmPendingReviewContainerView());
				break;
			case 'CCM_RAPID_BILL_REPORTS':
				this.showChildView('mainContent', new CcmRapidBillReportsContainerView());
				break;
			case 'CCM_PENDING_UNENROLL':
				this.showChildView('mainContent', new CcmPendingUnenrollContainerView());
				break;
      case 'CCM_PROVIDER_APPROVAL':
        this.showChildView('mainContent', new CcmProviderApprovalContainerView());
        break;
			case 'CCM_UNENROLLED':
				this.showChildView('mainContent', new CcmUnenrolledContainerView());
				break;
			case 'CPC_PATIENT_LIST':
				this.showChildView('mainContent', new CpcPatientListView());
				break;
			case 'CPC_ECQM':
				this.showChildView('mainContent', new CpcEcqmContainerView());
				break;
			case 'CPC_DOCUMENTS':
				this.showChildView('mainContent', new CpcDocumentsContainerView());
				break;
			case 'MIPS_NEW_DASHBOARD':
				this.showChildView('mainContent', new MipsNewDashboardContainerView());
				break;
			case 'MIPS_DASHBOARD':
				this.showChildView('mainContent', new MipsDashboardContainerView());
				break;
			case 'MIPS_QUALITY_MEASURES':
				this.showChildView('mainContent', new MipsQualitySummaryContainerView());
				break;
            case 'MIPS_QUALITY_MEASURES_DIABETES_POOR_CONTROL':
                this.showChildView('mainContent', new MipsQualityMeasureContainerView( { measure: 'diabetes_poor_control', title: 'Diabetes Poor Control' } ));
                break;
			case 'MIPS_QUALITY_MEASURES_TOBACCO_USE':
				this.showChildView('mainContent', new MipsQualityMeasureContainerView( { measure: 'tobacco_use', title: 'Tobacco Use' } ));
				break;
			case 'MIPS_QUALITY_MEASURES_ADVANCE_CARE_PLAN':
				this.showChildView('mainContent', new MipsQualityMeasureContainerView( { measure: 'advance_care_plan', title: 'Advance Care Plan' } ));
				break;
            case 'MIPS_QUALITY_MEASURES_PNEUMOCOCCAL_VACCINATION':
                this.showChildView('mainContent', new MipsQualityMeasureContainerView( { measure: 'pneumococcal_vaccination_status', title: 'Pneumococcal Vaccination Status' } ));
                break;
            case 'MIPS_QUALITY_MEASURES_FALL_RISK_ASSESSMENT':
                this.showChildView('mainContent', new MipsQualityMeasureContainerView( { measure: 'falls_risk_assessment', title: 'Falls - Risk Assessment' } ));
                break;
            case 'MIPS_QUALITY_MEASURES_FALL_CARE_PLAN':
                this.showChildView('mainContent', new MipsQualityMeasureContainerView( { measure: 'falls_care_plan', title: 'Falls - Care Plan' } ));
                break;
			case 'MIPS_ADVANCING_CARE':
				this.showChildView('mainContent', new MipsAdvancingCareSummaryContainerView());
				break;
            case 'MIPS_ADVANCING_CARE_BASE_SCORE':
                this.showChildView('mainContent', new MipsAdvancingCareBaseScoreContainerView( { score: 'base_score', title: 'Base Score' } ));
                break;
            case 'MIPS_ADVANCING_CARE_PERFORMANCE_SCORE':
                this.showChildView('mainContent', new MipsAdvancingCarePerformanceScoreContainerView( { score: 'performance_score', title: 'Performance Score' } ));
                break;
            case 'MIPS_ADVANCING_CARE_BONUS_SCORE':
                this.showChildView('mainContent', new MipsAdvancingCareBonusScoreContainerView( { score: 'bonus_score', title: 'Bonus Score' } ));
                break;
			case 'MIPS_IMPROVEMENT_ACTIVITIES':
				this.showChildView('mainContent', new MipsImprovementActivitiesContainerView( { score: 'improvement_activities', title: 'Improvement Activities' } ));
				break;
            case 'PE_REPORTING':
                this.showChildView('mainContent', new PEReportingContainerView());
                break;
            case 'PE_FILE_MANAGER':
                this.showChildView('mainContent', new PEFileManagerContainerView());
                break;
			case 'PE_RAPID_REVIEW':
				this.showChildView('mainContent', new PERapidReviewContainerView());
				break;
			case 'PE_AWAITING_APPROVAL':
				this.showChildView('mainContent', new PEAwaitingApprovalContainerView());
				break;
            case 'PE_DECLINED':
                this.showChildView('mainContent', new PEDeclinedContainerView());
                break;
			case 'UTILITIES_HISTORICAL_BILLING':
				this.showChildView('mainContent', new UtilitiesHistoricalBillingContainerView());
				break;
			case 'UTILITIES_MANAGE_USERS':
				this.showChildView('mainContent', new UtilitiesManageUsersContainerView());
				break;
			case 'UTILITIES_RESOURCES':
				this.showChildView('mainContent', new UtilitiesResourcesContainerView());
				break;
			case 'UTILITIES_SUPPORT':
				this.showChildView('mainContent', new UtilitiesSupportContainerView());
				break;
      		case 'UTILITIES_CHANGE_PASSWORD':
				this.showChildView('mainContent', new UtilitiesChangePasswordContainerView());
				break;
			case 'UTILITIES_RESET_ENVIRONMENT':
        		this.showChildView('mainContent', new UtilitiesResetEnvironmentView());
        		break;
			case 'UTILITIES_UPLOAD':
				this.showChildView('mainContent', new UtilitiesUploadContainerView());
				break;
			case 'UTILITIES_BULK_PROVIDER_CHANGE':
				this.showChildView('mainContent', new UtilitiesBulkProviderChangeContainerView());
				break;
			case 'UTILITIES_BULK_PROVIDER_SUSPENSION':
				this.showChildView('mainContent', new UtilitiesBulkProviderSuspensionContainerView());
				break;
			case 'UTILITIES_BULK_PROVIDER_TRANSITION':
				this.showChildView('mainContent', new BulkProviderTransitionContainerView());
				break;
			case 'CCM_REVENUE_DASHBOARD':
				this.showChildView('mainContent', new RevenueDashboardContainerView());
				break;
			case 'CCM_PROVIDER_TRANSITION':
				this.showChildView('mainContent', new CcmProviderTransitionContainerView());
				break;
			default:
				break;
		}
	}

});
