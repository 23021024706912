// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

// Internal Dependencies - screens/views/templates
import  providerAssociationView  from '../../../../templates/Utilities/ManageUsers/providerAssociationView';
import ProviderAssociationDropdownContainerView from './ProviderAssociationDropdownContainerView';
import AddProviderButtonView from './AddProviderButtonView';

// Internal Dependencies - models/collections
import { AssociatedProvidersCollection } from './models/AssociatedProvidersCollection';

export default Marionette.LayoutView.extend({

	className: 'col m12',

	regions: {
		providerAssociationSelectContainer: '.provider-association-select-collection-container',
		addProviderButtonContainer: '.add-provider-button-container'
	},

	events: {
		'click #selectAllProviders': 'determineProvidersDropdown',
		'click .add-provider-button': 'addProvider'
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.collection = new AssociatedProvidersCollection();
		this.collection.setInitialProviders( this.model );
		this.channel.reply( 'AssociatedProvidersCollection', () => this.collection );
		this.listenTo( this.collection, 'update', this.allProvidersSelected )
	},

	getTemplate: function() {
		return providerAssociationView( this.model );
	},

	onShow: function() {

		// If the model's 'associatedProviders' attribute has a value of -1 in the array (all providers), the
		// 'Associate All Providers With This Nurse' checkbox is checked, otherwise it stays unchecked
		if ( this.model.get( 'associatedProviders' ).indexOf( -1 ) > -1 ) {
			$('#selectAllProviders').prop( 'checked', true );
		}
		this.allProvidersSelected();
		this.determineProvidersDropdown();
	},

	allProvidersSelected: function() {
		if ( this.collection.providerList.length == this.collection.length || $('#selectAllProviders').prop( 'checked' ) ) {
			this.getRegion( 'addProviderButtonContainer' ).empty();
		} else {
			this.showChildView( 'addProviderButtonContainer', new AddProviderButtonView( {model: this.model, collection: this.collection} ) );
		}
	},

	addProvider: function() {
		this.collection.addProvider();
	},

	determineProvidersDropdown: function() {

		// If the 'Associate All Providers With This Nurse' checkbox is not checked, the region to select providers is populated,
		// if it is checked, the region is emptied
		if ( $('#selectAllProviders').prop( 'checked' ) ) {
			this.getRegion( 'providerAssociationSelectContainer' ).empty();
		} else {
			this.showChildView( 'providerAssociationSelectContainer', new ProviderAssociationDropdownContainerView( {model: this.model, collection: this.collection} ) );
		}
		this.allProvidersSelected()
	}

});
