// External Dependencies
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import _ from 'underscore';

// Internal Dependencies - screens/views/templates
import  errorCompositeView  from '../../../../../templates/CCM/RapidBill/Error/errorCompositeView';
import ErrorItemView from './ErrorItemView';

// Internal Dependencies - models/collections
import { ErrorCollection } from './models/ErrorCollection';
import { RapidBillModel } from '../models/RapidBillModel';

export default Marionette.CompositeView.extend({

	childView: ErrorItemView,

	childViewContainer: '.error-collection-container',

	className: '.error-composite-view striped',

	events: {
		'click #selectAll': 'selectAll',
		'click .download-list': 'downloadCSV',
	},

	initialize: function() {
		this.channel = Backbone.Radio.channel( 'AppChannel' );
		this.app = this.channel.request( 'app' );
		this.currentPage = 0;
		this.pageSize = 20;


		this.app.ccm_controller.getErrorList();
		this.listenTo( this.channel, 'rerender', this.render);
		this.listenTo( this.channel, 'set:patientList', this.setErrorList );
		this.listenTo( this.collection, 'update', this.render );
		this.listenTo( this.channel, 'set:selectedProviderId', this.getErrorList );
		this.listenTo( this.channel, 'reset:currentPage', this.resetCurrentPage.bind( this ) );
		
		this.collection.loading = true;
		this.channel.reply( 'currentPage', () => {
			return this.currentPage;
		});
		this.channel.reply( 'pageSize', () => {
			return this.pageSize;
		});

		$('.content-container').addClass('widen');
	},

	getTemplate: function() {
		return errorCompositeView( this.collection );
	},

	getErrorList: function() {
		this.collection.loading = true;
		this.render();
		this.app.ccm_controller.getErrorList('reset:currentPage');
	},
	setErrorList: function() {
		this.collection.loading = false;
		this.render();
		this.channel.trigger( 'show:contentSpinner' );
		this.collection.reset();
		let errorList = this.app.ccm_controller.patientList;
		errorList = _.map( errorList, (patient ) => new RapidBillModel( Object.assign({},patient) ) );
		this.collection.renderNoResults = errorList && errorList.length ? false : true;
		let search = this.app.ccm_controller.searchInput;
		this.collection.search = search ? search.substring( search.lastIndexOf("=") + 1 ) : '';
		this.collection.add( errorList );
		this.channel.trigger( 'set:rapidBilling', this.collection );
		this.collection.checkSelected();
		this.channel.trigger( 'hide:contentSpinner' );
		errorList.length && $( '.has-billable-patients' ).removeClass( 'hide' );
		!errorList.length && this.render();
	},

	resetCurrentPage: function() {
		this.currentPage = 0;
		this.app.ccm_controller.patients.currentPage = this.currentPage;
		this.channel.trigger( 'reset:totals' );
	},

	selectAll: function() {
		let shouldSelect = $( '#selectAll' ).prop( 'checked' );
		this.collection.selectAll( shouldSelect );
	},

	downloadCSV: function() {
		let billable_status = 'ERROR';
		this.collection.downloadCSV( billable_status );
	},

	onDestroy: function() {
		this.channel.trigger( 'remove:search' );
		this.app.ccm_controller.clearValue( 'patientList' );
		$('.content-container').removeClass('widen');
	},

});
